/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.4.2 - https://www.flaticon.com/uicons/interface-icons
*/
@font-face {
    font-family: "uicons-thin-straight";
    src: url("../../webfonts/uicons-thin-straight.woff2") format("woff2"),
    url("../../webfonts/uicons-thin-straight.woff") format("woff"),
    url("../../webfonts/uicons-thin-straight.eot#iefix") format("embedded-opentype");
    font-display: swap;
}
    i[class^="fi-ts-"]:before, i[class*=" fi-ts-"]:before, span[class^="fi-ts-"]:before, span[class*="fi-ts-"]:before {
font-family: uicons-thin-straight !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .variable-selector-00:before {
    content: "\fe00";
    }
        .variable-selector-01:before {
    content: "\fe01";
    }
        .variable-selector-02:before {
    content: "\fe02";
    }
        .variable-selector-03:before {
    content: "\fe03";
    }
        .variable-selector-04:before {
    content: "\fe04";
    }
        .variable-selector-05:before {
    content: "\fe05";
    }
        .variable-selector-06:before {
    content: "\fe06";
    }
        .variable-selector-07:before {
    content: "\fe07";
    }
        .variable-selector-08:before {
    content: "\fe08";
    }
        .variable-selector-09:before {
    content: "\fe09";
    }
        .variable-selector-0a:before {
    content: "\fe0a";
    }
        .variable-selector-0b:before {
    content: "\fe0b";
    }
        .variable-selector-0c:before {
    content: "\fe0c";
    }
        .variable-selector-0d:before {
    content: "\fe0d";
    }
        .variable-selector-0e:before {
    content: "\fe0e";
    }
        .variable-selector-0f:before {
    content: "\fe0f";
    }
        .combining-half-marks-00:before {
    content: "\fe20";
    }
        .combining-half-marks-01:before {
    content: "\fe21";
    }
        .combining-half-marks-02:before {
    content: "\fe22";
    }
        .combining-half-marks-03:before {
    content: "\fe23";
    }
        .combining-half-marks-04:before {
    content: "\fe24";
    }
        .combining-half-marks-05:before {
    content: "\fe25";
    }
        .combining-half-marks-06:before {
    content: "\fe26";
    }
        .combining-half-marks-07:before {
    content: "\fe27";
    }
        .combining-half-marks-08:before {
    content: "\fe28";
    }
        .combining-half-marks-09:before {
    content: "\fe29";
    }
        .combining-half-marks-0a:before {
    content: "\fe2a";
    }
        .combining-half-marks-0b:before {
    content: "\fe2b";
    }
        .combining-half-marks-0c:before {
    content: "\fe2c";
    }
        .combining-half-marks-0d:before {
    content: "\fe2d";
    }
        .combining-half-marks-0e:before {
    content: "\fe2e";
    }
        .combining-half-marks-0f:before {
    content: "\fe2f";
    }
        .zero-width-no-break-space-0f:before {
    content: "\feff";
    }
        .fi-ts-0:before {
    content: "\f101";
    }
        .fi-ts-00s-music-disc:before {
    content: "\f102";
    }
        .fi-ts-1:before {
    content: "\f103";
    }
        .fi-ts-2:before {
    content: "\f104";
    }
        .fi-ts-3:before {
    content: "\f105";
    }
        .fi-ts-360-degrees:before {
    content: "\f106";
    }
        .fi-ts-4:before {
    content: "\f107";
    }
        .fi-ts-404:before {
    content: "\f108";
    }
        .fi-ts-5:before {
    content: "\f109";
    }
        .fi-ts-6:before {
    content: "\f10a";
    }
        .fi-ts-60s-music-disc:before {
    content: "\f10b";
    }
        .fi-ts-7:before {
    content: "\f10c";
    }
        .fi-ts-70s-music-disc:before {
    content: "\f10d";
    }
        .fi-ts-8:before {
    content: "\f10e";
    }
        .fi-ts-80s-music-disc:before {
    content: "\f10f";
    }
        .fi-ts-9:before {
    content: "\f110";
    }
        .fi-ts-90s-music-disc:before {
    content: "\f111";
    }
        .fi-ts-a:before {
    content: "\f112";
    }
        .fi-ts-accident:before {
    content: "\f113";
    }
        .fi-ts-acorn:before {
    content: "\f114";
    }
        .fi-ts-ad-paid:before {
    content: "\f115";
    }
        .fi-ts-ad:before {
    content: "\f116";
    }
        .fi-ts-add-document:before {
    content: "\f117";
    }
        .fi-ts-add-folder:before {
    content: "\f118";
    }
        .fi-ts-add-image:before {
    content: "\f119";
    }
        .fi-ts-add:before {
    content: "\f11a";
    }
        .fi-ts-address-book:before {
    content: "\f11b";
    }
        .fi-ts-address-card:before {
    content: "\f11c";
    }
        .fi-ts-admin-alt:before {
    content: "\f11d";
    }
        .fi-ts-admin:before {
    content: "\f11e";
    }
        .fi-ts-age-alt:before {
    content: "\f11f";
    }
        .fi-ts-age-restriction-eighteen:before {
    content: "\f120";
    }
        .fi-ts-age-restriction-seven:before {
    content: "\f121";
    }
        .fi-ts-age-restriction-six:before {
    content: "\f122";
    }
        .fi-ts-age-restriction-sixteen:before {
    content: "\f123";
    }
        .fi-ts-age-restriction-thirteen:before {
    content: "\f124";
    }
        .fi-ts-age-restriction-three:before {
    content: "\f125";
    }
        .fi-ts-age-restriction-twelve:before {
    content: "\f126";
    }
        .fi-ts-age-restriction-twenty-one:before {
    content: "\f127";
    }
        .fi-ts-age-restriction-zero:before {
    content: "\f128";
    }
        .fi-ts-age:before {
    content: "\f129";
    }
        .fi-ts-air-conditioner:before {
    content: "\f12a";
    }
        .fi-ts-air-freshener:before {
    content: "\f12b";
    }
        .fi-ts-air-pollution:before {
    content: "\f12c";
    }
        .fi-ts-airplane-journey:before {
    content: "\f12d";
    }
        .fi-ts-airplane-window-open:before {
    content: "\f12e";
    }
        .fi-ts-airplay:before {
    content: "\f12f";
    }
        .fi-ts-alarm-clock:before {
    content: "\f130";
    }
        .fi-ts-alarm-exclamation:before {
    content: "\f131";
    }
        .fi-ts-alarm-plus:before {
    content: "\f132";
    }
        .fi-ts-alarm-snooze:before {
    content: "\f133";
    }
        .fi-ts-album-circle-plus:before {
    content: "\f134";
    }
        .fi-ts-album-circle-user:before {
    content: "\f135";
    }
        .fi-ts-album-collection:before {
    content: "\f136";
    }
        .fi-ts-album:before {
    content: "\f137";
    }
        .fi-ts-algorithm:before {
    content: "\f138";
    }
        .fi-ts-alicorn:before {
    content: "\f139";
    }
        .fi-ts-alien:before {
    content: "\f13a";
    }
        .fi-ts-align-slash:before {
    content: "\f13b";
    }
        .fi-ts-ambulance:before {
    content: "\f13c";
    }
        .fi-ts-analyse-alt:before {
    content: "\f13d";
    }
        .fi-ts-analyse:before {
    content: "\f13e";
    }
        .fi-ts-anatomical-heart:before {
    content: "\f13f";
    }
        .fi-ts-anchor:before {
    content: "\f140";
    }
        .fi-ts-angel:before {
    content: "\f141";
    }
        .fi-ts-angle-90:before {
    content: "\f142";
    }
        .fi-ts-angle-circle-down:before {
    content: "\f143";
    }
        .fi-ts-angle-circle-left:before {
    content: "\f144";
    }
        .fi-ts-angle-circle-right:before {
    content: "\f145";
    }
        .fi-ts-angle-circle-up:before {
    content: "\f146";
    }
        .fi-ts-angle-double-left:before {
    content: "\f147";
    }
        .fi-ts-angle-double-right:before {
    content: "\f148";
    }
        .fi-ts-angle-double-small-down:before {
    content: "\f149";
    }
        .fi-ts-angle-double-small-left:before {
    content: "\f14a";
    }
        .fi-ts-angle-double-small-right:before {
    content: "\f14b";
    }
        .fi-ts-angle-double-small-up:before {
    content: "\f14c";
    }
        .fi-ts-angle-down:before {
    content: "\f14d";
    }
        .fi-ts-angle-left:before {
    content: "\f14e";
    }
        .fi-ts-angle-right:before {
    content: "\f14f";
    }
        .fi-ts-angle-small-down:before {
    content: "\f150";
    }
        .fi-ts-angle-small-left:before {
    content: "\f151";
    }
        .fi-ts-angle-small-right:before {
    content: "\f152";
    }
        .fi-ts-angle-small-up:before {
    content: "\f153";
    }
        .fi-ts-angle-square-down:before {
    content: "\f154";
    }
        .fi-ts-angle-square-left:before {
    content: "\f155";
    }
        .fi-ts-angle-square-right:before {
    content: "\f156";
    }
        .fi-ts-angle-square-up:before {
    content: "\f157";
    }
        .fi-ts-angle-up:before {
    content: "\f158";
    }
        .fi-ts-angle:before {
    content: "\f159";
    }
        .fi-ts-angles-up-down:before {
    content: "\f15a";
    }
        .fi-ts-angry:before {
    content: "\f15b";
    }
        .fi-ts-animated-icon:before {
    content: "\f15c";
    }
        .fi-ts-ankh:before {
    content: "\f15d";
    }
        .fi-ts-answer-alt:before {
    content: "\f15e";
    }
        .fi-ts-answer:before {
    content: "\f15f";
    }
        .fi-ts-apartment:before {
    content: "\f160";
    }
        .fi-ts-aperture:before {
    content: "\f161";
    }
        .fi-ts-api:before {
    content: "\f162";
    }
        .fi-ts-apple-core:before {
    content: "\f163";
    }
        .fi-ts-apple-crate:before {
    content: "\f164";
    }
        .fi-ts-apple:before {
    content: "\f165";
    }
        .fi-ts-archway:before {
    content: "\f166";
    }
        .fi-ts-arrow-alt-circle-down:before {
    content: "\f167";
    }
        .fi-ts-arrow-alt-circle-left:before {
    content: "\f168";
    }
        .fi-ts-arrow-alt-circle-right:before {
    content: "\f169";
    }
        .fi-ts-arrow-alt-circle-up:before {
    content: "\f16a";
    }
        .fi-ts-arrow-alt-down:before {
    content: "\f16b";
    }
        .fi-ts-arrow-alt-from-bottom:before {
    content: "\f16c";
    }
        .fi-ts-arrow-alt-from-left:before {
    content: "\f16d";
    }
        .fi-ts-arrow-alt-from-right:before {
    content: "\f16e";
    }
        .fi-ts-arrow-alt-from-top:before {
    content: "\f16f";
    }
        .fi-ts-arrow-alt-left:before {
    content: "\f170";
    }
        .fi-ts-arrow-alt-right:before {
    content: "\f171";
    }
        .fi-ts-arrow-alt-square-down:before {
    content: "\f172";
    }
        .fi-ts-arrow-alt-square-left:before {
    content: "\f173";
    }
        .fi-ts-arrow-alt-square-right:before {
    content: "\f174";
    }
        .fi-ts-arrow-alt-square-up:before {
    content: "\f175";
    }
        .fi-ts-arrow-alt-to-bottom:before {
    content: "\f176";
    }
        .fi-ts-arrow-alt-to-left:before {
    content: "\f177";
    }
        .fi-ts-arrow-alt-to-right:before {
    content: "\f178";
    }
        .fi-ts-arrow-alt-to-top:before {
    content: "\f179";
    }
        .fi-ts-arrow-alt-up:before {
    content: "\f17a";
    }
        .fi-ts-arrow-circle-down:before {
    content: "\f17b";
    }
        .fi-ts-arrow-circle-left:before {
    content: "\f17c";
    }
        .fi-ts-arrow-circle-right:before {
    content: "\f17d";
    }
        .fi-ts-arrow-circle-up:before {
    content: "\f17e";
    }
        .fi-ts-arrow-comparison:before {
    content: "\f17f";
    }
        .fi-ts-arrow-down-from-arc:before {
    content: "\f180";
    }
        .fi-ts-arrow-down-from-dotted-line:before {
    content: "\f181";
    }
        .fi-ts-arrow-down-left:before {
    content: "\f182";
    }
        .fi-ts-arrow-down-small-big:before {
    content: "\f183";
    }
        .fi-ts-arrow-down-to-dotted-line:before {
    content: "\f184";
    }
        .fi-ts-arrow-down-to-square:before {
    content: "\f185";
    }
        .fi-ts-arrow-down-triangle-square:before {
    content: "\f186";
    }
        .fi-ts-arrow-down:before {
    content: "\f187";
    }
        .fi-ts-arrow-from-bottom:before {
    content: "\f188";
    }
        .fi-ts-arrow-from-left:before {
    content: "\f189";
    }
        .fi-ts-arrow-from-right:before {
    content: "\f18a";
    }
        .fi-ts-arrow-from-top:before {
    content: "\f18b";
    }
        .fi-ts-arrow-left-from-arc:before {
    content: "\f18c";
    }
        .fi-ts-arrow-left-from-line:before {
    content: "\f18d";
    }
        .fi-ts-arrow-left-to-arc:before {
    content: "\f18e";
    }
        .fi-ts-arrow-left:before {
    content: "\f18f";
    }
        .fi-ts-arrow-progress-alt:before {
    content: "\f190";
    }
        .fi-ts-arrow-progress:before {
    content: "\f191";
    }
        .fi-ts-arrow-right-to-bracket:before {
    content: "\f192";
    }
        .fi-ts-arrow-right:before {
    content: "\f193";
    }
        .fi-ts-arrow-small-down:before {
    content: "\f194";
    }
        .fi-ts-arrow-small-left:before {
    content: "\f195";
    }
        .fi-ts-arrow-small-right:before {
    content: "\f196";
    }
        .fi-ts-arrow-small-up:before {
    content: "\f197";
    }
        .fi-ts-arrow-square-down:before {
    content: "\f198";
    }
        .fi-ts-arrow-square-left:before {
    content: "\f199";
    }
        .fi-ts-arrow-square-right:before {
    content: "\f19a";
    }
        .fi-ts-arrow-square-up:before {
    content: "\f19b";
    }
        .fi-ts-arrow-to-bottom:before {
    content: "\f19c";
    }
        .fi-ts-arrow-to-left:before {
    content: "\f19d";
    }
        .fi-ts-arrow-to-right:before {
    content: "\f19e";
    }
        .fi-ts-arrow-to-top:before {
    content: "\f19f";
    }
        .fi-ts-arrow-trend-down:before {
    content: "\f1a0";
    }
        .fi-ts-arrow-trend-up:before {
    content: "\f1a1";
    }
        .fi-ts-arrow-turn-down-left:before {
    content: "\f1a2";
    }
        .fi-ts-arrow-turn-down-right:before {
    content: "\f1a3";
    }
        .fi-ts-arrow-turn-left-up:before {
    content: "\f1a4";
    }
        .fi-ts-arrow-up-from-dotted-line:before {
    content: "\f1a5";
    }
        .fi-ts-arrow-up-from-ground-water:before {
    content: "\f1a6";
    }
        .fi-ts-arrow-up-from-square:before {
    content: "\f1a7";
    }
        .fi-ts-arrow-up-left-from-circle:before {
    content: "\f1a8";
    }
        .fi-ts-arrow-up-left:before {
    content: "\f1a9";
    }
        .fi-ts-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f1aa";
    }
        .fi-ts-arrow-up-right-from-square:before {
    content: "\f1ab";
    }
        .fi-ts-arrow-up-right:before {
    content: "\f1ac";
    }
        .fi-ts-arrow-up-small-big:before {
    content: "\f1ad";
    }
        .fi-ts-arrow-up-square-triangle:before {
    content: "\f1ae";
    }
        .fi-ts-arrow-up-to-arc:before {
    content: "\f1af";
    }
        .fi-ts-arrow-up-to-dotted-line:before {
    content: "\f1b0";
    }
        .fi-ts-arrow-up:before {
    content: "\f1b1";
    }
        .fi-ts-arrow-upward-growth-crypto:before {
    content: "\f1b2";
    }
        .fi-ts-arrows-alt-h:before {
    content: "\f1b3";
    }
        .fi-ts-arrows-alt-v:before {
    content: "\f1b4";
    }
        .fi-ts-arrows-alt:before {
    content: "\f1b5";
    }
        .fi-ts-arrows-cross:before {
    content: "\f1b6";
    }
        .fi-ts-arrows-from-dotted-line:before {
    content: "\f1b7";
    }
        .fi-ts-arrows-from-line:before {
    content: "\f1b8";
    }
        .fi-ts-arrows-h-copy:before {
    content: "\f1b9";
    }
        .fi-ts-arrows-h:before {
    content: "\f1ba";
    }
        .fi-ts-arrows-repeat-1:before {
    content: "\f1bb";
    }
        .fi-ts-arrows-repeat:before {
    content: "\f1bc";
    }
        .fi-ts-arrows-retweet:before {
    content: "\f1bd";
    }
        .fi-ts-arrows-split-right-and-left:before {
    content: "\f1be";
    }
        .fi-ts-arrows-split-up-and-left:before {
    content: "\f1bf";
    }
        .fi-ts-arrows-to-circle:before {
    content: "\f1c0";
    }
        .fi-ts-arrows-to-dotted-line:before {
    content: "\f1c1";
    }
        .fi-ts-arrows-to-eye:before {
    content: "\f1c2";
    }
        .fi-ts-arrows-to-line:before {
    content: "\f1c3";
    }
        .fi-ts-arrows:before {
    content: "\f1c4";
    }
        .fi-ts-artificial-intelligence:before {
    content: "\f1c5";
    }
        .fi-ts-assept-document:before {
    content: "\f1c6";
    }
        .fi-ts-assessment-alt:before {
    content: "\f1c7";
    }
        .fi-ts-assessment:before {
    content: "\f1c8";
    }
        .fi-ts-assign:before {
    content: "\f1c9";
    }
        .fi-ts-assistive-listening-systems:before {
    content: "\f1ca";
    }
        .fi-ts-asterik:before {
    content: "\f1cb";
    }
        .fi-ts-at:before {
    content: "\f1cc";
    }
        .fi-ts-attribution-pen:before {
    content: "\f1cd";
    }
        .fi-ts-attribution-pencil:before {
    content: "\f1ce";
    }
        .fi-ts-aubergine:before {
    content: "\f1cf";
    }
        .fi-ts-auction:before {
    content: "\f1d0";
    }
        .fi-ts-audio-description-slash:before {
    content: "\f1d1";
    }
        .fi-ts-audit-alt:before {
    content: "\f1d2";
    }
        .fi-ts-audit:before {
    content: "\f1d3";
    }
        .fi-ts-austral-sign:before {
    content: "\f1d4";
    }
        .fi-ts-avocado:before {
    content: "\f1d5";
    }
        .fi-ts-award:before {
    content: "\f1d6";
    }
        .fi-ts-axe-battle:before {
    content: "\f1d7";
    }
        .fi-ts-axe:before {
    content: "\f1d8";
    }
        .fi-ts-b:before {
    content: "\f1d9";
    }
        .fi-ts-baby-carriage:before {
    content: "\f1da";
    }
        .fi-ts-baby:before {
    content: "\f1db";
    }
        .fi-ts-back-up:before {
    content: "\f1dc";
    }
        .fi-ts-background:before {
    content: "\f1dd";
    }
        .fi-ts-backpack:before {
    content: "\f1de";
    }
        .fi-ts-bacon:before {
    content: "\f1df";
    }
        .fi-ts-bacteria:before {
    content: "\f1e0";
    }
        .fi-ts-bacterium:before {
    content: "\f1e1";
    }
        .fi-ts-badge-check:before {
    content: "\f1e2";
    }
        .fi-ts-badge-dollar:before {
    content: "\f1e3";
    }
        .fi-ts-badge-leaf:before {
    content: "\f1e4";
    }
        .fi-ts-badge-percent:before {
    content: "\f1e5";
    }
        .fi-ts-badge-sheriff:before {
    content: "\f1e6";
    }
        .fi-ts-badge:before {
    content: "\f1e7";
    }
        .fi-ts-badger-honey:before {
    content: "\f1e8";
    }
        .fi-ts-badget-check-alt:before {
    content: "\f1e9";
    }
        .fi-ts-badminton:before {
    content: "\f1ea";
    }
        .fi-ts-bag-map-pin:before {
    content: "\f1eb";
    }
        .fi-ts-bag-seedling:before {
    content: "\f1ec";
    }
        .fi-ts-bag-shopping-minus:before {
    content: "\f1ed";
    }
        .fi-ts-bags-shopping:before {
    content: "\f1ee";
    }
        .fi-ts-baguette:before {
    content: "\f1ef";
    }
        .fi-ts-bahai:before {
    content: "\f1f0";
    }
        .fi-ts-baht-sign:before {
    content: "\f1f1";
    }
        .fi-ts-balance-scale-left:before {
    content: "\f1f2";
    }
        .fi-ts-balance-scale-right:before {
    content: "\f1f3";
    }
        .fi-ts-ball-pile:before {
    content: "\f1f4";
    }
        .fi-ts-ballet-dance:before {
    content: "\f1f5";
    }
        .fi-ts-balloon:before {
    content: "\f1f6";
    }
        .fi-ts-balloons:before {
    content: "\f1f7";
    }
        .fi-ts-ballot-check:before {
    content: "\f1f8";
    }
        .fi-ts-ballot:before {
    content: "\f1f9";
    }
        .fi-ts-ban-bug:before {
    content: "\f1fa";
    }
        .fi-ts-banana:before {
    content: "\f1fb";
    }
        .fi-ts-band-aid:before {
    content: "\f1fc";
    }
        .fi-ts-bangladeshi-taka-sign:before {
    content: "\f1fd";
    }
        .fi-ts-banjo:before {
    content: "\f1fe";
    }
        .fi-ts-bank:before {
    content: "\f1ff";
    }
        .fi-ts-banner-2:before {
    content: "\f200";
    }
        .fi-ts-banner-3:before {
    content: "\f201";
    }
        .fi-ts-banner-4:before {
    content: "\f202";
    }
        .fi-ts-banner-5:before {
    content: "\f203";
    }
        .fi-ts-banner:before {
    content: "\f204";
    }
        .fi-ts-barber-pole:before {
    content: "\f205";
    }
        .fi-ts-barber-shop:before {
    content: "\f206";
    }
        .fi-ts-barcode-read:before {
    content: "\f207";
    }
        .fi-ts-barcode-scan:before {
    content: "\f208";
    }
        .fi-ts-barcode:before {
    content: "\f209";
    }
        .fi-ts-bars-filter:before {
    content: "\f20a";
    }
        .fi-ts-bars-progress:before {
    content: "\f20b";
    }
        .fi-ts-bars-sort:before {
    content: "\f20c";
    }
        .fi-ts-bars-staggered:before {
    content: "\f20d";
    }
        .fi-ts-baseball-alt:before {
    content: "\f20e";
    }
        .fi-ts-basket-shopping-minus:before {
    content: "\f20f";
    }
        .fi-ts-basket-shopping-plus:before {
    content: "\f210";
    }
        .fi-ts-basket-shopping-simple:before {
    content: "\f211";
    }
        .fi-ts-basketball-hoop:before {
    content: "\f212";
    }
        .fi-ts-bat:before {
    content: "\f213";
    }
        .fi-ts-bath:before {
    content: "\f214";
    }
        .fi-ts-battery-bolt:before {
    content: "\f215";
    }
        .fi-ts-battery-empty:before {
    content: "\f216";
    }
        .fi-ts-battery-exclamation:before {
    content: "\f217";
    }
        .fi-ts-battery-full:before {
    content: "\f218";
    }
        .fi-ts-battery-half:before {
    content: "\f219";
    }
        .fi-ts-battery-quarter:before {
    content: "\f21a";
    }
        .fi-ts-battery-slash:before {
    content: "\f21b";
    }
        .fi-ts-battery-three-quarters:before {
    content: "\f21c";
    }
        .fi-ts-beacon:before {
    content: "\f21d";
    }
        .fi-ts-bed-alt:before {
    content: "\f21e";
    }
        .fi-ts-bed-bunk:before {
    content: "\f21f";
    }
        .fi-ts-bed-empty:before {
    content: "\f220";
    }
        .fi-ts-bee:before {
    content: "\f221";
    }
        .fi-ts-beer-mug-empty:before {
    content: "\f222";
    }
        .fi-ts-beer:before {
    content: "\f223";
    }
        .fi-ts-bell-concierge:before {
    content: "\f224";
    }
        .fi-ts-bell-ring:before {
    content: "\f225";
    }
        .fi-ts-bell-school-slash:before {
    content: "\f226";
    }
        .fi-ts-bell-school:before {
    content: "\f227";
    }
        .fi-ts-bell-slash:before {
    content: "\f228";
    }
        .fi-ts-bells:before {
    content: "\f229";
    }
        .fi-ts-bench-tree:before {
    content: "\f22a";
    }
        .fi-ts-benefit-diamond-alt:before {
    content: "\f22b";
    }
        .fi-ts-benefit-diamond:before {
    content: "\f22c";
    }
        .fi-ts-benefit-hand:before {
    content: "\f22d";
    }
        .fi-ts-benefit-increase:before {
    content: "\f22e";
    }
        .fi-ts-benefit-porcent:before {
    content: "\f22f";
    }
        .fi-ts-benefit:before {
    content: "\f230";
    }
        .fi-ts-betamax:before {
    content: "\f231";
    }
        .fi-ts-bible:before {
    content: "\f232";
    }
        .fi-ts-bicycle-journey:before {
    content: "\f233";
    }
        .fi-ts-biking-mountain:before {
    content: "\f234";
    }
        .fi-ts-biking:before {
    content: "\f235";
    }
        .fi-ts-bill-arrow:before {
    content: "\f236";
    }
        .fi-ts-bin-bottles:before {
    content: "\f237";
    }
        .fi-ts-binary-circle-check:before {
    content: "\f238";
    }
        .fi-ts-binary-lock:before {
    content: "\f239";
    }
        .fi-ts-binary-slash:before {
    content: "\f23a";
    }
        .fi-ts-binary:before {
    content: "\f23b";
    }
        .fi-ts-binoculars:before {
    content: "\f23c";
    }
        .fi-ts-bio-leaves:before {
    content: "\f23d";
    }
        .fi-ts-bio:before {
    content: "\f23e";
    }
        .fi-ts-biohazard:before {
    content: "\f23f";
    }
        .fi-ts-bird:before {
    content: "\f240";
    }
        .fi-ts-bitcoin-sign:before {
    content: "\f241";
    }
        .fi-ts-blanket:before {
    content: "\f242";
    }
        .fi-ts-blender-phone:before {
    content: "\f243";
    }
        .fi-ts-blender:before {
    content: "\f244";
    }
        .fi-ts-blinds-open:before {
    content: "\f245";
    }
        .fi-ts-blinds-raised:before {
    content: "\f246";
    }
        .fi-ts-blinds:before {
    content: "\f247";
    }
        .fi-ts-block-brick:before {
    content: "\f248";
    }
        .fi-ts-block-microphone:before {
    content: "\f249";
    }
        .fi-ts-block-question:before {
    content: "\f24a";
    }
        .fi-ts-block-quote:before {
    content: "\f24b";
    }
        .fi-ts-block:before {
    content: "\f24c";
    }
        .fi-ts-blockchain-3:before {
    content: "\f24d";
    }
        .fi-ts-blog-pencil:before {
    content: "\f24e";
    }
        .fi-ts-blog-text:before {
    content: "\f24f";
    }
        .fi-ts-blood-dropper:before {
    content: "\f250";
    }
        .fi-ts-blood-test-tube-alt:before {
    content: "\f251";
    }
        .fi-ts-blood-test-tube:before {
    content: "\f252";
    }
        .fi-ts-blood:before {
    content: "\f253";
    }
        .fi-ts-blueberries:before {
    content: "\f254";
    }
        .fi-ts-blueprint:before {
    content: "\f255";
    }
        .fi-ts-bolt-auto:before {
    content: "\f256";
    }
        .fi-ts-bolt-slash:before {
    content: "\f257";
    }
        .fi-ts-bolt:before {
    content: "\f258";
    }
        .fi-ts-bomb:before {
    content: "\f259";
    }
        .fi-ts-bone-break:before {
    content: "\f25a";
    }
        .fi-ts-bone:before {
    content: "\f25b";
    }
        .fi-ts-bong:before {
    content: "\f25c";
    }
        .fi-ts-bonus-alt:before {
    content: "\f25d";
    }
        .fi-ts-bonus-star:before {
    content: "\f25e";
    }
        .fi-ts-bonus:before {
    content: "\f25f";
    }
        .fi-ts-book-alt:before {
    content: "\f260";
    }
        .fi-ts-book-arrow-right:before {
    content: "\f261";
    }
        .fi-ts-book-arrow-up:before {
    content: "\f262";
    }
        .fi-ts-book-atlas:before {
    content: "\f263";
    }
        .fi-ts-book-bookmark:before {
    content: "\f264";
    }
        .fi-ts-book-circle-arrow-right:before {
    content: "\f265";
    }
        .fi-ts-book-circle-arrow-up:before {
    content: "\f266";
    }
        .fi-ts-book-copy:before {
    content: "\f267";
    }
        .fi-ts-book-dead:before {
    content: "\f268";
    }
        .fi-ts-book-font:before {
    content: "\f269";
    }
        .fi-ts-book-heart:before {
    content: "\f26a";
    }
        .fi-ts-book-medical:before {
    content: "\f26b";
    }
        .fi-ts-book-open-cover:before {
    content: "\f26c";
    }
        .fi-ts-book-open-reader:before {
    content: "\f26d";
    }
        .fi-ts-book-plus:before {
    content: "\f26e";
    }
        .fi-ts-book-quran:before {
    content: "\f26f";
    }
        .fi-ts-book-section:before {
    content: "\f270";
    }
        .fi-ts-book-spells:before {
    content: "\f271";
    }
        .fi-ts-book-tanakh:before {
    content: "\f272";
    }
        .fi-ts-book-user:before {
    content: "\f273";
    }
        .fi-ts-Booking:before {
    content: "\f274";
    }
        .fi-ts-bookmark-slash:before {
    content: "\f275";
    }
        .fi-ts-books-medical:before {
    content: "\f276";
    }
        .fi-ts-books:before {
    content: "\f277";
    }
        .fi-ts-boot-heeled:before {
    content: "\f278";
    }
        .fi-ts-boot:before {
    content: "\f279";
    }
        .fi-ts-booth-curtain:before {
    content: "\f27a";
    }
        .fi-ts-border-all:before {
    content: "\f27b";
    }
        .fi-ts-border-bottom:before {
    content: "\f27c";
    }
        .fi-ts-border-center-h:before {
    content: "\f27d";
    }
        .fi-ts-border-center-v:before {
    content: "\f27e";
    }
        .fi-ts-border-inner:before {
    content: "\f27f";
    }
        .fi-ts-border-left:before {
    content: "\f280";
    }
        .fi-ts-border-none:before {
    content: "\f281";
    }
        .fi-ts-border-outer:before {
    content: "\f282";
    }
        .fi-ts-border-right:before {
    content: "\f283";
    }
        .fi-ts-border-style-alt:before {
    content: "\f284";
    }
        .fi-ts-border-style:before {
    content: "\f285";
    }
        .fi-ts-border-top:before {
    content: "\f286";
    }
        .fi-ts-boss:before {
    content: "\f287";
    }
        .fi-ts-bottle-droplet:before {
    content: "\f288";
    }
        .fi-ts-bottle:before {
    content: "\f289";
    }
        .fi-ts-bow-arrow:before {
    content: "\f28a";
    }
        .fi-ts-bowl-chopsticks-noodles:before {
    content: "\f28b";
    }
        .fi-ts-bowl-chopsticks:before {
    content: "\f28c";
    }
        .fi-ts-bowl-rice:before {
    content: "\f28d";
    }
        .fi-ts-bowl-scoop:before {
    content: "\f28e";
    }
        .fi-ts-bowl-scoops:before {
    content: "\f28f";
    }
        .fi-ts-bowl-soft-serve:before {
    content: "\f290";
    }
        .fi-ts-bowl-spoon:before {
    content: "\f291";
    }
        .fi-ts-bowling-ball:before {
    content: "\f292";
    }
        .fi-ts-bowling-pins:before {
    content: "\f293";
    }
        .fi-ts-box-alt:before {
    content: "\f294";
    }
        .fi-ts-box-ballot:before {
    content: "\f295";
    }
        .fi-ts-box-check:before {
    content: "\f296";
    }
        .fi-ts-box-circle-check:before {
    content: "\f297";
    }
        .fi-ts-box-dollar:before {
    content: "\f298";
    }
        .fi-ts-box-fragile:before {
    content: "\f299";
    }
        .fi-ts-box-heart:before {
    content: "\f29a";
    }
        .fi-ts-box-open-full:before {
    content: "\f29b";
    }
        .fi-ts-box-open:before {
    content: "\f29c";
    }
        .fi-ts-box-tissue:before {
    content: "\f29d";
    }
        .fi-ts-box-up:before {
    content: "\f29e";
    }
        .fi-ts-boxes:before {
    content: "\f29f";
    }
        .fi-ts-boxing-glove:before {
    content: "\f2a0";
    }
        .fi-ts-bracket-curly-right:before {
    content: "\f2a1";
    }
        .fi-ts-bracket-curly:before {
    content: "\f2a2";
    }
        .fi-ts-bracket-round-right:before {
    content: "\f2a3";
    }
        .fi-ts-bracket-round:before {
    content: "\f2a4";
    }
        .fi-ts-bracket-square-right:before {
    content: "\f2a5";
    }
        .fi-ts-bracket-square:before {
    content: "\f2a6";
    }
        .fi-ts-brackets-curly:before {
    content: "\f2a7";
    }
        .fi-ts-brackets-round:before {
    content: "\f2a8";
    }
        .fi-ts-brackets-square:before {
    content: "\f2a9";
    }
        .fi-ts-braille-a:before {
    content: "\f2aa";
    }
        .fi-ts-braille-b:before {
    content: "\f2ab";
    }
        .fi-ts-braille-c:before {
    content: "\f2ac";
    }
        .fi-ts-braille-d:before {
    content: "\f2ad";
    }
        .fi-ts-braille-e:before {
    content: "\f2ae";
    }
        .fi-ts-braille-g:before {
    content: "\f2af";
    }
        .fi-ts-braille-h:before {
    content: "\f2b0";
    }
        .fi-ts-braille-i:before {
    content: "\f2b1";
    }
        .fi-ts-braille-j:before {
    content: "\f2b2";
    }
        .fi-ts-braille-k:before {
    content: "\f2b3";
    }
        .fi-ts-braille-l:before {
    content: "\f2b4";
    }
        .fi-ts-braille-m:before {
    content: "\f2b5";
    }
        .fi-ts-braille-n-alt:before {
    content: "\f2b6";
    }
        .fi-ts-braille-n:before {
    content: "\f2b7";
    }
        .fi-ts-braille-o:before {
    content: "\f2b8";
    }
        .fi-ts-braille-p:before {
    content: "\f2b9";
    }
        .fi-ts-braille-q:before {
    content: "\f2ba";
    }
        .fi-ts-braille-r:before {
    content: "\f2bb";
    }
        .fi-ts-braille-s:before {
    content: "\f2bc";
    }
        .fi-ts-braille-t:before {
    content: "\f2bd";
    }
        .fi-ts-braille-u:before {
    content: "\f2be";
    }
        .fi-ts-braille-v:before {
    content: "\f2bf";
    }
        .fi-ts-braille-w:before {
    content: "\f2c0";
    }
        .fi-ts-braille-x:before {
    content: "\f2c1";
    }
        .fi-ts-braille-y:before {
    content: "\f2c2";
    }
        .fi-ts-braille-z:before {
    content: "\f2c3";
    }
        .fi-ts-braille:before {
    content: "\f2c4";
    }
        .fi-ts-brain-circuit:before {
    content: "\f2c5";
    }
        .fi-ts-brain:before {
    content: "\f2c6";
    }
        .fi-ts-brake-warning:before {
    content: "\f2c7";
    }
        .fi-ts-branching:before {
    content: "\f2c8";
    }
        .fi-ts-brand:before {
    content: "\f2c9";
    }
        .fi-ts-branding:before {
    content: "\f2ca";
    }
        .fi-ts-bread-loaf:before {
    content: "\f2cb";
    }
        .fi-ts-bread-slice-butter:before {
    content: "\f2cc";
    }
        .fi-ts-bread-slice:before {
    content: "\f2cd";
    }
        .fi-ts-bread:before {
    content: "\f2ce";
    }
        .fi-ts-briefcase-arrow-right:before {
    content: "\f2cf";
    }
        .fi-ts-briefcase-blank:before {
    content: "\f2d0";
    }
        .fi-ts-brightness-low:before {
    content: "\f2d1";
    }
        .fi-ts-brightness:before {
    content: "\f2d2";
    }
        .fi-ts-bring-forward:before {
    content: "\f2d3";
    }
        .fi-ts-bring-front:before {
    content: "\f2d4";
    }
        .fi-ts-broadcast-tower:before {
    content: "\f2d5";
    }
        .fi-ts-broccoli:before {
    content: "\f2d6";
    }
        .fi-ts-broken-image:before {
    content: "\f2d7";
    }
        .fi-ts-broom-ball:before {
    content: "\f2d8";
    }
        .fi-ts-browser-ui:before {
    content: "\f2d9";
    }
        .fi-ts-browser:before {
    content: "\f2da";
    }
        .fi-ts-browsers:before {
    content: "\f2db";
    }
        .fi-ts-bucket:before {
    content: "\f2dc";
    }
        .fi-ts-budget-alt:before {
    content: "\f2dd";
    }
        .fi-ts-budget:before {
    content: "\f2de";
    }
        .fi-ts-bug-slash:before {
    content: "\f2df";
    }
        .fi-ts-bugs:before {
    content: "\f2e0";
    }
        .fi-ts-build-alt:before {
    content: "\f2e1";
    }
        .fi-ts-build:before {
    content: "\f2e2";
    }
        .fi-ts-builder:before {
    content: "\f2e3";
    }
        .fi-ts-building-circle-arrow-right:before {
    content: "\f2e4";
    }
        .fi-ts-building-ngo:before {
    content: "\f2e5";
    }
        .fi-ts-building:before {
    content: "\f2e6";
    }
        .fi-ts-bulb:before {
    content: "\f2e7";
    }
        .fi-ts-bullet:before {
    content: "\f2e8";
    }
        .fi-ts-bullhorn:before {
    content: "\f2e9";
    }
        .fi-ts-bullseye-arrow:before {
    content: "\f2ea";
    }
        .fi-ts-bullseye-pointer:before {
    content: "\f2eb";
    }
        .fi-ts-bullseye:before {
    content: "\f2ec";
    }
        .fi-ts-burger-alt:before {
    content: "\f2ed";
    }
        .fi-ts-burger-fries:before {
    content: "\f2ee";
    }
        .fi-ts-burger-glass:before {
    content: "\f2ef";
    }
        .fi-ts-burrito:before {
    content: "\f2f0";
    }
        .fi-ts-burst:before {
    content: "\f2f1";
    }
        .fi-ts-bus-alt:before {
    content: "\f2f2";
    }
        .fi-ts-bus:before {
    content: "\f2f3";
    }
        .fi-ts-business-time:before {
    content: "\f2f4";
    }
        .fi-ts-Butter:before {
    content: "\f2f5";
    }
        .fi-ts-butterfly:before {
    content: "\f2f6";
    }
        .fi-ts-c:before {
    content: "\f2f7";
    }
        .fi-ts-cabin:before {
    content: "\f2f8";
    }
        .fi-ts-cactus:before {
    content: "\f2f9";
    }
        .fi-ts-cage-empty:before {
    content: "\f2fa";
    }
        .fi-ts-cake-birthday:before {
    content: "\f2fb";
    }
        .fi-ts-cake-slice:before {
    content: "\f2fc";
    }
        .fi-ts-cake-wedding:before {
    content: "\f2fd";
    }
        .fi-ts-calculator-bill:before {
    content: "\f2fe";
    }
        .fi-ts-calculator-math-tax:before {
    content: "\f2ff";
    }
        .fi-ts-calculator-money:before {
    content: "\f300";
    }
        .fi-ts-calculator-simple:before {
    content: "\f301";
    }
        .fi-ts-calculator:before {
    content: "\f302";
    }
        .fi-ts-calendar-arrow-down:before {
    content: "\f303";
    }
        .fi-ts-calendar-arrow-up:before {
    content: "\f304";
    }
        .fi-ts-calendar-check:before {
    content: "\f305";
    }
        .fi-ts-calendar-clock:before {
    content: "\f306";
    }
        .fi-ts-calendar-day:before {
    content: "\f307";
    }
        .fi-ts-calendar-days:before {
    content: "\f308";
    }
        .fi-ts-calendar-event-tax:before {
    content: "\f309";
    }
        .fi-ts-calendar-exclamation:before {
    content: "\f30a";
    }
        .fi-ts-calendar-gavel-legal:before {
    content: "\f30b";
    }
        .fi-ts-calendar-heart:before {
    content: "\f30c";
    }
        .fi-ts-calendar-image:before {
    content: "\f30d";
    }
        .fi-ts-calendar-lines-pen:before {
    content: "\f30e";
    }
        .fi-ts-calendar-lines:before {
    content: "\f30f";
    }
        .fi-ts-calendar-minus:before {
    content: "\f310";
    }
        .fi-ts-calendar-payment-loan:before {
    content: "\f311";
    }
        .fi-ts-calendar-pen:before {
    content: "\f312";
    }
        .fi-ts-calendar-plus:before {
    content: "\f313";
    }
        .fi-ts-calendar-salary:before {
    content: "\f314";
    }
        .fi-ts-calendar-shift-swap:before {
    content: "\f315";
    }
        .fi-ts-calendar-star:before {
    content: "\f316";
    }
        .fi-ts-calendar-swap:before {
    content: "\f317";
    }
        .fi-ts-calendar-week:before {
    content: "\f318";
    }
        .fi-ts-calendar-xmark:before {
    content: "\f319";
    }
        .fi-ts-calendars:before {
    content: "\f31a";
    }
        .fi-ts-call-duration:before {
    content: "\f31b";
    }
        .fi-ts-call-history:before {
    content: "\f31c";
    }
        .fi-ts-call-incoming:before {
    content: "\f31d";
    }
        .fi-ts-call-missed:before {
    content: "\f31e";
    }
        .fi-ts-call-outgoing:before {
    content: "\f31f";
    }
        .fi-ts-camcorder:before {
    content: "\f320";
    }
        .fi-ts-camera-cctv:before {
    content: "\f321";
    }
        .fi-ts-camera-movie:before {
    content: "\f322";
    }
        .fi-ts-camera-retro:before {
    content: "\f323";
    }
        .fi-ts-camera-rotate:before {
    content: "\f324";
    }
        .fi-ts-camera-security:before {
    content: "\f325";
    }
        .fi-ts-camera-slash:before {
    content: "\f326";
    }
        .fi-ts-camera-viewfinder:before {
    content: "\f327";
    }
        .fi-ts-camera:before {
    content: "\f328";
    }
        .fi-ts-campfire:before {
    content: "\f329";
    }
        .fi-ts-can-food:before {
    content: "\f32a";
    }
        .fi-ts-candle-holder:before {
    content: "\f32b";
    }
        .fi-ts-candle-lotus-yoga:before {
    content: "\f32c";
    }
        .fi-ts-candle-pose-yoga:before {
    content: "\f32d";
    }
        .fi-ts-candy-alt:before {
    content: "\f32e";
    }
        .fi-ts-candy-bar:before {
    content: "\f32f";
    }
        .fi-ts-candy-cane:before {
    content: "\f330";
    }
        .fi-ts-candy-corn:before {
    content: "\f331";
    }
        .fi-ts-candy-sweet:before {
    content: "\f332";
    }
        .fi-ts-candy:before {
    content: "\f333";
    }
        .fi-ts-Cannabis:before {
    content: "\f334";
    }
        .fi-ts-canned-food:before {
    content: "\f335";
    }
        .fi-ts-capsules:before {
    content: "\f336";
    }
        .fi-ts-car-alt:before {
    content: "\f337";
    }
        .fi-ts-car-battery:before {
    content: "\f338";
    }
        .fi-ts-car-bolt:before {
    content: "\f339";
    }
        .fi-ts-car-building:before {
    content: "\f33a";
    }
        .fi-ts-car-bump:before {
    content: "\f33b";
    }
        .fi-ts-car-bus:before {
    content: "\f33c";
    }
        .fi-ts-car-circle-bolt:before {
    content: "\f33d";
    }
        .fi-ts-car-crash:before {
    content: "\f33e";
    }
        .fi-ts-car-garage:before {
    content: "\f33f";
    }
        .fi-ts-car-journey:before {
    content: "\f340";
    }
        .fi-ts-car-mechanic:before {
    content: "\f341";
    }
        .fi-ts-car-rear:before {
    content: "\f342";
    }
        .fi-ts-car-side-bolt:before {
    content: "\f343";
    }
        .fi-ts-car-side:before {
    content: "\f344";
    }
        .fi-ts-car-tilt:before {
    content: "\f345";
    }
        .fi-ts-car-tunnel:before {
    content: "\f346";
    }
        .fi-ts-car-wash:before {
    content: "\f347";
    }
        .fi-ts-caravan-alt:before {
    content: "\f348";
    }
        .fi-ts-caravan:before {
    content: "\f349";
    }
        .fi-ts-card-club:before {
    content: "\f34a";
    }
        .fi-ts-card-diamond:before {
    content: "\f34b";
    }
        .fi-ts-card-heart:before {
    content: "\f34c";
    }
        .fi-ts-card-spade:before {
    content: "\f34d";
    }
        .fi-ts-cardinal-compass:before {
    content: "\f34e";
    }
        .fi-ts-career-growth:before {
    content: "\f34f";
    }
        .fi-ts-career-path:before {
    content: "\f350";
    }
        .fi-ts-caret-circle-down:before {
    content: "\f351";
    }
        .fi-ts-caret-circle-right:before {
    content: "\f352";
    }
        .fi-ts-caret-circle-up:before {
    content: "\f353";
    }
        .fi-ts-caret-down:before {
    content: "\f354";
    }
        .fi-ts-caret-left:before {
    content: "\f355";
    }
        .fi-ts-caret-right:before {
    content: "\f356";
    }
        .fi-ts-caret-square-down:before {
    content: "\f357";
    }
        .fi-ts-caret-square-left_1:before {
    content: "\f358";
    }
        .fi-ts-caret-square-left:before {
    content: "\f359";
    }
        .fi-ts-caret-square-right:before {
    content: "\f35a";
    }
        .fi-ts-caret-square-up:before {
    content: "\f35b";
    }
        .fi-ts-caret-up:before {
    content: "\f35c";
    }
        .fi-ts-carrot:before {
    content: "\f35d";
    }
        .fi-ts-cars-crash:before {
    content: "\f35e";
    }
        .fi-ts-cars:before {
    content: "\f35f";
    }
        .fi-ts-cart-arrow-down:before {
    content: "\f360";
    }
        .fi-ts-cart-minus:before {
    content: "\f361";
    }
        .fi-ts-cart-shopping-fast:before {
    content: "\f362";
    }
        .fi-ts-cash-register:before {
    content: "\f363";
    }
        .fi-ts-cassette-tape:before {
    content: "\f364";
    }
        .fi-ts-cassette-vhs:before {
    content: "\f365";
    }
        .fi-ts-castle:before {
    content: "\f366";
    }
        .fi-ts-cat-head:before {
    content: "\f367";
    }
        .fi-ts-cat-space:before {
    content: "\f368";
    }
        .fi-ts-cat:before {
    content: "\f369";
    }
        .fi-ts-catalog-alt:before {
    content: "\f36a";
    }
        .fi-ts-catalog-magazine:before {
    content: "\f36b";
    }
        .fi-ts-catalog:before {
    content: "\f36c";
    }
        .fi-ts-category-alt:before {
    content: "\f36d";
    }
        .fi-ts-category:before {
    content: "\f36e";
    }
        .fi-ts-cauldron:before {
    content: "\f36f";
    }
        .fi-ts-cedi-sign:before {
    content: "\f370";
    }
        .fi-ts-cello:before {
    content: "\f371";
    }
        .fi-ts-cent-sign:before {
    content: "\f372";
    }
        .fi-ts-chair-office:before {
    content: "\f373";
    }
        .fi-ts-chair:before {
    content: "\f374";
    }
        .fi-ts-chalkboard-user:before {
    content: "\f375";
    }
        .fi-ts-chalkboard:before {
    content: "\f376";
    }
        .fi-ts-challenge-alt:before {
    content: "\f377";
    }
        .fi-ts-challenge:before {
    content: "\f378";
    }
        .fi-ts-channel:before {
    content: "\f379";
    }
        .fi-ts-charging-station:before {
    content: "\f37a";
    }
        .fi-ts-chart-area:before {
    content: "\f37b";
    }
        .fi-ts-chart-arrow-down:before {
    content: "\f37c";
    }
        .fi-ts-chart-arrow-grow:before {
    content: "\f37d";
    }
        .fi-ts-chart-bullet:before {
    content: "\f37e";
    }
        .fi-ts-chart-candlestick:before {
    content: "\f37f";
    }
        .fi-ts-chart-connected:before {
    content: "\f380";
    }
        .fi-ts-chart-gantt:before {
    content: "\f381";
    }
        .fi-ts-chart-histogram:before {
    content: "\f382";
    }
        .fi-ts-chart-kanban:before {
    content: "\f383";
    }
        .fi-ts-chart-line-up-down:before {
    content: "\f384";
    }
        .fi-ts-chart-line-up:before {
    content: "\f385";
    }
        .fi-ts-chart-mixed-up-circle-currency:before {
    content: "\f386";
    }
        .fi-ts-chart-mixed-up-circle-dollar:before {
    content: "\f387";
    }
        .fi-ts-chart-mixed:before {
    content: "\f388";
    }
        .fi-ts-chart-network:before {
    content: "\f389";
    }
        .fi-ts-chart-pie-alt:before {
    content: "\f38a";
    }
        .fi-ts-chart-pie-simple-circle-currency:before {
    content: "\f38b";
    }
        .fi-ts-chart-pie-simple-circle-dollar:before {
    content: "\f38c";
    }
        .fi-ts-chart-pie:before {
    content: "\f38d";
    }
        .fi-ts-chart-pyramid:before {
    content: "\f38e";
    }
        .fi-ts-chart-radar:before {
    content: "\f38f";
    }
        .fi-ts-chart-scatter-3d:before {
    content: "\f390";
    }
        .fi-ts-chart-scatter-bubble:before {
    content: "\f391";
    }
        .fi-ts-chart-scatter:before {
    content: "\f392";
    }
        .fi-ts-chart-set-theory:before {
    content: "\f393";
    }
        .fi-ts-chart-simple-horizontal:before {
    content: "\f394";
    }
        .fi-ts-chart-simple:before {
    content: "\f395";
    }
        .fi-ts-chart-tree-map:before {
    content: "\f396";
    }
        .fi-ts-chart-tree:before {
    content: "\f397";
    }
        .fi-ts-chart-user:before {
    content: "\f398";
    }
        .fi-ts-chart-waterfall:before {
    content: "\f399";
    }
        .fi-ts-chatbot-speech-bubble:before {
    content: "\f39a";
    }
        .fi-ts-chatbot:before {
    content: "\f39b";
    }
        .fi-ts-cheap-bill:before {
    content: "\f39c";
    }
        .fi-ts-cheap-dollar:before {
    content: "\f39d";
    }
        .fi-ts-cheap-stack-dollar:before {
    content: "\f39e";
    }
        .fi-ts-cheap-stack:before {
    content: "\f39f";
    }
        .fi-ts-cheap:before {
    content: "\f3a0";
    }
        .fi-ts-check-circle:before {
    content: "\f3a1";
    }
        .fi-ts-check-double:before {
    content: "\f3a2";
    }
        .fi-ts-check-in-calendar:before {
    content: "\f3a3";
    }
        .fi-ts-check-out-calendar:before {
    content: "\f3a4";
    }
        .fi-ts-checklist-task-budget:before {
    content: "\f3a5";
    }
        .fi-ts-cheese-alt:before {
    content: "\f3a6";
    }
        .fi-ts-cheese:before {
    content: "\f3a7";
    }
        .fi-ts-cheeseburger:before {
    content: "\f3a8";
    }
        .fi-ts-cherry:before {
    content: "\f3a9";
    }
        .fi-ts-chess-bishop:before {
    content: "\f3aa";
    }
        .fi-ts-chess-board:before {
    content: "\f3ab";
    }
        .fi-ts-chess-clock-alt:before {
    content: "\f3ac";
    }
        .fi-ts-chess-clock:before {
    content: "\f3ad";
    }
        .fi-ts-chess-king-alt:before {
    content: "\f3ae";
    }
        .fi-ts-chess-king:before {
    content: "\f3af";
    }
        .fi-ts-chess-knight-alt:before {
    content: "\f3b0";
    }
        .fi-ts-chess-knight:before {
    content: "\f3b1";
    }
        .fi-ts-chess-pawn-alt:before {
    content: "\f3b2";
    }
        .fi-ts-chess-pawn:before {
    content: "\f3b3";
    }
        .fi-ts-chess-queen-alt:before {
    content: "\f3b4";
    }
        .fi-ts-chess-queen:before {
    content: "\f3b5";
    }
        .fi-ts-chess-rook-alt:before {
    content: "\f3b6";
    }
        .fi-ts-chess-rook:before {
    content: "\f3b7";
    }
        .fi-ts-chess:before {
    content: "\f3b8";
    }
        .fi-ts-chevron-double-down:before {
    content: "\f3b9";
    }
        .fi-ts-chevron-double-up:before {
    content: "\f3ba";
    }
        .fi-ts-child-head:before {
    content: "\f3bb";
    }
        .fi-ts-child:before {
    content: "\f3bc";
    }
        .fi-ts-chimney:before {
    content: "\f3bd";
    }
        .fi-ts-chip:before {
    content: "\f3be";
    }
        .fi-ts-chocolate-bar:before {
    content: "\f3bf";
    }
        .fi-ts-choir-singing:before {
    content: "\f3c0";
    }
        .fi-ts-choose-alt:before {
    content: "\f3c1";
    }
        .fi-ts-choose:before {
    content: "\f3c2";
    }
        .fi-ts-church:before {
    content: "\f3c3";
    }
        .fi-ts-circle-0:before {
    content: "\f3c4";
    }
        .fi-ts-circle-1:before {
    content: "\f3c5";
    }
        .fi-ts-circle-2:before {
    content: "\f3c6";
    }
        .fi-ts-circle-3:before {
    content: "\f3c7";
    }
        .fi-ts-circle-4:before {
    content: "\f3c8";
    }
        .fi-ts-circle-5:before {
    content: "\f3c9";
    }
        .fi-ts-circle-6:before {
    content: "\f3ca";
    }
        .fi-ts-circle-7:before {
    content: "\f3cb";
    }
        .fi-ts-circle-8:before {
    content: "\f3cc";
    }
        .fi-ts-circle-9:before {
    content: "\f3cd";
    }
        .fi-ts-circle-a:before {
    content: "\f3ce";
    }
        .fi-ts-circle-b:before {
    content: "\f3cf";
    }
        .fi-ts-circle-bolt:before {
    content: "\f3d0";
    }
        .fi-ts-circle-book-open:before {
    content: "\f3d1";
    }
        .fi-ts-circle-bookmark:before {
    content: "\f3d2";
    }
        .fi-ts-circle-c:before {
    content: "\f3d3";
    }
        .fi-ts-circle-calendar:before {
    content: "\f3d4";
    }
        .fi-ts-circle-camera:before {
    content: "\f3d5";
    }
        .fi-ts-circle-d:before {
    content: "\f3d6";
    }
        .fi-ts-circle-dashed:before {
    content: "\f3d7";
    }
        .fi-ts-circle-divide:before {
    content: "\f3d8";
    }
        .fi-ts-circle-e:before {
    content: "\f3d9";
    }
        .fi-ts-circle-ellipsis-vertical:before {
    content: "\f3da";
    }
        .fi-ts-circle-ellipsis:before {
    content: "\f3db";
    }
        .fi-ts-circle-envelope:before {
    content: "\f3dc";
    }
        .fi-ts-circle-exclamation-check:before {
    content: "\f3dd";
    }
        .fi-ts-circle-f:before {
    content: "\f3de";
    }
        .fi-ts-circle-g:before {
    content: "\f3df";
    }
        .fi-ts-circle-h:before {
    content: "\f3e0";
    }
        .fi-ts-circle-half-stroke:before {
    content: "\f3e1";
    }
        .fi-ts-circle-half:before {
    content: "\f3e2";
    }
        .fi-ts-circle-heart:before {
    content: "\f3e3";
    }
        .fi-ts-circle-i:before {
    content: "\f3e4";
    }
        .fi-ts-circle-j:before {
    content: "\f3e5";
    }
        .fi-ts-circle-k:before {
    content: "\f3e6";
    }
        .fi-ts-circle-l:before {
    content: "\f3e7";
    }
        .fi-ts-circle-m:before {
    content: "\f3e8";
    }
        .fi-ts-circle-microphone-lines:before {
    content: "\f3e9";
    }
        .fi-ts-circle-microphone:before {
    content: "\f3ea";
    }
        .fi-ts-circle-n:before {
    content: "\f3eb";
    }
        .fi-ts-circle-nodes:before {
    content: "\f3ec";
    }
        .fi-ts-circle-o:before {
    content: "\f3ed";
    }
        .fi-ts-circle-overlap:before {
    content: "\f3ee";
    }
        .fi-ts-circle-p:before {
    content: "\f3ef";
    }
        .fi-ts-circle-phone-flip:before {
    content: "\f3f0";
    }
        .fi-ts-circle-phone-hangup:before {
    content: "\f3f1";
    }
        .fi-ts-circle-phone:before {
    content: "\f3f2";
    }
        .fi-ts-circle-q:before {
    content: "\f3f3";
    }
        .fi-ts-circle-quarter:before {
    content: "\f3f4";
    }
        .fi-ts-circle-quarters-alt:before {
    content: "\f3f5";
    }
        .fi-ts-circle-r:before {
    content: "\f3f6";
    }
        .fi-ts-circle-s:before {
    content: "\f3f7";
    }
        .fi-ts-circle-small:before {
    content: "\f3f8";
    }
        .fi-ts-circle-star:before {
    content: "\f3f9";
    }
        .fi-ts-circle-t:before {
    content: "\f3fa";
    }
        .fi-ts-circle-three-quarters:before {
    content: "\f3fb";
    }
        .fi-ts-circle-trash:before {
    content: "\f3fc";
    }
        .fi-ts-circle-u:before {
    content: "\f3fd";
    }
        .fi-ts-circle-user:before {
    content: "\f3fe";
    }
        .fi-ts-circle-v:before {
    content: "\f3ff";
    }
        .fi-ts-circle-video:before {
    content: "\f400";
    }
        .fi-ts-circle-w:before {
    content: "\f401";
    }
        .fi-ts-circle-waveform-lines:before {
    content: "\f402";
    }
        .fi-ts-circle-x:before {
    content: "\f403";
    }
        .fi-ts-circle-xmark:before {
    content: "\f404";
    }
        .fi-ts-circle-y:before {
    content: "\f405";
    }
        .fi-ts-circle-z:before {
    content: "\f406";
    }
        .fi-ts-circle:before {
    content: "\f407";
    }
        .fi-ts-citrus-slice:before {
    content: "\f408";
    }
        .fi-ts-citrus:before {
    content: "\f409";
    }
        .fi-ts-city:before {
    content: "\f40a";
    }
        .fi-ts-clapperboard-play:before {
    content: "\f40b";
    }
        .fi-ts-clapperboard:before {
    content: "\f40c";
    }
        .fi-ts-clarinet:before {
    content: "\f40d";
    }
        .fi-ts-claw-marks:before {
    content: "\f40e";
    }
        .fi-ts-clear-alt:before {
    content: "\f40f";
    }
        .fi-ts-clip-file:before {
    content: "\f410";
    }
        .fi-ts-clip-mail:before {
    content: "\f411";
    }
        .fi-ts-clipboard-check:before {
    content: "\f412";
    }
        .fi-ts-clipboard-exclamation:before {
    content: "\f413";
    }
        .fi-ts-clipboard-list-check:before {
    content: "\f414";
    }
        .fi-ts-clipboard-list:before {
    content: "\f415";
    }
        .fi-ts-clipboard-prescription:before {
    content: "\f416";
    }
        .fi-ts-clipboard-question:before {
    content: "\f417";
    }
        .fi-ts-clipboard-user:before {
    content: "\f418";
    }
        .fi-ts-clipboard:before {
    content: "\f419";
    }
        .fi-ts-clipoard-wrong:before {
    content: "\f41a";
    }
        .fi-ts-clock-desk:before {
    content: "\f41b";
    }
        .fi-ts-clock-eight-thirty:before {
    content: "\f41c";
    }
        .fi-ts-clock-eleven-thirty:before {
    content: "\f41d";
    }
        .fi-ts-clock-eleven:before {
    content: "\f41e";
    }
        .fi-ts-clock-five-thirty:before {
    content: "\f41f";
    }
        .fi-ts-clock-five:before {
    content: "\f420";
    }
        .fi-ts-clock-four-thirty:before {
    content: "\f421";
    }
        .fi-ts-clock-nine-thirty:before {
    content: "\f422";
    }
        .fi-ts-clock-nine:before {
    content: "\f423";
    }
        .fi-ts-clock-one-thirty:before {
    content: "\f424";
    }
        .fi-ts-clock-one:before {
    content: "\f425";
    }
        .fi-ts-clock-seven-thirty:before {
    content: "\f426";
    }
        .fi-ts-clock-seven:before {
    content: "\f427";
    }
        .fi-ts-clock-six-thirty:before {
    content: "\f428";
    }
        .fi-ts-clock-six:before {
    content: "\f429";
    }
        .fi-ts-clock-ten-thirty:before {
    content: "\f42a";
    }
        .fi-ts-clock-ten:before {
    content: "\f42b";
    }
        .fi-ts-clock-three-thirty:before {
    content: "\f42c";
    }
        .fi-ts-clock-three:before {
    content: "\f42d";
    }
        .fi-ts-clock-time-tracking:before {
    content: "\f42e";
    }
        .fi-ts-clock-twelve-thirty:before {
    content: "\f42f";
    }
        .fi-ts-clock-twelve:before {
    content: "\f430";
    }
        .fi-ts-clock-two-thirty:before {
    content: "\f431";
    }
        .fi-ts-clock-two:before {
    content: "\f432";
    }
        .fi-ts-clock-up-arrow:before {
    content: "\f433";
    }
        .fi-ts-clone:before {
    content: "\f434";
    }
        .fi-ts-closed-captioning-slash:before {
    content: "\f435";
    }
        .fi-ts-clothes-hanger:before {
    content: "\f436";
    }
        .fi-ts-cloud-back-up-alt:before {
    content: "\f437";
    }
        .fi-ts-cloud-back-up:before {
    content: "\f438";
    }
        .fi-ts-cloud-check:before {
    content: "\f439";
    }
        .fi-ts-cloud-code:before {
    content: "\f43a";
    }
        .fi-ts-cloud-disabled:before {
    content: "\f43b";
    }
        .fi-ts-cloud-download-alt:before {
    content: "\f43c";
    }
        .fi-ts-cloud-download:before {
    content: "\f43d";
    }
        .fi-ts-cloud-drizzle:before {
    content: "\f43e";
    }
        .fi-ts-cloud-exclamation:before {
    content: "\f43f";
    }
        .fi-ts-cloud-gear-automation:before {
    content: "\f440";
    }
        .fi-ts-cloud-hail-mixed:before {
    content: "\f441";
    }
        .fi-ts-cloud-hail:before {
    content: "\f442";
    }
        .fi-ts-cloud-meatball:before {
    content: "\f443";
    }
        .fi-ts-cloud-moon-rain:before {
    content: "\f444";
    }
        .fi-ts-cloud-moon:before {
    content: "\f445";
    }
        .fi-ts-cloud-question:before {
    content: "\f446";
    }
        .fi-ts-cloud-rain:before {
    content: "\f447";
    }
        .fi-ts-cloud-rainbow:before {
    content: "\f448";
    }
        .fi-ts-cloud-share:before {
    content: "\f449";
    }
        .fi-ts-cloud-showers-heavy:before {
    content: "\f44a";
    }
        .fi-ts-cloud-showers:before {
    content: "\f44b";
    }
        .fi-ts-cloud-sleet:before {
    content: "\f44c";
    }
        .fi-ts-cloud-snow:before {
    content: "\f44d";
    }
        .fi-ts-cloud-sun-rain:before {
    content: "\f44e";
    }
        .fi-ts-cloud-sun:before {
    content: "\f44f";
    }
        .fi-ts-cloud-upload-alt:before {
    content: "\f450";
    }
        .fi-ts-cloud:before {
    content: "\f451";
    }
        .fi-ts-clouds-moon:before {
    content: "\f452";
    }
        .fi-ts-clouds-sun:before {
    content: "\f453";
    }
        .fi-ts-clouds:before {
    content: "\f454";
    }
        .fi-ts-clover-alt:before {
    content: "\f455";
    }
        .fi-ts-club:before {
    content: "\f456";
    }
        .fi-ts-cocktail:before {
    content: "\f457";
    }
        .fi-ts-coconut:before {
    content: "\f458";
    }
        .fi-ts-code-branch:before {
    content: "\f459";
    }
        .fi-ts-code-commit:before {
    content: "\f45a";
    }
        .fi-ts-code-compare:before {
    content: "\f45b";
    }
        .fi-ts-code-fork:before {
    content: "\f45c";
    }
        .fi-ts-code-merge:before {
    content: "\f45d";
    }
        .fi-ts-code-pull-request-closed:before {
    content: "\f45e";
    }
        .fi-ts-code-pull-request-draft:before {
    content: "\f45f";
    }
        .fi-ts-code-pull-request:before {
    content: "\f460";
    }
        .fi-ts-code-simple:before {
    content: "\f461";
    }
        .fi-ts-coffee-bean:before {
    content: "\f462";
    }
        .fi-ts-coffee-beans:before {
    content: "\f463";
    }
        .fi-ts-coffee-heart:before {
    content: "\f464";
    }
        .fi-ts-coffee-pot:before {
    content: "\f465";
    }
        .fi-ts-coffee:before {
    content: "\f466";
    }
        .fi-ts-coffin-cross:before {
    content: "\f467";
    }
        .fi-ts-coffin:before {
    content: "\f468";
    }
        .fi-ts-coin-up-arrow:before {
    content: "\f469";
    }
        .fi-ts-coin:before {
    content: "\f46a";
    }
        .fi-ts-coins:before {
    content: "\f46b";
    }
        .fi-ts-colon-sign:before {
    content: "\f46c";
    }
        .fi-ts-columns-3:before {
    content: "\f46d";
    }
        .fi-ts-comet:before {
    content: "\f46e";
    }
        .fi-ts-command:before {
    content: "\f46f";
    }
        .fi-ts-comment-alt-check:before {
    content: "\f470";
    }
        .fi-ts-comment-alt-dots:before {
    content: "\f471";
    }
        .fi-ts-comment-alt-edit:before {
    content: "\f472";
    }
        .fi-ts-comment-alt-medical:before {
    content: "\f473";
    }
        .fi-ts-comment-alt-middle-top:before {
    content: "\f474";
    }
        .fi-ts-comment-alt-middle:before {
    content: "\f475";
    }
        .fi-ts-comment-alt-minus:before {
    content: "\f476";
    }
        .fi-ts-comment-alt-music:before {
    content: "\f477";
    }
        .fi-ts-comment-arrow-down:before {
    content: "\f478";
    }
        .fi-ts-comment-arrow-up-right:before {
    content: "\f479";
    }
        .fi-ts-comment-arrow-up:before {
    content: "\f47a";
    }
        .fi-ts-comment-check:before {
    content: "\f47b";
    }
        .fi-ts-comment-code:before {
    content: "\f47c";
    }
        .fi-ts-comment-dollar:before {
    content: "\f47d";
    }
        .fi-ts-comment-dots:before {
    content: "\f47e";
    }
        .fi-ts-comment-exclamation:before {
    content: "\f47f";
    }
        .fi-ts-comment-heart:before {
    content: "\f480";
    }
        .fi-ts-comment-image:before {
    content: "\f481";
    }
        .fi-ts-comment-info:before {
    content: "\f482";
    }
        .fi-ts-comment-medical:before {
    content: "\f483";
    }
        .fi-ts-comment-minus:before {
    content: "\f484";
    }
        .fi-ts-comment-pen:before {
    content: "\f485";
    }
        .fi-ts-comment-question:before {
    content: "\f486";
    }
        .fi-ts-comment-quote:before {
    content: "\f487";
    }
        .fi-ts-comment-slash:before {
    content: "\f488";
    }
        .fi-ts-comment-smile:before {
    content: "\f489";
    }
        .fi-ts-comment-sms:before {
    content: "\f48a";
    }
        .fi-ts-comment-text:before {
    content: "\f48b";
    }
        .fi-ts-comment-user:before {
    content: "\f48c";
    }
        .fi-ts-comment-xmark:before {
    content: "\f48d";
    }
        .fi-ts-comments-dollar:before {
    content: "\f48e";
    }
        .fi-ts-comments-question-check:before {
    content: "\f48f";
    }
        .fi-ts-comments-question:before {
    content: "\f490";
    }
        .fi-ts-comments:before {
    content: "\f491";
    }
        .fi-ts-compass-alt:before {
    content: "\f492";
    }
        .fi-ts-compass-east:before {
    content: "\f493";
    }
        .fi-ts-compass-north:before {
    content: "\f494";
    }
        .fi-ts-compass-slash:before {
    content: "\f495";
    }
        .fi-ts-compass-south:before {
    content: "\f496";
    }
        .fi-ts-compass-west:before {
    content: "\f497";
    }
        .fi-ts-completed:before {
    content: "\f498";
    }
        .fi-ts-compliance-clipboard:before {
    content: "\f499";
    }
        .fi-ts-compliance-document:before {
    content: "\f49a";
    }
        .fi-ts-compliance:before {
    content: "\f49b";
    }
        .fi-ts-compress-alt:before {
    content: "\f49c";
    }
        .fi-ts-computer-classic:before {
    content: "\f49d";
    }
        .fi-ts-computer-mouse:before {
    content: "\f49e";
    }
        .fi-ts-computer-speaker:before {
    content: "\f49f";
    }
        .fi-ts-computer:before {
    content: "\f4a0";
    }
        .fi-ts-concierge-bell:before {
    content: "\f4a1";
    }
        .fi-ts-condition-alt:before {
    content: "\f4a2";
    }
        .fi-ts-condition:before {
    content: "\f4a3";
    }
        .fi-ts-confetti:before {
    content: "\f4a4";
    }
        .fi-ts-constellation:before {
    content: "\f4a5";
    }
        .fi-ts-container-storage:before {
    content: "\f4a6";
    }
        .fi-ts-convert-document:before {
    content: "\f4a7";
    }
        .fi-ts-convert-shapes:before {
    content: "\f4a8";
    }
        .fi-ts-conveyor-belt-alt:before {
    content: "\f4a9";
    }
        .fi-ts-conveyor-belt-arm:before {
    content: "\f4aa";
    }
        .fi-ts-conveyor-belt-empty:before {
    content: "\f4ab";
    }
        .fi-ts-conveyor-belt:before {
    content: "\f4ac";
    }
        .fi-ts-cookie-alt:before {
    content: "\f4ad";
    }
        .fi-ts-copy-alt:before {
    content: "\f4ae";
    }
        .fi-ts-copy-image:before {
    content: "\f4af";
    }
        .fi-ts-copy:before {
    content: "\f4b0";
    }
        .fi-ts-corn:before {
    content: "\f4b1";
    }
        .fi-ts-corporate-alt:before {
    content: "\f4b2";
    }
        .fi-ts-corporate:before {
    content: "\f4b3";
    }
        .fi-ts-couch:before {
    content: "\f4b4";
    }
        .fi-ts-court-sport:before {
    content: "\f4b5";
    }
        .fi-ts-cow-alt:before {
    content: "\f4b6";
    }
        .fi-ts-cow:before {
    content: "\f4b7";
    }
        .fi-ts-cowbell-circle-plus:before {
    content: "\f4b8";
    }
        .fi-ts-cowbell-more:before {
    content: "\f4b9";
    }
        .fi-ts-cowbell:before {
    content: "\f4ba";
    }
        .fi-ts-crab:before {
    content: "\f4bb";
    }
        .fi-ts-crate-empty:before {
    content: "\f4bc";
    }
        .fi-ts-cream:before {
    content: "\f4bd";
    }
        .fi-ts-credit-card-buyer:before {
    content: "\f4be";
    }
        .fi-ts-credit-card-eye:before {
    content: "\f4bf";
    }
        .fi-ts-cricket:before {
    content: "\f4c0";
    }
        .fi-ts-crm-alt:before {
    content: "\f4c1";
    }
        .fi-ts-crm-computer:before {
    content: "\f4c2";
    }
        .fi-ts-CRM:before {
    content: "\f4c3";
    }
        .fi-ts-croissant:before {
    content: "\f4c4";
    }
        .fi-ts-cross-religion:before {
    content: "\f4c5";
    }
        .fi-ts-crow:before {
    content: "\f4c6";
    }
        .fi-ts-crutch:before {
    content: "\f4c7";
    }
        .fi-ts-crutches:before {
    content: "\f4c8";
    }
        .fi-ts-cruzeiro-sign:before {
    content: "\f4c9";
    }
        .fi-ts-crypto-calendar:before {
    content: "\f4ca";
    }
        .fi-ts-cryptocurrency:before {
    content: "\f4cb";
    }
        .fi-ts-crystal-ball:before {
    content: "\f4cc";
    }
        .fi-ts-cube:before {
    content: "\f4cd";
    }
        .fi-ts-cubes-stacked:before {
    content: "\f4ce";
    }
        .fi-ts-cubes:before {
    content: "\f4cf";
    }
        .fi-ts-cucumber:before {
    content: "\f4d0";
    }
        .fi-ts-cup-straw-swoosh:before {
    content: "\f4d1";
    }
        .fi-ts-cup-straw:before {
    content: "\f4d2";
    }
        .fi-ts-cup-togo:before {
    content: "\f4d3";
    }
        .fi-ts-cupcake-alt:before {
    content: "\f4d4";
    }
        .fi-ts-cupcake:before {
    content: "\f4d5";
    }
        .fi-ts-curling:before {
    content: "\f4d6";
    }
        .fi-ts-cursor-finger:before {
    content: "\f4d7";
    }
        .fi-ts-cursor-plus:before {
    content: "\f4d8";
    }
        .fi-ts-cursor:before {
    content: "\f4d9";
    }
        .fi-ts-curve-alt:before {
    content: "\f4da";
    }
        .fi-ts-curve-arrow:before {
    content: "\f4db";
    }
        .fi-ts-curve:before {
    content: "\f4dc";
    }
        .fi-ts-custard:before {
    content: "\f4dd";
    }
        .fi-ts-customer-care:before {
    content: "\f4de";
    }
        .fi-ts-customer-service:before {
    content: "\f4df";
    }
        .fi-ts-customization-cogwheel:before {
    content: "\f4e0";
    }
        .fi-ts-customization:before {
    content: "\f4e1";
    }
        .fi-ts-customize-computer:before {
    content: "\f4e2";
    }
        .fi-ts-customize-edit:before {
    content: "\f4e3";
    }
        .fi-ts-customize:before {
    content: "\f4e4";
    }
        .fi-ts-CV:before {
    content: "\f4e5";
    }
        .fi-ts-cvv-card:before {
    content: "\f4e6";
    }
        .fi-ts-d:before {
    content: "\f4e7";
    }
        .fi-ts-dagger:before {
    content: "\f4e8";
    }
        .fi-ts-daily-calendar:before {
    content: "\f4e9";
    }
        .fi-ts-damage:before {
    content: "\f4ea";
    }
        .fi-ts-dashboard-monitor:before {
    content: "\f4eb";
    }
        .fi-ts-dashboard-panel:before {
    content: "\f4ec";
    }
        .fi-ts-dashboard:before {
    content: "\f4ed";
    }
        .fi-ts-database:before {
    content: "\f4ee";
    }
        .fi-ts-debt:before {
    content: "\f4ef";
    }
        .fi-ts-deer-rudolph:before {
    content: "\f4f0";
    }
        .fi-ts-deer:before {
    content: "\f4f1";
    }
        .fi-ts-delete-document:before {
    content: "\f4f2";
    }
        .fi-ts-delete-right:before {
    content: "\f4f3";
    }
        .fi-ts-delete-user:before {
    content: "\f4f4";
    }
        .fi-ts-delete:before {
    content: "\f4f5";
    }
        .fi-ts-democrat:before {
    content: "\f4f6";
    }
        .fi-ts-department-structure:before {
    content: "\f4f7";
    }
        .fi-ts-department:before {
    content: "\f4f8";
    }
        .fi-ts-deposit-alt:before {
    content: "\f4f9";
    }
        .fi-ts-deposit:before {
    content: "\f4fa";
    }
        .fi-ts-description-alt:before {
    content: "\f4fb";
    }
        .fi-ts-description:before {
    content: "\f4fc";
    }
        .fi-ts-desk:before {
    content: "\f4fd";
    }
        .fi-ts-desktop-arrow-down:before {
    content: "\f4fe";
    }
        .fi-ts-desktop-wallpaper:before {
    content: "\f4ff";
    }
        .fi-ts-devices:before {
    content: "\f500";
    }
        .fi-ts-dewpoint:before {
    content: "\f501";
    }
        .fi-ts-dharmachakra:before {
    content: "\f502";
    }
        .fi-ts-diagram-cells:before {
    content: "\f503";
    }
        .fi-ts-diagram-lean-canvas:before {
    content: "\f504";
    }
        .fi-ts-diagram-nested:before {
    content: "\f505";
    }
        .fi-ts-diagram-next:before {
    content: "\f506";
    }
        .fi-ts-diagram-predecessor:before {
    content: "\f507";
    }
        .fi-ts-diagram-previous:before {
    content: "\f508";
    }
        .fi-ts-diagram-project:before {
    content: "\f509";
    }
        .fi-ts-diagram-sankey:before {
    content: "\f50a";
    }
        .fi-ts-diagram-subtask:before {
    content: "\f50b";
    }
        .fi-ts-diagram-successor:before {
    content: "\f50c";
    }
        .fi-ts-diagram-venn:before {
    content: "\f50d";
    }
        .fi-ts-dial-high:before {
    content: "\f50e";
    }
        .fi-ts-dial-low:before {
    content: "\f50f";
    }
        .fi-ts-dial-max:before {
    content: "\f510";
    }
        .fi-ts-dial-med-low:before {
    content: "\f511";
    }
        .fi-ts-dial-med:before {
    content: "\f512";
    }
        .fi-ts-dial-min:before {
    content: "\f513";
    }
        .fi-ts-dial-off:before {
    content: "\f514";
    }
        .fi-ts-dial:before {
    content: "\f515";
    }
        .fi-ts-diamond-exclamation:before {
    content: "\f516";
    }
        .fi-ts-diamond-turn-right:before {
    content: "\f517";
    }
        .fi-ts-diamond:before {
    content: "\f518";
    }
        .fi-ts-diary-bookmark-down:before {
    content: "\f519";
    }
        .fi-ts-diary-bookmarks:before {
    content: "\f51a";
    }
        .fi-ts-diary-clasp:before {
    content: "\f51b";
    }
        .fi-ts-dice-alt:before {
    content: "\f51c";
    }
        .fi-ts-dice-d10:before {
    content: "\f51d";
    }
        .fi-ts-dice-d12:before {
    content: "\f51e";
    }
        .fi-ts-dice-d20:before {
    content: "\f51f";
    }
        .fi-ts-dice-d4:before {
    content: "\f520";
    }
        .fi-ts-dice-d6:before {
    content: "\f521";
    }
        .fi-ts-dice-d8:before {
    content: "\f522";
    }
        .fi-ts-dice-four:before {
    content: "\f523";
    }
        .fi-ts-dice-one:before {
    content: "\f524";
    }
        .fi-ts-dice-six:before {
    content: "\f525";
    }
        .fi-ts-dice-three:before {
    content: "\f526";
    }
        .fi-ts-dice-two:before {
    content: "\f527";
    }
        .fi-ts-digging:before {
    content: "\f528";
    }
        .fi-ts-digital-tachograph:before {
    content: "\f529";
    }
        .fi-ts-dinner:before {
    content: "\f52a";
    }
        .fi-ts-diploma:before {
    content: "\f52b";
    }
        .fi-ts-direction-signal-arrow:before {
    content: "\f52c";
    }
        .fi-ts-direction-signal:before {
    content: "\f52d";
    }
        .fi-ts-disc-drive:before {
    content: "\f52e";
    }
        .fi-ts-disco-ball:before {
    content: "\f52f";
    }
        .fi-ts-discover:before {
    content: "\f530";
    }
        .fi-ts-disease:before {
    content: "\f531";
    }
        .fi-ts-display-arrow-down:before {
    content: "\f532";
    }
        .fi-ts-display-chart-up:before {
    content: "\f533";
    }
        .fi-ts-display-code:before {
    content: "\f534";
    }
        .fi-ts-display-medical:before {
    content: "\f535";
    }
        .fi-ts-display-slash:before {
    content: "\f536";
    }
        .fi-ts-distribute-spacing-horizontal:before {
    content: "\f537";
    }
        .fi-ts-distribute-spacing-vertical:before {
    content: "\f538";
    }
        .fi-ts-divide:before {
    content: "\f539";
    }
        .fi-ts-dizzy:before {
    content: "\f53a";
    }
        .fi-ts-dna:before {
    content: "\f53b";
    }
        .fi-ts-do-not-enter:before {
    content: "\f53c";
    }
        .fi-ts-doctor:before {
    content: "\f53d";
    }
        .fi-ts-document-circle-wrong:before {
    content: "\f53e";
    }
        .fi-ts-document-paid:before {
    content: "\f53f";
    }
        .fi-ts-document-signed:before {
    content: "\f540";
    }
        .fi-ts-document:before {
    content: "\f541";
    }
        .fi-ts-dog-leashed:before {
    content: "\f542";
    }
        .fi-ts-dog:before {
    content: "\f543";
    }
        .fi-ts-dolly-flatbed-alt:before {
    content: "\f544";
    }
        .fi-ts-dolly-flatbed-empty:before {
    content: "\f545";
    }
        .fi-ts-dolly-flatbed:before {
    content: "\f546";
    }
        .fi-ts-dolphin:before {
    content: "\f547";
    }
        .fi-ts-domino-effect:before {
    content: "\f548";
    }
        .fi-ts-donate:before {
    content: "\f549";
    }
        .fi-ts-dong-sign:before {
    content: "\f54a";
    }
        .fi-ts-donut:before {
    content: "\f54b";
    }
        .fi-ts-door-closed:before {
    content: "\f54c";
    }
        .fi-ts-door-open:before {
    content: "\f54d";
    }
        .fi-ts-dot-circle:before {
    content: "\f54e";
    }
        .fi-ts-dot-pending:before {
    content: "\f54f";
    }
        .fi-ts-dove:before {
    content: "\f550";
    }
        .fi-ts-down-from-bracket:before {
    content: "\f551";
    }
        .fi-ts-down-left:before {
    content: "\f552";
    }
        .fi-ts-down-right:before {
    content: "\f553";
    }
        .fi-ts-down-to-line:before {
    content: "\f554";
    }
        .fi-ts-down:before {
    content: "\f555";
    }
        .fi-ts-drafting-compass:before {
    content: "\f556";
    }
        .fi-ts-dragon:before {
    content: "\f557";
    }
        .fi-ts-draw-polygon:before {
    content: "\f558";
    }
        .fi-ts-draw-square:before {
    content: "\f559";
    }
        .fi-ts-drawer-alt:before {
    content: "\f55a";
    }
        .fi-ts-drawer-empty:before {
    content: "\f55b";
    }
        .fi-ts-drawer:before {
    content: "\f55c";
    }
        .fi-ts-dreidel:before {
    content: "\f55d";
    }
        .fi-ts-drink-alt:before {
    content: "\f55e";
    }
        .fi-ts-drink:before {
    content: "\f55f";
    }
        .fi-ts-driver-man:before {
    content: "\f560";
    }
        .fi-ts-driver-woman:before {
    content: "\f561";
    }
        .fi-ts-drone-alt:before {
    content: "\f562";
    }
        .fi-ts-drone-front:before {
    content: "\f563";
    }
        .fi-ts-drone:before {
    content: "\f564";
    }
        .fi-ts-drop-down:before {
    content: "\f565";
    }
        .fi-ts-dropdown-select:before {
    content: "\f566";
    }
        .fi-ts-dropdown:before {
    content: "\f567";
    }
        .fi-ts-drum-steelpan:before {
    content: "\f568";
    }
        .fi-ts-drum:before {
    content: "\f569";
    }
        .fi-ts-drumstick-bite:before {
    content: "\f56a";
    }
        .fi-ts-drumstick:before {
    content: "\f56b";
    }
        .fi-ts-dryer-alt:before {
    content: "\f56c";
    }
        .fi-ts-dryer:before {
    content: "\f56d";
    }
        .fi-ts-duck:before {
    content: "\f56e";
    }
        .fi-ts-dumbbell-fitness:before {
    content: "\f56f";
    }
        .fi-ts-dumbbell-horizontal:before {
    content: "\f570";
    }
        .fi-ts-dumbbell-ray:before {
    content: "\f571";
    }
        .fi-ts-dumbbell-weightlifting:before {
    content: "\f572";
    }
        .fi-ts-dumpster-fire:before {
    content: "\f573";
    }
        .fi-ts-dumpster:before {
    content: "\f574";
    }
        .fi-ts-dungeon:before {
    content: "\f575";
    }
        .fi-ts-duration-alt:before {
    content: "\f576";
    }
        .fi-ts-duration:before {
    content: "\f577";
    }
        .fi-ts-e-learning:before {
    content: "\f578";
    }
        .fi-ts-e:before {
    content: "\f579";
    }
        .fi-ts-ear-deaf:before {
    content: "\f57a";
    }
        .fi-ts-ear-muffs:before {
    content: "\f57b";
    }
        .fi-ts-ear:before {
    content: "\f57c";
    }
        .fi-ts-earth-africa:before {
    content: "\f57d";
    }
        .fi-ts-earth-americas:before {
    content: "\f57e";
    }
        .fi-ts-earth-asia:before {
    content: "\f57f";
    }
        .fi-ts-earth-europa:before {
    content: "\f580";
    }
        .fi-ts-eclipse-alt:before {
    content: "\f581";
    }
        .fi-ts-eclipse:before {
    content: "\f582";
    }
        .fi-ts-effect:before {
    content: "\f583";
    }
        .fi-ts-egg-fried:before {
    content: "\f584";
    }
        .fi-ts-egg:before {
    content: "\f585";
    }
        .fi-ts-eject:before {
    content: "\f586";
    }
        .fi-ts-elephant:before {
    content: "\f587";
    }
        .fi-ts-elevator:before {
    content: "\f588";
    }
        .fi-ts-employee-alt:before {
    content: "\f589";
    }
        .fi-ts-employee-handbook:before {
    content: "\f58a";
    }
        .fi-ts-employee-man-alt:before {
    content: "\f58b";
    }
        .fi-ts-employee-man:before {
    content: "\f58c";
    }
        .fi-ts-employees-woman-man:before {
    content: "\f58d";
    }
        .fi-ts-employees:before {
    content: "\f58e";
    }
        .fi-ts-empty-set:before {
    content: "\f58f";
    }
        .fi-ts-endless-loop:before {
    content: "\f590";
    }
        .fi-ts-engine-warning:before {
    content: "\f591";
    }
        .fi-ts-engine:before {
    content: "\f592";
    }
        .fi-ts-envelope-ban:before {
    content: "\f593";
    }
        .fi-ts-envelope-bulk:before {
    content: "\f594";
    }
        .fi-ts-envelope-dot:before {
    content: "\f595";
    }
        .fi-ts-envelope-download:before {
    content: "\f596";
    }
        .fi-ts-envelope-heart:before {
    content: "\f597";
    }
        .fi-ts-envelope-marker:before {
    content: "\f598";
    }
        .fi-ts-envelope-open-dollar:before {
    content: "\f599";
    }
        .fi-ts-envelope-open-text:before {
    content: "\f59a";
    }
        .fi-ts-envelope-open:before {
    content: "\f59b";
    }
        .fi-ts-envelope-plus:before {
    content: "\f59c";
    }
        .fi-ts-envelopes:before {
    content: "\f59d";
    }
        .fi-ts-equality:before {
    content: "\f59e";
    }
        .fi-ts-equals:before {
    content: "\f59f";
    }
        .fi-ts-eraser:before {
    content: "\f5a0";
    }
        .fi-ts-error-camera:before {
    content: "\f5a1";
    }
        .fi-ts-escalator:before {
    content: "\f5a2";
    }
        .fi-ts-ethernet:before {
    content: "\f5a3";
    }
        .fi-ts-euro:before {
    content: "\f5a4";
    }
        .fi-ts-excavator:before {
    content: "\f5a5";
    }
        .fi-ts-exchange-alt:before {
    content: "\f5a6";
    }
        .fi-ts-exchange-cryptocurrency:before {
    content: "\f5a7";
    }
        .fi-ts-exchange:before {
    content: "\f5a8";
    }
        .fi-ts-exit-alt:before {
    content: "\f5a9";
    }
        .fi-ts-expand-arrows-alt:before {
    content: "\f5aa";
    }
        .fi-ts-expand-arrows:before {
    content: "\f5ab";
    }
        .fi-ts-expense-bill:before {
    content: "\f5ac";
    }
        .fi-ts-expense:before {
    content: "\f5ad";
    }
        .fi-ts-external-hard-drive:before {
    content: "\f5ae";
    }
        .fi-ts-external-world:before {
    content: "\f5af";
    }
        .fi-ts-eye-dropper-half:before {
    content: "\f5b0";
    }
        .fi-ts-eyes:before {
    content: "\f5b1";
    }
        .fi-ts-f:before {
    content: "\f5b2";
    }
        .fi-ts-fabric:before {
    content: "\f5b3";
    }
        .fi-ts-face-angry-horns:before {
    content: "\f5b4";
    }
        .fi-ts-face-anguished:before {
    content: "\f5b5";
    }
        .fi-ts-face-anxious-sweat:before {
    content: "\f5b6";
    }
        .fi-ts-face-astonished:before {
    content: "\f5b7";
    }
        .fi-ts-face-awesome:before {
    content: "\f5b8";
    }
        .fi-ts-face-beam-hand-over-mouth:before {
    content: "\f5b9";
    }
        .fi-ts-face-confounded:before {
    content: "\f5ba";
    }
        .fi-ts-face-confused:before {
    content: "\f5bb";
    }
        .fi-ts-face-cowboy-hat:before {
    content: "\f5bc";
    }
        .fi-ts-face-disappointed:before {
    content: "\f5bd";
    }
        .fi-ts-face-disguise:before {
    content: "\f5be";
    }
        .fi-ts-face-downcast-sweat:before {
    content: "\f5bf";
    }
        .fi-ts-face-drooling:before {
    content: "\f5c0";
    }
        .fi-ts-face-explode:before {
    content: "\f5c1";
    }
        .fi-ts-face-expressionless:before {
    content: "\f5c2";
    }
        .fi-ts-face-eyes-xmarks:before {
    content: "\f5c3";
    }
        .fi-ts-face-fearful:before {
    content: "\f5c4";
    }
        .fi-ts-face-glasses:before {
    content: "\f5c5";
    }
        .fi-ts-face-grin-tongue-wink:before {
    content: "\f5c6";
    }
        .fi-ts-face-hand-yawn:before {
    content: "\f5c7";
    }
        .fi-ts-face-head-bandage:before {
    content: "\f5c8";
    }
        .fi-ts-face-hushed:before {
    content: "\f5c9";
    }
        .fi-ts-face-icicles:before {
    content: "\f5ca";
    }
        .fi-ts-face-lying:before {
    content: "\f5cb";
    }
        .fi-ts-face-mask:before {
    content: "\f5cc";
    }
        .fi-ts-face-monocle:before {
    content: "\f5cd";
    }
        .fi-ts-face-nauseated:before {
    content: "\f5ce";
    }
        .fi-ts-face-nose-steam:before {
    content: "\f5cf";
    }
        .fi-ts-face-party:before {
    content: "\f5d0";
    }
        .fi-ts-face-pensive:before {
    content: "\f5d1";
    }
        .fi-ts-face-persevering:before {
    content: "\f5d2";
    }
        .fi-ts-face-pleading:before {
    content: "\f5d3";
    }
        .fi-ts-face-raised-eyebrow:before {
    content: "\f5d4";
    }
        .fi-ts-face-relieved:before {
    content: "\f5d5";
    }
        .fi-ts-face-sad-sweat:before {
    content: "\f5d6";
    }
        .fi-ts-face-scream:before {
    content: "\f5d7";
    }
        .fi-ts-face-shush:before {
    content: "\f5d8";
    }
        .fi-ts-face-sleeping:before {
    content: "\f5d9";
    }
        .fi-ts-face-sleepy:before {
    content: "\f5da";
    }
        .fi-ts-face-smile-halo:before {
    content: "\f5db";
    }
        .fi-ts-face-smile-hearts:before {
    content: "\f5dc";
    }
        .fi-ts-face-smile-horns:before {
    content: "\f5dd";
    }
        .fi-ts-face-smile-tear:before {
    content: "\f5de";
    }
        .fi-ts-face-smile-tongue:before {
    content: "\f5df";
    }
        .fi-ts-face-smile-upside-down:before {
    content: "\f5e0";
    }
        .fi-ts-face-smiling-hands:before {
    content: "\f5e1";
    }
        .fi-ts-face-smirking:before {
    content: "\f5e2";
    }
        .fi-ts-face-sunglasses-alt:before {
    content: "\f5e3";
    }
        .fi-ts-face-sunglasses:before {
    content: "\f5e4";
    }
        .fi-ts-face-swear:before {
    content: "\f5e5";
    }
        .fi-ts-face-thermometer:before {
    content: "\f5e6";
    }
        .fi-ts-face-thinking:before {
    content: "\f5e7";
    }
        .fi-ts-face-tissue:before {
    content: "\f5e8";
    }
        .fi-ts-face-tongue-money:before {
    content: "\f5e9";
    }
        .fi-ts-face-tongue-sweat:before {
    content: "\f5ea";
    }
        .fi-ts-face-unamused:before {
    content: "\f5eb";
    }
        .fi-ts-face-viewfinder:before {
    content: "\f5ec";
    }
        .fi-ts-face-vomit:before {
    content: "\f5ed";
    }
        .fi-ts-face-weary:before {
    content: "\f5ee";
    }
        .fi-ts-face-woozy:before {
    content: "\f5ef";
    }
        .fi-ts-face-worried:before {
    content: "\f5f0";
    }
        .fi-ts-face-zany:before {
    content: "\f5f1";
    }
        .fi-ts-face-zipper:before {
    content: "\f5f2";
    }
        .fi-ts-fail:before {
    content: "\f5f3";
    }
        .fi-ts-falafel:before {
    content: "\f5f4";
    }
        .fi-ts-family-dress:before {
    content: "\f5f5";
    }
        .fi-ts-family-pants:before {
    content: "\f5f6";
    }
        .fi-ts-family:before {
    content: "\f5f7";
    }
        .fi-ts-fan-table:before {
    content: "\f5f8";
    }
        .fi-ts-fan:before {
    content: "\f5f9";
    }
        .fi-ts-farm:before {
    content: "\f5fa";
    }
        .fi-ts-faucet-drip:before {
    content: "\f5fb";
    }
        .fi-ts-faucet:before {
    content: "\f5fc";
    }
        .fi-ts-fax:before {
    content: "\f5fd";
    }
        .fi-ts-feather-pointed:before {
    content: "\f5fe";
    }
        .fi-ts-feather:before {
    content: "\f5ff";
    }
        .fi-ts-features-alt:before {
    content: "\f600";
    }
        .fi-ts-features:before {
    content: "\f601";
    }
        .fi-ts-fee-receipt:before {
    content: "\f602";
    }
        .fi-ts-fee:before {
    content: "\f603";
    }
        .fi-ts-feedback-alt:before {
    content: "\f604";
    }
        .fi-ts-feedback-cycle-loop:before {
    content: "\f605";
    }
        .fi-ts-feedback-hand:before {
    content: "\f606";
    }
        .fi-ts-feedback-review:before {
    content: "\f607";
    }
        .fi-ts-feedback:before {
    content: "\f608";
    }
        .fi-ts-fence:before {
    content: "\f609";
    }
        .fi-ts-ferris-wheel:before {
    content: "\f60a";
    }
        .fi-ts-field-hockey:before {
    content: "\f60b";
    }
        .fi-ts-fighter-jet:before {
    content: "\f60c";
    }
        .fi-ts-file-audio:before {
    content: "\f60d";
    }
        .fi-ts-file-binary:before {
    content: "\f60e";
    }
        .fi-ts-file-chart-line:before {
    content: "\f60f";
    }
        .fi-ts-file-chart-pie:before {
    content: "\f610";
    }
        .fi-ts-file-circle-info:before {
    content: "\f611";
    }
        .fi-ts-file-cloud:before {
    content: "\f612";
    }
        .fi-ts-file-code:before {
    content: "\f613";
    }
        .fi-ts-file-csv:before {
    content: "\f614";
    }
        .fi-ts-file-download:before {
    content: "\f615";
    }
        .fi-ts-file-edit:before {
    content: "\f616";
    }
        .fi-ts-file-excel:before {
    content: "\f617";
    }
        .fi-ts-file-exclamation:before {
    content: "\f618";
    }
        .fi-ts-file-export:before {
    content: "\f619";
    }
        .fi-ts-file-image:before {
    content: "\f61a";
    }
        .fi-ts-file-import:before {
    content: "\f61b";
    }
        .fi-ts-file-invoice-dollar:before {
    content: "\f61c";
    }
        .fi-ts-file-invoice:before {
    content: "\f61d";
    }
        .fi-ts-file-medical-alt:before {
    content: "\f61e";
    }
        .fi-ts-file-medical:before {
    content: "\f61f";
    }
        .fi-ts-file-minus:before {
    content: "\f620";
    }
        .fi-ts-file-pdf:before {
    content: "\f621";
    }
        .fi-ts-file-powerpoint:before {
    content: "\f622";
    }
        .fi-ts-file-prescription:before {
    content: "\f623";
    }
        .fi-ts-file-signature:before {
    content: "\f624";
    }
        .fi-ts-file-spreadsheet:before {
    content: "\f625";
    }
        .fi-ts-file-upload:before {
    content: "\f626";
    }
        .fi-ts-file-user:before {
    content: "\f627";
    }
        .fi-ts-file-video:before {
    content: "\f628";
    }
        .fi-ts-file-word:before {
    content: "\f629";
    }
        .fi-ts-file-zipper:before {
    content: "\f62a";
    }
        .fi-ts-files-medical:before {
    content: "\f62b";
    }
        .fi-ts-film-canister:before {
    content: "\f62c";
    }
        .fi-ts-film-slash:before {
    content: "\f62d";
    }
        .fi-ts-films:before {
    content: "\f62e";
    }
        .fi-ts-filter-list:before {
    content: "\f62f";
    }
        .fi-ts-filter-slash:before {
    content: "\f630";
    }
        .fi-ts-filter:before {
    content: "\f631";
    }
        .fi-ts-filters:before {
    content: "\f632";
    }
        .fi-ts-fingerprint:before {
    content: "\f633";
    }
        .fi-ts-fire-burner:before {
    content: "\f634";
    }
        .fi-ts-fire-extinguisher:before {
    content: "\f635";
    }
        .fi-ts-fire-flame-curved:before {
    content: "\f636";
    }
        .fi-ts-fire-flame-simple:before {
    content: "\f637";
    }
        .fi-ts-fire-hydrant:before {
    content: "\f638";
    }
        .fi-ts-fire-smoke:before {
    content: "\f639";
    }
        .fi-ts-fireplace:before {
    content: "\f63a";
    }
        .fi-ts-first-award:before {
    content: "\f63b";
    }
        .fi-ts-first-laurel:before {
    content: "\f63c";
    }
        .fi-ts-first-medal:before {
    content: "\f63d";
    }
        .fi-ts-first:before {
    content: "\f63e";
    }
        .fi-ts-fish-bones:before {
    content: "\f63f";
    }
        .fi-ts-fish-cooked:before {
    content: "\f640";
    }
        .fi-ts-fish:before {
    content: "\f641";
    }
        .fi-ts-fishing-rod:before {
    content: "\f642";
    }
        .fi-ts-fist-move:before {
    content: "\f643";
    }
        .fi-ts-flag-alt:before {
    content: "\f644";
    }
        .fi-ts-flag-checkered:before {
    content: "\f645";
    }
        .fi-ts-flag-usa:before {
    content: "\f646";
    }
        .fi-ts-flag:before {
    content: "\f647";
    }
        .fi-ts-flame:before {
    content: "\f648";
    }
        .fi-ts-flashlight:before {
    content: "\f649";
    }
        .fi-ts-flask-gear:before {
    content: "\f64a";
    }
        .fi-ts-flask-poison:before {
    content: "\f64b";
    }
        .fi-ts-flask-potion:before {
    content: "\f64c";
    }
        .fi-ts-flask:before {
    content: "\f64d";
    }
        .fi-ts-flatbread-stuffed:before {
    content: "\f64e";
    }
        .fi-ts-flatbread:before {
    content: "\f64f";
    }
        .fi-ts-floor-alt:before {
    content: "\f650";
    }
        .fi-ts-floor-layer:before {
    content: "\f651";
    }
        .fi-ts-floor:before {
    content: "\f652";
    }
        .fi-ts-floppy-disk-circle-arrow-right:before {
    content: "\f653";
    }
        .fi-ts-floppy-disk-circle-xmark:before {
    content: "\f654";
    }
        .fi-ts-floppy-disk-pen:before {
    content: "\f655";
    }
        .fi-ts-floppy-disks:before {
    content: "\f656";
    }
        .fi-ts-florin-sign:before {
    content: "\f657";
    }
        .fi-ts-flower-bouquet:before {
    content: "\f658";
    }
        .fi-ts-flower-butterfly:before {
    content: "\f659";
    }
        .fi-ts-flower-daffodil:before {
    content: "\f65a";
    }
        .fi-ts-flower-tulip:before {
    content: "\f65b";
    }
        .fi-ts-flower:before {
    content: "\f65c";
    }
        .fi-ts-flushed:before {
    content: "\f65d";
    }
        .fi-ts-flute:before {
    content: "\f65e";
    }
        .fi-ts-flux-capacitor:before {
    content: "\f65f";
    }
        .fi-ts-fly-insect:before {
    content: "\f660";
    }
        .fi-ts-flying-disc:before {
    content: "\f661";
    }
        .fi-ts-fog:before {
    content: "\f662";
    }
        .fi-ts-folder-download:before {
    content: "\f663";
    }
        .fi-ts-folder-fire:before {
    content: "\f664";
    }
        .fi-ts-folder-minus:before {
    content: "\f665";
    }
        .fi-ts-folder-open:before {
    content: "\f666";
    }
        .fi-ts-folder-times:before {
    content: "\f667";
    }
        .fi-ts-folder-tree:before {
    content: "\f668";
    }
        .fi-ts-folder-upload:before {
    content: "\f669";
    }
        .fi-ts-folder-xmark:before {
    content: "\f66a";
    }
        .fi-ts-folders:before {
    content: "\f66b";
    }
        .fi-ts-follow-folder:before {
    content: "\f66c";
    }
        .fi-ts-followcollection:before {
    content: "\f66d";
    }
        .fi-ts-fondue-pot:before {
    content: "\f66e";
    }
        .fi-ts-foreign-language-audio:before {
    content: "\f66f";
    }
        .fi-ts-fork:before {
    content: "\f670";
    }
        .fi-ts-forklift:before {
    content: "\f671";
    }
        .fi-ts-fort:before {
    content: "\f672";
    }
        .fi-ts-forward-fast:before {
    content: "\f673";
    }
        .fi-ts-fox:before {
    content: "\f674";
    }
        .fi-ts-frame:before {
    content: "\f675";
    }
        .fi-ts-franc-sign:before {
    content: "\f676";
    }
        .fi-ts-free-delivery:before {
    content: "\f677";
    }
        .fi-ts-free:before {
    content: "\f678";
    }
        .fi-ts-french-fries:before {
    content: "\f679";
    }
        .fi-ts-friday:before {
    content: "\f67a";
    }
        .fi-ts-frog:before {
    content: "\f67b";
    }
        .fi-ts-frown:before {
    content: "\f67c";
    }
        .fi-ts-ftp:before {
    content: "\f67d";
    }
        .fi-ts-fuel-gauge:before {
    content: "\f67e";
    }
        .fi-ts-function-process:before {
    content: "\f67f";
    }
        .fi-ts-function-square:before {
    content: "\f680";
    }
        .fi-ts-function:before {
    content: "\f681";
    }
        .fi-ts-funnel-dollar:before {
    content: "\f682";
    }
        .fi-ts-g:before {
    content: "\f683";
    }
        .fi-ts-galaxy-alt:before {
    content: "\f684";
    }
        .fi-ts-galaxy-planet:before {
    content: "\f685";
    }
        .fi-ts-galaxy-star:before {
    content: "\f686";
    }
        .fi-ts-galaxy:before {
    content: "\f687";
    }
        .fi-ts-gallery-thumbnails:before {
    content: "\f688";
    }
        .fi-ts-game-board-alt:before {
    content: "\f689";
    }
        .fi-ts-gamepad:before {
    content: "\f68a";
    }
        .fi-ts-garage-car:before {
    content: "\f68b";
    }
        .fi-ts-garage-open:before {
    content: "\f68c";
    }
        .fi-ts-garage:before {
    content: "\f68d";
    }
        .fi-ts-garlic-alt:before {
    content: "\f68e";
    }
        .fi-ts-garlic:before {
    content: "\f68f";
    }
        .fi-ts-gas-pump-slash:before {
    content: "\f690";
    }
        .fi-ts-gas-pump:before {
    content: "\f691";
    }
        .fi-ts-gauge-circle-bolt:before {
    content: "\f692";
    }
        .fi-ts-gauge-circle-minus:before {
    content: "\f693";
    }
        .fi-ts-gauge-circle-plus:before {
    content: "\f694";
    }
        .fi-ts-gavel:before {
    content: "\f695";
    }
        .fi-ts-gay-couple:before {
    content: "\f696";
    }
        .fi-ts-gears:before {
    content: "\f697";
    }
        .fi-ts-gem:before {
    content: "\f698";
    }
        .fi-ts-general:before {
    content: "\f699";
    }
        .fi-ts-ghost:before {
    content: "\f69a";
    }
        .fi-ts-gif-square:before {
    content: "\f69b";
    }
        .fi-ts-gif:before {
    content: "\f69c";
    }
        .fi-ts-gift-box-benefits:before {
    content: "\f69d";
    }
        .fi-ts-gift-card:before {
    content: "\f69e";
    }
        .fi-ts-gift:before {
    content: "\f69f";
    }
        .fi-ts-gifts:before {
    content: "\f6a0";
    }
        .fi-ts-gingerbread-man:before {
    content: "\f6a1";
    }
        .fi-ts-glass-champagne:before {
    content: "\f6a2";
    }
        .fi-ts-glass-cheers:before {
    content: "\f6a3";
    }
        .fi-ts-glass-citrus:before {
    content: "\f6a4";
    }
        .fi-ts-glass-empty:before {
    content: "\f6a5";
    }
        .fi-ts-glass-half:before {
    content: "\f6a6";
    }
        .fi-ts-glass-water-droplet:before {
    content: "\f6a7";
    }
        .fi-ts-glass-whiskey-rocks:before {
    content: "\f6a8";
    }
        .fi-ts-glass-whiskey:before {
    content: "\f6a9";
    }
        .fi-ts-glass:before {
    content: "\f6aa";
    }
        .fi-ts-glasses:before {
    content: "\f6ab";
    }
        .fi-ts-globe-alt:before {
    content: "\f6ac";
    }
        .fi-ts-globe-pointer:before {
    content: "\f6ad";
    }
        .fi-ts-globe-snow:before {
    content: "\f6ae";
    }
        .fi-ts-goal-net:before {
    content: "\f6af";
    }
        .fi-ts-golf-ball:before {
    content: "\f6b0";
    }
        .fi-ts-golf-club:before {
    content: "\f6b1";
    }
        .fi-ts-golf-hole:before {
    content: "\f6b2";
    }
        .fi-ts-gopuram:before {
    content: "\f6b3";
    }
        .fi-ts-government-budget:before {
    content: "\f6b4";
    }
        .fi-ts-government-flag:before {
    content: "\f6b5";
    }
        .fi-ts-government-user:before {
    content: "\f6b6";
    }
        .fi-ts-gps-navigation:before {
    content: "\f6b7";
    }
        .fi-ts-graduation-cap:before {
    content: "\f6b8";
    }
        .fi-ts-gramophone:before {
    content: "\f6b9";
    }
        .fi-ts-grape:before {
    content: "\f6ba";
    }
        .fi-ts-graph-curve:before {
    content: "\f6bb";
    }
        .fi-ts-graphic-style:before {
    content: "\f6bc";
    }
        .fi-ts-graphic-tablet:before {
    content: "\f6bd";
    }
        .fi-ts-grate-droplet:before {
    content: "\f6be";
    }
        .fi-ts-grate:before {
    content: "\f6bf";
    }
        .fi-ts-greater-than-equal:before {
    content: "\f6c0";
    }
        .fi-ts-greater-than:before {
    content: "\f6c1";
    }
        .fi-ts-greek-helmet:before {
    content: "\f6c2";
    }
        .fi-ts-grid-dividers:before {
    content: "\f6c3";
    }
        .fi-ts-grill-hot-alt:before {
    content: "\f6c4";
    }
        .fi-ts-grill:before {
    content: "\f6c5";
    }
        .fi-ts-grimace:before {
    content: "\f6c6";
    }
        .fi-ts-grin-alt:before {
    content: "\f6c7";
    }
        .fi-ts-grin-beam-sweat:before {
    content: "\f6c8";
    }
        .fi-ts-grin-beam:before {
    content: "\f6c9";
    }
        .fi-ts-grin-hearts:before {
    content: "\f6ca";
    }
        .fi-ts-grin-squint-tears:before {
    content: "\f6cb";
    }
        .fi-ts-grin-squint:before {
    content: "\f6cc";
    }
        .fi-ts-grin-stars:before {
    content: "\f6cd";
    }
        .fi-ts-grin-tears:before {
    content: "\f6ce";
    }
        .fi-ts-grin-tongue-squint:before {
    content: "\f6cf";
    }
        .fi-ts-grin-tongue-wink:before {
    content: "\f6d0";
    }
        .fi-ts-grin-tongue:before {
    content: "\f6d1";
    }
        .fi-ts-grin-wink:before {
    content: "\f6d2";
    }
        .fi-ts-grin:before {
    content: "\f6d3";
    }
        .fi-ts-grip-dots-vertical:before {
    content: "\f6d4";
    }
        .fi-ts-grip-dots:before {
    content: "\f6d5";
    }
        .fi-ts-grip-horizontal:before {
    content: "\f6d6";
    }
        .fi-ts-grip-lines-vertical:before {
    content: "\f6d7";
    }
        .fi-ts-grip-lines:before {
    content: "\f6d8";
    }
        .fi-ts-grip-vertical:before {
    content: "\f6d9";
    }
        .fi-ts-grocery-bag:before {
    content: "\f6da";
    }
        .fi-ts-grocery-basket:before {
    content: "\f6db";
    }
        .fi-ts-group-arrows-rotate:before {
    content: "\f6dc";
    }
        .fi-ts-growth-chart-invest:before {
    content: "\f6dd";
    }
        .fi-ts-guarani-sign:before {
    content: "\f6de";
    }
        .fi-ts-guide-alt:before {
    content: "\f6df";
    }
        .fi-ts-guide:before {
    content: "\f6e0";
    }
        .fi-ts-guitar-electric:before {
    content: "\f6e1";
    }
        .fi-ts-guitar:before {
    content: "\f6e2";
    }
        .fi-ts-guitars:before {
    content: "\f6e3";
    }
        .fi-ts-gun-squirt:before {
    content: "\f6e4";
    }
        .fi-ts-gym:before {
    content: "\f6e5";
    }
        .fi-ts-h-square:before {
    content: "\f6e6";
    }
        .fi-ts-h:before {
    content: "\f6e7";
    }
        .fi-ts-h1:before {
    content: "\f6e8";
    }
        .fi-ts-h2:before {
    content: "\f6e9";
    }
        .fi-ts-h3:before {
    content: "\f6ea";
    }
        .fi-ts-h4:before {
    content: "\f6eb";
    }
        .fi-ts-hair-clipper:before {
    content: "\f6ec";
    }
        .fi-ts-hamburger-soda:before {
    content: "\f6ed";
    }
        .fi-ts-hamburger:before {
    content: "\f6ee";
    }
        .fi-ts-hammer-crash:before {
    content: "\f6ef";
    }
        .fi-ts-hammer-war:before {
    content: "\f6f0";
    }
        .fi-ts-hammer:before {
    content: "\f6f1";
    }
        .fi-ts-hamsa:before {
    content: "\f6f2";
    }
        .fi-ts-hand-back-fist:before {
    content: "\f6f3";
    }
        .fi-ts-hand-back-point-down:before {
    content: "\f6f4";
    }
        .fi-ts-hand-back-point-left:before {
    content: "\f6f5";
    }
        .fi-ts-hand-back-point-ribbon:before {
    content: "\f6f6";
    }
        .fi-ts-hand-back-point-right:before {
    content: "\f6f7";
    }
        .fi-ts-hand-bill:before {
    content: "\f6f8";
    }
        .fi-ts-hand-dots:before {
    content: "\f6f9";
    }
        .fi-ts-hand-fingers-crossed:before {
    content: "\f6fa";
    }
        .fi-ts-hand-fist:before {
    content: "\f6fb";
    }
        .fi-ts-hand-heart:before {
    content: "\f6fc";
    }
        .fi-ts-hand-holding-box:before {
    content: "\f6fd";
    }
        .fi-ts-hand-holding-droplet:before {
    content: "\f6fe";
    }
        .fi-ts-hand-holding-heart:before {
    content: "\f6ff";
    }
        .fi-ts-hand-holding-magic:before {
    content: "\f700";
    }
        .fi-ts-hand-holding-medical:before {
    content: "\f701";
    }
        .fi-ts-hand-holding-seeding:before {
    content: "\f702";
    }
        .fi-ts-hand-holding-skull:before {
    content: "\f703";
    }
        .fi-ts-hand-holding-usd:before {
    content: "\f704";
    }
        .fi-ts-hand-holding-water:before {
    content: "\f705";
    }
        .fi-ts-hand-horns:before {
    content: "\f706";
    }
        .fi-ts-hand-lizard:before {
    content: "\f707";
    }
        .fi-ts-hand-love:before {
    content: "\f708";
    }
        .fi-ts-hand-middle-finger:before {
    content: "\f709";
    }
        .fi-ts-hand-paper:before {
    content: "\f70a";
    }
        .fi-ts-hand-peace:before {
    content: "\f70b";
    }
        .fi-ts-hand-point-ribbon:before {
    content: "\f70c";
    }
        .fi-ts-hand-scissors:before {
    content: "\f70d";
    }
        .fi-ts-hand-sparkles:before {
    content: "\f70e";
    }
        .fi-ts-hand-spock:before {
    content: "\f70f";
    }
        .fi-ts-hand-wave:before {
    content: "\f710";
    }
        .fi-ts-handmade:before {
    content: "\f711";
    }
        .fi-ts-hands-bubbles:before {
    content: "\f712";
    }
        .fi-ts-hands-clapping:before {
    content: "\f713";
    }
        .fi-ts-hands-heart:before {
    content: "\f714";
    }
        .fi-ts-hands-holding-diamond:before {
    content: "\f715";
    }
        .fi-ts-hands-holding:before {
    content: "\f716";
    }
        .fi-ts-hands-usd:before {
    content: "\f717";
    }
        .fi-ts-handshake-angle:before {
    content: "\f718";
    }
        .fi-ts-handshake-deal-loan:before {
    content: "\f719";
    }
        .fi-ts-handshake-house:before {
    content: "\f71a";
    }
        .fi-ts-handshake-simple-slash:before {
    content: "\f71b";
    }
        .fi-ts-handshake-trust:before {
    content: "\f71c";
    }
        .fi-ts-handshake:before {
    content: "\f71d";
    }
        .fi-ts-hard-hat:before {
    content: "\f71e";
    }
        .fi-ts-hashtag-lock:before {
    content: "\f71f";
    }
        .fi-ts-hashtag:before {
    content: "\f720";
    }
        .fi-ts-hat-beach:before {
    content: "\f721";
    }
        .fi-ts-hat-birthday:before {
    content: "\f722";
    }
        .fi-ts-hat-chef:before {
    content: "\f723";
    }
        .fi-ts-hat-cowboy-side:before {
    content: "\f724";
    }
        .fi-ts-hat-cowboy:before {
    content: "\f725";
    }
        .fi-ts-hat-santa:before {
    content: "\f726";
    }
        .fi-ts-hat-winter:before {
    content: "\f727";
    }
        .fi-ts-hat-witch:before {
    content: "\f728";
    }
        .fi-ts-hat-wizard:before {
    content: "\f729";
    }
        .fi-ts-hdd:before {
    content: "\f72a";
    }
        .fi-ts-head-side-brain:before {
    content: "\f72b";
    }
        .fi-ts-head-side-cough-slash:before {
    content: "\f72c";
    }
        .fi-ts-head-side-cough:before {
    content: "\f72d";
    }
        .fi-ts-head-side-headphones:before {
    content: "\f72e";
    }
        .fi-ts-head-side-heart:before {
    content: "\f72f";
    }
        .fi-ts-head-side-mask:before {
    content: "\f730";
    }
        .fi-ts-head-side-medical:before {
    content: "\f731";
    }
        .fi-ts-head-side-thinking:before {
    content: "\f732";
    }
        .fi-ts-head-side-virus:before {
    content: "\f733";
    }
        .fi-ts-head-side:before {
    content: "\f734";
    }
        .fi-ts-head-vr:before {
    content: "\f735";
    }
        .fi-ts-heading:before {
    content: "\f736";
    }
        .fi-ts-heart-arrow:before {
    content: "\f737";
    }
        .fi-ts-heart-brain:before {
    content: "\f738";
    }
        .fi-ts-heart-crack:before {
    content: "\f739";
    }
        .fi-ts-heart-half-stroke:before {
    content: "\f73a";
    }
        .fi-ts-heart-half:before {
    content: "\f73b";
    }
        .fi-ts-heart-health-muscle:before {
    content: "\f73c";
    }
        .fi-ts-heart-lock:before {
    content: "\f73d";
    }
        .fi-ts-heart-partner-handshake:before {
    content: "\f73e";
    }
        .fi-ts-heart-rate:before {
    content: "\f73f";
    }
        .fi-ts-heart-slash:before {
    content: "\f740";
    }
        .fi-ts-heart-upside-down:before {
    content: "\f741";
    }
        .fi-ts-heat:before {
    content: "\f742";
    }
        .fi-ts-helicopter-side:before {
    content: "\f743";
    }
        .fi-ts-helmet-battle:before {
    content: "\f744";
    }
        .fi-ts-hexagon-check:before {
    content: "\f745";
    }
        .fi-ts-hexagon-divide:before {
    content: "\f746";
    }
        .fi-ts-hexagon-exclamation:before {
    content: "\f747";
    }
        .fi-ts-hexagon:before {
    content: "\f748";
    }
        .fi-ts-high-definition:before {
    content: "\f749";
    }
        .fi-ts-high-five-celebration-yes:before {
    content: "\f74a";
    }
        .fi-ts-high-five:before {
    content: "\f74b";
    }
        .fi-ts-highlighter-line:before {
    content: "\f74c";
    }
        .fi-ts-highlighter:before {
    content: "\f74d";
    }
        .fi-ts-hiking:before {
    content: "\f74e";
    }
        .fi-ts-hippo:before {
    content: "\f74f";
    }
        .fi-ts-hockey-mask:before {
    content: "\f750";
    }
        .fi-ts-hockey-puck:before {
    content: "\f751";
    }
        .fi-ts-hockey-stick-puck:before {
    content: "\f752";
    }
        .fi-ts-hockey-sticks:before {
    content: "\f753";
    }
        .fi-ts-holding-hand-gear:before {
    content: "\f754";
    }
        .fi-ts-holding-hand-revenue:before {
    content: "\f755";
    }
        .fi-ts-holly-berry:before {
    content: "\f756";
    }
        .fi-ts-home-heart:before {
    content: "\f757";
    }
        .fi-ts-honey-pot:before {
    content: "\f758";
    }
        .fi-ts-hood-cloak:before {
    content: "\f759";
    }
        .fi-ts-horizontal-rule:before {
    content: "\f75a";
    }
        .fi-ts-horse-head:before {
    content: "\f75b";
    }
        .fi-ts-horse-saddle:before {
    content: "\f75c";
    }
        .fi-ts-horse:before {
    content: "\f75d";
    }
        .fi-ts-horseshoe-broken:before {
    content: "\f75e";
    }
        .fi-ts-horseshoe:before {
    content: "\f75f";
    }
        .fi-ts-hose-reel:before {
    content: "\f760";
    }
        .fi-ts-hose:before {
    content: "\f761";
    }
        .fi-ts-hospital-symbol:before {
    content: "\f762";
    }
        .fi-ts-hospital-user:before {
    content: "\f763";
    }
        .fi-ts-hospital:before {
    content: "\f764";
    }
        .fi-ts-hospitals:before {
    content: "\f765";
    }
        .fi-ts-hot-tub:before {
    content: "\f766";
    }
        .fi-ts-hotdog:before {
    content: "\f767";
    }
        .fi-ts-hotel:before {
    content: "\f768";
    }
        .fi-ts-hourglass-end:before {
    content: "\f769";
    }
        .fi-ts-hourglass-start:before {
    content: "\f76a";
    }
        .fi-ts-hourglass:before {
    content: "\f76b";
    }
        .fi-ts-house-blank:before {
    content: "\f76c";
    }
        .fi-ts-house-building:before {
    content: "\f76d";
    }
        .fi-ts-house-chimney-blank:before {
    content: "\f76e";
    }
        .fi-ts-house-chimney-crack:before {
    content: "\f76f";
    }
        .fi-ts-house-chimney-heart:before {
    content: "\f770";
    }
        .fi-ts-house-chimney-medical:before {
    content: "\f771";
    }
        .fi-ts-house-chimney-user:before {
    content: "\f772";
    }
        .fi-ts-house-chimney-window:before {
    content: "\f773";
    }
        .fi-ts-house-chimney:before {
    content: "\f774";
    }
        .fi-ts-house-circle-check:before {
    content: "\f775";
    }
        .fi-ts-house-circle-exclamation:before {
    content: "\f776";
    }
        .fi-ts-house-circle-xmark:before {
    content: "\f777";
    }
        .fi-ts-house-crack-alt:before {
    content: "\f778";
    }
        .fi-ts-house-crack:before {
    content: "\f779";
    }
        .fi-ts-house-day:before {
    content: "\f77a";
    }
        .fi-ts-house-fire:before {
    content: "\f77b";
    }
        .fi-ts-house-flag:before {
    content: "\f77c";
    }
        .fi-ts-house-flood:before {
    content: "\f77d";
    }
        .fi-ts-house-laptop:before {
    content: "\f77e";
    }
        .fi-ts-house-leave:before {
    content: "\f77f";
    }
        .fi-ts-house-lock:before {
    content: "\f780";
    }
        .fi-ts-house-medical:before {
    content: "\f781";
    }
        .fi-ts-house-night:before {
    content: "\f782";
    }
        .fi-ts-house-return:before {
    content: "\f783";
    }
        .fi-ts-house-signal:before {
    content: "\f784";
    }
        .fi-ts-house-tree:before {
    content: "\f785";
    }
        .fi-ts-house-tsunami:before {
    content: "\f786";
    }
        .fi-ts-house-turret:before {
    content: "\f787";
    }
        .fi-ts-house-user:before {
    content: "\f788";
    }
        .fi-ts-house-window:before {
    content: "\f789";
    }
        .fi-ts-hr-group:before {
    content: "\f78a";
    }
        .fi-ts-hr-person:before {
    content: "\f78b";
    }
        .fi-ts-hr:before {
    content: "\f78c";
    }
        .fi-ts-hryvnia:before {
    content: "\f78d";
    }
        .fi-ts-humidity:before {
    content: "\f78e";
    }
        .fi-ts-hundred-points:before {
    content: "\f78f";
    }
        .fi-ts-hurricane:before {
    content: "\f790";
    }
        .fi-ts-i:before {
    content: "\f791";
    }
        .fi-ts-ice-cream:before {
    content: "\f792";
    }
        .fi-ts-icicles:before {
    content: "\f793";
    }
        .fi-ts-icon-star:before {
    content: "\f794";
    }
        .fi-ts-id-badge:before {
    content: "\f795";
    }
        .fi-ts-id-card-clip-alt:before {
    content: "\f796";
    }
        .fi-ts-igloo:before {
    content: "\f797";
    }
        .fi-ts-image-slash:before {
    content: "\f798";
    }
        .fi-ts-images-user:before {
    content: "\f799";
    }
        .fi-ts-images:before {
    content: "\f79a";
    }
        .fi-ts-improve-user:before {
    content: "\f79b";
    }
        .fi-ts-inbox-in:before {
    content: "\f79c";
    }
        .fi-ts-inbox-out:before {
    content: "\f79d";
    }
        .fi-ts-inboxes:before {
    content: "\f79e";
    }
        .fi-ts-incense-sticks-yoga:before {
    content: "\f79f";
    }
        .fi-ts-incognito:before {
    content: "\f7a0";
    }
        .fi-ts-indian-rupee-sign:before {
    content: "\f7a1";
    }
        .fi-ts-industry-alt:before {
    content: "\f7a2";
    }
        .fi-ts-industry-windows:before {
    content: "\f7a3";
    }
        .fi-ts-infinity:before {
    content: "\f7a4";
    }
        .fi-ts-info-guide:before {
    content: "\f7a5";
    }
        .fi-ts-information:before {
    content: "\f7a6";
    }
        .fi-ts-inhaler:before {
    content: "\f7a7";
    }
        .fi-ts-input-numeric:before {
    content: "\f7a8";
    }
        .fi-ts-input-pipe:before {
    content: "\f7a9";
    }
        .fi-ts-input-text:before {
    content: "\f7aa";
    }
        .fi-ts-insert-alt:before {
    content: "\f7ab";
    }
        .fi-ts-insert-arrows:before {
    content: "\f7ac";
    }
        .fi-ts-insert-button-circle:before {
    content: "\f7ad";
    }
        .fi-ts-insert-credit-card:before {
    content: "\f7ae";
    }
        .fi-ts-insert-square:before {
    content: "\f7af";
    }
        .fi-ts-insert:before {
    content: "\f7b0";
    }
        .fi-ts-insight-alt:before {
    content: "\f7b1";
    }
        .fi-ts-insight-head:before {
    content: "\f7b2";
    }
        .fi-ts-insight:before {
    content: "\f7b3";
    }
        .fi-ts-integral:before {
    content: "\f7b4";
    }
        .fi-ts-internet-speed-wifi:before {
    content: "\f7b5";
    }
        .fi-ts-intersection:before {
    content: "\f7b6";
    }
        .fi-ts-introduction-handshake:before {
    content: "\f7b7";
    }
        .fi-ts-introduction:before {
    content: "\f7b8";
    }
        .fi-ts-inventory-alt:before {
    content: "\f7b9";
    }
        .fi-ts-invest:before {
    content: "\f7ba";
    }
        .fi-ts-investment:before {
    content: "\f7bb";
    }
        .fi-ts-invite-alt:before {
    content: "\f7bc";
    }
        .fi-ts-invite:before {
    content: "\f7bd";
    }
        .fi-ts-iot-alt:before {
    content: "\f7be";
    }
        .fi-ts-iot:before {
    content: "\f7bf";
    }
        .fi-ts-ip-address:before {
    content: "\f7c0";
    }
        .fi-ts-island-tropical:before {
    content: "\f7c1";
    }
        .fi-ts-issue-loupe:before {
    content: "\f7c2";
    }
        .fi-ts-it-alt:before {
    content: "\f7c3";
    }
        .fi-ts-it-computer:before {
    content: "\f7c4";
    }
        .fi-ts-it:before {
    content: "\f7c5";
    }
        .fi-ts-italian-lira-sign:before {
    content: "\f7c6";
    }
        .fi-ts-j:before {
    content: "\f7c7";
    }
        .fi-ts-jam:before {
    content: "\f7c8";
    }
        .fi-ts-jar-alt:before {
    content: "\f7c9";
    }
        .fi-ts-jar-wheat:before {
    content: "\f7ca";
    }
        .fi-ts-javascript:before {
    content: "\f7cb";
    }
        .fi-ts-joint:before {
    content: "\f7cc";
    }
        .fi-ts-joker:before {
    content: "\f7cd";
    }
        .fi-ts-journal-alt:before {
    content: "\f7ce";
    }
        .fi-ts-journal:before {
    content: "\f7cf";
    }
        .fi-ts-journey:before {
    content: "\f7d0";
    }
        .fi-ts-joystick:before {
    content: "\f7d1";
    }
        .fi-ts-jpg:before {
    content: "\f7d2";
    }
        .fi-ts-jug-alt:before {
    content: "\f7d3";
    }
        .fi-ts-jug-bottle:before {
    content: "\f7d4";
    }
        .fi-ts-jug:before {
    content: "\f7d5";
    }
        .fi-ts-jumping-rope:before {
    content: "\f7d6";
    }
        .fi-ts-k:before {
    content: "\f7d7";
    }
        .fi-ts-kaaba:before {
    content: "\f7d8";
    }
        .fi-ts-kazoo:before {
    content: "\f7d9";
    }
        .fi-ts-kerning:before {
    content: "\f7da";
    }
        .fi-ts-key-lock-crypto:before {
    content: "\f7db";
    }
        .fi-ts-key-skeleton-left-right:before {
    content: "\f7dc";
    }
        .fi-ts-key:before {
    content: "\f7dd";
    }
        .fi-ts-keyboard-brightness-low:before {
    content: "\f7de";
    }
        .fi-ts-keyboard-brightness:before {
    content: "\f7df";
    }
        .fi-ts-keyboard-down:before {
    content: "\f7e0";
    }
        .fi-ts-keyboard-left:before {
    content: "\f7e1";
    }
        .fi-ts-keyboard:before {
    content: "\f7e2";
    }
        .fi-ts-keynote:before {
    content: "\f7e3";
    }
        .fi-ts-kidneys:before {
    content: "\f7e4";
    }
        .fi-ts-kip-sign:before {
    content: "\f7e5";
    }
        .fi-ts-kiss-beam:before {
    content: "\f7e6";
    }
        .fi-ts-kiss-wink-heart:before {
    content: "\f7e7";
    }
        .fi-ts-kiss:before {
    content: "\f7e8";
    }
        .fi-ts-kite:before {
    content: "\f7e9";
    }
        .fi-ts-kiwi-bird:before {
    content: "\f7ea";
    }
        .fi-ts-kiwi-fruit:before {
    content: "\f7eb";
    }
        .fi-ts-knife-kitchen:before {
    content: "\f7ec";
    }
        .fi-ts-knife:before {
    content: "\f7ed";
    }
        .fi-ts-knitting:before {
    content: "\f7ee";
    }
        .fi-ts-kpi-evaluation:before {
    content: "\f7ef";
    }
        .fi-ts-kpi:before {
    content: "\f7f0";
    }
        .fi-ts-l:before {
    content: "\f7f1";
    }
        .fi-ts-lacrosse-stick-ball:before {
    content: "\f7f2";
    }
        .fi-ts-lacrosse-stick:before {
    content: "\f7f3";
    }
        .fi-ts-lambda:before {
    content: "\f7f4";
    }
        .fi-ts-lamp-desk:before {
    content: "\f7f5";
    }
        .fi-ts-lamp-floor:before {
    content: "\f7f6";
    }
        .fi-ts-lamp-street:before {
    content: "\f7f7";
    }
        .fi-ts-lamp:before {
    content: "\f7f8";
    }
        .fi-ts-land-layer-location:before {
    content: "\f7f9";
    }
        .fi-ts-land-layers:before {
    content: "\f7fa";
    }
        .fi-ts-land-location:before {
    content: "\f7fb";
    }
        .fi-ts-landmark-alt:before {
    content: "\f7fc";
    }
        .fi-ts-language:before {
    content: "\f7fd";
    }
        .fi-ts-laptop-arrow-down:before {
    content: "\f7fe";
    }
        .fi-ts-laptop-binary:before {
    content: "\f7ff";
    }
        .fi-ts-laptop-code:before {
    content: "\f800";
    }
        .fi-ts-laptop-medical:before {
    content: "\f801";
    }
        .fi-ts-laptop-mobile:before {
    content: "\f802";
    }
        .fi-ts-laptop-slash:before {
    content: "\f803";
    }
        .fi-ts-laptop:before {
    content: "\f804";
    }
        .fi-ts-lari-sign:before {
    content: "\f805";
    }
        .fi-ts-lasso-sparkles:before {
    content: "\f806";
    }
        .fi-ts-last-square:before {
    content: "\f807";
    }
        .fi-ts-laugh-beam:before {
    content: "\f808";
    }
        .fi-ts-laugh-squint:before {
    content: "\f809";
    }
        .fi-ts-laugh-wink:before {
    content: "\f80a";
    }
        .fi-ts-laugh:before {
    content: "\f80b";
    }
        .fi-ts-lawyer-man:before {
    content: "\f80c";
    }
        .fi-ts-lawyer-woman:before {
    content: "\f80d";
    }
        .fi-ts-layer-minus:before {
    content: "\f80e";
    }
        .fi-ts-layer-plus:before {
    content: "\f80f";
    }
        .fi-ts-lead-funnel:before {
    content: "\f810";
    }
        .fi-ts-lead-management:before {
    content: "\f811";
    }
        .fi-ts-lead:before {
    content: "\f812";
    }
        .fi-ts-leader-alt:before {
    content: "\f813";
    }
        .fi-ts-leader-speech:before {
    content: "\f814";
    }
        .fi-ts-leader:before {
    content: "\f815";
    }
        .fi-ts-leaderboard-alt:before {
    content: "\f816";
    }
        .fi-ts-leaderboard-trophy:before {
    content: "\f817";
    }
        .fi-ts-leaderboard:before {
    content: "\f818";
    }
        .fi-ts-leadership-alt:before {
    content: "\f819";
    }
        .fi-ts-leadership:before {
    content: "\f81a";
    }
        .fi-ts-leaf-heart:before {
    content: "\f81b";
    }
        .fi-ts-leaf-maple:before {
    content: "\f81c";
    }
        .fi-ts-leaf-oak:before {
    content: "\f81d";
    }
        .fi-ts-leaf:before {
    content: "\f81e";
    }
        .fi-ts-leafy-green:before {
    content: "\f81f";
    }
        .fi-ts-leave:before {
    content: "\f820";
    }
        .fi-ts-left-from-bracket:before {
    content: "\f821";
    }
        .fi-ts-left:before {
    content: "\f822";
    }
        .fi-ts-legal:before {
    content: "\f823";
    }
        .fi-ts-lemon:before {
    content: "\f824";
    }
        .fi-ts-lesbian-couple:before {
    content: "\f825";
    }
        .fi-ts-less-than-equal:before {
    content: "\f826";
    }
        .fi-ts-less-than:before {
    content: "\f827";
    }
        .fi-ts-lesson-class:before {
    content: "\f828";
    }
        .fi-ts-lesson:before {
    content: "\f829";
    }
        .fi-ts-lettuce:before {
    content: "\f82a";
    }
        .fi-ts-level-down-alt:before {
    content: "\f82b";
    }
        .fi-ts-level-down:before {
    content: "\f82c";
    }
        .fi-ts-level-up-alt:before {
    content: "\f82d";
    }
        .fi-ts-level-up:before {
    content: "\f82e";
    }
        .fi-ts-license:before {
    content: "\f82f";
    }
        .fi-ts-life:before {
    content: "\f830";
    }
        .fi-ts-light-ceiling:before {
    content: "\f831";
    }
        .fi-ts-light-emergency-on:before {
    content: "\f832";
    }
        .fi-ts-light-emergency:before {
    content: "\f833";
    }
        .fi-ts-light-switch-off:before {
    content: "\f834";
    }
        .fi-ts-light-switch-on:before {
    content: "\f835";
    }
        .fi-ts-light-switch:before {
    content: "\f836";
    }
        .fi-ts-lightbulb-cfl-on:before {
    content: "\f837";
    }
        .fi-ts-lightbulb-cfl:before {
    content: "\f838";
    }
        .fi-ts-lightbulb-dollar:before {
    content: "\f839";
    }
        .fi-ts-lightbulb-exclamation:before {
    content: "\f83a";
    }
        .fi-ts-lightbulb-head:before {
    content: "\f83b";
    }
        .fi-ts-lightbulb-on:before {
    content: "\f83c";
    }
        .fi-ts-lightbulb-question:before {
    content: "\f83d";
    }
        .fi-ts-lightbulb-setting:before {
    content: "\f83e";
    }
        .fi-ts-lightbulb-slash:before {
    content: "\f83f";
    }
        .fi-ts-lighthouse:before {
    content: "\f840";
    }
        .fi-ts-lights-holiday:before {
    content: "\f841";
    }
        .fi-ts-limit-hand:before {
    content: "\f842";
    }
        .fi-ts-limit-speedometer:before {
    content: "\f843";
    }
        .fi-ts-link-alt:before {
    content: "\f844";
    }
        .fi-ts-link-horizontal-slash:before {
    content: "\f845";
    }
        .fi-ts-link-horizontal:before {
    content: "\f846";
    }
        .fi-ts-link-slash-alt:before {
    content: "\f847";
    }
        .fi-ts-link-slash:before {
    content: "\f848";
    }
        .fi-ts-lion-head:before {
    content: "\f849";
    }
        .fi-ts-lion:before {
    content: "\f84a";
    }
        .fi-ts-lips:before {
    content: "\f84b";
    }
        .fi-ts-lipstick:before {
    content: "\f84c";
    }
        .fi-ts-lira-sign:before {
    content: "\f84d";
    }
        .fi-ts-list-check:before {
    content: "\f84e";
    }
        .fi-ts-list-dropdown:before {
    content: "\f84f";
    }
        .fi-ts-list-music:before {
    content: "\f850";
    }
        .fi-ts-list-timeline:before {
    content: "\f851";
    }
        .fi-ts-litecoin-sign:before {
    content: "\f852";
    }
        .fi-ts-live-alt:before {
    content: "\f853";
    }
        .fi-ts-live:before {
    content: "\f854";
    }
        .fi-ts-loading:before {
    content: "\f855";
    }
        .fi-ts-loan:before {
    content: "\f856";
    }
        .fi-ts-lobster:before {
    content: "\f857";
    }
        .fi-ts-location-alt:before {
    content: "\f858";
    }
        .fi-ts-location-arrow:before {
    content: "\f859";
    }
        .fi-ts-location-crosshairs-slash:before {
    content: "\f85a";
    }
        .fi-ts-location-crosshairs:before {
    content: "\f85b";
    }
        .fi-ts-location-dot-slash:before {
    content: "\f85c";
    }
        .fi-ts-location-exclamation:before {
    content: "\f85d";
    }
        .fi-ts-lock-alt:before {
    content: "\f85e";
    }
        .fi-ts-lock-hashtag:before {
    content: "\f85f";
    }
        .fi-ts-lock-open-alt:before {
    content: "\f860";
    }
        .fi-ts-locust:before {
    content: "\f861";
    }
        .fi-ts-loop-square:before {
    content: "\f862";
    }
        .fi-ts-loveseat:before {
    content: "\f863";
    }
        .fi-ts-low-vision:before {
    content: "\f864";
    }
        .fi-ts-luchador:before {
    content: "\f865";
    }
        .fi-ts-luggage-cart:before {
    content: "\f866";
    }
        .fi-ts-lungs-virus:before {
    content: "\f867";
    }
        .fi-ts-lungs:before {
    content: "\f868";
    }
        .fi-ts-m:before {
    content: "\f869";
    }
        .fi-ts-mace:before {
    content: "\f86a";
    }
        .fi-ts-magnet-user:before {
    content: "\f86b";
    }
        .fi-ts-magnet:before {
    content: "\f86c";
    }
        .fi-ts-mailbox:before {
    content: "\f86d";
    }
        .fi-ts-makeup-brush:before {
    content: "\f86e";
    }
        .fi-ts-man-head:before {
    content: "\f86f";
    }
        .fi-ts-man-scientist:before {
    content: "\f870";
    }
        .fi-ts-manat-sign:before {
    content: "\f871";
    }
        .fi-ts-mandolin:before {
    content: "\f872";
    }
        .fi-ts-mango:before {
    content: "\f873";
    }
        .fi-ts-manhole:before {
    content: "\f874";
    }
        .fi-ts-map-location-track:before {
    content: "\f875";
    }
        .fi-ts-map-marker-check:before {
    content: "\f876";
    }
        .fi-ts-map-marker-edit:before {
    content: "\f877";
    }
        .fi-ts-map-marker-question:before {
    content: "\f878";
    }
        .fi-ts-map-marker-slash:before {
    content: "\f879";
    }
        .fi-ts-map-marker-smile:before {
    content: "\f87a";
    }
        .fi-ts-map-pin:before {
    content: "\f87b";
    }
        .fi-ts-map-point:before {
    content: "\f87c";
    }
        .fi-ts-marker-time:before {
    content: "\f87d";
    }
        .fi-ts-marketplace-alt:before {
    content: "\f87e";
    }
        .fi-ts-marketplace-store:before {
    content: "\f87f";
    }
        .fi-ts-marketplace:before {
    content: "\f880";
    }
        .fi-ts-mars-double:before {
    content: "\f881";
    }
        .fi-ts-mars-stroke-right:before {
    content: "\f882";
    }
        .fi-ts-mars-stroke-up:before {
    content: "\f883";
    }
        .fi-ts-mars:before {
    content: "\f884";
    }
        .fi-ts-martini-glass-citrus:before {
    content: "\f885";
    }
        .fi-ts-martini-glass-empty:before {
    content: "\f886";
    }
        .fi-ts-mask-carnival:before {
    content: "\f887";
    }
        .fi-ts-mask-face:before {
    content: "\f888";
    }
        .fi-ts-mask-snorkel:before {
    content: "\f889";
    }
        .fi-ts-massage:before {
    content: "\f88a";
    }
        .fi-ts-match-fire:before {
    content: "\f88b";
    }
        .fi-ts-mattress-pillow:before {
    content: "\f88c";
    }
        .fi-ts-measuring-tape:before {
    content: "\f88d";
    }
        .fi-ts-meat:before {
    content: "\f88e";
    }
        .fi-ts-medal:before {
    content: "\f88f";
    }
        .fi-ts-medical-star:before {
    content: "\f890";
    }
        .fi-ts-medicine:before {
    content: "\f891";
    }
        .fi-ts-meditation:before {
    content: "\f892";
    }
        .fi-ts-meeting-alt:before {
    content: "\f893";
    }
        .fi-ts-meeting:before {
    content: "\f894";
    }
        .fi-ts-megaphone-announcement-leader:before {
    content: "\f895";
    }
        .fi-ts-megaphone:before {
    content: "\f896";
    }
        .fi-ts-meh-blank:before {
    content: "\f897";
    }
        .fi-ts-meh-rolling-eyes:before {
    content: "\f898";
    }
        .fi-ts-meh:before {
    content: "\f899";
    }
        .fi-ts-melon-alt:before {
    content: "\f89a";
    }
        .fi-ts-melon:before {
    content: "\f89b";
    }
        .fi-ts-member-list:before {
    content: "\f89c";
    }
        .fi-ts-member-search:before {
    content: "\f89d";
    }
        .fi-ts-membership-vip:before {
    content: "\f89e";
    }
        .fi-ts-membership:before {
    content: "\f89f";
    }
        .fi-ts-memo-circle-check:before {
    content: "\f8a0";
    }
        .fi-ts-memo-pad:before {
    content: "\f8a1";
    }
        .fi-ts-memo:before {
    content: "\f8a2";
    }
        .fi-ts-memory:before {
    content: "\f8a3";
    }
        .fi-ts-mercury:before {
    content: "\f8a4";
    }
        .fi-ts-message-arrow-down:before {
    content: "\f8a5";
    }
        .fi-ts-message-arrow-up-right:before {
    content: "\f8a6";
    }
        .fi-ts-message-arrow-up:before {
    content: "\f8a7";
    }
        .fi-ts-message-bot:before {
    content: "\f8a8";
    }
        .fi-ts-message-code:before {
    content: "\f8a9";
    }
        .fi-ts-message-dollar:before {
    content: "\f8aa";
    }
        .fi-ts-message-heart:before {
    content: "\f8ab";
    }
        .fi-ts-message-image:before {
    content: "\f8ac";
    }
        .fi-ts-message-question:before {
    content: "\f8ad";
    }
        .fi-ts-message-quote:before {
    content: "\f8ae";
    }
        .fi-ts-message-slash:before {
    content: "\f8af";
    }
        .fi-ts-message-sms:before {
    content: "\f8b0";
    }
        .fi-ts-message-text:before {
    content: "\f8b1";
    }
        .fi-ts-message-xmark:before {
    content: "\f8b2";
    }
        .fi-ts-messages-dollar:before {
    content: "\f8b3";
    }
        .fi-ts-messages-question:before {
    content: "\f8b4";
    }
        .fi-ts-messages:before {
    content: "\f8b5";
    }
        .fi-ts-meteor:before {
    content: "\f8b6";
    }
        .fi-ts-meter-bolt:before {
    content: "\f8b7";
    }
        .fi-ts-meter-droplet:before {
    content: "\f8b8";
    }
        .fi-ts-meter-fire:before {
    content: "\f8b9";
    }
        .fi-ts-meter:before {
    content: "\f8ba";
    }
        .fi-ts-method:before {
    content: "\f8bb";
    }
        .fi-ts-microchip-ai:before {
    content: "\f8bc";
    }
        .fi-ts-microchip:before {
    content: "\f8bd";
    }
        .fi-ts-microphone-alt:before {
    content: "\f8be";
    }
        .fi-ts-microphone-slash:before {
    content: "\f8bf";
    }
        .fi-ts-microscope:before {
    content: "\f8c0";
    }
        .fi-ts-microwave:before {
    content: "\f8c1";
    }
        .fi-ts-milk-alt:before {
    content: "\f8c2";
    }
        .fi-ts-milk:before {
    content: "\f8c3";
    }
        .fi-ts-mill-sign:before {
    content: "\f8c4";
    }
        .fi-ts-mind-share:before {
    content: "\f8c5";
    }
        .fi-ts-minus-circle:before {
    content: "\f8c6";
    }
        .fi-ts-minus-hexagon:before {
    content: "\f8c7";
    }
        .fi-ts-mistletoe:before {
    content: "\f8c8";
    }
        .fi-ts-mix:before {
    content: "\f8c9";
    }
        .fi-ts-mobile-button:before {
    content: "\f8ca";
    }
        .fi-ts-mobile-notch:before {
    content: "\f8cb";
    }
        .fi-ts-mobile:before {
    content: "\f8cc";
    }
        .fi-ts-mockup:before {
    content: "\f8cd";
    }
        .fi-ts-mode-alt:before {
    content: "\f8ce";
    }
        .fi-ts-mode:before {
    content: "\f8cf";
    }
        .fi-ts-model-cube-arrows:before {
    content: "\f8d0";
    }
        .fi-ts-model-cube-space:before {
    content: "\f8d1";
    }
        .fi-ts-model-cube:before {
    content: "\f8d2";
    }
        .fi-ts-module:before {
    content: "\f8d3";
    }
        .fi-ts-monday:before {
    content: "\f8d4";
    }
        .fi-ts-money-bill-simple:before {
    content: "\f8d5";
    }
        .fi-ts-money-bill-transfer:before {
    content: "\f8d6";
    }
        .fi-ts-money-bill-wave-alt:before {
    content: "\f8d7";
    }
        .fi-ts-money-bill-wave:before {
    content: "\f8d8";
    }
        .fi-ts-money-bills-simple:before {
    content: "\f8d9";
    }
        .fi-ts-money-bills:before {
    content: "\f8da";
    }
        .fi-ts-money-check-edit-alt:before {
    content: "\f8db";
    }
        .fi-ts-money-check-edit:before {
    content: "\f8dc";
    }
        .fi-ts-money-check:before {
    content: "\f8dd";
    }
        .fi-ts-money-coin-transfer:before {
    content: "\f8de";
    }
        .fi-ts-money-from-bracket:before {
    content: "\f8df";
    }
        .fi-ts-money-simple-from-bracket:before {
    content: "\f8e0";
    }
        .fi-ts-money-transfer-alt:before {
    content: "\f8e1";
    }
        .fi-ts-money-transfer-coin-arrow:before {
    content: "\f8e2";
    }
        .fi-ts-money-transfer-smartphone:before {
    content: "\f8e3";
    }
        .fi-ts-money-wings:before {
    content: "\f8e4";
    }
        .fi-ts-money:before {
    content: "\f8e5";
    }
        .fi-ts-monkey:before {
    content: "\f8e6";
    }
        .fi-ts-monument:before {
    content: "\f8e7";
    }
        .fi-ts-moon-stars:before {
    content: "\f8e8";
    }
        .fi-ts-moon:before {
    content: "\f8e9";
    }
        .fi-ts-moped:before {
    content: "\f8ea";
    }
        .fi-ts-mortar-pestle:before {
    content: "\f8eb";
    }
        .fi-ts-mortgage:before {
    content: "\f8ec";
    }
        .fi-ts-mosque-alt:before {
    content: "\f8ed";
    }
        .fi-ts-mosque-moon:before {
    content: "\f8ee";
    }
        .fi-ts-mosque:before {
    content: "\f8ef";
    }
        .fi-ts-mosquito-net:before {
    content: "\f8f0";
    }
        .fi-ts-mosquito:before {
    content: "\f8f1";
    }
        .fi-ts-motorcycle:before {
    content: "\f8f2";
    }
        .fi-ts-mound:before {
    content: "\f8f3";
    }
        .fi-ts-mountain-city:before {
    content: "\f8f4";
    }
        .fi-ts-mountain:before {
    content: "\f8f5";
    }
        .fi-ts-mountains:before {
    content: "\f8f6";
    }
        .fi-ts-mouse-field:before {
    content: "\f8f7";
    }
        .fi-ts-mouse:before {
    content: "\f8f8";
    }
        .fi-ts-mov-file:before {
    content: "\f8f9";
    }
        .fi-ts-move-to-folder-2:before {
    content: "\f8fa";
    }
        .fi-ts-move-to-folder:before {
    content: "\f8fb";
    }
        .fi-ts-mp3-file:before {
    content: "\f8fc";
    }
        .fi-ts-mp3-player:before {
    content: "\f8fd";
    }
        .fi-ts-mp4-file:before {
    content: "\f8fe";
    }
        .fi-ts-mug-alt:before {
    content: "\f8ff";
    }
        .fi-ts-mug-hot-alt:before {
    content: "\f900";
    }
        .fi-ts-mug-hot:before {
    content: "\f901";
    }
        .fi-ts-mug-marshmallows:before {
    content: "\f902";
    }
        .fi-ts-mug-tea-saucer:before {
    content: "\f903";
    }
        .fi-ts-mug-tea:before {
    content: "\f904";
    }
        .fi-ts-mug:before {
    content: "\f905";
    }
        .fi-ts-multiple-alt:before {
    content: "\f906";
    }
        .fi-ts-multiple:before {
    content: "\f907";
    }
        .fi-ts-muscle:before {
    content: "\f908";
    }
        .fi-ts-mushroom-alt:before {
    content: "\f909";
    }
        .fi-ts-mushroom:before {
    content: "\f90a";
    }
        .fi-ts-music-file:before {
    content: "\f90b";
    }
        .fi-ts-music-note-slash:before {
    content: "\f90c";
    }
        .fi-ts-music-note:before {
    content: "\f90d";
    }
        .fi-ts-music-slash:before {
    content: "\f90e";
    }
        .fi-ts-n:before {
    content: "\f90f";
    }
        .fi-ts-naira-sign:before {
    content: "\f910";
    }
        .fi-ts-narwhal:before {
    content: "\f911";
    }
        .fi-ts-nesting-dolls:before {
    content: "\f912";
    }
        .fi-ts-network-analytic:before {
    content: "\f913";
    }
        .fi-ts-network-cloud:before {
    content: "\f914";
    }
        .fi-ts-network:before {
    content: "\f915";
    }
        .fi-ts-neuter:before {
    content: "\f916";
    }
        .fi-ts-newsletter-subscribe:before {
    content: "\f917";
    }
        .fi-ts-newspaper-open:before {
    content: "\f918";
    }
        .fi-ts-newspaper:before {
    content: "\f919";
    }
        .fi-ts-nfc-lock:before {
    content: "\f91a";
    }
        .fi-ts-nfc-magnifying-glass:before {
    content: "\f91b";
    }
        .fi-ts-nfc-pen:before {
    content: "\f91c";
    }
        .fi-ts-nfc-slash:before {
    content: "\f91d";
    }
        .fi-ts-nfc-trash:before {
    content: "\f91e";
    }
        .fi-ts-nfc:before {
    content: "\f91f";
    }
        .fi-ts-night-day:before {
    content: "\f920";
    }
        .fi-ts-no-fee:before {
    content: "\f921";
    }
        .fi-ts-no-people:before {
    content: "\f922";
    }
        .fi-ts-noodles:before {
    content: "\f923";
    }
        .fi-ts-nose:before {
    content: "\f924";
    }
        .fi-ts-not-equal:before {
    content: "\f925";
    }
        .fi-ts-not-found-alt:before {
    content: "\f926";
    }
        .fi-ts-not-found-magnifying-glass:before {
    content: "\f927";
    }
        .fi-ts-not-found:before {
    content: "\f928";
    }
        .fi-ts-notdef:before {
    content: "\f929";
    }
        .fi-ts-note-medical:before {
    content: "\f92a";
    }
        .fi-ts-note-sticky:before {
    content: "\f92b";
    }
        .fi-ts-note:before {
    content: "\f92c";
    }
        .fi-ts-notebook-alt:before {
    content: "\f92d";
    }
        .fi-ts-notebook:before {
    content: "\f92e";
    }
        .fi-ts-notes-medical:before {
    content: "\f92f";
    }
        .fi-ts-notes:before {
    content: "\f930";
    }
        .fi-ts-o:before {
    content: "\f931";
    }
        .fi-ts-object-exclude:before {
    content: "\f932";
    }
        .fi-ts-object-group:before {
    content: "\f933";
    }
        .fi-ts-object-intersect:before {
    content: "\f934";
    }
        .fi-ts-object-subtract:before {
    content: "\f935";
    }
        .fi-ts-object-ungroup:before {
    content: "\f936";
    }
        .fi-ts-object-union:before {
    content: "\f937";
    }
        .fi-ts-objects-column:before {
    content: "\f938";
    }
        .fi-ts-octagon-check:before {
    content: "\f939";
    }
        .fi-ts-octagon-divide:before {
    content: "\f93a";
    }
        .fi-ts-octagon-exclamation:before {
    content: "\f93b";
    }
        .fi-ts-octagon-minus:before {
    content: "\f93c";
    }
        .fi-ts-octagon-plus:before {
    content: "\f93d";
    }
        .fi-ts-octagon-xmark:before {
    content: "\f93e";
    }
        .fi-ts-octagon:before {
    content: "\f93f";
    }
        .fi-ts-oil-can:before {
    content: "\f940";
    }
        .fi-ts-oil-temp:before {
    content: "\f941";
    }
        .fi-ts-olive-oil:before {
    content: "\f942";
    }
        .fi-ts-olive:before {
    content: "\f943";
    }
        .fi-ts-olives:before {
    content: "\f944";
    }
        .fi-ts-om:before {
    content: "\f945";
    }
        .fi-ts-omega:before {
    content: "\f946";
    }
        .fi-ts-onboarding:before {
    content: "\f947";
    }
        .fi-ts-onion:before {
    content: "\f948";
    }
        .fi-ts-open-mail-clip:before {
    content: "\f949";
    }
        .fi-ts-operating-system-upgrade:before {
    content: "\f94a";
    }
        .fi-ts-operation:before {
    content: "\f94b";
    }
        .fi-ts-order-history:before {
    content: "\f94c";
    }
        .fi-ts-organization-chart:before {
    content: "\f94d";
    }
        .fi-ts-ornament:before {
    content: "\f94e";
    }
        .fi-ts-otp:before {
    content: "\f94f";
    }
        .fi-ts-otter:before {
    content: "\f950";
    }
        .fi-ts-outdent:before {
    content: "\f951";
    }
        .fi-ts-oval-alt:before {
    content: "\f952";
    }
        .fi-ts-oval:before {
    content: "\f953";
    }
        .fi-ts-oven:before {
    content: "\f954";
    }
        .fi-ts-overline:before {
    content: "\f955";
    }
        .fi-ts-overview:before {
    content: "\f956";
    }
        .fi-ts-p:before {
    content: "\f957";
    }
        .fi-ts-package:before {
    content: "\f958";
    }
        .fi-ts-padlock-check:before {
    content: "\f959";
    }
        .fi-ts-page-break:before {
    content: "\f95a";
    }
        .fi-ts-pager:before {
    content: "\f95b";
    }
        .fi-ts-paid:before {
    content: "\f95c";
    }
        .fi-ts-paint-roller:before {
    content: "\f95d";
    }
        .fi-ts-paintbrush-pencil:before {
    content: "\f95e";
    }
        .fi-ts-pallet-alt:before {
    content: "\f95f";
    }
        .fi-ts-pallet:before {
    content: "\f960";
    }
        .fi-ts-pan-food:before {
    content: "\f961";
    }
        .fi-ts-pan-frying:before {
    content: "\f962";
    }
        .fi-ts-pan:before {
    content: "\f963";
    }
        .fi-ts-pancakes:before {
    content: "\f964";
    }
        .fi-ts-panorama:before {
    content: "\f965";
    }
        .fi-ts-paper-plane-top:before {
    content: "\f966";
    }
        .fi-ts-paper-plane:before {
    content: "\f967";
    }
        .fi-ts-paperclip-vertical:before {
    content: "\f968";
    }
        .fi-ts-parachute-box:before {
    content: "\f969";
    }
        .fi-ts-paragraph-left:before {
    content: "\f96a";
    }
        .fi-ts-paragraph:before {
    content: "\f96b";
    }
        .fi-ts-parking-circle-slash:before {
    content: "\f96c";
    }
        .fi-ts-parking-circle:before {
    content: "\f96d";
    }
        .fi-ts-parking-slash:before {
    content: "\f96e";
    }
        .fi-ts-parking:before {
    content: "\f96f";
    }
        .fi-ts-party-bell:before {
    content: "\f970";
    }
        .fi-ts-party-horn:before {
    content: "\f971";
    }
        .fi-ts-passport:before {
    content: "\f972";
    }
        .fi-ts-password-alt:before {
    content: "\f973";
    }
        .fi-ts-password-computer:before {
    content: "\f974";
    }
        .fi-ts-password-email:before {
    content: "\f975";
    }
        .fi-ts-password-smartphone:before {
    content: "\f976";
    }
        .fi-ts-password:before {
    content: "\f977";
    }
        .fi-ts-paste:before {
    content: "\f978";
    }
        .fi-ts-pattern:before {
    content: "\f979";
    }
        .fi-ts-pause-circle:before {
    content: "\f97a";
    }
        .fi-ts-pause-square:before {
    content: "\f97b";
    }
        .fi-ts-paw-claws:before {
    content: "\f97c";
    }
        .fi-ts-paw-heart:before {
    content: "\f97d";
    }
        .fi-ts-paw:before {
    content: "\f97e";
    }
        .fi-ts-payment-pos:before {
    content: "\f97f";
    }
        .fi-ts-payroll-calendar:before {
    content: "\f980";
    }
        .fi-ts-payroll-check:before {
    content: "\f981";
    }
        .fi-ts-payroll:before {
    content: "\f982";
    }
        .fi-ts-peace:before {
    content: "\f983";
    }
        .fi-ts-peach:before {
    content: "\f984";
    }
        .fi-ts-peanut:before {
    content: "\f985";
    }
        .fi-ts-peanuts:before {
    content: "\f986";
    }
        .fi-ts-peapod:before {
    content: "\f987";
    }
        .fi-ts-pear:before {
    content: "\f988";
    }
        .fi-ts-pedestal:before {
    content: "\f989";
    }
        .fi-ts-pen-circle:before {
    content: "\f98a";
    }
        .fi-ts-pen-clip-slash:before {
    content: "\f98b";
    }
        .fi-ts-pen-clip:before {
    content: "\f98c";
    }
        .fi-ts-pen-fancy-slash:before {
    content: "\f98d";
    }
        .fi-ts-pen-fancy:before {
    content: "\f98e";
    }
        .fi-ts-pen-field:before {
    content: "\f98f";
    }
        .fi-ts-pen-nib-slash:before {
    content: "\f990";
    }
        .fi-ts-pen-nib:before {
    content: "\f991";
    }
        .fi-ts-pen-slash:before {
    content: "\f992";
    }
        .fi-ts-pen-square:before {
    content: "\f993";
    }
        .fi-ts-pen-swirl:before {
    content: "\f994";
    }
        .fi-ts-pencil-paintbrush:before {
    content: "\f995";
    }
        .fi-ts-pencil-ruler:before {
    content: "\f996";
    }
        .fi-ts-pencil-slash:before {
    content: "\f997";
    }
        .fi-ts-pending:before {
    content: "\f998";
    }
        .fi-ts-pennant:before {
    content: "\f999";
    }
        .fi-ts-people-arrows-left-right:before {
    content: "\f99a";
    }
        .fi-ts-people-carry-box:before {
    content: "\f99b";
    }
        .fi-ts-people-dress:before {
    content: "\f99c";
    }
        .fi-ts-people-network-partner:before {
    content: "\f99d";
    }
        .fi-ts-people-pants:before {
    content: "\f99e";
    }
        .fi-ts-people-poll:before {
    content: "\f99f";
    }
        .fi-ts-people-pulling:before {
    content: "\f9a0";
    }
        .fi-ts-people-roof:before {
    content: "\f9a1";
    }
        .fi-ts-people:before {
    content: "\f9a2";
    }
        .fi-ts-pepper-alt:before {
    content: "\f9a3";
    }
        .fi-ts-pepper-hot:before {
    content: "\f9a4";
    }
        .fi-ts-pepper:before {
    content: "\f9a5";
    }
        .fi-ts-percent-10:before {
    content: "\f9a6";
    }
        .fi-ts-percent-100:before {
    content: "\f9a7";
    }
        .fi-ts-percent-20:before {
    content: "\f9a8";
    }
        .fi-ts-percent-25:before {
    content: "\f9a9";
    }
        .fi-ts-percent-30:before {
    content: "\f9aa";
    }
        .fi-ts-percent-40:before {
    content: "\f9ab";
    }
        .fi-ts-percent-50:before {
    content: "\f9ac";
    }
        .fi-ts-percent-60:before {
    content: "\f9ad";
    }
        .fi-ts-percent-70:before {
    content: "\f9ae";
    }
        .fi-ts-percent-75:before {
    content: "\f9af";
    }
        .fi-ts-percent-80:before {
    content: "\f9b0";
    }
        .fi-ts-percent-90:before {
    content: "\f9b1";
    }
        .fi-ts-percentage:before {
    content: "\f9b2";
    }
        .fi-ts-person-burst:before {
    content: "\f9b3";
    }
        .fi-ts-person-carry-box:before {
    content: "\f9b4";
    }
        .fi-ts-person-circle-check:before {
    content: "\f9b5";
    }
        .fi-ts-person-circle-exclamation:before {
    content: "\f9b6";
    }
        .fi-ts-person-circle-minus:before {
    content: "\f9b7";
    }
        .fi-ts-person-circle-plus:before {
    content: "\f9b8";
    }
        .fi-ts-person-circle-question:before {
    content: "\f9b9";
    }
        .fi-ts-person-circle-xmark:before {
    content: "\f9ba";
    }
        .fi-ts-person-cv:before {
    content: "\f9bb";
    }
        .fi-ts-person-dolly-empty:before {
    content: "\f9bc";
    }
        .fi-ts-person-dolly:before {
    content: "\f9bd";
    }
        .fi-ts-person-dress-simple:before {
    content: "\f9be";
    }
        .fi-ts-person-dress:before {
    content: "\f9bf";
    }
        .fi-ts-person-luggage:before {
    content: "\f9c0";
    }
        .fi-ts-person-lunge:before {
    content: "\f9c1";
    }
        .fi-ts-person-pilates:before {
    content: "\f9c2";
    }
        .fi-ts-person-praying:before {
    content: "\f9c3";
    }
        .fi-ts-person-pregnant:before {
    content: "\f9c4";
    }
        .fi-ts-person-seat-reclined:before {
    content: "\f9c5";
    }
        .fi-ts-person-seat:before {
    content: "\f9c6";
    }
        .fi-ts-person-shelter:before {
    content: "\f9c7";
    }
        .fi-ts-person-sign:before {
    content: "\f9c8";
    }
        .fi-ts-person-simple:before {
    content: "\f9c9";
    }
        .fi-ts-person-walking-with-cane:before {
    content: "\f9ca";
    }
        .fi-ts-peseta-sign:before {
    content: "\f9cb";
    }
        .fi-ts-peso-sign:before {
    content: "\f9cc";
    }
        .fi-ts-pets:before {
    content: "\f9cd";
    }
        .fi-ts-pharmacy:before {
    content: "\f9ce";
    }
        .fi-ts-phone-call:before {
    content: "\f9cf";
    }
        .fi-ts-phone-cross:before {
    content: "\f9d0";
    }
        .fi-ts-phone-flip:before {
    content: "\f9d1";
    }
        .fi-ts-phone-heart-message:before {
    content: "\f9d2";
    }
        .fi-ts-phone-office:before {
    content: "\f9d3";
    }
        .fi-ts-phone-pause:before {
    content: "\f9d4";
    }
        .fi-ts-phone-plus:before {
    content: "\f9d5";
    }
        .fi-ts-phone-rotary:before {
    content: "\f9d6";
    }
        .fi-ts-phone-slash:before {
    content: "\f9d7";
    }
        .fi-ts-photo-capture:before {
    content: "\f9d8";
    }
        .fi-ts-photo-film-music:before {
    content: "\f9d9";
    }
        .fi-ts-photo-video:before {
    content: "\f9da";
    }
        .fi-ts-physics:before {
    content: "\f9db";
    }
        .fi-ts-Pi:before {
    content: "\f9dc";
    }
        .fi-ts-piano-keyboard:before {
    content: "\f9dd";
    }
        .fi-ts-piano:before {
    content: "\f9de";
    }
        .fi-ts-pickaxe:before {
    content: "\f9df";
    }
        .fi-ts-picking-box:before {
    content: "\f9e0";
    }
        .fi-ts-picking:before {
    content: "\f9e1";
    }
        .fi-ts-picnic:before {
    content: "\f9e2";
    }
        .fi-ts-picpeople-filled:before {
    content: "\f9e3";
    }
        .fi-ts-picpeople:before {
    content: "\f9e4";
    }
        .fi-ts-pie:before {
    content: "\f9e5";
    }
        .fi-ts-pig-bank-bulb:before {
    content: "\f9e6";
    }
        .fi-ts-pig-face:before {
    content: "\f9e7";
    }
        .fi-ts-pig:before {
    content: "\f9e8";
    }
        .fi-ts-piggy-bank-budget:before {
    content: "\f9e9";
    }
        .fi-ts-piggy-bank:before {
    content: "\f9ea";
    }
        .fi-ts-pills:before {
    content: "\f9eb";
    }
        .fi-ts-pinata:before {
    content: "\f9ec";
    }
        .fi-ts-pineapple-alt:before {
    content: "\f9ed";
    }
        .fi-ts-pineapple:before {
    content: "\f9ee";
    }
        .fi-ts-pipe-smoking:before {
    content: "\f9ef";
    }
        .fi-ts-pizza-slice:before {
    content: "\f9f0";
    }
        .fi-ts-place-of-worship:before {
    content: "\f9f1";
    }
        .fi-ts-plan-strategy:before {
    content: "\f9f2";
    }
        .fi-ts-plan:before {
    content: "\f9f3";
    }
        .fi-ts-plane-alt:before {
    content: "\f9f4";
    }
        .fi-ts-plane-arrival:before {
    content: "\f9f5";
    }
        .fi-ts-plane-departure:before {
    content: "\f9f6";
    }
        .fi-ts-plane-prop:before {
    content: "\f9f7";
    }
        .fi-ts-plane-slash:before {
    content: "\f9f8";
    }
        .fi-ts-plane-tail:before {
    content: "\f9f9";
    }
        .fi-ts-planet-moon:before {
    content: "\f9fa";
    }
        .fi-ts-planet-ringed:before {
    content: "\f9fb";
    }
        .fi-ts-plant-care:before {
    content: "\f9fc";
    }
        .fi-ts-plant-growth:before {
    content: "\f9fd";
    }
        .fi-ts-plant-seed-invest:before {
    content: "\f9fe";
    }
        .fi-ts-plant-wilt:before {
    content: "\f9ff";
    }
        .fi-ts-plate-utensils:before {
    content: "\fa00";
    }
        .fi-ts-plate-wheat:before {
    content: "\fa01";
    }
        .fi-ts-plate:before {
    content: "\fa02";
    }
        .fi-ts-play-circle:before {
    content: "\fa03";
    }
        .fi-ts-play-microphone:before {
    content: "\fa04";
    }
        .fi-ts-play-pause:before {
    content: "\fa05";
    }
        .fi-ts-plug-alt:before {
    content: "\fa06";
    }
        .fi-ts-plug-cable:before {
    content: "\fa07";
    }
        .fi-ts-plug-circle-bolt:before {
    content: "\fa08";
    }
        .fi-ts-plug-circle-check:before {
    content: "\fa09";
    }
        .fi-ts-plug-circle-exclamation:before {
    content: "\fa0a";
    }
        .fi-ts-plug-circle-minus:before {
    content: "\fa0b";
    }
        .fi-ts-plug-circle-plus:before {
    content: "\fa0c";
    }
        .fi-ts-plug-connection:before {
    content: "\fa0d";
    }
        .fi-ts-plug:before {
    content: "\fa0e";
    }
        .fi-ts-plus-hexagon:before {
    content: "\fa0f";
    }
        .fi-ts-plus-minus:before {
    content: "\fa10";
    }
        .fi-ts-png-file:before {
    content: "\fa11";
    }
        .fi-ts-podcast:before {
    content: "\fa12";
    }
        .fi-ts-podium-star:before {
    content: "\fa13";
    }
        .fi-ts-podium-victory-leader:before {
    content: "\fa14";
    }
        .fi-ts-podium:before {
    content: "\fa15";
    }
        .fi-ts-point-of-sale-bill:before {
    content: "\fa16";
    }
        .fi-ts-point-of-sale-signal:before {
    content: "\fa17";
    }
        .fi-ts-point-of-sale:before {
    content: "\fa18";
    }
        .fi-ts-pointer-loading:before {
    content: "\fa19";
    }
        .fi-ts-pointer-text:before {
    content: "\fa1a";
    }
        .fi-ts-police-box:before {
    content: "\fa1b";
    }
        .fi-ts-poll-h:before {
    content: "\fa1c";
    }
        .fi-ts-pollution:before {
    content: "\fa1d";
    }
        .fi-ts-pompebled:before {
    content: "\fa1e";
    }
        .fi-ts-poo-bolt:before {
    content: "\fa1f";
    }
        .fi-ts-poo:before {
    content: "\fa20";
    }
        .fi-ts-pool-8-ball:before {
    content: "\fa21";
    }
        .fi-ts-poop:before {
    content: "\fa22";
    }
        .fi-ts-popcorn:before {
    content: "\fa23";
    }
        .fi-ts-popsicle:before {
    content: "\fa24";
    }
        .fi-ts-portal-enter:before {
    content: "\fa25";
    }
        .fi-ts-portal-exit:before {
    content: "\fa26";
    }
        .fi-ts-postal-address:before {
    content: "\fa27";
    }
        .fi-ts-pot:before {
    content: "\fa28";
    }
        .fi-ts-potato:before {
    content: "\fa29";
    }
        .fi-ts-pound:before {
    content: "\fa2a";
    }
        .fi-ts-ppt-file:before {
    content: "\fa2b";
    }
        .fi-ts-practice:before {
    content: "\fa2c";
    }
        .fi-ts-praying-hands:before {
    content: "\fa2d";
    }
        .fi-ts-prescription-bottle-alt:before {
    content: "\fa2e";
    }
        .fi-ts-prescription-bottle-pill:before {
    content: "\fa2f";
    }
        .fi-ts-prescription-bottle:before {
    content: "\fa30";
    }
        .fi-ts-prescription:before {
    content: "\fa31";
    }
        .fi-ts-presentation:before {
    content: "\fa32";
    }
        .fi-ts-preview:before {
    content: "\fa33";
    }
        .fi-ts-previous-square:before {
    content: "\fa34";
    }
        .fi-ts-print-magnifying-glass:before {
    content: "\fa35";
    }
        .fi-ts-print-slash:before {
    content: "\fa36";
    }
        .fi-ts-print:before {
    content: "\fa37";
    }
        .fi-ts-priority-arrow:before {
    content: "\fa38";
    }
        .fi-ts-priority-arrows:before {
    content: "\fa39";
    }
        .fi-ts-priority-importance:before {
    content: "\fa3a";
    }
        .fi-ts-problem-solving:before {
    content: "\fa3b";
    }
        .fi-ts-procedures:before {
    content: "\fa3c";
    }
        .fi-ts-process:before {
    content: "\fa3d";
    }
        .fi-ts-productivity:before {
    content: "\fa3e";
    }
        .fi-ts-progress-complete:before {
    content: "\fa3f";
    }
        .fi-ts-progress-download:before {
    content: "\fa40";
    }
        .fi-ts-progress-upload:before {
    content: "\fa41";
    }
        .fi-ts-projector:before {
    content: "\fa42";
    }
        .fi-ts-protractor:before {
    content: "\fa43";
    }
        .fi-ts-pulse:before {
    content: "\fa44";
    }
        .fi-ts-pump-medical:before {
    content: "\fa45";
    }
        .fi-ts-pump:before {
    content: "\fa46";
    }
        .fi-ts-pumpkin-alt-2:before {
    content: "\fa47";
    }
        .fi-ts-pumpkin-alt:before {
    content: "\fa48";
    }
        .fi-ts-pumpkin:before {
    content: "\fa49";
    }
        .fi-ts-puzzle-alt:before {
    content: "\fa4a";
    }
        .fi-ts-puzzle-piece-integration:before {
    content: "\fa4b";
    }
        .fi-ts-puzzle-piece:before {
    content: "\fa4c";
    }
        .fi-ts-puzzle-pieces:before {
    content: "\fa4d";
    }
        .fi-ts-pyramid:before {
    content: "\fa4e";
    }
        .fi-ts-q:before {
    content: "\fa4f";
    }
        .fi-ts-qr-scan:before {
    content: "\fa50";
    }
        .fi-ts-QR:before {
    content: "\fa51";
    }
        .fi-ts-qrcode:before {
    content: "\fa52";
    }
        .fi-ts-question-square:before {
    content: "\fa53";
    }
        .fi-ts-question:before {
    content: "\fa54";
    }
        .fi-ts-queue-alt:before {
    content: "\fa55";
    }
        .fi-ts-queue-line:before {
    content: "\fa56";
    }
        .fi-ts-queue-signal:before {
    content: "\fa57";
    }
        .fi-ts-queue:before {
    content: "\fa58";
    }
        .fi-ts-quill-pen-story:before {
    content: "\fa59";
    }
        .fi-ts-quiz-alt:before {
    content: "\fa5a";
    }
        .fi-ts-quiz:before {
    content: "\fa5b";
    }
        .fi-ts-r:before {
    content: "\fa5c";
    }
        .fi-ts-rabbit-fast:before {
    content: "\fa5d";
    }
        .fi-ts-rabbit:before {
    content: "\fa5e";
    }
        .fi-ts-raccoon:before {
    content: "\fa5f";
    }
        .fi-ts-racquet:before {
    content: "\fa60";
    }
        .fi-ts-radar-monitoring-track:before {
    content: "\fa61";
    }
        .fi-ts-radar:before {
    content: "\fa62";
    }
        .fi-ts-radiation-alt:before {
    content: "\fa63";
    }
        .fi-ts-radiation:before {
    content: "\fa64";
    }
        .fi-ts-radio-alt:before {
    content: "\fa65";
    }
        .fi-ts-radio-button:before {
    content: "\fa66";
    }
        .fi-ts-radio:before {
    content: "\fa67";
    }
        .fi-ts-radish:before {
    content: "\fa68";
    }
        .fi-ts-rainbow:before {
    content: "\fa69";
    }
        .fi-ts-raindrops:before {
    content: "\fa6a";
    }
        .fi-ts-ram:before {
    content: "\fa6b";
    }
        .fi-ts-ramp-loading:before {
    content: "\fa6c";
    }
        .fi-ts-rank:before {
    content: "\fa6d";
    }
        .fi-ts-ranking-podium-empty:before {
    content: "\fa6e";
    }
        .fi-ts-ranking-podium:before {
    content: "\fa6f";
    }
        .fi-ts-ranking-star:before {
    content: "\fa70";
    }
        .fi-ts-ranking-stars:before {
    content: "\fa71";
    }
        .fi-ts-raygun:before {
    content: "\fa72";
    }
        .fi-ts-razor-barber:before {
    content: "\fa73";
    }
        .fi-ts-react:before {
    content: "\fa74";
    }
        .fi-ts-recipe-book:before {
    content: "\fa75";
    }
        .fi-ts-recipe:before {
    content: "\fa76";
    }
        .fi-ts-record-vinyl:before {
    content: "\fa77";
    }
        .fi-ts-rectangle-barcode:before {
    content: "\fa78";
    }
        .fi-ts-rectangle-code:before {
    content: "\fa79";
    }
        .fi-ts-rectangle-history-circle-plus:before {
    content: "\fa7a";
    }
        .fi-ts-rectangle-horizontal:before {
    content: "\fa7b";
    }
        .fi-ts-rectangle-list:before {
    content: "\fa7c";
    }
        .fi-ts-rectangle-panoramic:before {
    content: "\fa7d";
    }
        .fi-ts-rectangle-pro:before {
    content: "\fa7e";
    }
        .fi-ts-rectangle-vertical-history:before {
    content: "\fa7f";
    }
        .fi-ts-rectangle-vertical:before {
    content: "\fa80";
    }
        .fi-ts-rectangle-xmark:before {
    content: "\fa81";
    }
        .fi-ts-rectangles-mixed:before {
    content: "\fa82";
    }
        .fi-ts-recycle-bin:before {
    content: "\fa83";
    }
        .fi-ts-recycle:before {
    content: "\fa84";
    }
        .fi-ts-redo-alt:before {
    content: "\fa85";
    }
        .fi-ts-reel:before {
    content: "\fa86";
    }
        .fi-ts-refer-arrow:before {
    content: "\fa87";
    }
        .fi-ts-refer:before {
    content: "\fa88";
    }
        .fi-ts-referral-alt:before {
    content: "\fa89";
    }
        .fi-ts-referral-user:before {
    content: "\fa8a";
    }
        .fi-ts-referral:before {
    content: "\fa8b";
    }
        .fi-ts-reflect-horizontal-alt:before {
    content: "\fa8c";
    }
        .fi-ts-reflect-horizontal:before {
    content: "\fa8d";
    }
        .fi-ts-reflect-vertical:before {
    content: "\fa8e";
    }
        .fi-ts-refrigerator:before {
    content: "\fa8f";
    }
        .fi-ts-refund-alt:before {
    content: "\fa90";
    }
        .fi-ts-refund:before {
    content: "\fa91";
    }
        .fi-ts-region-pin-alt:before {
    content: "\fa92";
    }
        .fi-ts-region-pin:before {
    content: "\fa93";
    }
        .fi-ts-registered:before {
    content: "\fa94";
    }
        .fi-ts-registration-paper:before {
    content: "\fa95";
    }
        .fi-ts-remove-folder:before {
    content: "\fa96";
    }
        .fi-ts-remove-user:before {
    content: "\fa97";
    }
        .fi-ts-rent-signal:before {
    content: "\fa98";
    }
        .fi-ts-rent:before {
    content: "\fa99";
    }
        .fi-ts-replace:before {
    content: "\fa9a";
    }
        .fi-ts-replay-10:before {
    content: "\fa9b";
    }
        .fi-ts-replay-30:before {
    content: "\fa9c";
    }
        .fi-ts-replay-5:before {
    content: "\fa9d";
    }
        .fi-ts-reply-all:before {
    content: "\fa9e";
    }
        .fi-ts-republican:before {
    content: "\fa9f";
    }
        .fi-ts-reservation-smartphone:before {
    content: "\faa0";
    }
        .fi-ts-reservation-table:before {
    content: "\faa1";
    }
        .fi-ts-resistance-band:before {
    content: "\faa2";
    }
        .fi-ts-resources:before {
    content: "\faa3";
    }
        .fi-ts-restaurant:before {
    content: "\faa4";
    }
        .fi-ts-restock:before {
    content: "\faa5";
    }
        .fi-ts-restroom-simple:before {
    content: "\faa6";
    }
        .fi-ts-resume:before {
    content: "\faa7";
    }
        .fi-ts-revenue-alt:before {
    content: "\faa8";
    }
        .fi-ts-revenue-euro:before {
    content: "\faa9";
    }
        .fi-ts-Revenue:before {
    content: "\faaa";
    }
        .fi-ts-review:before {
    content: "\faab";
    }
        .fi-ts-rewind-button-circle:before {
    content: "\faac";
    }
        .fi-ts-rhombus:before {
    content: "\faad";
    }
        .fi-ts-ribbon:before {
    content: "\faae";
    }
        .fi-ts-right-from-bracket:before {
    content: "\faaf";
    }
        .fi-ts-right:before {
    content: "\fab0";
    }
        .fi-ts-ring-diamond:before {
    content: "\fab1";
    }
        .fi-ts-ring:before {
    content: "\fab2";
    }
        .fi-ts-rings-wedding:before {
    content: "\fab3";
    }
        .fi-ts-risk-alt:before {
    content: "\fab4";
    }
        .fi-ts-risk:before {
    content: "\fab5";
    }
        .fi-ts-road-barrier:before {
    content: "\fab6";
    }
        .fi-ts-road-sign-left:before {
    content: "\fab7";
    }
        .fi-ts-robot:before {
    content: "\fab8";
    }
        .fi-ts-robotic-arm:before {
    content: "\fab9";
    }
        .fi-ts-rocket-lunch:before {
    content: "\faba";
    }
        .fi-ts-rocket:before {
    content: "\fabb";
    }
        .fi-ts-roller-coaster:before {
    content: "\fabc";
    }
        .fi-ts-room-service:before {
    content: "\fabd";
    }
        .fi-ts-rotate-exclamation:before {
    content: "\fabe";
    }
        .fi-ts-rotate-reverse:before {
    content: "\fabf";
    }
        .fi-ts-rotate-square:before {
    content: "\fac0";
    }
        .fi-ts-roulette:before {
    content: "\fac1";
    }
        .fi-ts-route-highway:before {
    content: "\fac2";
    }
        .fi-ts-route-interstate:before {
    content: "\fac3";
    }
        .fi-ts-route:before {
    content: "\fac4";
    }
        .fi-ts-router:before {
    content: "\fac5";
    }
        .fi-ts-rss-alt:before {
    content: "\fac6";
    }
        .fi-ts-rss:before {
    content: "\fac7";
    }
        .fi-ts-ruble-sign:before {
    content: "\fac8";
    }
        .fi-ts-rugby-helmet:before {
    content: "\fac9";
    }
        .fi-ts-ruler-combined:before {
    content: "\faca";
    }
        .fi-ts-ruler-horizontal:before {
    content: "\facb";
    }
        .fi-ts-ruler-triangle:before {
    content: "\facc";
    }
        .fi-ts-ruler-vertical:before {
    content: "\facd";
    }
        .fi-ts-rules-alt:before {
    content: "\face";
    }
        .fi-ts-rules:before {
    content: "\facf";
    }
        .fi-ts-running-track:before {
    content: "\fad0";
    }
        .fi-ts-running:before {
    content: "\fad1";
    }
        .fi-ts-rupee-sign:before {
    content: "\fad2";
    }
        .fi-ts-rupiah-sign:before {
    content: "\fad3";
    }
        .fi-ts-rv:before {
    content: "\fad4";
    }
        .fi-ts-s:before {
    content: "\fad5";
    }
        .fi-ts-sack-dollar:before {
    content: "\fad6";
    }
        .fi-ts-sack:before {
    content: "\fad7";
    }
        .fi-ts-sad-cry:before {
    content: "\fad8";
    }
        .fi-ts-sad-tear:before {
    content: "\fad9";
    }
        .fi-ts-sad:before {
    content: "\fada";
    }
        .fi-ts-safe-box:before {
    content: "\fadb";
    }
        .fi-ts-sailboat:before {
    content: "\fadc";
    }
        .fi-ts-salad:before {
    content: "\fadd";
    }
        .fi-ts-salary-alt:before {
    content: "\fade";
    }
        .fi-ts-salt-pepper:before {
    content: "\fadf";
    }
        .fi-ts-salt-shaker:before {
    content: "\fae0";
    }
        .fi-ts-sandwich-alt:before {
    content: "\fae1";
    }
        .fi-ts-sandwich:before {
    content: "\fae2";
    }
        .fi-ts-satellite-dish:before {
    content: "\fae3";
    }
        .fi-ts-satellite:before {
    content: "\fae4";
    }
        .fi-ts-saturday:before {
    content: "\fae5";
    }
        .fi-ts-sauce:before {
    content: "\fae6";
    }
        .fi-ts-sausage:before {
    content: "\fae7";
    }
        .fi-ts-sax-hot:before {
    content: "\fae8";
    }
        .fi-ts-saxophone:before {
    content: "\fae9";
    }
        .fi-ts-scale-comparison-alt:before {
    content: "\faea";
    }
        .fi-ts-scale-comparison:before {
    content: "\faeb";
    }
        .fi-ts-scale:before {
    content: "\faec";
    }
        .fi-ts-scalpel-path:before {
    content: "\faed";
    }
        .fi-ts-scalpel:before {
    content: "\faee";
    }
        .fi-ts-scanner-gun:before {
    content: "\faef";
    }
        .fi-ts-scanner-image:before {
    content: "\faf0";
    }
        .fi-ts-scanner-keyboard:before {
    content: "\faf1";
    }
        .fi-ts-scanner-touchscreen:before {
    content: "\faf2";
    }
        .fi-ts-scarecrow:before {
    content: "\faf3";
    }
        .fi-ts-scarf:before {
    content: "\faf4";
    }
        .fi-ts-school-bus:before {
    content: "\faf5";
    }
        .fi-ts-school:before {
    content: "\faf6";
    }
        .fi-ts-scooter:before {
    content: "\faf7";
    }
        .fi-ts-screen:before {
    content: "\faf8";
    }
        .fi-ts-screencast:before {
    content: "\faf9";
    }
        .fi-ts-screw-alt:before {
    content: "\fafa";
    }
        .fi-ts-screw:before {
    content: "\fafb";
    }
        .fi-ts-screwdriver:before {
    content: "\fafc";
    }
        .fi-ts-scribble:before {
    content: "\fafd";
    }
        .fi-ts-script:before {
    content: "\fafe";
    }
        .fi-ts-scroll-document-story:before {
    content: "\faff";
    }
        .fi-ts-scroll-old:before {
    content: "\fb00";
    }
        .fi-ts-scroll-torah:before {
    content: "\fb01";
    }
        .fi-ts-scroll:before {
    content: "\fb02";
    }
        .fi-ts-scrubber:before {
    content: "\fb03";
    }
        .fi-ts-scythe:before {
    content: "\fb04";
    }
        .fi-ts-sd-card:before {
    content: "\fb05";
    }
        .fi-ts-sd-cards:before {
    content: "\fb06";
    }
        .fi-ts-seal-exclamation:before {
    content: "\fb07";
    }
        .fi-ts-seal-question:before {
    content: "\fb08";
    }
        .fi-ts-seal:before {
    content: "\fb09";
    }
        .fi-ts-search-alt:before {
    content: "\fb0a";
    }
        .fi-ts-search-dollar:before {
    content: "\fb0b";
    }
        .fi-ts-search-heart:before {
    content: "\fb0c";
    }
        .fi-ts-search-location:before {
    content: "\fb0d";
    }
        .fi-ts-seat-airline:before {
    content: "\fb0e";
    }
        .fi-ts-seatbelt-safety-driver:before {
    content: "\fb0f";
    }
        .fi-ts-second-award:before {
    content: "\fb10";
    }
        .fi-ts-second-laurel:before {
    content: "\fb11";
    }
        .fi-ts-second-medal:before {
    content: "\fb12";
    }
        .fi-ts-second:before {
    content: "\fb13";
    }
        .fi-ts-seedling:before {
    content: "\fb14";
    }
        .fi-ts-selection:before {
    content: "\fb15";
    }
        .fi-ts-sell:before {
    content: "\fb16";
    }
        .fi-ts-seller-store:before {
    content: "\fb17";
    }
        .fi-ts-seller:before {
    content: "\fb18";
    }
        .fi-ts-selling:before {
    content: "\fb19";
    }
        .fi-ts-send-back:before {
    content: "\fb1a";
    }
        .fi-ts-send-backward:before {
    content: "\fb1b";
    }
        .fi-ts-send-money-smartphone:before {
    content: "\fb1c";
    }
        .fi-ts-send-money:before {
    content: "\fb1d";
    }
        .fi-ts-sensor-alert:before {
    content: "\fb1e";
    }
        .fi-ts-sensor-fire:before {
    content: "\fb1f";
    }
        .fi-ts-sensor-on:before {
    content: "\fb20";
    }
        .fi-ts-sensor-smoke:before {
    content: "\fb21";
    }
        .fi-ts-sensor:before {
    content: "\fb22";
    }
        .fi-ts-share-alt-square:before {
    content: "\fb23";
    }
        .fi-ts-share-square:before {
    content: "\fb24";
    }
        .fi-ts-sheep:before {
    content: "\fb25";
    }
        .fi-ts-shekel-sign:before {
    content: "\fb26";
    }
        .fi-ts-shelves:before {
    content: "\fb27";
    }
        .fi-ts-shield-alt:before {
    content: "\fb28";
    }
        .fi-ts-shield-cat:before {
    content: "\fb29";
    }
        .fi-ts-shield-cross:before {
    content: "\fb2a";
    }
        .fi-ts-shield-dog:before {
    content: "\fb2b";
    }
        .fi-ts-shield-exclamation:before {
    content: "\fb2c";
    }
        .fi-ts-shield-interrogation:before {
    content: "\fb2d";
    }
        .fi-ts-shield-keyhole:before {
    content: "\fb2e";
    }
        .fi-ts-shield-minus:before {
    content: "\fb2f";
    }
        .fi-ts-shield-security-risk:before {
    content: "\fb30";
    }
        .fi-ts-shield-slash:before {
    content: "\fb31";
    }
        .fi-ts-shield-trust:before {
    content: "\fb32";
    }
        .fi-ts-shield-virus:before {
    content: "\fb33";
    }
        .fi-ts-shield-xmark:before {
    content: "\fb34";
    }
        .fi-ts-shipping-fast:before {
    content: "\fb35";
    }
        .fi-ts-shipping-timed:before {
    content: "\fb36";
    }
        .fi-ts-shirt-long-sleeve:before {
    content: "\fb37";
    }
        .fi-ts-shirt-running:before {
    content: "\fb38";
    }
        .fi-ts-shirt-tank-top:before {
    content: "\fb39";
    }
        .fi-ts-shirt:before {
    content: "\fb3a";
    }
        .fi-ts-shish-kebab:before {
    content: "\fb3b";
    }
        .fi-ts-shoe-prints:before {
    content: "\fb3c";
    }
        .fi-ts-shop-lock:before {
    content: "\fb3d";
    }
        .fi-ts-shop-slash:before {
    content: "\fb3e";
    }
        .fi-ts-shopping-basket:before {
    content: "\fb3f";
    }
        .fi-ts-shopping-cart-buyer:before {
    content: "\fb40";
    }
        .fi-ts-shovel-snow:before {
    content: "\fb41";
    }
        .fi-ts-shovel:before {
    content: "\fb42";
    }
        .fi-ts-shower-down:before {
    content: "\fb43";
    }
        .fi-ts-shower:before {
    content: "\fb44";
    }
        .fi-ts-shredder:before {
    content: "\fb45";
    }
        .fi-ts-shrimp:before {
    content: "\fb46";
    }
        .fi-ts-shuttle-van:before {
    content: "\fb47";
    }
        .fi-ts-shuttlecock:before {
    content: "\fb48";
    }
        .fi-ts-Sickle:before {
    content: "\fb49";
    }
        .fi-ts-sidebar-flip:before {
    content: "\fb4a";
    }
        .fi-ts-sidebar:before {
    content: "\fb4b";
    }
        .fi-ts-sigma:before {
    content: "\fb4c";
    }
        .fi-ts-sign-hanging:before {
    content: "\fb4d";
    }
        .fi-ts-sign-in-alt:before {
    content: "\fb4e";
    }
        .fi-ts-sign-out-alt:before {
    content: "\fb4f";
    }
        .fi-ts-sign-posts-wrench:before {
    content: "\fb50";
    }
        .fi-ts-sign-posts:before {
    content: "\fb51";
    }
        .fi-ts-sign-up:before {
    content: "\fb52";
    }
        .fi-ts-signal-alt-1:before {
    content: "\fb53";
    }
        .fi-ts-signal-alt-2:before {
    content: "\fb54";
    }
        .fi-ts-signal-alt-slash:before {
    content: "\fb55";
    }
        .fi-ts-signal-alt:before {
    content: "\fb56";
    }
        .fi-ts-signal-bars-fair:before {
    content: "\fb57";
    }
        .fi-ts-signal-bars-good:before {
    content: "\fb58";
    }
        .fi-ts-signal-bars-weak:before {
    content: "\fb59";
    }
        .fi-ts-signal-stream-slash:before {
    content: "\fb5a";
    }
        .fi-ts-signal-stream:before {
    content: "\fb5b";
    }
        .fi-ts-signature-lock:before {
    content: "\fb5c";
    }
        .fi-ts-signature-slash:before {
    content: "\fb5d";
    }
        .fi-ts-signature:before {
    content: "\fb5e";
    }
        .fi-ts-sim-card:before {
    content: "\fb5f";
    }
        .fi-ts-sim-cards:before {
    content: "\fb60";
    }
        .fi-ts-sink:before {
    content: "\fb61";
    }
        .fi-ts-siren-on:before {
    content: "\fb62";
    }
        .fi-ts-Siren:before {
    content: "\fb63";
    }
        .fi-ts-site-alt:before {
    content: "\fb64";
    }
        .fi-ts-site-browser:before {
    content: "\fb65";
    }
        .fi-ts-site:before {
    content: "\fb66";
    }
        .fi-ts-sitemap:before {
    content: "\fb67";
    }
        .fi-ts-skating:before {
    content: "\fb68";
    }
        .fi-ts-skeleton-ribs:before {
    content: "\fb69";
    }
        .fi-ts-skeleton:before {
    content: "\fb6a";
    }
        .fi-ts-skewer:before {
    content: "\fb6b";
    }
        .fi-ts-ski-boot-ski:before {
    content: "\fb6c";
    }
        .fi-ts-ski-jump:before {
    content: "\fb6d";
    }
        .fi-ts-ski-lift:before {
    content: "\fb6e";
    }
        .fi-ts-skiing-nordic:before {
    content: "\fb6f";
    }
        .fi-ts-skiing:before {
    content: "\fb70";
    }
        .fi-ts-skill-alt:before {
    content: "\fb71";
    }
        .fi-ts-skill-user:before {
    content: "\fb72";
    }
        .fi-ts-skill:before {
    content: "\fb73";
    }
        .fi-ts-skin-arrow:before {
    content: "\fb74";
    }
        .fi-ts-skin-drop:before {
    content: "\fb75";
    }
        .fi-ts-skin-hair:before {
    content: "\fb76";
    }
        .fi-ts-skin:before {
    content: "\fb77";
    }
        .fi-ts-skip-15-seconds:before {
    content: "\fb78";
    }
        .fi-ts-skull-cow:before {
    content: "\fb79";
    }
        .fi-ts-skull-crossbones:before {
    content: "\fb7a";
    }
        .fi-ts-skull:before {
    content: "\fb7b";
    }
        .fi-ts-slash:before {
    content: "\fb7c";
    }
        .fi-ts-sledding:before {
    content: "\fb7d";
    }
        .fi-ts-sleeping-bag:before {
    content: "\fb7e";
    }
        .fi-ts-sleigh:before {
    content: "\fb7f";
    }
        .fi-ts-sliders-h-square:before {
    content: "\fb80";
    }
        .fi-ts-sliders-v-square:before {
    content: "\fb81";
    }
        .fi-ts-sliders-v:before {
    content: "\fb82";
    }
        .fi-ts-slot-machine:before {
    content: "\fb83";
    }
        .fi-ts-smart-home-alt:before {
    content: "\fb84";
    }
        .fi-ts-smart-home:before {
    content: "\fb85";
    }
        .fi-ts-smartphone:before {
    content: "\fb86";
    }
        .fi-ts-smile-beam:before {
    content: "\fb87";
    }
        .fi-ts-smile-plus:before {
    content: "\fb88";
    }
        .fi-ts-smile-wink:before {
    content: "\fb89";
    }
        .fi-ts-smile:before {
    content: "\fb8a";
    }
        .fi-ts-smiley-comment-alt:before {
    content: "\fb8b";
    }
        .fi-ts-smog:before {
    content: "\fb8c";
    }
        .fi-ts-smoke:before {
    content: "\fb8d";
    }
        .fi-ts-smoking-ban:before {
    content: "\fb8e";
    }
        .fi-ts-smoking:before {
    content: "\fb8f";
    }
        .fi-ts-snake:before {
    content: "\fb90";
    }
        .fi-ts-snap:before {
    content: "\fb91";
    }
        .fi-ts-snooze:before {
    content: "\fb92";
    }
        .fi-ts-snow-blowing:before {
    content: "\fb93";
    }
        .fi-ts-snowboarding:before {
    content: "\fb94";
    }
        .fi-ts-snowflake-droplets:before {
    content: "\fb95";
    }
        .fi-ts-snowflake:before {
    content: "\fb96";
    }
        .fi-ts-snowflakes:before {
    content: "\fb97";
    }
        .fi-ts-snowman-alt:before {
    content: "\fb98";
    }
        .fi-ts-snowman-head:before {
    content: "\fb99";
    }
        .fi-ts-snowmobile:before {
    content: "\fb9a";
    }
        .fi-ts-snowplow:before {
    content: "\fb9b";
    }
        .fi-ts-soap-alt:before {
    content: "\fb9c";
    }
        .fi-ts-soap:before {
    content: "\fb9d";
    }
        .fi-ts-socks:before {
    content: "\fb9e";
    }
        .fi-ts-sofa-size:before {
    content: "\fb9f";
    }
        .fi-ts-sofa:before {
    content: "\fba0";
    }
        .fi-ts-solar-panel-sun:before {
    content: "\fba1";
    }
        .fi-ts-solar-panel:before {
    content: "\fba2";
    }
        .fi-ts-solar-system:before {
    content: "\fba3";
    }
        .fi-ts-sold-house:before {
    content: "\fba4";
    }
        .fi-ts-sold-signal:before {
    content: "\fba5";
    }
        .fi-ts-sort-alpha-down-alt:before {
    content: "\fba6";
    }
        .fi-ts-sort-alpha-down:before {
    content: "\fba7";
    }
        .fi-ts-sort-alpha-up-alt:before {
    content: "\fba8";
    }
        .fi-ts-sort-alpha-up:before {
    content: "\fba9";
    }
        .fi-ts-sort-alt:before {
    content: "\fbaa";
    }
        .fi-ts-sort-amount-down-alt:before {
    content: "\fbab";
    }
        .fi-ts-sort-amount-down:before {
    content: "\fbac";
    }
        .fi-ts-sort-amount-up-alt:before {
    content: "\fbad";
    }
        .fi-ts-sort-amount-up:before {
    content: "\fbae";
    }
        .fi-ts-sort-circle-down:before {
    content: "\fbaf";
    }
        .fi-ts-sort-circle-up:before {
    content: "\fbb0";
    }
        .fi-ts-sort-circle:before {
    content: "\fbb1";
    }
        .fi-ts-sort-down:before {
    content: "\fbb2";
    }
        .fi-ts-sort-numeric-down-alt:before {
    content: "\fbb3";
    }
        .fi-ts-sort-numeric-down:before {
    content: "\fbb4";
    }
        .fi-ts-sort-shapes-down:before {
    content: "\fbb5";
    }
        .fi-ts-sort-shapes-up:before {
    content: "\fbb6";
    }
        .fi-ts-sort-size-down:before {
    content: "\fbb7";
    }
        .fi-ts-sort-size-up:before {
    content: "\fbb8";
    }
        .fi-ts-sort:before {
    content: "\fbb9";
    }
        .fi-ts-soup:before {
    content: "\fbba";
    }
        .fi-ts-source-data:before {
    content: "\fbbb";
    }
        .fi-ts-source-document-alt:before {
    content: "\fbbc";
    }
        .fi-ts-source-document:before {
    content: "\fbbd";
    }
        .fi-ts-spa:before {
    content: "\fbbe";
    }
        .fi-ts-space-shuttle:before {
    content: "\fbbf";
    }
        .fi-ts-space-station-moon-alt:before {
    content: "\fbc0";
    }
        .fi-ts-space-station-moon:before {
    content: "\fbc1";
    }
        .fi-ts-spade:before {
    content: "\fbc2";
    }
        .fi-ts-spaghetti-monster-flying:before {
    content: "\fbc3";
    }
        .fi-ts-spain-map:before {
    content: "\fbc4";
    }
        .fi-ts-sparkles:before {
    content: "\fbc5";
    }
        .fi-ts-spartan-helmet:before {
    content: "\fbc6";
    }
        .fi-ts-speaker:before {
    content: "\fbc7";
    }
        .fi-ts-speakers:before {
    content: "\fbc8";
    }
        .fi-ts-speech-bubble-story:before {
    content: "\fbc9";
    }
        .fi-ts-speedometer-arrow:before {
    content: "\fbca";
    }
        .fi-ts-speedometer-kpi:before {
    content: "\fbcb";
    }
        .fi-ts-sphere:before {
    content: "\fbcc";
    }
        .fi-ts-spider-black-widow:before {
    content: "\fbcd";
    }
        .fi-ts-spider-web:before {
    content: "\fbce";
    }
        .fi-ts-spider:before {
    content: "\fbcf";
    }
        .fi-ts-split-up-relation:before {
    content: "\fbd0";
    }
        .fi-ts-split:before {
    content: "\fbd1";
    }
        .fi-ts-splotch:before {
    content: "\fbd2";
    }
        .fi-ts-spoon:before {
    content: "\fbd3";
    }
        .fi-ts-spray-can-sparkles:before {
    content: "\fbd4";
    }
        .fi-ts-spray-can:before {
    content: "\fbd5";
    }
        .fi-ts-spring-calendar:before {
    content: "\fbd6";
    }
        .fi-ts-sprinkler:before {
    content: "\fbd7";
    }
        .fi-ts-spy:before {
    content: "\fbd8";
    }
        .fi-ts-square-0:before {
    content: "\fbd9";
    }
        .fi-ts-square-1:before {
    content: "\fbda";
    }
        .fi-ts-square-2:before {
    content: "\fbdb";
    }
        .fi-ts-square-3:before {
    content: "\fbdc";
    }
        .fi-ts-square-4:before {
    content: "\fbdd";
    }
        .fi-ts-square-5:before {
    content: "\fbde";
    }
        .fi-ts-square-6:before {
    content: "\fbdf";
    }
        .fi-ts-square-7:before {
    content: "\fbe0";
    }
        .fi-ts-square-8:before {
    content: "\fbe1";
    }
        .fi-ts-square-9:before {
    content: "\fbe2";
    }
        .fi-ts-square-a:before {
    content: "\fbe3";
    }
        .fi-ts-square-b:before {
    content: "\fbe4";
    }
        .fi-ts-square-bolt:before {
    content: "\fbe5";
    }
        .fi-ts-square-c:before {
    content: "\fbe6";
    }
        .fi-ts-square-code:before {
    content: "\fbe7";
    }
        .fi-ts-square-d:before {
    content: "\fbe8";
    }
        .fi-ts-square-dashed-circle-plus:before {
    content: "\fbe9";
    }
        .fi-ts-square-dashed:before {
    content: "\fbea";
    }
        .fi-ts-square-divide:before {
    content: "\fbeb";
    }
        .fi-ts-square-e:before {
    content: "\fbec";
    }
        .fi-ts-square-ellipsis-vertical:before {
    content: "\fbed";
    }
        .fi-ts-square-ellipsis:before {
    content: "\fbee";
    }
        .fi-ts-square-exclamation:before {
    content: "\fbef";
    }
        .fi-ts-square-f:before {
    content: "\fbf0";
    }
        .fi-ts-square-g:before {
    content: "\fbf1";
    }
        .fi-ts-square-h:before {
    content: "\fbf2";
    }
        .fi-ts-square-heart:before {
    content: "\fbf3";
    }
        .fi-ts-square-i:before {
    content: "\fbf4";
    }
        .fi-ts-square-info:before {
    content: "\fbf5";
    }
        .fi-ts-square-j:before {
    content: "\fbf6";
    }
        .fi-ts-square-k:before {
    content: "\fbf7";
    }
        .fi-ts-square-kanban:before {
    content: "\fbf8";
    }
        .fi-ts-square-l:before {
    content: "\fbf9";
    }
        .fi-ts-square-m:before {
    content: "\fbfa";
    }
        .fi-ts-square-minus:before {
    content: "\fbfb";
    }
        .fi-ts-square-n:before {
    content: "\fbfc";
    }
        .fi-ts-square-o:before {
    content: "\fbfd";
    }
        .fi-ts-square-p:before {
    content: "\fbfe";
    }
        .fi-ts-square-phone-hangup:before {
    content: "\fbff";
    }
        .fi-ts-square-plus:before {
    content: "\fc00";
    }
        .fi-ts-square-poll-horizontal:before {
    content: "\fc01";
    }
        .fi-ts-square-poll-vertical:before {
    content: "\fc02";
    }
        .fi-ts-square-q:before {
    content: "\fc03";
    }
        .fi-ts-square-quote:before {
    content: "\fc04";
    }
        .fi-ts-square-r:before {
    content: "\fc05";
    }
        .fi-ts-square-root:before {
    content: "\fc06";
    }
        .fi-ts-square-s:before {
    content: "\fc07";
    }
        .fi-ts-square-small:before {
    content: "\fc08";
    }
        .fi-ts-square-star:before {
    content: "\fc09";
    }
        .fi-ts-square-t:before {
    content: "\fc0a";
    }
        .fi-ts-square-terminal:before {
    content: "\fc0b";
    }
        .fi-ts-square-u:before {
    content: "\fc0c";
    }
        .fi-ts-square-up-right:before {
    content: "\fc0d";
    }
        .fi-ts-square-v:before {
    content: "\fc0e";
    }
        .fi-ts-square-w:before {
    content: "\fc0f";
    }
        .fi-ts-square-x:before {
    content: "\fc10";
    }
        .fi-ts-square-y:before {
    content: "\fc11";
    }
        .fi-ts-square-z:before {
    content: "\fc12";
    }
        .fi-ts-square:before {
    content: "\fc13";
    }
        .fi-ts-squid:before {
    content: "\fc14";
    }
        .fi-ts-squircle:before {
    content: "\fc15";
    }
        .fi-ts-squirrel:before {
    content: "\fc16";
    }
        .fi-ts-staff:before {
    content: "\fc17";
    }
        .fi-ts-stage-concert:before {
    content: "\fc18";
    }
        .fi-ts-stage-theatre:before {
    content: "\fc19";
    }
        .fi-ts-stage:before {
    content: "\fc1a";
    }
        .fi-ts-stairs:before {
    content: "\fc1b";
    }
        .fi-ts-stamp:before {
    content: "\fc1c";
    }
        .fi-ts-standard-definition:before {
    content: "\fc1d";
    }
        .fi-ts-star-and-crescent:before {
    content: "\fc1e";
    }
        .fi-ts-star-christmas:before {
    content: "\fc1f";
    }
        .fi-ts-star-comment-alt:before {
    content: "\fc20";
    }
        .fi-ts-star-exclamation:before {
    content: "\fc21";
    }
        .fi-ts-star-octogram:before {
    content: "\fc22";
    }
        .fi-ts-star-of-david:before {
    content: "\fc23";
    }
        .fi-ts-star-sharp-half-stroke:before {
    content: "\fc24";
    }
        .fi-ts-star-sharp-half:before {
    content: "\fc25";
    }
        .fi-ts-star-shooting:before {
    content: "\fc26";
    }
        .fi-ts-starfighter:before {
    content: "\fc27";
    }
        .fi-ts-stars:before {
    content: "\fc28";
    }
        .fi-ts-state-country:before {
    content: "\fc29";
    }
        .fi-ts-stationary-bike:before {
    content: "\fc2a";
    }
        .fi-ts-stats:before {
    content: "\fc2b";
    }
        .fi-ts-steak:before {
    content: "\fc2c";
    }
        .fi-ts-steering-wheel:before {
    content: "\fc2d";
    }
        .fi-ts-step-backward:before {
    content: "\fc2e";
    }
        .fi-ts-step-forward:before {
    content: "\fc2f";
    }
        .fi-ts-sterling-sign:before {
    content: "\fc30";
    }
        .fi-ts-stethoscope:before {
    content: "\fc31";
    }
        .fi-ts-sticker:before {
    content: "\fc32";
    }
        .fi-ts-stocking:before {
    content: "\fc33";
    }
        .fi-ts-stomach:before {
    content: "\fc34";
    }
        .fi-ts-stop-circle:before {
    content: "\fc35";
    }
        .fi-ts-stop-square:before {
    content: "\fc36";
    }
        .fi-ts-stopwatch:before {
    content: "\fc37";
    }
        .fi-ts-store-alt:before {
    content: "\fc38";
    }
        .fi-ts-store-buyer:before {
    content: "\fc39";
    }
        .fi-ts-store-lock:before {
    content: "\fc3a";
    }
        .fi-ts-store-slash:before {
    content: "\fc3b";
    }
        .fi-ts-story-book:before {
    content: "\fc3c";
    }
        .fi-ts-story-fairy-tale:before {
    content: "\fc3d";
    }
        .fi-ts-story-fantasy:before {
    content: "\fc3e";
    }
        .fi-ts-strategy-chess-risk:before {
    content: "\fc3f";
    }
        .fi-ts-strawberry:before {
    content: "\fc40";
    }
        .fi-ts-street-view:before {
    content: "\fc41";
    }
        .fi-ts-stretcher:before {
    content: "\fc42";
    }
        .fi-ts-strikethrough:before {
    content: "\fc43";
    }
        .fi-ts-stroopwafel:before {
    content: "\fc44";
    }
        .fi-ts-student-alt:before {
    content: "\fc45";
    }
        .fi-ts-student:before {
    content: "\fc46";
    }
        .fi-ts-subscript:before {
    content: "\fc47";
    }
        .fi-ts-subscription-alt:before {
    content: "\fc48";
    }
        .fi-ts-subscription:before {
    content: "\fc49";
    }
        .fi-ts-subtitles-slash:before {
    content: "\fc4a";
    }
        .fi-ts-subway:before {
    content: "\fc4b";
    }
        .fi-ts-suitcase-alt:before {
    content: "\fc4c";
    }
        .fi-ts-summary-check:before {
    content: "\fc4d";
    }
        .fi-ts-summer:before {
    content: "\fc4e";
    }
        .fi-ts-sun-dust:before {
    content: "\fc4f";
    }
        .fi-ts-sun-plant-wilt:before {
    content: "\fc50";
    }
        .fi-ts-sun-salutation-yoga:before {
    content: "\fc51";
    }
        .fi-ts-sun:before {
    content: "\fc52";
    }
        .fi-ts-sunday:before {
    content: "\fc53";
    }
        .fi-ts-sunglasses-alt:before {
    content: "\fc54";
    }
        .fi-ts-sunglasses:before {
    content: "\fc55";
    }
        .fi-ts-sunrise-alt:before {
    content: "\fc56";
    }
        .fi-ts-sunrise:before {
    content: "\fc57";
    }
        .fi-ts-sunset:before {
    content: "\fc58";
    }
        .fi-ts-superscript:before {
    content: "\fc59";
    }
        .fi-ts-supplier-alt:before {
    content: "\fc5a";
    }
        .fi-ts-supplier:before {
    content: "\fc5b";
    }
        .fi-ts-surprise:before {
    content: "\fc5c";
    }
        .fi-ts-surveillance-camera:before {
    content: "\fc5d";
    }
        .fi-ts-survey-xmark:before {
    content: "\fc5e";
    }
        .fi-ts-sushi-alt:before {
    content: "\fc5f";
    }
        .fi-ts-sushi-roll:before {
    content: "\fc60";
    }
        .fi-ts-sushi:before {
    content: "\fc61";
    }
        .fi-ts-svg:before {
    content: "\fc62";
    }
        .fi-ts-swap:before {
    content: "\fc63";
    }
        .fi-ts-swatchbook:before {
    content: "\fc64";
    }
        .fi-ts-swimmer:before {
    content: "\fc65";
    }
        .fi-ts-swimming-pool:before {
    content: "\fc66";
    }
        .fi-ts-swing:before {
    content: "\fc67";
    }
        .fi-ts-swipe-down:before {
    content: "\fc68";
    }
        .fi-ts-swipe-left:before {
    content: "\fc69";
    }
        .fi-ts-swipe-right:before {
    content: "\fc6a";
    }
        .fi-ts-swipe-up:before {
    content: "\fc6b";
    }
        .fi-ts-symbols:before {
    content: "\fc6c";
    }
        .fi-ts-synagogue:before {
    content: "\fc6d";
    }
        .fi-ts-syringe:before {
    content: "\fc6e";
    }
        .fi-ts-system-cloud:before {
    content: "\fc6f";
    }
        .fi-ts-t:before {
    content: "\fc70";
    }
        .fi-ts-tab-folder:before {
    content: "\fc71";
    }
        .fi-ts-table-columns:before {
    content: "\fc72";
    }
        .fi-ts-table-layout:before {
    content: "\fc73";
    }
        .fi-ts-table-list:before {
    content: "\fc74";
    }
        .fi-ts-table-picnic:before {
    content: "\fc75";
    }
        .fi-ts-table-pivot:before {
    content: "\fc76";
    }
        .fi-ts-table-rows:before {
    content: "\fc77";
    }
        .fi-ts-table-tree:before {
    content: "\fc78";
    }
        .fi-ts-table:before {
    content: "\fc79";
    }
        .fi-ts-tablet-android-alt:before {
    content: "\fc7a";
    }
        .fi-ts-tablet-android:before {
    content: "\fc7b";
    }
        .fi-ts-tablet-rugged:before {
    content: "\fc7c";
    }
        .fi-ts-tablet:before {
    content: "\fc7d";
    }
        .fi-ts-tachometer-alt-average:before {
    content: "\fc7e";
    }
        .fi-ts-tachometer-alt-fastest:before {
    content: "\fc7f";
    }
        .fi-ts-tachometer-alt-slow:before {
    content: "\fc80";
    }
        .fi-ts-tachometer-alt-slowest:before {
    content: "\fc81";
    }
        .fi-ts-tachometer-average:before {
    content: "\fc82";
    }
        .fi-ts-tachometer-fast:before {
    content: "\fc83";
    }
        .fi-ts-tachometer-fastest:before {
    content: "\fc84";
    }
        .fi-ts-tachometer-slow:before {
    content: "\fc85";
    }
        .fi-ts-tachometer-slowest:before {
    content: "\fc86";
    }
        .fi-ts-tachometer:before {
    content: "\fc87";
    }
        .fi-ts-taco:before {
    content: "\fc88";
    }
        .fi-ts-tags:before {
    content: "\fc89";
    }
        .fi-ts-talent-alt:before {
    content: "\fc8a";
    }
        .fi-ts-talent-hand:before {
    content: "\fc8b";
    }
        .fi-ts-talent:before {
    content: "\fc8c";
    }
        .fi-ts-tally-1:before {
    content: "\fc8d";
    }
        .fi-ts-tally-2:before {
    content: "\fc8e";
    }
        .fi-ts-tally-3:before {
    content: "\fc8f";
    }
        .fi-ts-tally-4:before {
    content: "\fc90";
    }
        .fi-ts-tally:before {
    content: "\fc91";
    }
        .fi-ts-tamale:before {
    content: "\fc92";
    }
        .fi-ts-tank-water:before {
    content: "\fc93";
    }
        .fi-ts-tap:before {
    content: "\fc94";
    }
        .fi-ts-tape:before {
    content: "\fc95";
    }
        .fi-ts-target-audience:before {
    content: "\fc96";
    }
        .fi-ts-target:before {
    content: "\fc97";
    }
        .fi-ts-tax-alt:before {
    content: "\fc98";
    }
        .fi-ts-tax:before {
    content: "\fc99";
    }
        .fi-ts-taxi-bus:before {
    content: "\fc9a";
    }
        .fi-ts-team-check-alt:before {
    content: "\fc9b";
    }
        .fi-ts-team-check:before {
    content: "\fc9c";
    }
        .fi-ts-teddy-bear:before {
    content: "\fc9d";
    }
        .fi-ts-teeth-open:before {
    content: "\fc9e";
    }
        .fi-ts-telescope:before {
    content: "\fc9f";
    }
        .fi-ts-temperature-down:before {
    content: "\fca0";
    }
        .fi-ts-temperature-frigid:before {
    content: "\fca1";
    }
        .fi-ts-temperature-high:before {
    content: "\fca2";
    }
        .fi-ts-temperature-list:before {
    content: "\fca3";
    }
        .fi-ts-temperature-low:before {
    content: "\fca4";
    }
        .fi-ts-temperature-up:before {
    content: "\fca5";
    }
        .fi-ts-template-alt:before {
    content: "\fca6";
    }
        .fi-ts-template:before {
    content: "\fca7";
    }
        .fi-ts-tenge:before {
    content: "\fca8";
    }
        .fi-ts-tent-arrow-down-to-line:before {
    content: "\fca9";
    }
        .fi-ts-tent-arrow-left-right:before {
    content: "\fcaa";
    }
        .fi-ts-tent-arrow-turn-left:before {
    content: "\fcab";
    }
        .fi-ts-tent-arrows-down:before {
    content: "\fcac";
    }
        .fi-ts-tents:before {
    content: "\fcad";
    }
        .fi-ts-terminal:before {
    content: "\fcae";
    }
        .fi-ts-terrace:before {
    content: "\fcaf";
    }
        .fi-ts-test-tube:before {
    content: "\fcb0";
    }
        .fi-ts-test:before {
    content: "\fcb1";
    }
        .fi-ts-text-box-dots:before {
    content: "\fcb2";
    }
        .fi-ts-text-box-edit:before {
    content: "\fcb3";
    }
        .fi-ts-text-box:before {
    content: "\fcb4";
    }
        .fi-ts-text-height:before {
    content: "\fcb5";
    }
        .fi-ts-text-shadow:before {
    content: "\fcb6";
    }
        .fi-ts-text-size:before {
    content: "\fcb7";
    }
        .fi-ts-text-slash:before {
    content: "\fcb8";
    }
        .fi-ts-text-width:before {
    content: "\fcb9";
    }
        .fi-ts-theater-masks:before {
    content: "\fcba";
    }
        .fi-ts-thermometer-alt:before {
    content: "\fcbb";
    }
        .fi-ts-thermometer-empty:before {
    content: "\fcbc";
    }
        .fi-ts-thermometer-full:before {
    content: "\fcbd";
    }
        .fi-ts-thermometer-half:before {
    content: "\fcbe";
    }
        .fi-ts-thermometer-quarter:before {
    content: "\fcbf";
    }
        .fi-ts-thermometer-three-quarters:before {
    content: "\fcc0";
    }
        .fi-ts-theta:before {
    content: "\fcc1";
    }
        .fi-ts-third-award:before {
    content: "\fcc2";
    }
        .fi-ts-third-laurel:before {
    content: "\fcc3";
    }
        .fi-ts-third-medal:before {
    content: "\fcc4";
    }
        .fi-ts-third:before {
    content: "\fcc5";
    }
        .fi-ts-thought-bubble:before {
    content: "\fcc6";
    }
        .fi-ts-three-leaf-clover:before {
    content: "\fcc7";
    }
        .fi-ts-thumbs-up-trust:before {
    content: "\fcc8";
    }
        .fi-ts-thunderstorm-moon:before {
    content: "\fcc9";
    }
        .fi-ts-thunderstorm-risk:before {
    content: "\fcca";
    }
        .fi-ts-thunderstorm-sun:before {
    content: "\fccb";
    }
        .fi-ts-thunderstorm:before {
    content: "\fccc";
    }
        .fi-ts-thursday:before {
    content: "\fccd";
    }
        .fi-ts-ticket-airline:before {
    content: "\fcce";
    }
        .fi-ts-ticket-alt:before {
    content: "\fccf";
    }
        .fi-ts-tickets-airline:before {
    content: "\fcd0";
    }
        .fi-ts-tie:before {
    content: "\fcd1";
    }
        .fi-ts-tilde:before {
    content: "\fcd2";
    }
        .fi-ts-time-add:before {
    content: "\fcd3";
    }
        .fi-ts-time-check:before {
    content: "\fcd4";
    }
        .fi-ts-time-delete:before {
    content: "\fcd5";
    }
        .fi-ts-time-fast:before {
    content: "\fcd6";
    }
        .fi-ts-time-forward-sixty:before {
    content: "\fcd7";
    }
        .fi-ts-time-forward-ten:before {
    content: "\fcd8";
    }
        .fi-ts-time-forward:before {
    content: "\fcd9";
    }
        .fi-ts-time-half-past:before {
    content: "\fcda";
    }
        .fi-ts-time-oclock:before {
    content: "\fcdb";
    }
        .fi-ts-time-quarter-past:before {
    content: "\fcdc";
    }
        .fi-ts-time-quarter-to:before {
    content: "\fcdd";
    }
        .fi-ts-time-twenty-four:before {
    content: "\fcde";
    }
        .fi-ts-time-watch-calendar:before {
    content: "\fcdf";
    }
        .fi-ts-times-hexagon:before {
    content: "\fce0";
    }
        .fi-ts-tint-slash:before {
    content: "\fce1";
    }
        .fi-ts-tip-coin:before {
    content: "\fce2";
    }
        .fi-ts-tire-flat:before {
    content: "\fce3";
    }
        .fi-ts-tire-pressure-warning:before {
    content: "\fce4";
    }
        .fi-ts-tire-rugged:before {
    content: "\fce5";
    }
        .fi-ts-tire:before {
    content: "\fce6";
    }
        .fi-ts-tired:before {
    content: "\fce7";
    }
        .fi-ts-to-do-alt:before {
    content: "\fce8";
    }
        .fi-ts-to-do:before {
    content: "\fce9";
    }
        .fi-ts-together-people:before {
    content: "\fcea";
    }
        .fi-ts-toggle-off:before {
    content: "\fceb";
    }
        .fi-ts-toggle-on:before {
    content: "\fcec";
    }
        .fi-ts-toilet-paper-blank-under:before {
    content: "\fced";
    }
        .fi-ts-toilet-paper-blank:before {
    content: "\fcee";
    }
        .fi-ts-toilet-paper-slash:before {
    content: "\fcef";
    }
        .fi-ts-toilet-paper-under-slash:before {
    content: "\fcf0";
    }
        .fi-ts-toilet-paper-under:before {
    content: "\fcf1";
    }
        .fi-ts-toilet:before {
    content: "\fcf2";
    }
        .fi-ts-toilets-portable:before {
    content: "\fcf3";
    }
        .fi-ts-token:before {
    content: "\fcf4";
    }
        .fi-ts-tomato:before {
    content: "\fcf5";
    }
        .fi-ts-tombstone-alt:before {
    content: "\fcf6";
    }
        .fi-ts-tombstone:before {
    content: "\fcf7";
    }
        .fi-ts-tool-box:before {
    content: "\fcf8";
    }
        .fi-ts-tools:before {
    content: "\fcf9";
    }
        .fi-ts-tooth:before {
    content: "\fcfa";
    }
        .fi-ts-toothbrush:before {
    content: "\fcfb";
    }
        .fi-ts-torch-inspiration-leader:before {
    content: "\fcfc";
    }
        .fi-ts-torii-gate:before {
    content: "\fcfd";
    }
        .fi-ts-tornado:before {
    content: "\fcfe";
    }
        .fi-ts-total:before {
    content: "\fcff";
    }
        .fi-ts-tour-guide-people:before {
    content: "\fd00";
    }
        .fi-ts-tour-virtual:before {
    content: "\fd01";
    }
        .fi-ts-tower-control:before {
    content: "\fd02";
    }
        .fi-ts-track:before {
    content: "\fd03";
    }
        .fi-ts-tractor:before {
    content: "\fd04";
    }
        .fi-ts-trademark:before {
    content: "\fd05";
    }
        .fi-ts-traffic-cone:before {
    content: "\fd06";
    }
        .fi-ts-traffic-light-go:before {
    content: "\fd07";
    }
        .fi-ts-traffic-light-slow:before {
    content: "\fd08";
    }
        .fi-ts-traffic-light-stop:before {
    content: "\fd09";
    }
        .fi-ts-traffic-light:before {
    content: "\fd0a";
    }
        .fi-ts-trailer:before {
    content: "\fd0b";
    }
        .fi-ts-train-journey:before {
    content: "\fd0c";
    }
        .fi-ts-train-station-building:before {
    content: "\fd0d";
    }
        .fi-ts-train-station:before {
    content: "\fd0e";
    }
        .fi-ts-train-subway-tunnel:before {
    content: "\fd0f";
    }
        .fi-ts-train-track:before {
    content: "\fd10";
    }
        .fi-ts-train-tram:before {
    content: "\fd11";
    }
        .fi-ts-tram:before {
    content: "\fd12";
    }
        .fi-ts-transformation-block:before {
    content: "\fd13";
    }
        .fi-ts-transformation-circle:before {
    content: "\fd14";
    }
        .fi-ts-transformation-design:before {
    content: "\fd15";
    }
        .fi-ts-transformation-shapes:before {
    content: "\fd16";
    }
        .fi-ts-transformer-bolt:before {
    content: "\fd17";
    }
        .fi-ts-transgender:before {
    content: "\fd18";
    }
        .fi-ts-transporter-1:before {
    content: "\fd19";
    }
        .fi-ts-transporter-2:before {
    content: "\fd1a";
    }
        .fi-ts-transporter-3:before {
    content: "\fd1b";
    }
        .fi-ts-transporter-4:before {
    content: "\fd1c";
    }
        .fi-ts-transporter-5:before {
    content: "\fd1d";
    }
        .fi-ts-transporter-6:before {
    content: "\fd1e";
    }
        .fi-ts-transporter-7:before {
    content: "\fd1f";
    }
        .fi-ts-transporter-empty:before {
    content: "\fd20";
    }
        .fi-ts-transporter:before {
    content: "\fd21";
    }
        .fi-ts-trash-can-check:before {
    content: "\fd22";
    }
        .fi-ts-trash-can-clock:before {
    content: "\fd23";
    }
        .fi-ts-trash-can-list:before {
    content: "\fd24";
    }
        .fi-ts-trash-can-plus:before {
    content: "\fd25";
    }
        .fi-ts-trash-can-slash:before {
    content: "\fd26";
    }
        .fi-ts-trash-check:before {
    content: "\fd27";
    }
        .fi-ts-trash-clock:before {
    content: "\fd28";
    }
        .fi-ts-trash-empty:before {
    content: "\fd29";
    }
        .fi-ts-trash-list:before {
    content: "\fd2a";
    }
        .fi-ts-trash-plus:before {
    content: "\fd2b";
    }
        .fi-ts-trash-restore-alt:before {
    content: "\fd2c";
    }
        .fi-ts-trash-restore:before {
    content: "\fd2d";
    }
        .fi-ts-trash-slash:before {
    content: "\fd2e";
    }
        .fi-ts-trash-undo-alt:before {
    content: "\fd2f";
    }
        .fi-ts-trash-undo:before {
    content: "\fd30";
    }
        .fi-ts-trash-xmark:before {
    content: "\fd31";
    }
        .fi-ts-treadmill:before {
    content: "\fd32";
    }
        .fi-ts-treasure-chest:before {
    content: "\fd33";
    }
        .fi-ts-treatment:before {
    content: "\fd34";
    }
        .fi-ts-tree-alt:before {
    content: "\fd35";
    }
        .fi-ts-tree-christmas:before {
    content: "\fd36";
    }
        .fi-ts-tree-deciduous:before {
    content: "\fd37";
    }
        .fi-ts-tree:before {
    content: "\fd38";
    }
        .fi-ts-trees-alt:before {
    content: "\fd39";
    }
        .fi-ts-trees:before {
    content: "\fd3a";
    }
        .fi-ts-triangle-music:before {
    content: "\fd3b";
    }
        .fi-ts-triangle-person-digging:before {
    content: "\fd3c";
    }
        .fi-ts-triangle-warning:before {
    content: "\fd3d";
    }
        .fi-ts-triangle:before {
    content: "\fd3e";
    }
        .fi-ts-tricycle:before {
    content: "\fd3f";
    }
        .fi-ts-trillium:before {
    content: "\fd40";
    }
        .fi-ts-trophy-achievement-skill:before {
    content: "\fd41";
    }
        .fi-ts-trophy-star:before {
    content: "\fd42";
    }
        .fi-ts-truck-arrow-left:before {
    content: "\fd43";
    }
        .fi-ts-truck-arrow-right:before {
    content: "\fd44";
    }
        .fi-ts-truck-bolt:before {
    content: "\fd45";
    }
        .fi-ts-truck-box:before {
    content: "\fd46";
    }
        .fi-ts-truck-check:before {
    content: "\fd47";
    }
        .fi-ts-truck-container-empty:before {
    content: "\fd48";
    }
        .fi-ts-truck-container:before {
    content: "\fd49";
    }
        .fi-ts-truck-couch:before {
    content: "\fd4a";
    }
        .fi-ts-truck-fire:before {
    content: "\fd4b";
    }
        .fi-ts-truck-flatbed:before {
    content: "\fd4c";
    }
        .fi-ts-truck-front:before {
    content: "\fd4d";
    }
        .fi-ts-truck-ladder:before {
    content: "\fd4e";
    }
        .fi-ts-truck-loading:before {
    content: "\fd4f";
    }
        .fi-ts-truck-medical:before {
    content: "\fd50";
    }
        .fi-ts-truck-monster:before {
    content: "\fd51";
    }
        .fi-ts-truck-moving:before {
    content: "\fd52";
    }
        .fi-ts-truck-pickup:before {
    content: "\fd53";
    }
        .fi-ts-truck-plow:before {
    content: "\fd54";
    }
        .fi-ts-truck-ramp:before {
    content: "\fd55";
    }
        .fi-ts-truck-tow:before {
    content: "\fd56";
    }
        .fi-ts-truck-utensils:before {
    content: "\fd57";
    }
        .fi-ts-trumpet:before {
    content: "\fd58";
    }
        .fi-ts-trust-alt:before {
    content: "\fd59";
    }
        .fi-ts-trust:before {
    content: "\fd5a";
    }
        .fi-ts-tshirt:before {
    content: "\fd5b";
    }
        .fi-ts-tty-answer:before {
    content: "\fd5c";
    }
        .fi-ts-tty:before {
    content: "\fd5d";
    }
        .fi-ts-tubes:before {
    content: "\fd5e";
    }
        .fi-ts-tuesday:before {
    content: "\fd5f";
    }
        .fi-ts-tugrik-sign:before {
    content: "\fd60";
    }
        .fi-ts-turkey:before {
    content: "\fd61";
    }
        .fi-ts-turn-left-down:before {
    content: "\fd62";
    }
        .fi-ts-turn-left:before {
    content: "\fd63";
    }
        .fi-ts-turn-right:before {
    content: "\fd64";
    }
        .fi-ts-turntable:before {
    content: "\fd65";
    }
        .fi-ts-turtle:before {
    content: "\fd66";
    }
        .fi-ts-tv-music:before {
    content: "\fd67";
    }
        .fi-ts-tv-retro:before {
    content: "\fd68";
    }
        .fi-ts-typewriter:before {
    content: "\fd69";
    }
        .fi-ts-u:before {
    content: "\fd6a";
    }
        .fi-ts-ufo-beam:before {
    content: "\fd6b";
    }
        .fi-ts-ufo:before {
    content: "\fd6c";
    }
        .fi-ts-ui-ux:before {
    content: "\fd6d";
    }
        .fi-ts-umbrella-beach:before {
    content: "\fd6e";
    }
        .fi-ts-umbrella:before {
    content: "\fd6f";
    }
        .fi-ts-under-construction:before {
    content: "\fd70";
    }
        .fi-ts-undo-alt:before {
    content: "\fd71";
    }
        .fi-ts-undo:before {
    content: "\fd72";
    }
        .fi-ts-unicorn:before {
    content: "\fd73";
    }
        .fi-ts-uniform-martial-arts:before {
    content: "\fd74";
    }
        .fi-ts-universal-access:before {
    content: "\fd75";
    }
        .fi-ts-up-from-bracket:before {
    content: "\fd76";
    }
        .fi-ts-up-left:before {
    content: "\fd77";
    }
        .fi-ts-up-right-from-square:before {
    content: "\fd78";
    }
        .fi-ts-up-right:before {
    content: "\fd79";
    }
        .fi-ts-up:before {
    content: "\fd7a";
    }
        .fi-ts-url:before {
    content: "\fd7b";
    }
        .fi-ts-usb-pendrive:before {
    content: "\fd7c";
    }
        .fi-ts-usd-circle:before {
    content: "\fd7d";
    }
        .fi-ts-usd-square:before {
    content: "\fd7e";
    }
        .fi-ts-user-alien:before {
    content: "\fd7f";
    }
        .fi-ts-user-astronaut:before {
    content: "\fd80";
    }
        .fi-ts-user-check:before {
    content: "\fd81";
    }
        .fi-ts-user-chef:before {
    content: "\fd82";
    }
        .fi-ts-user-coach:before {
    content: "\fd83";
    }
        .fi-ts-user-cowboy:before {
    content: "\fd84";
    }
        .fi-ts-user-crown:before {
    content: "\fd85";
    }
        .fi-ts-user-gear:before {
    content: "\fd86";
    }
        .fi-ts-user-graduate:before {
    content: "\fd87";
    }
        .fi-ts-user-headset:before {
    content: "\fd88";
    }
        .fi-ts-user-helmet-safety:before {
    content: "\fd89";
    }
        .fi-ts-user-injured:before {
    content: "\fd8a";
    }
        .fi-ts-user-interface-ui:before {
    content: "\fd8b";
    }
        .fi-ts-user-key:before {
    content: "\fd8c";
    }
        .fi-ts-user-lock:before {
    content: "\fd8d";
    }
        .fi-ts-user-md-chat:before {
    content: "\fd8e";
    }
        .fi-ts-user-md:before {
    content: "\fd8f";
    }
        .fi-ts-user-minus:before {
    content: "\fd90";
    }
        .fi-ts-user-music:before {
    content: "\fd91";
    }
        .fi-ts-user-ninja:before {
    content: "\fd92";
    }
        .fi-ts-user-nurse:before {
    content: "\fd93";
    }
        .fi-ts-user-pen:before {
    content: "\fd94";
    }
        .fi-ts-user-pilot-tie:before {
    content: "\fd95";
    }
        .fi-ts-user-pilot:before {
    content: "\fd96";
    }
        .fi-ts-user-police:before {
    content: "\fd97";
    }
        .fi-ts-user-robot-xmarks:before {
    content: "\fd98";
    }
        .fi-ts-user-robot:before {
    content: "\fd99";
    }
        .fi-ts-user-salary:before {
    content: "\fd9a";
    }
        .fi-ts-user-shield:before {
    content: "\fd9b";
    }
        .fi-ts-user-skill-gear:before {
    content: "\fd9c";
    }
        .fi-ts-user-slash:before {
    content: "\fd9d";
    }
        .fi-ts-user-tag:before {
    content: "\fd9e";
    }
        .fi-ts-user-time:before {
    content: "\fd9f";
    }
        .fi-ts-user-trust:before {
    content: "\fda0";
    }
        .fi-ts-user-unlock:before {
    content: "\fda1";
    }
        .fi-ts-user-visor:before {
    content: "\fda2";
    }
        .fi-ts-user-xmark:before {
    content: "\fda3";
    }
        .fi-ts-users-alt:before {
    content: "\fda4";
    }
        .fi-ts-users-class:before {
    content: "\fda5";
    }
        .fi-ts-users-gear:before {
    content: "\fda6";
    }
        .fi-ts-users-medical:before {
    content: "\fda7";
    }
        .fi-ts-users-slash:before {
    content: "\fda8";
    }
        .fi-ts-users:before {
    content: "\fda9";
    }
        .fi-ts-utensils-slash:before {
    content: "\fdaa";
    }
        .fi-ts-utensils:before {
    content: "\fdab";
    }
        .fi-ts-utility-pole-double:before {
    content: "\fdac";
    }
        .fi-ts-utility-pole:before {
    content: "\fdad";
    }
        .fi-ts-ux-browser:before {
    content: "\fdae";
    }
        .fi-ts-ux:before {
    content: "\fdaf";
    }
        .fi-ts-v:before {
    content: "\fdb0";
    }
        .fi-ts-vacuum-robot:before {
    content: "\fdb1";
    }
        .fi-ts-vacuum:before {
    content: "\fdb2";
    }
        .fi-ts-value-absolute:before {
    content: "\fdb3";
    }
        .fi-ts-vault:before {
    content: "\fdb4";
    }
        .fi-ts-vector-circle:before {
    content: "\fdb5";
    }
        .fi-ts-vector-polygon:before {
    content: "\fdb6";
    }
        .fi-ts-venus-double:before {
    content: "\fdb7";
    }
        .fi-ts-venus-mars:before {
    content: "\fdb8";
    }
        .fi-ts-venus:before {
    content: "\fdb9";
    }
        .fi-ts-vest-patches:before {
    content: "\fdba";
    }
        .fi-ts-vest:before {
    content: "\fdbb";
    }
        .fi-ts-video-arrow-down-left:before {
    content: "\fdbc";
    }
        .fi-ts-video-arrow-up-right:before {
    content: "\fdbd";
    }
        .fi-ts-video-camera-alt:before {
    content: "\fdbe";
    }
        .fi-ts-video-camera:before {
    content: "\fdbf";
    }
        .fi-ts-video-duration:before {
    content: "\fdc0";
    }
        .fi-ts-video-plus:before {
    content: "\fdc1";
    }
        .fi-ts-video-slash:before {
    content: "\fdc2";
    }
        .fi-ts-videoconference:before {
    content: "\fdc3";
    }
        .fi-ts-vihara:before {
    content: "\fdc4";
    }
        .fi-ts-violin:before {
    content: "\fdc5";
    }
        .fi-ts-virus-slash:before {
    content: "\fdc6";
    }
        .fi-ts-virus:before {
    content: "\fdc7";
    }
        .fi-ts-viruses:before {
    content: "\fdc8";
    }
        .fi-ts-visit:before {
    content: "\fdc9";
    }
        .fi-ts-voicemail:before {
    content: "\fdca";
    }
        .fi-ts-volcano:before {
    content: "\fdcb";
    }
        .fi-ts-volume-down:before {
    content: "\fdcc";
    }
        .fi-ts-volume-mute:before {
    content: "\fdcd";
    }
        .fi-ts-volume-off:before {
    content: "\fdce";
    }
        .fi-ts-volume-slash:before {
    content: "\fdcf";
    }
        .fi-ts-vote-nay:before {
    content: "\fdd0";
    }
        .fi-ts-vote-yea:before {
    content: "\fdd1";
    }
        .fi-ts-vr-cardboard:before {
    content: "\fdd2";
    }
        .fi-ts-w:before {
    content: "\fdd3";
    }
        .fi-ts-waffle:before {
    content: "\fdd4";
    }
        .fi-ts-wagon-covered:before {
    content: "\fdd5";
    }
        .fi-ts-walker:before {
    content: "\fdd6";
    }
        .fi-ts-walkie-talkie:before {
    content: "\fdd7";
    }
        .fi-ts-walking:before {
    content: "\fdd8";
    }
        .fi-ts-wallet-arrow:before {
    content: "\fdd9";
    }
        .fi-ts-wallet-buyer:before {
    content: "\fdda";
    }
        .fi-ts-wallet:before {
    content: "\fddb";
    }
        .fi-ts-warehouse-alt:before {
    content: "\fddc";
    }
        .fi-ts-warranty:before {
    content: "\fddd";
    }
        .fi-ts-washer:before {
    content: "\fdde";
    }
        .fi-ts-waste-pollution:before {
    content: "\fddf";
    }
        .fi-ts-waste:before {
    content: "\fde0";
    }
        .fi-ts-watch-calculator:before {
    content: "\fde1";
    }
        .fi-ts-watch-fitness:before {
    content: "\fde2";
    }
        .fi-ts-watch-smart:before {
    content: "\fde3";
    }
        .fi-ts-watch:before {
    content: "\fde4";
    }
        .fi-ts-water-bottle:before {
    content: "\fde5";
    }
        .fi-ts-water-ladder:before {
    content: "\fde6";
    }
        .fi-ts-water-lower:before {
    content: "\fde7";
    }
        .fi-ts-water-rise:before {
    content: "\fde8";
    }
        .fi-ts-water:before {
    content: "\fde9";
    }
        .fi-ts-watermelon:before {
    content: "\fdea";
    }
        .fi-ts-wave-sine:before {
    content: "\fdeb";
    }
        .fi-ts-wave-square:before {
    content: "\fdec";
    }
        .fi-ts-wave-triangle:before {
    content: "\fded";
    }
        .fi-ts-wave:before {
    content: "\fdee";
    }
        .fi-ts-waveform-path:before {
    content: "\fdef";
    }
        .fi-ts-waveform:before {
    content: "\fdf0";
    }
        .fi-ts-web-design:before {
    content: "\fdf1";
    }
        .fi-ts-web:before {
    content: "\fdf2";
    }
        .fi-ts-webcam-slash:before {
    content: "\fdf3";
    }
        .fi-ts-webcam:before {
    content: "\fdf4";
    }
        .fi-ts-webhook:before {
    content: "\fdf5";
    }
        .fi-ts-wednesday:before {
    content: "\fdf6";
    }
        .fi-ts-whale:before {
    content: "\fdf7";
    }
        .fi-ts-wheat-awn-circle-exclamation:before {
    content: "\fdf8";
    }
        .fi-ts-wheat-awn-slash:before {
    content: "\fdf9";
    }
        .fi-ts-wheat-awn:before {
    content: "\fdfa";
    }
        .fi-ts-wheat-slash:before {
    content: "\fdfb";
    }
        .fi-ts-wheat:before {
    content: "\fdfc";
    }
        .fi-ts-wheelchair-move:before {
    content: "\fdfd";
    }
        .fi-ts-wheelchair-sleeping:before {
    content: "\fdfe";
    }
        .fi-ts-wheelchair:before {
    content: "\fdff";
    }
        .fi-ts-whistle:before {
    content: "\fe10";
    }
        .fi-ts-white-space:before {
    content: "\fe11";
    }
        .fi-ts-wifi-1:before {
    content: "\fe12";
    }
        .fi-ts-wifi-2:before {
    content: "\fe13";
    }
        .fi-ts-wifi-alt:before {
    content: "\fe14";
    }
        .fi-ts-wifi-exclamation:before {
    content: "\fe15";
    }
        .fi-ts-wifi-slash:before {
    content: "\fe16";
    }
        .fi-ts-wifi:before {
    content: "\fe17";
    }
        .fi-ts-wind-turbine:before {
    content: "\fe18";
    }
        .fi-ts-wind-warning:before {
    content: "\fe19";
    }
        .fi-ts-wind:before {
    content: "\fe1a";
    }
        .fi-ts-window-alt:before {
    content: "\fe1b";
    }
        .fi-ts-window-frame-open:before {
    content: "\fe1c";
    }
        .fi-ts-window-frame:before {
    content: "\fe1d";
    }
        .fi-ts-window-maximize:before {
    content: "\fe1e";
    }
        .fi-ts-window-minimize:before {
    content: "\fe1f";
    }
        .fi-ts-window-restore:before {
    content: "\fe30";
    }
        .fi-ts-windsock:before {
    content: "\fe31";
    }
        .fi-ts-wine-bottle:before {
    content: "\fe32";
    }
        .fi-ts-wine-glass-crack:before {
    content: "\fe33";
    }
        .fi-ts-wine-glass-empty:before {
    content: "\fe34";
    }
        .fi-ts-wishlist-heart:before {
    content: "\fe35";
    }
        .fi-ts-wishlist-star:before {
    content: "\fe36";
    }
        .fi-ts-woman-head:before {
    content: "\fe37";
    }
        .fi-ts-woman-scientist:before {
    content: "\fe38";
    }
        .fi-ts-won-sign:before {
    content: "\fe39";
    }
        .fi-ts-work-in-progress:before {
    content: "\fe3a";
    }
        .fi-ts-workflow-alt:before {
    content: "\fe3b";
    }
        .fi-ts-workflow-setting-alt:before {
    content: "\fe3c";
    }
        .fi-ts-workflow-setting:before {
    content: "\fe3d";
    }
        .fi-ts-workflow:before {
    content: "\fe3e";
    }
        .fi-ts-workshop:before {
    content: "\fe3f";
    }
        .fi-ts-world:before {
    content: "\fe40";
    }
        .fi-ts-worm:before {
    content: "\fe41";
    }
        .fi-ts-wreath:before {
    content: "\fe42";
    }
        .fi-ts-wrench-alt:before {
    content: "\fe43";
    }
        .fi-ts-wrench-simple:before {
    content: "\fe44";
    }
        .fi-ts-x-ray:before {
    content: "\fe45";
    }
        .fi-ts-x:before {
    content: "\fe46";
    }
        .fi-ts-y:before {
    content: "\fe47";
    }
        .fi-ts-yen:before {
    content: "\fe48";
    }
        .fi-ts-yin-yang:before {
    content: "\fe49";
    }
        .fi-ts-z:before {
    content: "\fe4a";
    }
        .fi-ts-zip-file:before {
    content: "\fe4b";
    }
