/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.4.2 - https://www.flaticon.com/uicons/interface-icons
*/
@font-face {
    font-family: "uicons-thin-rounded";
    src: url("../../webfonts/uicons-thin-rounded.woff2") format("woff2"),
    url("../../webfonts/uicons-thin-rounded.woff") format("woff"),
    url("../../webfonts/uicons-thin-rounded.eot#iefix") format("embedded-opentype");
    font-display: swap;
}
    i[class^="fi-tr-"]:before, i[class*=" fi-tr-"]:before, span[class^="fi-tr-"]:before, span[class*="fi-tr-"]:before {
font-family: uicons-thin-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .variable-selector-00:before {
    content: "\fe00";
    }
        .variable-selector-01:before {
    content: "\fe01";
    }
        .variable-selector-02:before {
    content: "\fe02";
    }
        .variable-selector-03:before {
    content: "\fe03";
    }
        .variable-selector-04:before {
    content: "\fe04";
    }
        .variable-selector-05:before {
    content: "\fe05";
    }
        .variable-selector-06:before {
    content: "\fe06";
    }
        .variable-selector-07:before {
    content: "\fe07";
    }
        .variable-selector-08:before {
    content: "\fe08";
    }
        .variable-selector-09:before {
    content: "\fe09";
    }
        .variable-selector-0a:before {
    content: "\fe0a";
    }
        .variable-selector-0b:before {
    content: "\fe0b";
    }
        .variable-selector-0c:before {
    content: "\fe0c";
    }
        .variable-selector-0d:before {
    content: "\fe0d";
    }
        .variable-selector-0e:before {
    content: "\fe0e";
    }
        .variable-selector-0f:before {
    content: "\fe0f";
    }
        .combining-half-marks-00:before {
    content: "\fe20";
    }
        .combining-half-marks-01:before {
    content: "\fe21";
    }
        .combining-half-marks-02:before {
    content: "\fe22";
    }
        .combining-half-marks-03:before {
    content: "\fe23";
    }
        .combining-half-marks-04:before {
    content: "\fe24";
    }
        .combining-half-marks-05:before {
    content: "\fe25";
    }
        .combining-half-marks-06:before {
    content: "\fe26";
    }
        .combining-half-marks-07:before {
    content: "\fe27";
    }
        .combining-half-marks-08:before {
    content: "\fe28";
    }
        .combining-half-marks-09:before {
    content: "\fe29";
    }
        .combining-half-marks-0a:before {
    content: "\fe2a";
    }
        .combining-half-marks-0b:before {
    content: "\fe2b";
    }
        .combining-half-marks-0c:before {
    content: "\fe2c";
    }
        .combining-half-marks-0d:before {
    content: "\fe2d";
    }
        .combining-half-marks-0e:before {
    content: "\fe2e";
    }
        .combining-half-marks-0f:before {
    content: "\fe2f";
    }
        .zero-width-no-break-space-0f:before {
    content: "\feff";
    }
        .fi-tr-0:before {
    content: "\f101";
    }
        .fi-tr-00s-music-disc:before {
    content: "\f102";
    }
        .fi-tr-1:before {
    content: "\f103";
    }
        .fi-tr-2:before {
    content: "\f104";
    }
        .fi-tr-3:before {
    content: "\f105";
    }
        .fi-tr-360-degrees:before {
    content: "\f106";
    }
        .fi-tr-4:before {
    content: "\f107";
    }
        .fi-tr-404:before {
    content: "\f108";
    }
        .fi-tr-5:before {
    content: "\f109";
    }
        .fi-tr-6:before {
    content: "\f10a";
    }
        .fi-tr-60s-music-disc:before {
    content: "\f10b";
    }
        .fi-tr-7:before {
    content: "\f10c";
    }
        .fi-tr-70s-music-disc:before {
    content: "\f10d";
    }
        .fi-tr-8:before {
    content: "\f10e";
    }
        .fi-tr-80s-music-disc:before {
    content: "\f10f";
    }
        .fi-tr-9:before {
    content: "\f110";
    }
        .fi-tr-90s-music-disc:before {
    content: "\f111";
    }
        .fi-tr-a:before {
    content: "\f112";
    }
        .fi-tr-accident:before {
    content: "\f113";
    }
        .fi-tr-acorn:before {
    content: "\f114";
    }
        .fi-tr-ad-paid:before {
    content: "\f115";
    }
        .fi-tr-ad:before {
    content: "\f116";
    }
        .fi-tr-add-document:before {
    content: "\f117";
    }
        .fi-tr-add-folder:before {
    content: "\f118";
    }
        .fi-tr-add-image:before {
    content: "\f119";
    }
        .fi-tr-add:before {
    content: "\f11a";
    }
        .fi-tr-address-book:before {
    content: "\f11b";
    }
        .fi-tr-address-card:before {
    content: "\f11c";
    }
        .fi-tr-admin-alt:before {
    content: "\f11d";
    }
        .fi-tr-admin:before {
    content: "\f11e";
    }
        .fi-tr-age-alt:before {
    content: "\f11f";
    }
        .fi-tr-age-restriction-eighteen:before {
    content: "\f120";
    }
        .fi-tr-age-restriction-seven:before {
    content: "\f121";
    }
        .fi-tr-age-restriction-six:before {
    content: "\f122";
    }
        .fi-tr-age-restriction-sixteen:before {
    content: "\f123";
    }
        .fi-tr-age-restriction-thirteen:before {
    content: "\f124";
    }
        .fi-tr-age-restriction-three:before {
    content: "\f125";
    }
        .fi-tr-age-restriction-twelve:before {
    content: "\f126";
    }
        .fi-tr-age-restriction-twenty-one:before {
    content: "\f127";
    }
        .fi-tr-age-restriction-zero:before {
    content: "\f128";
    }
        .fi-tr-age:before {
    content: "\f129";
    }
        .fi-tr-air-conditioner:before {
    content: "\f12a";
    }
        .fi-tr-air-freshener:before {
    content: "\f12b";
    }
        .fi-tr-air-pollution:before {
    content: "\f12c";
    }
        .fi-tr-airplane-journey:before {
    content: "\f12d";
    }
        .fi-tr-airplane-window-open:before {
    content: "\f12e";
    }
        .fi-tr-airplay:before {
    content: "\f12f";
    }
        .fi-tr-alarm-clock:before {
    content: "\f130";
    }
        .fi-tr-alarm-exclamation:before {
    content: "\f131";
    }
        .fi-tr-alarm-plus:before {
    content: "\f132";
    }
        .fi-tr-alarm-snooze:before {
    content: "\f133";
    }
        .fi-tr-album-circle-plus:before {
    content: "\f134";
    }
        .fi-tr-album-circle-user:before {
    content: "\f135";
    }
        .fi-tr-album-collection:before {
    content: "\f136";
    }
        .fi-tr-album:before {
    content: "\f137";
    }
        .fi-tr-algorithm:before {
    content: "\f138";
    }
        .fi-tr-alicorn:before {
    content: "\f139";
    }
        .fi-tr-alien:before {
    content: "\f13a";
    }
        .fi-tr-align-slash:before {
    content: "\f13b";
    }
        .fi-tr-ambulance:before {
    content: "\f13c";
    }
        .fi-tr-analyse-alt:before {
    content: "\f13d";
    }
        .fi-tr-analyse:before {
    content: "\f13e";
    }
        .fi-tr-anatomical-heart:before {
    content: "\f13f";
    }
        .fi-tr-anchor:before {
    content: "\f140";
    }
        .fi-tr-angel:before {
    content: "\f141";
    }
        .fi-tr-angle-90:before {
    content: "\f142";
    }
        .fi-tr-angle-circle-down:before {
    content: "\f143";
    }
        .fi-tr-angle-circle-left:before {
    content: "\f144";
    }
        .fi-tr-angle-circle-right:before {
    content: "\f145";
    }
        .fi-tr-angle-circle-up:before {
    content: "\f146";
    }
        .fi-tr-angle-double-left:before {
    content: "\f147";
    }
        .fi-tr-angle-double-right:before {
    content: "\f148";
    }
        .fi-tr-angle-double-small-down:before {
    content: "\f149";
    }
        .fi-tr-angle-double-small-left:before {
    content: "\f14a";
    }
        .fi-tr-angle-double-small-right:before {
    content: "\f14b";
    }
        .fi-tr-angle-double-small-up:before {
    content: "\f14c";
    }
        .fi-tr-angle-down:before {
    content: "\f14d";
    }
        .fi-tr-angle-left:before {
    content: "\f14e";
    }
        .fi-tr-angle-right:before {
    content: "\f14f";
    }
        .fi-tr-angle-small-down:before {
    content: "\f150";
    }
        .fi-tr-angle-small-left:before {
    content: "\f151";
    }
        .fi-tr-angle-small-right:before {
    content: "\f152";
    }
        .fi-tr-angle-small-up:before {
    content: "\f153";
    }
        .fi-tr-angle-square-down:before {
    content: "\f154";
    }
        .fi-tr-angle-square-left:before {
    content: "\f155";
    }
        .fi-tr-angle-square-right:before {
    content: "\f156";
    }
        .fi-tr-angle-square-up:before {
    content: "\f157";
    }
        .fi-tr-angle-up:before {
    content: "\f158";
    }
        .fi-tr-angle:before {
    content: "\f159";
    }
        .fi-tr-angles-up-down:before {
    content: "\f15a";
    }
        .fi-tr-angry:before {
    content: "\f15b";
    }
        .fi-tr-animated-icon:before {
    content: "\f15c";
    }
        .fi-tr-ankh:before {
    content: "\f15d";
    }
        .fi-tr-answer-alt:before {
    content: "\f15e";
    }
        .fi-tr-answer:before {
    content: "\f15f";
    }
        .fi-tr-apartment:before {
    content: "\f160";
    }
        .fi-tr-aperture:before {
    content: "\f161";
    }
        .fi-tr-api:before {
    content: "\f162";
    }
        .fi-tr-apple-core:before {
    content: "\f163";
    }
        .fi-tr-apple-crate:before {
    content: "\f164";
    }
        .fi-tr-apple:before {
    content: "\f165";
    }
        .fi-tr-archway:before {
    content: "\f166";
    }
        .fi-tr-arrow-alt-circle-down:before {
    content: "\f167";
    }
        .fi-tr-arrow-alt-circle-left:before {
    content: "\f168";
    }
        .fi-tr-arrow-alt-circle-right:before {
    content: "\f169";
    }
        .fi-tr-arrow-alt-circle-up:before {
    content: "\f16a";
    }
        .fi-tr-arrow-alt-down:before {
    content: "\f16b";
    }
        .fi-tr-arrow-alt-from-bottom:before {
    content: "\f16c";
    }
        .fi-tr-arrow-alt-from-left:before {
    content: "\f16d";
    }
        .fi-tr-arrow-alt-from-right:before {
    content: "\f16e";
    }
        .fi-tr-arrow-alt-from-top:before {
    content: "\f16f";
    }
        .fi-tr-arrow-alt-left:before {
    content: "\f170";
    }
        .fi-tr-arrow-alt-right:before {
    content: "\f171";
    }
        .fi-tr-arrow-alt-square-down:before {
    content: "\f172";
    }
        .fi-tr-arrow-alt-square-left:before {
    content: "\f173";
    }
        .fi-tr-arrow-alt-square-right:before {
    content: "\f174";
    }
        .fi-tr-arrow-alt-square-up:before {
    content: "\f175";
    }
        .fi-tr-arrow-alt-to-bottom:before {
    content: "\f176";
    }
        .fi-tr-arrow-alt-to-left:before {
    content: "\f177";
    }
        .fi-tr-arrow-alt-to-right:before {
    content: "\f178";
    }
        .fi-tr-arrow-alt-to-top:before {
    content: "\f179";
    }
        .fi-tr-arrow-alt-up:before {
    content: "\f17a";
    }
        .fi-tr-arrow-circle-down:before {
    content: "\f17b";
    }
        .fi-tr-arrow-circle-left:before {
    content: "\f17c";
    }
        .fi-tr-arrow-circle-right:before {
    content: "\f17d";
    }
        .fi-tr-arrow-circle-up:before {
    content: "\f17e";
    }
        .fi-tr-arrow-comparison:before {
    content: "\f17f";
    }
        .fi-tr-arrow-down-from-arc:before {
    content: "\f180";
    }
        .fi-tr-arrow-down-from-dotted-line:before {
    content: "\f181";
    }
        .fi-tr-arrow-down-left:before {
    content: "\f182";
    }
        .fi-tr-arrow-down-small-big:before {
    content: "\f183";
    }
        .fi-tr-arrow-down-to-dotted-line:before {
    content: "\f184";
    }
        .fi-tr-arrow-down-to-square:before {
    content: "\f185";
    }
        .fi-tr-arrow-down-triangle-square:before {
    content: "\f186";
    }
        .fi-tr-arrow-down:before {
    content: "\f187";
    }
        .fi-tr-arrow-from-bottom:before {
    content: "\f188";
    }
        .fi-tr-arrow-from-left:before {
    content: "\f189";
    }
        .fi-tr-arrow-from-right:before {
    content: "\f18a";
    }
        .fi-tr-arrow-from-top:before {
    content: "\f18b";
    }
        .fi-tr-arrow-left-from-arc:before {
    content: "\f18c";
    }
        .fi-tr-arrow-left-from-line:before {
    content: "\f18d";
    }
        .fi-tr-arrow-left-to-arc:before {
    content: "\f18e";
    }
        .fi-tr-arrow-left:before {
    content: "\f18f";
    }
        .fi-tr-arrow-progress-alt:before {
    content: "\f190";
    }
        .fi-tr-arrow-progress:before {
    content: "\f191";
    }
        .fi-tr-arrow-right-to-bracket:before {
    content: "\f192";
    }
        .fi-tr-arrow-right:before {
    content: "\f193";
    }
        .fi-tr-arrow-small-down:before {
    content: "\f194";
    }
        .fi-tr-arrow-small-left:before {
    content: "\f195";
    }
        .fi-tr-arrow-small-right:before {
    content: "\f196";
    }
        .fi-tr-arrow-small-up:before {
    content: "\f197";
    }
        .fi-tr-arrow-square-down:before {
    content: "\f198";
    }
        .fi-tr-arrow-square-left:before {
    content: "\f199";
    }
        .fi-tr-arrow-square-right:before {
    content: "\f19a";
    }
        .fi-tr-arrow-square-up:before {
    content: "\f19b";
    }
        .fi-tr-arrow-to-bottom:before {
    content: "\f19c";
    }
        .fi-tr-arrow-to-left:before {
    content: "\f19d";
    }
        .fi-tr-arrow-to-right:before {
    content: "\f19e";
    }
        .fi-tr-arrow-to-top:before {
    content: "\f19f";
    }
        .fi-tr-arrow-trend-down:before {
    content: "\f1a0";
    }
        .fi-tr-arrow-trend-up:before {
    content: "\f1a1";
    }
        .fi-tr-arrow-turn-down-left:before {
    content: "\f1a2";
    }
        .fi-tr-arrow-turn-down-right:before {
    content: "\f1a3";
    }
        .fi-tr-arrow-turn-left-up:before {
    content: "\f1a4";
    }
        .fi-tr-arrow-up-from-dotted-line:before {
    content: "\f1a5";
    }
        .fi-tr-arrow-up-from-ground-water:before {
    content: "\f1a6";
    }
        .fi-tr-arrow-up-from-square:before {
    content: "\f1a7";
    }
        .fi-tr-arrow-up-left-from-circle:before {
    content: "\f1a8";
    }
        .fi-tr-arrow-up-left:before {
    content: "\f1a9";
    }
        .fi-tr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f1aa";
    }
        .fi-tr-arrow-up-right-from-square:before {
    content: "\f1ab";
    }
        .fi-tr-arrow-up-right:before {
    content: "\f1ac";
    }
        .fi-tr-arrow-up-small-big:before {
    content: "\f1ad";
    }
        .fi-tr-arrow-up-square-triangle:before {
    content: "\f1ae";
    }
        .fi-tr-arrow-up-to-arc:before {
    content: "\f1af";
    }
        .fi-tr-arrow-up-to-dotted-line:before {
    content: "\f1b0";
    }
        .fi-tr-arrow-up:before {
    content: "\f1b1";
    }
        .fi-tr-arrow-upward-growth-crypto:before {
    content: "\f1b2";
    }
        .fi-tr-arrows-alt-h:before {
    content: "\f1b3";
    }
        .fi-tr-arrows-alt-v:before {
    content: "\f1b4";
    }
        .fi-tr-arrows-alt:before {
    content: "\f1b5";
    }
        .fi-tr-arrows-cross:before {
    content: "\f1b6";
    }
        .fi-tr-arrows-from-dotted-line:before {
    content: "\f1b7";
    }
        .fi-tr-arrows-from-line:before {
    content: "\f1b8";
    }
        .fi-tr-arrows-h-copy:before {
    content: "\f1b9";
    }
        .fi-tr-arrows-h:before {
    content: "\f1ba";
    }
        .fi-tr-arrows-repeat-1:before {
    content: "\f1bb";
    }
        .fi-tr-arrows-repeat:before {
    content: "\f1bc";
    }
        .fi-tr-arrows-retweet:before {
    content: "\f1bd";
    }
        .fi-tr-arrows-split-right-and-left:before {
    content: "\f1be";
    }
        .fi-tr-arrows-split-up-and-left:before {
    content: "\f1bf";
    }
        .fi-tr-arrows-to-circle:before {
    content: "\f1c0";
    }
        .fi-tr-arrows-to-dotted-line:before {
    content: "\f1c1";
    }
        .fi-tr-arrows-to-line:before {
    content: "\f1c2";
    }
        .fi-tr-arrows:before {
    content: "\f1c3";
    }
        .fi-tr-artificial-intelligence:before {
    content: "\f1c4";
    }
        .fi-tr-assept-document:before {
    content: "\f1c5";
    }
        .fi-tr-assessment-alt:before {
    content: "\f1c6";
    }
        .fi-tr-assessment:before {
    content: "\f1c7";
    }
        .fi-tr-assign:before {
    content: "\f1c8";
    }
        .fi-tr-assistive-listening-systems:before {
    content: "\f1c9";
    }
        .fi-tr-asterik:before {
    content: "\f1ca";
    }
        .fi-tr-at:before {
    content: "\f1cb";
    }
        .fi-tr-attribution-pen:before {
    content: "\f1cc";
    }
        .fi-tr-attribution-pencil:before {
    content: "\f1cd";
    }
        .fi-tr-aubergine:before {
    content: "\f1ce";
    }
        .fi-tr-auction:before {
    content: "\f1cf";
    }
        .fi-tr-audio-description-slash:before {
    content: "\f1d0";
    }
        .fi-tr-audit-alt:before {
    content: "\f1d1";
    }
        .fi-tr-audit:before {
    content: "\f1d2";
    }
        .fi-tr-austral-sign:before {
    content: "\f1d3";
    }
        .fi-tr-avocado:before {
    content: "\f1d4";
    }
        .fi-tr-award:before {
    content: "\f1d5";
    }
        .fi-tr-axe-battle:before {
    content: "\f1d6";
    }
        .fi-tr-axe:before {
    content: "\f1d7";
    }
        .fi-tr-b:before {
    content: "\f1d8";
    }
        .fi-tr-baby-carriage:before {
    content: "\f1d9";
    }
        .fi-tr-baby:before {
    content: "\f1da";
    }
        .fi-tr-back-up:before {
    content: "\f1db";
    }
        .fi-tr-background:before {
    content: "\f1dc";
    }
        .fi-tr-backpack:before {
    content: "\f1dd";
    }
        .fi-tr-bacon:before {
    content: "\f1de";
    }
        .fi-tr-bacteria:before {
    content: "\f1df";
    }
        .fi-tr-bacterium:before {
    content: "\f1e0";
    }
        .fi-tr-badge-check:before {
    content: "\f1e1";
    }
        .fi-tr-badge-dollar:before {
    content: "\f1e2";
    }
        .fi-tr-badge-leaf:before {
    content: "\f1e3";
    }
        .fi-tr-badge-percent:before {
    content: "\f1e4";
    }
        .fi-tr-badge-sheriff:before {
    content: "\f1e5";
    }
        .fi-tr-badge:before {
    content: "\f1e6";
    }
        .fi-tr-badger-honey:before {
    content: "\f1e7";
    }
        .fi-tr-badget-check-alt:before {
    content: "\f1e8";
    }
        .fi-tr-badminton:before {
    content: "\f1e9";
    }
        .fi-tr-bag-map-pin:before {
    content: "\f1ea";
    }
        .fi-tr-bag-seedling:before {
    content: "\f1eb";
    }
        .fi-tr-bag-shopping-minus:before {
    content: "\f1ec";
    }
        .fi-tr-bags-shopping:before {
    content: "\f1ed";
    }
        .fi-tr-baguette:before {
    content: "\f1ee";
    }
        .fi-tr-bahai:before {
    content: "\f1ef";
    }
        .fi-tr-baht-sign:before {
    content: "\f1f0";
    }
        .fi-tr-balance-scale-left:before {
    content: "\f1f1";
    }
        .fi-tr-balance-scale-right:before {
    content: "\f1f2";
    }
        .fi-tr-ball-pile:before {
    content: "\f1f3";
    }
        .fi-tr-ballet-dance:before {
    content: "\f1f4";
    }
        .fi-tr-balloon:before {
    content: "\f1f5";
    }
        .fi-tr-balloons:before {
    content: "\f1f6";
    }
        .fi-tr-ballot-check:before {
    content: "\f1f7";
    }
        .fi-tr-ballot:before {
    content: "\f1f8";
    }
        .fi-tr-ban-bug:before {
    content: "\f1f9";
    }
        .fi-tr-banana:before {
    content: "\f1fa";
    }
        .fi-tr-band-aid:before {
    content: "\f1fb";
    }
        .fi-tr-bangladeshi-taka-sign:before {
    content: "\f1fc";
    }
        .fi-tr-banjo:before {
    content: "\f1fd";
    }
        .fi-tr-bank:before {
    content: "\f1fe";
    }
        .fi-tr-banner-2:before {
    content: "\f1ff";
    }
        .fi-tr-banner-3:before {
    content: "\f200";
    }
        .fi-tr-banner-4:before {
    content: "\f201";
    }
        .fi-tr-banner-5:before {
    content: "\f202";
    }
        .fi-tr-banner:before {
    content: "\f203";
    }
        .fi-tr-barber-pole:before {
    content: "\f204";
    }
        .fi-tr-barber-shop:before {
    content: "\f205";
    }
        .fi-tr-barcode-read:before {
    content: "\f206";
    }
        .fi-tr-barcode-scan:before {
    content: "\f207";
    }
        .fi-tr-barcode:before {
    content: "\f208";
    }
        .fi-tr-bars-filter:before {
    content: "\f209";
    }
        .fi-tr-bars-progress:before {
    content: "\f20a";
    }
        .fi-tr-bars-sort:before {
    content: "\f20b";
    }
        .fi-tr-bars-staggered:before {
    content: "\f20c";
    }
        .fi-tr-baseball-alt:before {
    content: "\f20d";
    }
        .fi-tr-basket-shopping-minus:before {
    content: "\f20e";
    }
        .fi-tr-basket-shopping-plus:before {
    content: "\f20f";
    }
        .fi-tr-basket-shopping-simple:before {
    content: "\f210";
    }
        .fi-tr-basketball-hoop:before {
    content: "\f211";
    }
        .fi-tr-bat:before {
    content: "\f212";
    }
        .fi-tr-bath:before {
    content: "\f213";
    }
        .fi-tr-battery-bolt:before {
    content: "\f214";
    }
        .fi-tr-battery-empty:before {
    content: "\f215";
    }
        .fi-tr-battery-exclamation:before {
    content: "\f216";
    }
        .fi-tr-battery-full:before {
    content: "\f217";
    }
        .fi-tr-battery-half:before {
    content: "\f218";
    }
        .fi-tr-battery-quarter:before {
    content: "\f219";
    }
        .fi-tr-battery-slash:before {
    content: "\f21a";
    }
        .fi-tr-battery-three-quarters:before {
    content: "\f21b";
    }
        .fi-tr-beacon:before {
    content: "\f21c";
    }
        .fi-tr-bed-alt:before {
    content: "\f21d";
    }
        .fi-tr-bed-bunk:before {
    content: "\f21e";
    }
        .fi-tr-bed-empty:before {
    content: "\f21f";
    }
        .fi-tr-bee:before {
    content: "\f220";
    }
        .fi-tr-beer-mug-empty:before {
    content: "\f221";
    }
        .fi-tr-beer:before {
    content: "\f222";
    }
        .fi-tr-bell-concierge:before {
    content: "\f223";
    }
        .fi-tr-bell-ring:before {
    content: "\f224";
    }
        .fi-tr-bell-school-slash:before {
    content: "\f225";
    }
        .fi-tr-bell-school:before {
    content: "\f226";
    }
        .fi-tr-bell-slash:before {
    content: "\f227";
    }
        .fi-tr-bells:before {
    content: "\f228";
    }
        .fi-tr-bench-tree:before {
    content: "\f229";
    }
        .fi-tr-benefit-diamond-alt:before {
    content: "\f22a";
    }
        .fi-tr-benefit-diamond:before {
    content: "\f22b";
    }
        .fi-tr-benefit-hand:before {
    content: "\f22c";
    }
        .fi-tr-benefit-increase:before {
    content: "\f22d";
    }
        .fi-tr-benefit-porcent:before {
    content: "\f22e";
    }
        .fi-tr-benefit:before {
    content: "\f22f";
    }
        .fi-tr-betamax:before {
    content: "\f230";
    }
        .fi-tr-bible:before {
    content: "\f231";
    }
        .fi-tr-bicycle-journey:before {
    content: "\f232";
    }
        .fi-tr-biking-mountain:before {
    content: "\f233";
    }
        .fi-tr-biking:before {
    content: "\f234";
    }
        .fi-tr-bill-arrow:before {
    content: "\f235";
    }
        .fi-tr-bin-bottles:before {
    content: "\f236";
    }
        .fi-tr-binary-circle-check:before {
    content: "\f237";
    }
        .fi-tr-binary-lock:before {
    content: "\f238";
    }
        .fi-tr-binary-slash:before {
    content: "\f239";
    }
        .fi-tr-binary:before {
    content: "\f23a";
    }
        .fi-tr-binoculars:before {
    content: "\f23b";
    }
        .fi-tr-bio-leaves:before {
    content: "\f23c";
    }
        .fi-tr-bio:before {
    content: "\f23d";
    }
        .fi-tr-biohazard:before {
    content: "\f23e";
    }
        .fi-tr-bird:before {
    content: "\f23f";
    }
        .fi-tr-bitcoin-sign:before {
    content: "\f240";
    }
        .fi-tr-blanket:before {
    content: "\f241";
    }
        .fi-tr-blender-phone:before {
    content: "\f242";
    }
        .fi-tr-blender:before {
    content: "\f243";
    }
        .fi-tr-blinds-open:before {
    content: "\f244";
    }
        .fi-tr-blinds-raised:before {
    content: "\f245";
    }
        .fi-tr-blinds:before {
    content: "\f246";
    }
        .fi-tr-block-brick:before {
    content: "\f247";
    }
        .fi-tr-block-microphone:before {
    content: "\f248";
    }
        .fi-tr-block-question:before {
    content: "\f249";
    }
        .fi-tr-block-quote:before {
    content: "\f24a";
    }
        .fi-tr-block:before {
    content: "\f24b";
    }
        .fi-tr-blockchain-3:before {
    content: "\f24c";
    }
        .fi-tr-blog-pencil:before {
    content: "\f24d";
    }
        .fi-tr-blog-text:before {
    content: "\f24e";
    }
        .fi-tr-blood-dropper:before {
    content: "\f24f";
    }
        .fi-tr-blood-test-tube-alt:before {
    content: "\f250";
    }
        .fi-tr-blood-test-tube:before {
    content: "\f251";
    }
        .fi-tr-blood:before {
    content: "\f252";
    }
        .fi-tr-blueberries:before {
    content: "\f253";
    }
        .fi-tr-blueprint:before {
    content: "\f254";
    }
        .fi-tr-bolt-auto:before {
    content: "\f255";
    }
        .fi-tr-bolt-slash:before {
    content: "\f256";
    }
        .fi-tr-bolt:before {
    content: "\f257";
    }
        .fi-tr-bomb:before {
    content: "\f258";
    }
        .fi-tr-bone-break:before {
    content: "\f259";
    }
        .fi-tr-bone:before {
    content: "\f25a";
    }
        .fi-tr-bong:before {
    content: "\f25b";
    }
        .fi-tr-bonus-alt:before {
    content: "\f25c";
    }
        .fi-tr-bonus-star:before {
    content: "\f25d";
    }
        .fi-tr-bonus:before {
    content: "\f25e";
    }
        .fi-tr-book-alt:before {
    content: "\f25f";
    }
        .fi-tr-book-arrow-right:before {
    content: "\f260";
    }
        .fi-tr-book-arrow-up:before {
    content: "\f261";
    }
        .fi-tr-book-atlas:before {
    content: "\f262";
    }
        .fi-tr-book-bookmark:before {
    content: "\f263";
    }
        .fi-tr-book-circle-arrow-right:before {
    content: "\f264";
    }
        .fi-tr-book-circle-arrow-up:before {
    content: "\f265";
    }
        .fi-tr-book-copy:before {
    content: "\f266";
    }
        .fi-tr-book-dead:before {
    content: "\f267";
    }
        .fi-tr-book-font:before {
    content: "\f268";
    }
        .fi-tr-book-heart:before {
    content: "\f269";
    }
        .fi-tr-book-medical:before {
    content: "\f26a";
    }
        .fi-tr-book-open-cover:before {
    content: "\f26b";
    }
        .fi-tr-book-open-reader:before {
    content: "\f26c";
    }
        .fi-tr-book-plus:before {
    content: "\f26d";
    }
        .fi-tr-book-quran:before {
    content: "\f26e";
    }
        .fi-tr-book-section:before {
    content: "\f26f";
    }
        .fi-tr-book-spells:before {
    content: "\f270";
    }
        .fi-tr-book-tanakh:before {
    content: "\f271";
    }
        .fi-tr-book-user:before {
    content: "\f272";
    }
        .fi-tr-Booking:before {
    content: "\f273";
    }
        .fi-tr-bookmark-slash:before {
    content: "\f274";
    }
        .fi-tr-books-medical:before {
    content: "\f275";
    }
        .fi-tr-books:before {
    content: "\f276";
    }
        .fi-tr-boot-heeled:before {
    content: "\f277";
    }
        .fi-tr-boot:before {
    content: "\f278";
    }
        .fi-tr-booth-curtain:before {
    content: "\f279";
    }
        .fi-tr-border-all:before {
    content: "\f27a";
    }
        .fi-tr-border-bottom:before {
    content: "\f27b";
    }
        .fi-tr-border-center-h:before {
    content: "\f27c";
    }
        .fi-tr-border-center-v:before {
    content: "\f27d";
    }
        .fi-tr-border-inner:before {
    content: "\f27e";
    }
        .fi-tr-border-left:before {
    content: "\f27f";
    }
        .fi-tr-border-none:before {
    content: "\f280";
    }
        .fi-tr-border-outer:before {
    content: "\f281";
    }
        .fi-tr-border-right:before {
    content: "\f282";
    }
        .fi-tr-border-style-alt:before {
    content: "\f283";
    }
        .fi-tr-border-style:before {
    content: "\f284";
    }
        .fi-tr-border-top:before {
    content: "\f285";
    }
        .fi-tr-boss:before {
    content: "\f286";
    }
        .fi-tr-bottle-droplet:before {
    content: "\f287";
    }
        .fi-tr-bottle:before {
    content: "\f288";
    }
        .fi-tr-bow-arrow:before {
    content: "\f289";
    }
        .fi-tr-bowl-chopsticks-noodles:before {
    content: "\f28a";
    }
        .fi-tr-bowl-chopsticks:before {
    content: "\f28b";
    }
        .fi-tr-bowl-rice:before {
    content: "\f28c";
    }
        .fi-tr-bowl-scoop:before {
    content: "\f28d";
    }
        .fi-tr-bowl-scoops:before {
    content: "\f28e";
    }
        .fi-tr-bowl-soft-serve:before {
    content: "\f28f";
    }
        .fi-tr-bowl-spoon:before {
    content: "\f290";
    }
        .fi-tr-bowling-ball:before {
    content: "\f291";
    }
        .fi-tr-bowling-pins:before {
    content: "\f292";
    }
        .fi-tr-box-alt:before {
    content: "\f293";
    }
        .fi-tr-box-ballot:before {
    content: "\f294";
    }
        .fi-tr-box-check:before {
    content: "\f295";
    }
        .fi-tr-box-circle-check:before {
    content: "\f296";
    }
        .fi-tr-box-dollar:before {
    content: "\f297";
    }
        .fi-tr-box-fragile:before {
    content: "\f298";
    }
        .fi-tr-box-heart:before {
    content: "\f299";
    }
        .fi-tr-box-open-full:before {
    content: "\f29a";
    }
        .fi-tr-box-open:before {
    content: "\f29b";
    }
        .fi-tr-box-tissue:before {
    content: "\f29c";
    }
        .fi-tr-box-up:before {
    content: "\f29d";
    }
        .fi-tr-boxes:before {
    content: "\f29e";
    }
        .fi-tr-boxing-glove:before {
    content: "\f29f";
    }
        .fi-tr-bracket-curly-right:before {
    content: "\f2a0";
    }
        .fi-tr-bracket-curly:before {
    content: "\f2a1";
    }
        .fi-tr-bracket-round-right:before {
    content: "\f2a2";
    }
        .fi-tr-bracket-round:before {
    content: "\f2a3";
    }
        .fi-tr-bracket-square-right:before {
    content: "\f2a4";
    }
        .fi-tr-bracket-square:before {
    content: "\f2a5";
    }
        .fi-tr-brackets-curly:before {
    content: "\f2a6";
    }
        .fi-tr-brackets-round:before {
    content: "\f2a7";
    }
        .fi-tr-brackets-square:before {
    content: "\f2a8";
    }
        .fi-tr-braille-a:before {
    content: "\f2a9";
    }
        .fi-tr-braille-b:before {
    content: "\f2aa";
    }
        .fi-tr-braille-c:before {
    content: "\f2ab";
    }
        .fi-tr-braille-d:before {
    content: "\f2ac";
    }
        .fi-tr-braille-e:before {
    content: "\f2ad";
    }
        .fi-tr-braille-g:before {
    content: "\f2ae";
    }
        .fi-tr-braille-h:before {
    content: "\f2af";
    }
        .fi-tr-braille-i:before {
    content: "\f2b0";
    }
        .fi-tr-braille-j:before {
    content: "\f2b1";
    }
        .fi-tr-braille-k:before {
    content: "\f2b2";
    }
        .fi-tr-braille-l:before {
    content: "\f2b3";
    }
        .fi-tr-braille-m:before {
    content: "\f2b4";
    }
        .fi-tr-braille-n-alt:before {
    content: "\f2b5";
    }
        .fi-tr-braille-n:before {
    content: "\f2b6";
    }
        .fi-tr-braille-o:before {
    content: "\f2b7";
    }
        .fi-tr-braille-p:before {
    content: "\f2b8";
    }
        .fi-tr-braille-q:before {
    content: "\f2b9";
    }
        .fi-tr-braille-r:before {
    content: "\f2ba";
    }
        .fi-tr-braille-s:before {
    content: "\f2bb";
    }
        .fi-tr-braille-t:before {
    content: "\f2bc";
    }
        .fi-tr-braille-u:before {
    content: "\f2bd";
    }
        .fi-tr-braille-v:before {
    content: "\f2be";
    }
        .fi-tr-braille-w:before {
    content: "\f2bf";
    }
        .fi-tr-braille-x:before {
    content: "\f2c0";
    }
        .fi-tr-braille-y:before {
    content: "\f2c1";
    }
        .fi-tr-braille-z:before {
    content: "\f2c2";
    }
        .fi-tr-braille:before {
    content: "\f2c3";
    }
        .fi-tr-brain-circuit:before {
    content: "\f2c4";
    }
        .fi-tr-brain:before {
    content: "\f2c5";
    }
        .fi-tr-brake-warning:before {
    content: "\f2c6";
    }
        .fi-tr-branching:before {
    content: "\f2c7";
    }
        .fi-tr-brand:before {
    content: "\f2c8";
    }
        .fi-tr-branding:before {
    content: "\f2c9";
    }
        .fi-tr-bread-loaf:before {
    content: "\f2ca";
    }
        .fi-tr-bread-slice-butter:before {
    content: "\f2cb";
    }
        .fi-tr-bread-slice:before {
    content: "\f2cc";
    }
        .fi-tr-bread:before {
    content: "\f2cd";
    }
        .fi-tr-briefcase-arrow-right:before {
    content: "\f2ce";
    }
        .fi-tr-briefcase-blank:before {
    content: "\f2cf";
    }
        .fi-tr-brightness-low:before {
    content: "\f2d0";
    }
        .fi-tr-brightness:before {
    content: "\f2d1";
    }
        .fi-tr-bring-forward:before {
    content: "\f2d2";
    }
        .fi-tr-bring-front:before {
    content: "\f2d3";
    }
        .fi-tr-broadcast-tower:before {
    content: "\f2d4";
    }
        .fi-tr-broccoli:before {
    content: "\f2d5";
    }
        .fi-tr-broken-image:before {
    content: "\f2d6";
    }
        .fi-tr-broom-ball:before {
    content: "\f2d7";
    }
        .fi-tr-browser-ui:before {
    content: "\f2d8";
    }
        .fi-tr-browser:before {
    content: "\f2d9";
    }
        .fi-tr-browsers:before {
    content: "\f2da";
    }
        .fi-tr-bucket:before {
    content: "\f2db";
    }
        .fi-tr-budget-alt:before {
    content: "\f2dc";
    }
        .fi-tr-budget:before {
    content: "\f2dd";
    }
        .fi-tr-bug-slash:before {
    content: "\f2de";
    }
        .fi-tr-bugs:before {
    content: "\f2df";
    }
        .fi-tr-build-alt:before {
    content: "\f2e0";
    }
        .fi-tr-build:before {
    content: "\f2e1";
    }
        .fi-tr-builder:before {
    content: "\f2e2";
    }
        .fi-tr-building-circle-arrow-right:before {
    content: "\f2e3";
    }
        .fi-tr-building-ngo:before {
    content: "\f2e4";
    }
        .fi-tr-building:before {
    content: "\f2e5";
    }
        .fi-tr-bulb:before {
    content: "\f2e6";
    }
        .fi-tr-bullet:before {
    content: "\f2e7";
    }
        .fi-tr-bullhorn:before {
    content: "\f2e8";
    }
        .fi-tr-bullseye-arrow:before {
    content: "\f2e9";
    }
        .fi-tr-bullseye-pointer:before {
    content: "\f2ea";
    }
        .fi-tr-bullseye:before {
    content: "\f2eb";
    }
        .fi-tr-burger-alt:before {
    content: "\f2ec";
    }
        .fi-tr-burger-fries:before {
    content: "\f2ed";
    }
        .fi-tr-burger-glass:before {
    content: "\f2ee";
    }
        .fi-tr-burrito:before {
    content: "\f2ef";
    }
        .fi-tr-burst:before {
    content: "\f2f0";
    }
        .fi-tr-bus-alt:before {
    content: "\f2f1";
    }
        .fi-tr-bus:before {
    content: "\f2f2";
    }
        .fi-tr-business-time:before {
    content: "\f2f3";
    }
        .fi-tr-Butter:before {
    content: "\f2f4";
    }
        .fi-tr-butterfly:before {
    content: "\f2f5";
    }
        .fi-tr-c:before {
    content: "\f2f6";
    }
        .fi-tr-cabin:before {
    content: "\f2f7";
    }
        .fi-tr-cactus:before {
    content: "\f2f8";
    }
        .fi-tr-cage-empty:before {
    content: "\f2f9";
    }
        .fi-tr-cake-birthday:before {
    content: "\f2fa";
    }
        .fi-tr-cake-slice:before {
    content: "\f2fb";
    }
        .fi-tr-cake-wedding:before {
    content: "\f2fc";
    }
        .fi-tr-calculator-bill:before {
    content: "\f2fd";
    }
        .fi-tr-calculator-math-tax:before {
    content: "\f2fe";
    }
        .fi-tr-calculator-money:before {
    content: "\f2ff";
    }
        .fi-tr-calculator-simple:before {
    content: "\f300";
    }
        .fi-tr-calculator:before {
    content: "\f301";
    }
        .fi-tr-calendar-arrow-down:before {
    content: "\f302";
    }
        .fi-tr-calendar-arrow-up:before {
    content: "\f303";
    }
        .fi-tr-calendar-check:before {
    content: "\f304";
    }
        .fi-tr-calendar-clock:before {
    content: "\f305";
    }
        .fi-tr-calendar-day:before {
    content: "\f306";
    }
        .fi-tr-calendar-days:before {
    content: "\f307";
    }
        .fi-tr-calendar-event-tax:before {
    content: "\f308";
    }
        .fi-tr-calendar-exclamation:before {
    content: "\f309";
    }
        .fi-tr-calendar-gavel-legal:before {
    content: "\f30a";
    }
        .fi-tr-calendar-heart:before {
    content: "\f30b";
    }
        .fi-tr-calendar-image:before {
    content: "\f30c";
    }
        .fi-tr-calendar-lines-pen:before {
    content: "\f30d";
    }
        .fi-tr-calendar-lines:before {
    content: "\f30e";
    }
        .fi-tr-calendar-minus:before {
    content: "\f30f";
    }
        .fi-tr-calendar-payment-loan:before {
    content: "\f310";
    }
        .fi-tr-calendar-pen:before {
    content: "\f311";
    }
        .fi-tr-calendar-plus:before {
    content: "\f312";
    }
        .fi-tr-calendar-salary:before {
    content: "\f313";
    }
        .fi-tr-calendar-shift-swap:before {
    content: "\f314";
    }
        .fi-tr-calendar-star:before {
    content: "\f315";
    }
        .fi-tr-calendar-swap:before {
    content: "\f316";
    }
        .fi-tr-calendar-week:before {
    content: "\f317";
    }
        .fi-tr-calendar-xmark:before {
    content: "\f318";
    }
        .fi-tr-calendars:before {
    content: "\f319";
    }
        .fi-tr-call-duration:before {
    content: "\f31a";
    }
        .fi-tr-call-history:before {
    content: "\f31b";
    }
        .fi-tr-call-incoming:before {
    content: "\f31c";
    }
        .fi-tr-call-missed:before {
    content: "\f31d";
    }
        .fi-tr-call-outgoing:before {
    content: "\f31e";
    }
        .fi-tr-camcorder:before {
    content: "\f31f";
    }
        .fi-tr-camera-cctv:before {
    content: "\f320";
    }
        .fi-tr-camera-movie:before {
    content: "\f321";
    }
        .fi-tr-camera-retro:before {
    content: "\f322";
    }
        .fi-tr-camera-rotate:before {
    content: "\f323";
    }
        .fi-tr-camera-security:before {
    content: "\f324";
    }
        .fi-tr-camera-slash:before {
    content: "\f325";
    }
        .fi-tr-camera-viewfinder:before {
    content: "\f326";
    }
        .fi-tr-camera:before {
    content: "\f327";
    }
        .fi-tr-campfire:before {
    content: "\f328";
    }
        .fi-tr-can-food:before {
    content: "\f329";
    }
        .fi-tr-candle-holder:before {
    content: "\f32a";
    }
        .fi-tr-candle-lotus-yoga:before {
    content: "\f32b";
    }
        .fi-tr-candle-pose-yoga:before {
    content: "\f32c";
    }
        .fi-tr-candy-alt:before {
    content: "\f32d";
    }
        .fi-tr-candy-bar:before {
    content: "\f32e";
    }
        .fi-tr-candy-cane:before {
    content: "\f32f";
    }
        .fi-tr-candy-corn:before {
    content: "\f330";
    }
        .fi-tr-candy-sweet:before {
    content: "\f331";
    }
        .fi-tr-candy:before {
    content: "\f332";
    }
        .fi-tr-Cannabis:before {
    content: "\f333";
    }
        .fi-tr-canned-food:before {
    content: "\f334";
    }
        .fi-tr-capsules:before {
    content: "\f335";
    }
        .fi-tr-car-alt:before {
    content: "\f336";
    }
        .fi-tr-car-battery:before {
    content: "\f337";
    }
        .fi-tr-car-bolt:before {
    content: "\f338";
    }
        .fi-tr-car-building:before {
    content: "\f339";
    }
        .fi-tr-car-bump:before {
    content: "\f33a";
    }
        .fi-tr-car-bus:before {
    content: "\f33b";
    }
        .fi-tr-car-circle-bolt:before {
    content: "\f33c";
    }
        .fi-tr-car-crash:before {
    content: "\f33d";
    }
        .fi-tr-car-garage:before {
    content: "\f33e";
    }
        .fi-tr-car-journey:before {
    content: "\f33f";
    }
        .fi-tr-car-mechanic:before {
    content: "\f340";
    }
        .fi-tr-car-rear:before {
    content: "\f341";
    }
        .fi-tr-car-side-bolt:before {
    content: "\f342";
    }
        .fi-tr-car-side:before {
    content: "\f343";
    }
        .fi-tr-car-tilt:before {
    content: "\f344";
    }
        .fi-tr-car-tunnel:before {
    content: "\f345";
    }
        .fi-tr-car-wash:before {
    content: "\f346";
    }
        .fi-tr-caravan-alt:before {
    content: "\f347";
    }
        .fi-tr-caravan:before {
    content: "\f348";
    }
        .fi-tr-card-club:before {
    content: "\f349";
    }
        .fi-tr-card-diamond:before {
    content: "\f34a";
    }
        .fi-tr-card-heart:before {
    content: "\f34b";
    }
        .fi-tr-card-spade:before {
    content: "\f34c";
    }
        .fi-tr-cardinal-compass:before {
    content: "\f34d";
    }
        .fi-tr-career-growth:before {
    content: "\f34e";
    }
        .fi-tr-career-path:before {
    content: "\f34f";
    }
        .fi-tr-caret-circle-down:before {
    content: "\f350";
    }
        .fi-tr-caret-circle-right:before {
    content: "\f351";
    }
        .fi-tr-caret-circle-up:before {
    content: "\f352";
    }
        .fi-tr-caret-down:before {
    content: "\f353";
    }
        .fi-tr-caret-left:before {
    content: "\f354";
    }
        .fi-tr-caret-right:before {
    content: "\f355";
    }
        .fi-tr-caret-square-down:before {
    content: "\f356";
    }
        .fi-tr-caret-square-left_1:before {
    content: "\f357";
    }
        .fi-tr-caret-square-left:before {
    content: "\f358";
    }
        .fi-tr-caret-square-right:before {
    content: "\f359";
    }
        .fi-tr-caret-square-up:before {
    content: "\f35a";
    }
        .fi-tr-caret-up:before {
    content: "\f35b";
    }
        .fi-tr-carrot:before {
    content: "\f35c";
    }
        .fi-tr-cars-crash:before {
    content: "\f35d";
    }
        .fi-tr-cars:before {
    content: "\f35e";
    }
        .fi-tr-cart-arrow-down:before {
    content: "\f35f";
    }
        .fi-tr-cart-minus:before {
    content: "\f360";
    }
        .fi-tr-cart-shopping-fast:before {
    content: "\f361";
    }
        .fi-tr-cash-register:before {
    content: "\f362";
    }
        .fi-tr-cassette-tape:before {
    content: "\f363";
    }
        .fi-tr-cassette-vhs:before {
    content: "\f364";
    }
        .fi-tr-castle:before {
    content: "\f365";
    }
        .fi-tr-cat-head:before {
    content: "\f366";
    }
        .fi-tr-cat-space:before {
    content: "\f367";
    }
        .fi-tr-cat:before {
    content: "\f368";
    }
        .fi-tr-catalog-alt:before {
    content: "\f369";
    }
        .fi-tr-catalog-magazine:before {
    content: "\f36a";
    }
        .fi-tr-catalog:before {
    content: "\f36b";
    }
        .fi-tr-category-alt:before {
    content: "\f36c";
    }
        .fi-tr-category:before {
    content: "\f36d";
    }
        .fi-tr-cauldron:before {
    content: "\f36e";
    }
        .fi-tr-cedi-sign:before {
    content: "\f36f";
    }
        .fi-tr-cello:before {
    content: "\f370";
    }
        .fi-tr-cent-sign:before {
    content: "\f371";
    }
        .fi-tr-chair-office:before {
    content: "\f372";
    }
        .fi-tr-chair:before {
    content: "\f373";
    }
        .fi-tr-chalkboard-user:before {
    content: "\f374";
    }
        .fi-tr-chalkboard:before {
    content: "\f375";
    }
        .fi-tr-challenge-alt:before {
    content: "\f376";
    }
        .fi-tr-challenge:before {
    content: "\f377";
    }
        .fi-tr-channel:before {
    content: "\f378";
    }
        .fi-tr-charging-station:before {
    content: "\f379";
    }
        .fi-tr-chart-area:before {
    content: "\f37a";
    }
        .fi-tr-chart-arrow-down:before {
    content: "\f37b";
    }
        .fi-tr-chart-arrow-grow:before {
    content: "\f37c";
    }
        .fi-tr-chart-bullet:before {
    content: "\f37d";
    }
        .fi-tr-chart-candlestick:before {
    content: "\f37e";
    }
        .fi-tr-chart-connected:before {
    content: "\f37f";
    }
        .fi-tr-chart-gantt:before {
    content: "\f380";
    }
        .fi-tr-chart-histogram:before {
    content: "\f381";
    }
        .fi-tr-chart-kanban:before {
    content: "\f382";
    }
        .fi-tr-chart-line-up-down:before {
    content: "\f383";
    }
        .fi-tr-chart-line-up:before {
    content: "\f384";
    }
        .fi-tr-chart-mixed-up-circle-currency:before {
    content: "\f385";
    }
        .fi-tr-chart-mixed-up-circle-dollar:before {
    content: "\f386";
    }
        .fi-tr-chart-mixed:before {
    content: "\f387";
    }
        .fi-tr-chart-network:before {
    content: "\f388";
    }
        .fi-tr-chart-pie-alt:before {
    content: "\f389";
    }
        .fi-tr-chart-pie-simple-circle-currency:before {
    content: "\f38a";
    }
        .fi-tr-chart-pie-simple-circle-dollar:before {
    content: "\f38b";
    }
        .fi-tr-chart-pie:before {
    content: "\f38c";
    }
        .fi-tr-chart-pyramid:before {
    content: "\f38d";
    }
        .fi-tr-chart-radar:before {
    content: "\f38e";
    }
        .fi-tr-chart-scatter-3d:before {
    content: "\f38f";
    }
        .fi-tr-chart-scatter-bubble:before {
    content: "\f390";
    }
        .fi-tr-chart-scatter:before {
    content: "\f391";
    }
        .fi-tr-chart-set-theory:before {
    content: "\f392";
    }
        .fi-tr-chart-simple-horizontal:before {
    content: "\f393";
    }
        .fi-tr-chart-simple:before {
    content: "\f394";
    }
        .fi-tr-chart-tree-map:before {
    content: "\f395";
    }
        .fi-tr-chart-tree:before {
    content: "\f396";
    }
        .fi-tr-chart-user:before {
    content: "\f397";
    }
        .fi-tr-chart-waterfall:before {
    content: "\f398";
    }
        .fi-tr-chatbot-speech-bubble:before {
    content: "\f399";
    }
        .fi-tr-chatbot:before {
    content: "\f39a";
    }
        .fi-tr-cheap-bill:before {
    content: "\f39b";
    }
        .fi-tr-cheap-dollar:before {
    content: "\f39c";
    }
        .fi-tr-cheap-stack-dollar:before {
    content: "\f39d";
    }
        .fi-tr-cheap-stack:before {
    content: "\f39e";
    }
        .fi-tr-cheap:before {
    content: "\f39f";
    }
        .fi-tr-check-circle:before {
    content: "\f3a0";
    }
        .fi-tr-check-double:before {
    content: "\f3a1";
    }
        .fi-tr-check-in-calendar:before {
    content: "\f3a2";
    }
        .fi-tr-check-out-calendar:before {
    content: "\f3a3";
    }
        .fi-tr-checklist-task-budget:before {
    content: "\f3a4";
    }
        .fi-tr-cheese-alt:before {
    content: "\f3a5";
    }
        .fi-tr-cheese:before {
    content: "\f3a6";
    }
        .fi-tr-cheeseburger:before {
    content: "\f3a7";
    }
        .fi-tr-cherry:before {
    content: "\f3a8";
    }
        .fi-tr-chess-bishop:before {
    content: "\f3a9";
    }
        .fi-tr-chess-board:before {
    content: "\f3aa";
    }
        .fi-tr-chess-clock-alt:before {
    content: "\f3ab";
    }
        .fi-tr-chess-clock:before {
    content: "\f3ac";
    }
        .fi-tr-chess-king-alt:before {
    content: "\f3ad";
    }
        .fi-tr-chess-king:before {
    content: "\f3ae";
    }
        .fi-tr-chess-knight-alt:before {
    content: "\f3af";
    }
        .fi-tr-chess-knight:before {
    content: "\f3b0";
    }
        .fi-tr-chess-pawn-alt:before {
    content: "\f3b1";
    }
        .fi-tr-chess-pawn:before {
    content: "\f3b2";
    }
        .fi-tr-chess-queen-alt:before {
    content: "\f3b3";
    }
        .fi-tr-chess-queen:before {
    content: "\f3b4";
    }
        .fi-tr-chess-rook-alt:before {
    content: "\f3b5";
    }
        .fi-tr-chess-rook:before {
    content: "\f3b6";
    }
        .fi-tr-chess:before {
    content: "\f3b7";
    }
        .fi-tr-chevron-double-down:before {
    content: "\f3b8";
    }
        .fi-tr-chevron-double-up:before {
    content: "\f3b9";
    }
        .fi-tr-child-head:before {
    content: "\f3ba";
    }
        .fi-tr-child:before {
    content: "\f3bb";
    }
        .fi-tr-chimney:before {
    content: "\f3bc";
    }
        .fi-tr-chip:before {
    content: "\f3bd";
    }
        .fi-tr-chocolate-bar:before {
    content: "\f3be";
    }
        .fi-tr-choir-singing:before {
    content: "\f3bf";
    }
        .fi-tr-choose-alt:before {
    content: "\f3c0";
    }
        .fi-tr-choose:before {
    content: "\f3c1";
    }
        .fi-tr-church:before {
    content: "\f3c2";
    }
        .fi-tr-circle-0:before {
    content: "\f3c3";
    }
        .fi-tr-circle-1:before {
    content: "\f3c4";
    }
        .fi-tr-circle-2:before {
    content: "\f3c5";
    }
        .fi-tr-circle-3:before {
    content: "\f3c6";
    }
        .fi-tr-circle-4:before {
    content: "\f3c7";
    }
        .fi-tr-circle-5:before {
    content: "\f3c8";
    }
        .fi-tr-circle-6:before {
    content: "\f3c9";
    }
        .fi-tr-circle-7:before {
    content: "\f3ca";
    }
        .fi-tr-circle-8:before {
    content: "\f3cb";
    }
        .fi-tr-circle-9:before {
    content: "\f3cc";
    }
        .fi-tr-circle-a:before {
    content: "\f3cd";
    }
        .fi-tr-circle-b:before {
    content: "\f3ce";
    }
        .fi-tr-circle-bolt:before {
    content: "\f3cf";
    }
        .fi-tr-circle-book-open:before {
    content: "\f3d0";
    }
        .fi-tr-circle-bookmark:before {
    content: "\f3d1";
    }
        .fi-tr-circle-c:before {
    content: "\f3d2";
    }
        .fi-tr-circle-calendar:before {
    content: "\f3d3";
    }
        .fi-tr-circle-camera:before {
    content: "\f3d4";
    }
        .fi-tr-circle-d:before {
    content: "\f3d5";
    }
        .fi-tr-circle-dashed:before {
    content: "\f3d6";
    }
        .fi-tr-circle-divide:before {
    content: "\f3d7";
    }
        .fi-tr-circle-e:before {
    content: "\f3d8";
    }
        .fi-tr-circle-ellipsis-vertical:before {
    content: "\f3d9";
    }
        .fi-tr-circle-ellipsis:before {
    content: "\f3da";
    }
        .fi-tr-circle-envelope:before {
    content: "\f3db";
    }
        .fi-tr-circle-exclamation-check:before {
    content: "\f3dc";
    }
        .fi-tr-circle-f:before {
    content: "\f3dd";
    }
        .fi-tr-circle-g:before {
    content: "\f3de";
    }
        .fi-tr-circle-h:before {
    content: "\f3df";
    }
        .fi-tr-circle-half-stroke:before {
    content: "\f3e0";
    }
        .fi-tr-circle-half:before {
    content: "\f3e1";
    }
        .fi-tr-circle-heart:before {
    content: "\f3e2";
    }
        .fi-tr-circle-i:before {
    content: "\f3e3";
    }
        .fi-tr-circle-j:before {
    content: "\f3e4";
    }
        .fi-tr-circle-k:before {
    content: "\f3e5";
    }
        .fi-tr-circle-l:before {
    content: "\f3e6";
    }
        .fi-tr-circle-m:before {
    content: "\f3e7";
    }
        .fi-tr-circle-microphone-lines:before {
    content: "\f3e8";
    }
        .fi-tr-circle-microphone:before {
    content: "\f3e9";
    }
        .fi-tr-circle-n:before {
    content: "\f3ea";
    }
        .fi-tr-circle-nodes:before {
    content: "\f3eb";
    }
        .fi-tr-circle-o:before {
    content: "\f3ec";
    }
        .fi-tr-circle-overlap:before {
    content: "\f3ed";
    }
        .fi-tr-circle-p:before {
    content: "\f3ee";
    }
        .fi-tr-circle-phone-flip:before {
    content: "\f3ef";
    }
        .fi-tr-circle-phone-hangup:before {
    content: "\f3f0";
    }
        .fi-tr-circle-phone:before {
    content: "\f3f1";
    }
        .fi-tr-circle-q:before {
    content: "\f3f2";
    }
        .fi-tr-circle-quarters-alt:before {
    content: "\f3f3";
    }
        .fi-tr-circle-quarters:before {
    content: "\f3f4";
    }
        .fi-tr-circle-r:before {
    content: "\f3f5";
    }
        .fi-tr-circle-s:before {
    content: "\f3f6";
    }
        .fi-tr-circle-small:before {
    content: "\f3f7";
    }
        .fi-tr-circle-star:before {
    content: "\f3f8";
    }
        .fi-tr-circle-t:before {
    content: "\f3f9";
    }
        .fi-tr-circle-three-quarters:before {
    content: "\f3fa";
    }
        .fi-tr-circle-trash:before {
    content: "\f3fb";
    }
        .fi-tr-circle-u:before {
    content: "\f3fc";
    }
        .fi-tr-circle-user:before {
    content: "\f3fd";
    }
        .fi-tr-circle-v:before {
    content: "\f3fe";
    }
        .fi-tr-circle-video:before {
    content: "\f3ff";
    }
        .fi-tr-circle-w:before {
    content: "\f400";
    }
        .fi-tr-circle-waveform-lines:before {
    content: "\f401";
    }
        .fi-tr-circle-x:before {
    content: "\f402";
    }
        .fi-tr-circle-xmark:before {
    content: "\f403";
    }
        .fi-tr-circle-y:before {
    content: "\f404";
    }
        .fi-tr-circle-z:before {
    content: "\f405";
    }
        .fi-tr-circle:before {
    content: "\f406";
    }
        .fi-tr-citrus-slice:before {
    content: "\f407";
    }
        .fi-tr-citrus:before {
    content: "\f408";
    }
        .fi-tr-city:before {
    content: "\f409";
    }
        .fi-tr-clapperboard-play:before {
    content: "\f40a";
    }
        .fi-tr-clapperboard:before {
    content: "\f40b";
    }
        .fi-tr-clarinet:before {
    content: "\f40c";
    }
        .fi-tr-claw-marks:before {
    content: "\f40d";
    }
        .fi-tr-clear-alt:before {
    content: "\f40e";
    }
        .fi-tr-clip-file:before {
    content: "\f40f";
    }
        .fi-tr-clip-mail:before {
    content: "\f410";
    }
        .fi-tr-clipboard-check:before {
    content: "\f411";
    }
        .fi-tr-clipboard-exclamation:before {
    content: "\f412";
    }
        .fi-tr-clipboard-list-check:before {
    content: "\f413";
    }
        .fi-tr-clipboard-list:before {
    content: "\f414";
    }
        .fi-tr-clipboard-prescription:before {
    content: "\f415";
    }
        .fi-tr-clipboard-question:before {
    content: "\f416";
    }
        .fi-tr-clipboard-user:before {
    content: "\f417";
    }
        .fi-tr-clipboard:before {
    content: "\f418";
    }
        .fi-tr-clipoard-wrong:before {
    content: "\f419";
    }
        .fi-tr-clock-desk:before {
    content: "\f41a";
    }
        .fi-tr-clock-eight-thirty:before {
    content: "\f41b";
    }
        .fi-tr-clock-eleven-thirty:before {
    content: "\f41c";
    }
        .fi-tr-clock-eleven:before {
    content: "\f41d";
    }
        .fi-tr-clock-five-thirty:before {
    content: "\f41e";
    }
        .fi-tr-clock-five:before {
    content: "\f41f";
    }
        .fi-tr-clock-four-thirty:before {
    content: "\f420";
    }
        .fi-tr-clock-nine-thirty:before {
    content: "\f421";
    }
        .fi-tr-clock-nine:before {
    content: "\f422";
    }
        .fi-tr-clock-one-thirty:before {
    content: "\f423";
    }
        .fi-tr-clock-one:before {
    content: "\f424";
    }
        .fi-tr-clock-seven-thirty:before {
    content: "\f425";
    }
        .fi-tr-clock-seven:before {
    content: "\f426";
    }
        .fi-tr-clock-six-thirty:before {
    content: "\f427";
    }
        .fi-tr-clock-six:before {
    content: "\f428";
    }
        .fi-tr-clock-ten-thirty:before {
    content: "\f429";
    }
        .fi-tr-clock-ten:before {
    content: "\f42a";
    }
        .fi-tr-clock-three-thirty:before {
    content: "\f42b";
    }
        .fi-tr-clock-three:before {
    content: "\f42c";
    }
        .fi-tr-clock-time-tracking:before {
    content: "\f42d";
    }
        .fi-tr-clock-twelve-thirty:before {
    content: "\f42e";
    }
        .fi-tr-clock-twelve:before {
    content: "\f42f";
    }
        .fi-tr-clock-two-thirty:before {
    content: "\f430";
    }
        .fi-tr-clock-two:before {
    content: "\f431";
    }
        .fi-tr-clock-up-arrow:before {
    content: "\f432";
    }
        .fi-tr-clone:before {
    content: "\f433";
    }
        .fi-tr-closed-captioning-slash:before {
    content: "\f434";
    }
        .fi-tr-clothes-hanger:before {
    content: "\f435";
    }
        .fi-tr-cloud-back-up-alt:before {
    content: "\f436";
    }
        .fi-tr-cloud-back-up:before {
    content: "\f437";
    }
        .fi-tr-cloud-check:before {
    content: "\f438";
    }
        .fi-tr-cloud-code:before {
    content: "\f439";
    }
        .fi-tr-cloud-disabled:before {
    content: "\f43a";
    }
        .fi-tr-cloud-download-alt:before {
    content: "\f43b";
    }
        .fi-tr-cloud-download:before {
    content: "\f43c";
    }
        .fi-tr-cloud-drizzle:before {
    content: "\f43d";
    }
        .fi-tr-cloud-exclamation:before {
    content: "\f43e";
    }
        .fi-tr-cloud-gear-automation:before {
    content: "\f43f";
    }
        .fi-tr-cloud-hail-mixed:before {
    content: "\f440";
    }
        .fi-tr-cloud-hail:before {
    content: "\f441";
    }
        .fi-tr-cloud-meatball:before {
    content: "\f442";
    }
        .fi-tr-cloud-moon-rain:before {
    content: "\f443";
    }
        .fi-tr-cloud-moon:before {
    content: "\f444";
    }
        .fi-tr-cloud-question:before {
    content: "\f445";
    }
        .fi-tr-cloud-rain:before {
    content: "\f446";
    }
        .fi-tr-cloud-rainbow:before {
    content: "\f447";
    }
        .fi-tr-cloud-share:before {
    content: "\f448";
    }
        .fi-tr-cloud-showers-heavy:before {
    content: "\f449";
    }
        .fi-tr-cloud-showers:before {
    content: "\f44a";
    }
        .fi-tr-cloud-sleet:before {
    content: "\f44b";
    }
        .fi-tr-cloud-snow:before {
    content: "\f44c";
    }
        .fi-tr-cloud-sun-rain:before {
    content: "\f44d";
    }
        .fi-tr-cloud-sun:before {
    content: "\f44e";
    }
        .fi-tr-cloud-upload-alt:before {
    content: "\f44f";
    }
        .fi-tr-cloud:before {
    content: "\f450";
    }
        .fi-tr-clouds-moon:before {
    content: "\f451";
    }
        .fi-tr-clouds-sun:before {
    content: "\f452";
    }
        .fi-tr-clouds:before {
    content: "\f453";
    }
        .fi-tr-clover-alt:before {
    content: "\f454";
    }
        .fi-tr-club:before {
    content: "\f455";
    }
        .fi-tr-cocktail:before {
    content: "\f456";
    }
        .fi-tr-coconut:before {
    content: "\f457";
    }
        .fi-tr-code-branch:before {
    content: "\f458";
    }
        .fi-tr-code-commit:before {
    content: "\f459";
    }
        .fi-tr-code-compare:before {
    content: "\f45a";
    }
        .fi-tr-code-fork:before {
    content: "\f45b";
    }
        .fi-tr-code-merge:before {
    content: "\f45c";
    }
        .fi-tr-code-pull-request-closed:before {
    content: "\f45d";
    }
        .fi-tr-code-pull-request-draft:before {
    content: "\f45e";
    }
        .fi-tr-code-pull-request:before {
    content: "\f45f";
    }
        .fi-tr-code-simple:before {
    content: "\f460";
    }
        .fi-tr-coffee-bean:before {
    content: "\f461";
    }
        .fi-tr-coffee-beans:before {
    content: "\f462";
    }
        .fi-tr-coffee-heart:before {
    content: "\f463";
    }
        .fi-tr-coffee-pot:before {
    content: "\f464";
    }
        .fi-tr-coffee:before {
    content: "\f465";
    }
        .fi-tr-coffin-cross:before {
    content: "\f466";
    }
        .fi-tr-coffin:before {
    content: "\f467";
    }
        .fi-tr-coin-up-arrow:before {
    content: "\f468";
    }
        .fi-tr-coin:before {
    content: "\f469";
    }
        .fi-tr-coins:before {
    content: "\f46a";
    }
        .fi-tr-colon-sign:before {
    content: "\f46b";
    }
        .fi-tr-columns-3:before {
    content: "\f46c";
    }
        .fi-tr-comet:before {
    content: "\f46d";
    }
        .fi-tr-command:before {
    content: "\f46e";
    }
        .fi-tr-comment-alt-check:before {
    content: "\f46f";
    }
        .fi-tr-comment-alt-dots:before {
    content: "\f470";
    }
        .fi-tr-comment-alt-edit:before {
    content: "\f471";
    }
        .fi-tr-comment-alt-medical:before {
    content: "\f472";
    }
        .fi-tr-comment-alt-middle-top:before {
    content: "\f473";
    }
        .fi-tr-comment-alt-middle:before {
    content: "\f474";
    }
        .fi-tr-comment-alt-minus:before {
    content: "\f475";
    }
        .fi-tr-comment-alt-music:before {
    content: "\f476";
    }
        .fi-tr-comment-arrow-down:before {
    content: "\f477";
    }
        .fi-tr-comment-arrow-up-right:before {
    content: "\f478";
    }
        .fi-tr-comment-arrow-up:before {
    content: "\f479";
    }
        .fi-tr-comment-check:before {
    content: "\f47a";
    }
        .fi-tr-comment-code:before {
    content: "\f47b";
    }
        .fi-tr-comment-dollar:before {
    content: "\f47c";
    }
        .fi-tr-comment-dots:before {
    content: "\f47d";
    }
        .fi-tr-comment-exclamation:before {
    content: "\f47e";
    }
        .fi-tr-comment-heart:before {
    content: "\f47f";
    }
        .fi-tr-comment-image:before {
    content: "\f480";
    }
        .fi-tr-comment-info:before {
    content: "\f481";
    }
        .fi-tr-comment-medical:before {
    content: "\f482";
    }
        .fi-tr-comment-minus:before {
    content: "\f483";
    }
        .fi-tr-comment-pen:before {
    content: "\f484";
    }
        .fi-tr-comment-question:before {
    content: "\f485";
    }
        .fi-tr-comment-quote:before {
    content: "\f486";
    }
        .fi-tr-comment-slash:before {
    content: "\f487";
    }
        .fi-tr-comment-smile:before {
    content: "\f488";
    }
        .fi-tr-comment-sms:before {
    content: "\f489";
    }
        .fi-tr-comment-text:before {
    content: "\f48a";
    }
        .fi-tr-comment-user:before {
    content: "\f48b";
    }
        .fi-tr-comment-xmark:before {
    content: "\f48c";
    }
        .fi-tr-comments-dollar:before {
    content: "\f48d";
    }
        .fi-tr-comments-question-check:before {
    content: "\f48e";
    }
        .fi-tr-comments-question:before {
    content: "\f48f";
    }
        .fi-tr-comments:before {
    content: "\f490";
    }
        .fi-tr-compass-alt:before {
    content: "\f491";
    }
        .fi-tr-compass-east:before {
    content: "\f492";
    }
        .fi-tr-compass-north:before {
    content: "\f493";
    }
        .fi-tr-compass-slash:before {
    content: "\f494";
    }
        .fi-tr-compass-south:before {
    content: "\f495";
    }
        .fi-tr-compass-west:before {
    content: "\f496";
    }
        .fi-tr-completed:before {
    content: "\f497";
    }
        .fi-tr-compliance-clipboard:before {
    content: "\f498";
    }
        .fi-tr-compliance-document:before {
    content: "\f499";
    }
        .fi-tr-compliance:before {
    content: "\f49a";
    }
        .fi-tr-compress-alt:before {
    content: "\f49b";
    }
        .fi-tr-computer-classic:before {
    content: "\f49c";
    }
        .fi-tr-computer-mouse:before {
    content: "\f49d";
    }
        .fi-tr-computer-speaker:before {
    content: "\f49e";
    }
        .fi-tr-computer:before {
    content: "\f49f";
    }
        .fi-tr-concierge-bell:before {
    content: "\f4a0";
    }
        .fi-tr-confetti:before {
    content: "\f4a1";
    }
        .fi-tr-constellation:before {
    content: "\f4a2";
    }
        .fi-tr-container-storage:before {
    content: "\f4a3";
    }
        .fi-tr-convert-document:before {
    content: "\f4a4";
    }
        .fi-tr-convert-shapes:before {
    content: "\f4a5";
    }
        .fi-tr-conveyor-belt-alt:before {
    content: "\f4a6";
    }
        .fi-tr-conveyor-belt-arm:before {
    content: "\f4a7";
    }
        .fi-tr-conveyor-belt-empty:before {
    content: "\f4a8";
    }
        .fi-tr-conveyor-belt:before {
    content: "\f4a9";
    }
        .fi-tr-cookie-alt:before {
    content: "\f4aa";
    }
        .fi-tr-copy-alt:before {
    content: "\f4ab";
    }
        .fi-tr-copy-image:before {
    content: "\f4ac";
    }
        .fi-tr-copy:before {
    content: "\f4ad";
    }
        .fi-tr-corn:before {
    content: "\f4ae";
    }
        .fi-tr-corporate-alt:before {
    content: "\f4af";
    }
        .fi-tr-corporate:before {
    content: "\f4b0";
    }
        .fi-tr-couch:before {
    content: "\f4b1";
    }
        .fi-tr-court-sport:before {
    content: "\f4b2";
    }
        .fi-tr-cow-alt:before {
    content: "\f4b3";
    }
        .fi-tr-cow:before {
    content: "\f4b4";
    }
        .fi-tr-cowbell-circle-plus:before {
    content: "\f4b5";
    }
        .fi-tr-cowbell-more:before {
    content: "\f4b6";
    }
        .fi-tr-cowbell:before {
    content: "\f4b7";
    }
        .fi-tr-crab:before {
    content: "\f4b8";
    }
        .fi-tr-crate-empty:before {
    content: "\f4b9";
    }
        .fi-tr-cream:before {
    content: "\f4ba";
    }
        .fi-tr-credit-card-buyer:before {
    content: "\f4bb";
    }
        .fi-tr-credit-card-eye:before {
    content: "\f4bc";
    }
        .fi-tr-cricket:before {
    content: "\f4bd";
    }
        .fi-tr-crm-alt:before {
    content: "\f4be";
    }
        .fi-tr-crm-computer:before {
    content: "\f4bf";
    }
        .fi-tr-CRM:before {
    content: "\f4c0";
    }
        .fi-tr-croissant:before {
    content: "\f4c1";
    }
        .fi-tr-cross-religion:before {
    content: "\f4c2";
    }
        .fi-tr-crow:before {
    content: "\f4c3";
    }
        .fi-tr-crutch:before {
    content: "\f4c4";
    }
        .fi-tr-crutches:before {
    content: "\f4c5";
    }
        .fi-tr-cruzeiro-sign:before {
    content: "\f4c6";
    }
        .fi-tr-crypto-calendar:before {
    content: "\f4c7";
    }
        .fi-tr-cryptocurrency:before {
    content: "\f4c8";
    }
        .fi-tr-crystal-ball:before {
    content: "\f4c9";
    }
        .fi-tr-cube:before {
    content: "\f4ca";
    }
        .fi-tr-cubes-stacked:before {
    content: "\f4cb";
    }
        .fi-tr-cubes:before {
    content: "\f4cc";
    }
        .fi-tr-cucumber:before {
    content: "\f4cd";
    }
        .fi-tr-cup-straw-swoosh:before {
    content: "\f4ce";
    }
        .fi-tr-cup-straw:before {
    content: "\f4cf";
    }
        .fi-tr-cup-togo:before {
    content: "\f4d0";
    }
        .fi-tr-cupcake-alt:before {
    content: "\f4d1";
    }
        .fi-tr-cupcake:before {
    content: "\f4d2";
    }
        .fi-tr-curling:before {
    content: "\f4d3";
    }
        .fi-tr-cursor-finger:before {
    content: "\f4d4";
    }
        .fi-tr-cursor-plus:before {
    content: "\f4d5";
    }
        .fi-tr-cursor:before {
    content: "\f4d6";
    }
        .fi-tr-curve-alt:before {
    content: "\f4d7";
    }
        .fi-tr-curve-arrow:before {
    content: "\f4d8";
    }
        .fi-tr-curve:before {
    content: "\f4d9";
    }
        .fi-tr-custard:before {
    content: "\f4da";
    }
        .fi-tr-customer-care:before {
    content: "\f4db";
    }
        .fi-tr-customer-service:before {
    content: "\f4dc";
    }
        .fi-tr-customization-cogwheel:before {
    content: "\f4dd";
    }
        .fi-tr-customization:before {
    content: "\f4de";
    }
        .fi-tr-customize-computer:before {
    content: "\f4df";
    }
        .fi-tr-customize-edit:before {
    content: "\f4e0";
    }
        .fi-tr-customize:before {
    content: "\f4e1";
    }
        .fi-tr-CV:before {
    content: "\f4e2";
    }
        .fi-tr-cvv-card:before {
    content: "\f4e3";
    }
        .fi-tr-d:before {
    content: "\f4e4";
    }
        .fi-tr-dagger:before {
    content: "\f4e5";
    }
        .fi-tr-daily-calendar:before {
    content: "\f4e6";
    }
        .fi-tr-damage:before {
    content: "\f4e7";
    }
        .fi-tr-dashboard-monitor:before {
    content: "\f4e8";
    }
        .fi-tr-dashboard-panel:before {
    content: "\f4e9";
    }
        .fi-tr-dashboard:before {
    content: "\f4ea";
    }
        .fi-tr-database:before {
    content: "\f4eb";
    }
        .fi-tr-debt:before {
    content: "\f4ec";
    }
        .fi-tr-deer-rudolph:before {
    content: "\f4ed";
    }
        .fi-tr-deer:before {
    content: "\f4ee";
    }
        .fi-tr-delete-document:before {
    content: "\f4ef";
    }
        .fi-tr-delete-right:before {
    content: "\f4f0";
    }
        .fi-tr-delete-user:before {
    content: "\f4f1";
    }
        .fi-tr-delete:before {
    content: "\f4f2";
    }
        .fi-tr-democrat:before {
    content: "\f4f3";
    }
        .fi-tr-department-structure:before {
    content: "\f4f4";
    }
        .fi-tr-department:before {
    content: "\f4f5";
    }
        .fi-tr-deposit-alt:before {
    content: "\f4f6";
    }
        .fi-tr-deposit:before {
    content: "\f4f7";
    }
        .fi-tr-description-alt:before {
    content: "\f4f8";
    }
        .fi-tr-description:before {
    content: "\f4f9";
    }
        .fi-tr-desk:before {
    content: "\f4fa";
    }
        .fi-tr-desktop-arrow-down:before {
    content: "\f4fb";
    }
        .fi-tr-desktop-wallpaper:before {
    content: "\f4fc";
    }
        .fi-tr-devices:before {
    content: "\f4fd";
    }
        .fi-tr-dewpoint:before {
    content: "\f4fe";
    }
        .fi-tr-dharmachakra:before {
    content: "\f4ff";
    }
        .fi-tr-diagram-cells:before {
    content: "\f500";
    }
        .fi-tr-diagram-lean-canvas:before {
    content: "\f501";
    }
        .fi-tr-diagram-nested:before {
    content: "\f502";
    }
        .fi-tr-diagram-next:before {
    content: "\f503";
    }
        .fi-tr-diagram-predecessor:before {
    content: "\f504";
    }
        .fi-tr-diagram-previous:before {
    content: "\f505";
    }
        .fi-tr-diagram-project:before {
    content: "\f506";
    }
        .fi-tr-diagram-sankey:before {
    content: "\f507";
    }
        .fi-tr-diagram-subtask:before {
    content: "\f508";
    }
        .fi-tr-diagram-successor:before {
    content: "\f509";
    }
        .fi-tr-diagram-venn:before {
    content: "\f50a";
    }
        .fi-tr-dial-high:before {
    content: "\f50b";
    }
        .fi-tr-dial-low:before {
    content: "\f50c";
    }
        .fi-tr-dial-max:before {
    content: "\f50d";
    }
        .fi-tr-dial-med-low:before {
    content: "\f50e";
    }
        .fi-tr-dial-med:before {
    content: "\f50f";
    }
        .fi-tr-dial-min:before {
    content: "\f510";
    }
        .fi-tr-dial-off:before {
    content: "\f511";
    }
        .fi-tr-dial:before {
    content: "\f512";
    }
        .fi-tr-diamond-exclamation:before {
    content: "\f513";
    }
        .fi-tr-diamond-turn-right:before {
    content: "\f514";
    }
        .fi-tr-diamond:before {
    content: "\f515";
    }
        .fi-tr-diary-bookmark-down:before {
    content: "\f516";
    }
        .fi-tr-diary-bookmarks:before {
    content: "\f517";
    }
        .fi-tr-diary-clasp:before {
    content: "\f518";
    }
        .fi-tr-dice-alt:before {
    content: "\f519";
    }
        .fi-tr-dice-d10:before {
    content: "\f51a";
    }
        .fi-tr-dice-d12:before {
    content: "\f51b";
    }
        .fi-tr-dice-d20:before {
    content: "\f51c";
    }
        .fi-tr-dice-d4:before {
    content: "\f51d";
    }
        .fi-tr-dice-d6:before {
    content: "\f51e";
    }
        .fi-tr-dice-d8:before {
    content: "\f51f";
    }
        .fi-tr-dice-four:before {
    content: "\f520";
    }
        .fi-tr-dice-one:before {
    content: "\f521";
    }
        .fi-tr-dice-six:before {
    content: "\f522";
    }
        .fi-tr-dice-three:before {
    content: "\f523";
    }
        .fi-tr-dice-two:before {
    content: "\f524";
    }
        .fi-tr-digging:before {
    content: "\f525";
    }
        .fi-tr-digital-tachograph:before {
    content: "\f526";
    }
        .fi-tr-dinner:before {
    content: "\f527";
    }
        .fi-tr-diploma:before {
    content: "\f528";
    }
        .fi-tr-disc-drive:before {
    content: "\f529";
    }
        .fi-tr-disco-ball:before {
    content: "\f52a";
    }
        .fi-tr-discover:before {
    content: "\f52b";
    }
        .fi-tr-disease:before {
    content: "\f52c";
    }
        .fi-tr-display-arrow-down:before {
    content: "\f52d";
    }
        .fi-tr-display-code:before {
    content: "\f52e";
    }
        .fi-tr-display-medical:before {
    content: "\f52f";
    }
        .fi-tr-display-slash:before {
    content: "\f530";
    }
        .fi-tr-display:before {
    content: "\f531";
    }
        .fi-tr-distribute-spacing-horizontal:before {
    content: "\f532";
    }
        .fi-tr-distribute-spacing-vertical:before {
    content: "\f533";
    }
        .fi-tr-divide:before {
    content: "\f534";
    }
        .fi-tr-dizzy:before {
    content: "\f535";
    }
        .fi-tr-dna:before {
    content: "\f536";
    }
        .fi-tr-do-not-enter:before {
    content: "\f537";
    }
        .fi-tr-doctor:before {
    content: "\f538";
    }
        .fi-tr-document-circle-wrong:before {
    content: "\f539";
    }
        .fi-tr-document-paid:before {
    content: "\f53a";
    }
        .fi-tr-document-signed:before {
    content: "\f53b";
    }
        .fi-tr-document:before {
    content: "\f53c";
    }
        .fi-tr-dog-leashed:before {
    content: "\f53d";
    }
        .fi-tr-dog:before {
    content: "\f53e";
    }
        .fi-tr-dolly-flatbed-alt:before {
    content: "\f53f";
    }
        .fi-tr-dolly-flatbed-empty:before {
    content: "\f540";
    }
        .fi-tr-dolly-flatbed:before {
    content: "\f541";
    }
        .fi-tr-dolphin:before {
    content: "\f542";
    }
        .fi-tr-domino-effect:before {
    content: "\f543";
    }
        .fi-tr-donate:before {
    content: "\f544";
    }
        .fi-tr-dong-sign:before {
    content: "\f545";
    }
        .fi-tr-donut:before {
    content: "\f546";
    }
        .fi-tr-door-closed:before {
    content: "\f547";
    }
        .fi-tr-door-open:before {
    content: "\f548";
    }
        .fi-tr-dot-circle:before {
    content: "\f549";
    }
        .fi-tr-dot-pending:before {
    content: "\f54a";
    }
        .fi-tr-dove:before {
    content: "\f54b";
    }
        .fi-tr-down-from-bracket:before {
    content: "\f54c";
    }
        .fi-tr-down-left:before {
    content: "\f54d";
    }
        .fi-tr-down-right:before {
    content: "\f54e";
    }
        .fi-tr-down-to-line:before {
    content: "\f54f";
    }
        .fi-tr-down:before {
    content: "\f550";
    }
        .fi-tr-drafting-compass:before {
    content: "\f551";
    }
        .fi-tr-dragon:before {
    content: "\f552";
    }
        .fi-tr-draw-polygon:before {
    content: "\f553";
    }
        .fi-tr-draw-square:before {
    content: "\f554";
    }
        .fi-tr-drawer-alt:before {
    content: "\f555";
    }
        .fi-tr-drawer-empty:before {
    content: "\f556";
    }
        .fi-tr-drawer:before {
    content: "\f557";
    }
        .fi-tr-dreidel:before {
    content: "\f558";
    }
        .fi-tr-drink-alt:before {
    content: "\f559";
    }
        .fi-tr-drink:before {
    content: "\f55a";
    }
        .fi-tr-driver-man:before {
    content: "\f55b";
    }
        .fi-tr-driver-woman:before {
    content: "\f55c";
    }
        .fi-tr-drone-alt:before {
    content: "\f55d";
    }
        .fi-tr-drone-front:before {
    content: "\f55e";
    }
        .fi-tr-drone:before {
    content: "\f55f";
    }
        .fi-tr-drop-down:before {
    content: "\f560";
    }
        .fi-tr-dropdown-select:before {
    content: "\f561";
    }
        .fi-tr-dropdown:before {
    content: "\f562";
    }
        .fi-tr-drum-steelpan:before {
    content: "\f563";
    }
        .fi-tr-drum:before {
    content: "\f564";
    }
        .fi-tr-drumstick-bite:before {
    content: "\f565";
    }
        .fi-tr-drumstick:before {
    content: "\f566";
    }
        .fi-tr-dryer-alt:before {
    content: "\f567";
    }
        .fi-tr-dryer:before {
    content: "\f568";
    }
        .fi-tr-duck:before {
    content: "\f569";
    }
        .fi-tr-dumbbell-fitness:before {
    content: "\f56a";
    }
        .fi-tr-dumbbell-horizontal:before {
    content: "\f56b";
    }
        .fi-tr-dumbbell-ray:before {
    content: "\f56c";
    }
        .fi-tr-dumbbell-weightlifting:before {
    content: "\f56d";
    }
        .fi-tr-dumpster-fire:before {
    content: "\f56e";
    }
        .fi-tr-dumpster:before {
    content: "\f56f";
    }
        .fi-tr-dungeon:before {
    content: "\f570";
    }
        .fi-tr-duration-alt:before {
    content: "\f571";
    }
        .fi-tr-duration:before {
    content: "\f572";
    }
        .fi-tr-e-learning:before {
    content: "\f573";
    }
        .fi-tr-e:before {
    content: "\f574";
    }
        .fi-tr-ear-deaf:before {
    content: "\f575";
    }
        .fi-tr-ear-muffs:before {
    content: "\f576";
    }
        .fi-tr-ear:before {
    content: "\f577";
    }
        .fi-tr-earth-africa:before {
    content: "\f578";
    }
        .fi-tr-earth-americas:before {
    content: "\f579";
    }
        .fi-tr-earth-asia:before {
    content: "\f57a";
    }
        .fi-tr-earth-europa:before {
    content: "\f57b";
    }
        .fi-tr-eclipse-alt:before {
    content: "\f57c";
    }
        .fi-tr-eclipse:before {
    content: "\f57d";
    }
        .fi-tr-effect:before {
    content: "\f57e";
    }
        .fi-tr-egg-fried:before {
    content: "\f57f";
    }
        .fi-tr-egg:before {
    content: "\f580";
    }
        .fi-tr-eject:before {
    content: "\f581";
    }
        .fi-tr-elephant:before {
    content: "\f582";
    }
        .fi-tr-elevator:before {
    content: "\f583";
    }
        .fi-tr-employee-alt:before {
    content: "\f584";
    }
        .fi-tr-employee-handbook:before {
    content: "\f585";
    }
        .fi-tr-employee-man-alt:before {
    content: "\f586";
    }
        .fi-tr-employee-man:before {
    content: "\f587";
    }
        .fi-tr-employees-woman-man:before {
    content: "\f588";
    }
        .fi-tr-employees:before {
    content: "\f589";
    }
        .fi-tr-empty-set:before {
    content: "\f58a";
    }
        .fi-tr-endless-loop:before {
    content: "\f58b";
    }
        .fi-tr-engine-warning:before {
    content: "\f58c";
    }
        .fi-tr-engine:before {
    content: "\f58d";
    }
        .fi-tr-envelope-ban:before {
    content: "\f58e";
    }
        .fi-tr-envelope-bulk:before {
    content: "\f58f";
    }
        .fi-tr-envelope-dot:before {
    content: "\f590";
    }
        .fi-tr-envelope-download:before {
    content: "\f591";
    }
        .fi-tr-envelope-heart:before {
    content: "\f592";
    }
        .fi-tr-envelope-marker:before {
    content: "\f593";
    }
        .fi-tr-envelope-open-dollar:before {
    content: "\f594";
    }
        .fi-tr-envelope-open-text:before {
    content: "\f595";
    }
        .fi-tr-envelope-open:before {
    content: "\f596";
    }
        .fi-tr-envelope-plus:before {
    content: "\f597";
    }
        .fi-tr-envelopes:before {
    content: "\f598";
    }
        .fi-tr-equality:before {
    content: "\f599";
    }
        .fi-tr-equals:before {
    content: "\f59a";
    }
        .fi-tr-eraser:before {
    content: "\f59b";
    }
        .fi-tr-error-camera:before {
    content: "\f59c";
    }
        .fi-tr-escalator:before {
    content: "\f59d";
    }
        .fi-tr-ethernet:before {
    content: "\f59e";
    }
        .fi-tr-euro:before {
    content: "\f59f";
    }
        .fi-tr-excavator:before {
    content: "\f5a0";
    }
        .fi-tr-exchange-alt:before {
    content: "\f5a1";
    }
        .fi-tr-exchange-cryptocurrency:before {
    content: "\f5a2";
    }
        .fi-tr-exchange:before {
    content: "\f5a3";
    }
        .fi-tr-exit-alt:before {
    content: "\f5a4";
    }
        .fi-tr-expand-arrows-alt:before {
    content: "\f5a5";
    }
        .fi-tr-expand-arrows:before {
    content: "\f5a6";
    }
        .fi-tr-expense-bill:before {
    content: "\f5a7";
    }
        .fi-tr-expense:before {
    content: "\f5a8";
    }
        .fi-tr-external-hard-drive:before {
    content: "\f5a9";
    }
        .fi-tr-external-world:before {
    content: "\f5aa";
    }
        .fi-tr-eye-dropper-half:before {
    content: "\f5ab";
    }
        .fi-tr-eyes:before {
    content: "\f5ac";
    }
        .fi-tr-f:before {
    content: "\f5ad";
    }
        .fi-tr-fabric:before {
    content: "\f5ae";
    }
        .fi-tr-face-angry-horns:before {
    content: "\f5af";
    }
        .fi-tr-face-anguished:before {
    content: "\f5b0";
    }
        .fi-tr-face-anxious-sweat:before {
    content: "\f5b1";
    }
        .fi-tr-face-astonished:before {
    content: "\f5b2";
    }
        .fi-tr-face-awesome:before {
    content: "\f5b3";
    }
        .fi-tr-face-beam-hand-over-mouth:before {
    content: "\f5b4";
    }
        .fi-tr-face-confounded:before {
    content: "\f5b5";
    }
        .fi-tr-face-confused:before {
    content: "\f5b6";
    }
        .fi-tr-face-cowboy-hat:before {
    content: "\f5b7";
    }
        .fi-tr-face-disappointed:before {
    content: "\f5b8";
    }
        .fi-tr-face-disguise:before {
    content: "\f5b9";
    }
        .fi-tr-face-downcast-sweat:before {
    content: "\f5ba";
    }
        .fi-tr-face-drooling:before {
    content: "\f5bb";
    }
        .fi-tr-face-explode:before {
    content: "\f5bc";
    }
        .fi-tr-face-expressionless:before {
    content: "\f5bd";
    }
        .fi-tr-face-eyes-xmarks:before {
    content: "\f5be";
    }
        .fi-tr-face-fearful:before {
    content: "\f5bf";
    }
        .fi-tr-face-glasses:before {
    content: "\f5c0";
    }
        .fi-tr-face-grin-tongue-wink:before {
    content: "\f5c1";
    }
        .fi-tr-face-hand-yawn:before {
    content: "\f5c2";
    }
        .fi-tr-face-head-bandage:before {
    content: "\f5c3";
    }
        .fi-tr-face-hushed:before {
    content: "\f5c4";
    }
        .fi-tr-face-icicles:before {
    content: "\f5c5";
    }
        .fi-tr-face-lying:before {
    content: "\f5c6";
    }
        .fi-tr-face-mask:before {
    content: "\f5c7";
    }
        .fi-tr-face-monocle:before {
    content: "\f5c8";
    }
        .fi-tr-face-nauseated:before {
    content: "\f5c9";
    }
        .fi-tr-face-nose-steam:before {
    content: "\f5ca";
    }
        .fi-tr-face-party:before {
    content: "\f5cb";
    }
        .fi-tr-face-pensive:before {
    content: "\f5cc";
    }
        .fi-tr-face-persevering:before {
    content: "\f5cd";
    }
        .fi-tr-face-pleading:before {
    content: "\f5ce";
    }
        .fi-tr-face-raised-eyebrow:before {
    content: "\f5cf";
    }
        .fi-tr-face-relieved:before {
    content: "\f5d0";
    }
        .fi-tr-face-sad-sweat:before {
    content: "\f5d1";
    }
        .fi-tr-face-scream:before {
    content: "\f5d2";
    }
        .fi-tr-face-shush:before {
    content: "\f5d3";
    }
        .fi-tr-face-sleeping:before {
    content: "\f5d4";
    }
        .fi-tr-face-sleepy:before {
    content: "\f5d5";
    }
        .fi-tr-face-smile-halo:before {
    content: "\f5d6";
    }
        .fi-tr-face-smile-hearts:before {
    content: "\f5d7";
    }
        .fi-tr-face-smile-horns:before {
    content: "\f5d8";
    }
        .fi-tr-face-smile-tear:before {
    content: "\f5d9";
    }
        .fi-tr-face-smile-tongue:before {
    content: "\f5da";
    }
        .fi-tr-face-smile-upside-down:before {
    content: "\f5db";
    }
        .fi-tr-face-smiling-hands:before {
    content: "\f5dc";
    }
        .fi-tr-face-smirking:before {
    content: "\f5dd";
    }
        .fi-tr-face-sunglasses-alt:before {
    content: "\f5de";
    }
        .fi-tr-face-sunglasses:before {
    content: "\f5df";
    }
        .fi-tr-face-swear:before {
    content: "\f5e0";
    }
        .fi-tr-face-thermometer:before {
    content: "\f5e1";
    }
        .fi-tr-face-thinking:before {
    content: "\f5e2";
    }
        .fi-tr-face-tissue:before {
    content: "\f5e3";
    }
        .fi-tr-face-tongue-money:before {
    content: "\f5e4";
    }
        .fi-tr-face-tongue-sweat:before {
    content: "\f5e5";
    }
        .fi-tr-face-unamused:before {
    content: "\f5e6";
    }
        .fi-tr-face-viewfinder:before {
    content: "\f5e7";
    }
        .fi-tr-face-vomit:before {
    content: "\f5e8";
    }
        .fi-tr-face-weary:before {
    content: "\f5e9";
    }
        .fi-tr-face-woozy:before {
    content: "\f5ea";
    }
        .fi-tr-face-worried:before {
    content: "\f5eb";
    }
        .fi-tr-face-zany:before {
    content: "\f5ec";
    }
        .fi-tr-face-zipper:before {
    content: "\f5ed";
    }
        .fi-tr-fail:before {
    content: "\f5ee";
    }
        .fi-tr-falafel:before {
    content: "\f5ef";
    }
        .fi-tr-family-dress:before {
    content: "\f5f0";
    }
        .fi-tr-family-pants:before {
    content: "\f5f1";
    }
        .fi-tr-family:before {
    content: "\f5f2";
    }
        .fi-tr-fan-table:before {
    content: "\f5f3";
    }
        .fi-tr-fan:before {
    content: "\f5f4";
    }
        .fi-tr-farm:before {
    content: "\f5f5";
    }
        .fi-tr-faucet-drip:before {
    content: "\f5f6";
    }
        .fi-tr-faucet:before {
    content: "\f5f7";
    }
        .fi-tr-fax:before {
    content: "\f5f8";
    }
        .fi-tr-feather-pointed:before {
    content: "\f5f9";
    }
        .fi-tr-feather:before {
    content: "\f5fa";
    }
        .fi-tr-features-alt:before {
    content: "\f5fb";
    }
        .fi-tr-features:before {
    content: "\f5fc";
    }
        .fi-tr-fee-receipt:before {
    content: "\f5fd";
    }
        .fi-tr-fee:before {
    content: "\f5fe";
    }
        .fi-tr-feedback-alt:before {
    content: "\f5ff";
    }
        .fi-tr-feedback-cycle-loop:before {
    content: "\f600";
    }
        .fi-tr-feedback-hand:before {
    content: "\f601";
    }
        .fi-tr-feedback-review:before {
    content: "\f602";
    }
        .fi-tr-feedback:before {
    content: "\f603";
    }
        .fi-tr-fence:before {
    content: "\f604";
    }
        .fi-tr-ferris-wheel:before {
    content: "\f605";
    }
        .fi-tr-field-hockey:before {
    content: "\f606";
    }
        .fi-tr-fighter-jet:before {
    content: "\f607";
    }
        .fi-tr-file-audio:before {
    content: "\f608";
    }
        .fi-tr-file-binary:before {
    content: "\f609";
    }
        .fi-tr-file-chart-line:before {
    content: "\f60a";
    }
        .fi-tr-file-chart-pie:before {
    content: "\f60b";
    }
        .fi-tr-file-circle-info:before {
    content: "\f60c";
    }
        .fi-tr-file-cloud:before {
    content: "\f60d";
    }
        .fi-tr-file-code:before {
    content: "\f60e";
    }
        .fi-tr-file-csv:before {
    content: "\f60f";
    }
        .fi-tr-file-download:before {
    content: "\f610";
    }
        .fi-tr-file-edit:before {
    content: "\f611";
    }
        .fi-tr-file-excel:before {
    content: "\f612";
    }
        .fi-tr-file-exclamation:before {
    content: "\f613";
    }
        .fi-tr-file-export:before {
    content: "\f614";
    }
        .fi-tr-file-image:before {
    content: "\f615";
    }
        .fi-tr-file-import:before {
    content: "\f616";
    }
        .fi-tr-file-invoice-dollar:before {
    content: "\f617";
    }
        .fi-tr-file-invoice:before {
    content: "\f618";
    }
        .fi-tr-file-medical-alt:before {
    content: "\f619";
    }
        .fi-tr-file-medical:before {
    content: "\f61a";
    }
        .fi-tr-file-minus:before {
    content: "\f61b";
    }
        .fi-tr-file-pdf:before {
    content: "\f61c";
    }
        .fi-tr-file-powerpoint:before {
    content: "\f61d";
    }
        .fi-tr-file-prescription:before {
    content: "\f61e";
    }
        .fi-tr-file-signature:before {
    content: "\f61f";
    }
        .fi-tr-file-spreadsheet:before {
    content: "\f620";
    }
        .fi-tr-file-upload:before {
    content: "\f621";
    }
        .fi-tr-file-user:before {
    content: "\f622";
    }
        .fi-tr-file-video:before {
    content: "\f623";
    }
        .fi-tr-file-word:before {
    content: "\f624";
    }
        .fi-tr-file-zipper:before {
    content: "\f625";
    }
        .fi-tr-files-medical:before {
    content: "\f626";
    }
        .fi-tr-film-canister:before {
    content: "\f627";
    }
        .fi-tr-film-slash:before {
    content: "\f628";
    }
        .fi-tr-films:before {
    content: "\f629";
    }
        .fi-tr-filter-list:before {
    content: "\f62a";
    }
        .fi-tr-filter-slash:before {
    content: "\f62b";
    }
        .fi-tr-filter:before {
    content: "\f62c";
    }
        .fi-tr-filters:before {
    content: "\f62d";
    }
        .fi-tr-fingerprint:before {
    content: "\f62e";
    }
        .fi-tr-fire-burner:before {
    content: "\f62f";
    }
        .fi-tr-fire-extinguisher:before {
    content: "\f630";
    }
        .fi-tr-fire-flame-curved:before {
    content: "\f631";
    }
        .fi-tr-fire-flame-simple:before {
    content: "\f632";
    }
        .fi-tr-fire-hydrant:before {
    content: "\f633";
    }
        .fi-tr-fire-smoke:before {
    content: "\f634";
    }
        .fi-tr-fireplace:before {
    content: "\f635";
    }
        .fi-tr-first-award:before {
    content: "\f636";
    }
        .fi-tr-first-laurel:before {
    content: "\f637";
    }
        .fi-tr-first-medal:before {
    content: "\f638";
    }
        .fi-tr-first:before {
    content: "\f639";
    }
        .fi-tr-fish-bones:before {
    content: "\f63a";
    }
        .fi-tr-fish-cooked:before {
    content: "\f63b";
    }
        .fi-tr-fish:before {
    content: "\f63c";
    }
        .fi-tr-fishing-rod:before {
    content: "\f63d";
    }
        .fi-tr-fist-move:before {
    content: "\f63e";
    }
        .fi-tr-flag-alt:before {
    content: "\f63f";
    }
        .fi-tr-flag-checkered:before {
    content: "\f640";
    }
        .fi-tr-flag-usa:before {
    content: "\f641";
    }
        .fi-tr-flag:before {
    content: "\f642";
    }
        .fi-tr-flame:before {
    content: "\f643";
    }
        .fi-tr-flashlight:before {
    content: "\f644";
    }
        .fi-tr-flask-gear:before {
    content: "\f645";
    }
        .fi-tr-flask-poison:before {
    content: "\f646";
    }
        .fi-tr-flask-potion:before {
    content: "\f647";
    }
        .fi-tr-flask:before {
    content: "\f648";
    }
        .fi-tr-flatbread-stuffed:before {
    content: "\f649";
    }
        .fi-tr-flatbread:before {
    content: "\f64a";
    }
        .fi-tr-floor-alt:before {
    content: "\f64b";
    }
        .fi-tr-floor-layer:before {
    content: "\f64c";
    }
        .fi-tr-floor:before {
    content: "\f64d";
    }
        .fi-tr-floppy-disk-circle-arrow-right:before {
    content: "\f64e";
    }
        .fi-tr-floppy-disk-circle-xmark:before {
    content: "\f64f";
    }
        .fi-tr-floppy-disk-pen:before {
    content: "\f650";
    }
        .fi-tr-floppy-disks:before {
    content: "\f651";
    }
        .fi-tr-florin-sign:before {
    content: "\f652";
    }
        .fi-tr-flower-bouquet:before {
    content: "\f653";
    }
        .fi-tr-flower-butterfly:before {
    content: "\f654";
    }
        .fi-tr-flower-daffodil:before {
    content: "\f655";
    }
        .fi-tr-flower-tulip:before {
    content: "\f656";
    }
        .fi-tr-flower:before {
    content: "\f657";
    }
        .fi-tr-flushed:before {
    content: "\f658";
    }
        .fi-tr-flute:before {
    content: "\f659";
    }
        .fi-tr-flux-capacitor:before {
    content: "\f65a";
    }
        .fi-tr-fly-insect:before {
    content: "\f65b";
    }
        .fi-tr-flying-disc:before {
    content: "\f65c";
    }
        .fi-tr-fog:before {
    content: "\f65d";
    }
        .fi-tr-folder-download:before {
    content: "\f65e";
    }
        .fi-tr-folder-fire:before {
    content: "\f65f";
    }
        .fi-tr-folder-minus:before {
    content: "\f660";
    }
        .fi-tr-folder-open:before {
    content: "\f661";
    }
        .fi-tr-folder-times:before {
    content: "\f662";
    }
        .fi-tr-folder-tree:before {
    content: "\f663";
    }
        .fi-tr-folder-upload:before {
    content: "\f664";
    }
        .fi-tr-folder-xmark:before {
    content: "\f665";
    }
        .fi-tr-folders:before {
    content: "\f666";
    }
        .fi-tr-follow-folder:before {
    content: "\f667";
    }
        .fi-tr-followcollection:before {
    content: "\f668";
    }
        .fi-tr-fondue-pot:before {
    content: "\f669";
    }
        .fi-tr-foreign-language-audio:before {
    content: "\f66a";
    }
        .fi-tr-fork:before {
    content: "\f66b";
    }
        .fi-tr-forklift:before {
    content: "\f66c";
    }
        .fi-tr-fort:before {
    content: "\f66d";
    }
        .fi-tr-forward-fast:before {
    content: "\f66e";
    }
        .fi-tr-fox:before {
    content: "\f66f";
    }
        .fi-tr-frame:before {
    content: "\f670";
    }
        .fi-tr-franc-sign:before {
    content: "\f671";
    }
        .fi-tr-free-delivery:before {
    content: "\f672";
    }
        .fi-tr-free:before {
    content: "\f673";
    }
        .fi-tr-french-fries:before {
    content: "\f674";
    }
        .fi-tr-friday:before {
    content: "\f675";
    }
        .fi-tr-frog:before {
    content: "\f676";
    }
        .fi-tr-frown:before {
    content: "\f677";
    }
        .fi-tr-ftp:before {
    content: "\f678";
    }
        .fi-tr-fuel-gauge:before {
    content: "\f679";
    }
        .fi-tr-function-process:before {
    content: "\f67a";
    }
        .fi-tr-function-square:before {
    content: "\f67b";
    }
        .fi-tr-function:before {
    content: "\f67c";
    }
        .fi-tr-funnel-dollar:before {
    content: "\f67d";
    }
        .fi-tr-g:before {
    content: "\f67e";
    }
        .fi-tr-galaxy-alt:before {
    content: "\f67f";
    }
        .fi-tr-galaxy-planet:before {
    content: "\f680";
    }
        .fi-tr-galaxy-star:before {
    content: "\f681";
    }
        .fi-tr-galaxy:before {
    content: "\f682";
    }
        .fi-tr-gallery-thumbnails:before {
    content: "\f683";
    }
        .fi-tr-game-board-alt:before {
    content: "\f684";
    }
        .fi-tr-gamepad:before {
    content: "\f685";
    }
        .fi-tr-garage-car:before {
    content: "\f686";
    }
        .fi-tr-garage-open:before {
    content: "\f687";
    }
        .fi-tr-garage:before {
    content: "\f688";
    }
        .fi-tr-garlic-alt:before {
    content: "\f689";
    }
        .fi-tr-garlic:before {
    content: "\f68a";
    }
        .fi-tr-gas-pump-slash:before {
    content: "\f68b";
    }
        .fi-tr-gas-pump:before {
    content: "\f68c";
    }
        .fi-tr-gauge-circle-bolt:before {
    content: "\f68d";
    }
        .fi-tr-gauge-circle-minus:before {
    content: "\f68e";
    }
        .fi-tr-gauge-circle-plus:before {
    content: "\f68f";
    }
        .fi-tr-gavel:before {
    content: "\f690";
    }
        .fi-tr-gay-couple:before {
    content: "\f691";
    }
        .fi-tr-gears:before {
    content: "\f692";
    }
        .fi-tr-gem:before {
    content: "\f693";
    }
        .fi-tr-general:before {
    content: "\f694";
    }
        .fi-tr-ghost:before {
    content: "\f695";
    }
        .fi-tr-gif-square:before {
    content: "\f696";
    }
        .fi-tr-gif:before {
    content: "\f697";
    }
        .fi-tr-gift-box-benefits:before {
    content: "\f698";
    }
        .fi-tr-gift-card:before {
    content: "\f699";
    }
        .fi-tr-gift:before {
    content: "\f69a";
    }
        .fi-tr-gifts:before {
    content: "\f69b";
    }
        .fi-tr-gingerbread-man:before {
    content: "\f69c";
    }
        .fi-tr-glass-champagne:before {
    content: "\f69d";
    }
        .fi-tr-glass-cheers:before {
    content: "\f69e";
    }
        .fi-tr-glass-citrus:before {
    content: "\f69f";
    }
        .fi-tr-glass-empty:before {
    content: "\f6a0";
    }
        .fi-tr-glass-half:before {
    content: "\f6a1";
    }
        .fi-tr-glass-water-droplet:before {
    content: "\f6a2";
    }
        .fi-tr-glass-whiskey-rocks:before {
    content: "\f6a3";
    }
        .fi-tr-glass-whiskey:before {
    content: "\f6a4";
    }
        .fi-tr-glass:before {
    content: "\f6a5";
    }
        .fi-tr-glasses:before {
    content: "\f6a6";
    }
        .fi-tr-globe-alt:before {
    content: "\f6a7";
    }
        .fi-tr-globe-pointer:before {
    content: "\f6a8";
    }
        .fi-tr-globe-snow:before {
    content: "\f6a9";
    }
        .fi-tr-goal-net:before {
    content: "\f6aa";
    }
        .fi-tr-golf-ball:before {
    content: "\f6ab";
    }
        .fi-tr-golf-club:before {
    content: "\f6ac";
    }
        .fi-tr-golf-hole:before {
    content: "\f6ad";
    }
        .fi-tr-gopuram:before {
    content: "\f6ae";
    }
        .fi-tr-government-budget:before {
    content: "\f6af";
    }
        .fi-tr-government-flag:before {
    content: "\f6b0";
    }
        .fi-tr-government-user:before {
    content: "\f6b1";
    }
        .fi-tr-gps-navigation:before {
    content: "\f6b2";
    }
        .fi-tr-graduation-cap:before {
    content: "\f6b3";
    }
        .fi-tr-gramophone:before {
    content: "\f6b4";
    }
        .fi-tr-grape:before {
    content: "\f6b5";
    }
        .fi-tr-graph-curve:before {
    content: "\f6b6";
    }
        .fi-tr-graphic-style:before {
    content: "\f6b7";
    }
        .fi-tr-graphic-tablet:before {
    content: "\f6b8";
    }
        .fi-tr-grate-droplet:before {
    content: "\f6b9";
    }
        .fi-tr-grate:before {
    content: "\f6ba";
    }
        .fi-tr-greater-than-equal:before {
    content: "\f6bb";
    }
        .fi-tr-greater-than:before {
    content: "\f6bc";
    }
        .fi-tr-greek-helmet:before {
    content: "\f6bd";
    }
        .fi-tr-grid-dividers:before {
    content: "\f6be";
    }
        .fi-tr-grill-hot-alt:before {
    content: "\f6bf";
    }
        .fi-tr-grill:before {
    content: "\f6c0";
    }
        .fi-tr-grimace:before {
    content: "\f6c1";
    }
        .fi-tr-grin-alt:before {
    content: "\f6c2";
    }
        .fi-tr-grin-beam-sweat:before {
    content: "\f6c3";
    }
        .fi-tr-grin-beam:before {
    content: "\f6c4";
    }
        .fi-tr-grin-hearts:before {
    content: "\f6c5";
    }
        .fi-tr-grin-squint-tears:before {
    content: "\f6c6";
    }
        .fi-tr-grin-squint:before {
    content: "\f6c7";
    }
        .fi-tr-grin-stars:before {
    content: "\f6c8";
    }
        .fi-tr-grin-tears:before {
    content: "\f6c9";
    }
        .fi-tr-grin-tongue-squint:before {
    content: "\f6ca";
    }
        .fi-tr-grin-tongue-wink:before {
    content: "\f6cb";
    }
        .fi-tr-grin-tongue:before {
    content: "\f6cc";
    }
        .fi-tr-grin-wink:before {
    content: "\f6cd";
    }
        .fi-tr-grin:before {
    content: "\f6ce";
    }
        .fi-tr-grip-dots-vertical:before {
    content: "\f6cf";
    }
        .fi-tr-grip-dots:before {
    content: "\f6d0";
    }
        .fi-tr-grip-horizontal:before {
    content: "\f6d1";
    }
        .fi-tr-grip-lines-vertical:before {
    content: "\f6d2";
    }
        .fi-tr-grip-lines:before {
    content: "\f6d3";
    }
        .fi-tr-grip-vertical:before {
    content: "\f6d4";
    }
        .fi-tr-grocery-bag:before {
    content: "\f6d5";
    }
        .fi-tr-grocery-basket:before {
    content: "\f6d6";
    }
        .fi-tr-group-arrows-rotate:before {
    content: "\f6d7";
    }
        .fi-tr-growth-chart-invest:before {
    content: "\f6d8";
    }
        .fi-tr-guarani-sign:before {
    content: "\f6d9";
    }
        .fi-tr-guide-alt:before {
    content: "\f6da";
    }
        .fi-tr-guide:before {
    content: "\f6db";
    }
        .fi-tr-guitar-electric:before {
    content: "\f6dc";
    }
        .fi-tr-guitar:before {
    content: "\f6dd";
    }
        .fi-tr-guitars:before {
    content: "\f6de";
    }
        .fi-tr-gun-squirt:before {
    content: "\f6df";
    }
        .fi-tr-gym:before {
    content: "\f6e0";
    }
        .fi-tr-h-square:before {
    content: "\f6e1";
    }
        .fi-tr-h:before {
    content: "\f6e2";
    }
        .fi-tr-h1:before {
    content: "\f6e3";
    }
        .fi-tr-h2:before {
    content: "\f6e4";
    }
        .fi-tr-h3:before {
    content: "\f6e5";
    }
        .fi-tr-h4:before {
    content: "\f6e6";
    }
        .fi-tr-hair-clipper:before {
    content: "\f6e7";
    }
        .fi-tr-hamburger-soda:before {
    content: "\f6e8";
    }
        .fi-tr-hamburger:before {
    content: "\f6e9";
    }
        .fi-tr-hammer-crash:before {
    content: "\f6ea";
    }
        .fi-tr-hammer-war:before {
    content: "\f6eb";
    }
        .fi-tr-hammer:before {
    content: "\f6ec";
    }
        .fi-tr-hamsa:before {
    content: "\f6ed";
    }
        .fi-tr-hand-back-fist:before {
    content: "\f6ee";
    }
        .fi-tr-hand-back-point-down:before {
    content: "\f6ef";
    }
        .fi-tr-hand-back-point-left:before {
    content: "\f6f0";
    }
        .fi-tr-hand-back-point-ribbon:before {
    content: "\f6f1";
    }
        .fi-tr-hand-back-point-right:before {
    content: "\f6f2";
    }
        .fi-tr-hand-bill:before {
    content: "\f6f3";
    }
        .fi-tr-hand-dots:before {
    content: "\f6f4";
    }
        .fi-tr-hand-fingers-crossed:before {
    content: "\f6f5";
    }
        .fi-tr-hand-fist:before {
    content: "\f6f6";
    }
        .fi-tr-hand-heart:before {
    content: "\f6f7";
    }
        .fi-tr-hand-holding-box:before {
    content: "\f6f8";
    }
        .fi-tr-hand-holding-droplet:before {
    content: "\f6f9";
    }
        .fi-tr-hand-holding-heart:before {
    content: "\f6fa";
    }
        .fi-tr-hand-holding-magic:before {
    content: "\f6fb";
    }
        .fi-tr-hand-holding-medical:before {
    content: "\f6fc";
    }
        .fi-tr-hand-holding-seeding:before {
    content: "\f6fd";
    }
        .fi-tr-hand-holding-skull:before {
    content: "\f6fe";
    }
        .fi-tr-hand-holding-usd:before {
    content: "\f6ff";
    }
        .fi-tr-hand-holding-water:before {
    content: "\f700";
    }
        .fi-tr-hand-horns:before {
    content: "\f701";
    }
        .fi-tr-hand-lizard:before {
    content: "\f702";
    }
        .fi-tr-hand-love:before {
    content: "\f703";
    }
        .fi-tr-hand-middle-finger:before {
    content: "\f704";
    }
        .fi-tr-hand-paper:before {
    content: "\f705";
    }
        .fi-tr-hand-peace:before {
    content: "\f706";
    }
        .fi-tr-hand-point-ribbon:before {
    content: "\f707";
    }
        .fi-tr-hand-scissors:before {
    content: "\f708";
    }
        .fi-tr-hand-sparkles:before {
    content: "\f709";
    }
        .fi-tr-hand-spock:before {
    content: "\f70a";
    }
        .fi-tr-hand-wave:before {
    content: "\f70b";
    }
        .fi-tr-handmade:before {
    content: "\f70c";
    }
        .fi-tr-hands-bubbles:before {
    content: "\f70d";
    }
        .fi-tr-hands-clapping:before {
    content: "\f70e";
    }
        .fi-tr-hands-heart:before {
    content: "\f70f";
    }
        .fi-tr-hands-holding-diamond:before {
    content: "\f710";
    }
        .fi-tr-hands-holding:before {
    content: "\f711";
    }
        .fi-tr-hands-usd:before {
    content: "\f712";
    }
        .fi-tr-handshake-angle:before {
    content: "\f713";
    }
        .fi-tr-handshake-deal-loan:before {
    content: "\f714";
    }
        .fi-tr-handshake-house:before {
    content: "\f715";
    }
        .fi-tr-handshake-simple-slash:before {
    content: "\f716";
    }
        .fi-tr-handshake-trust:before {
    content: "\f717";
    }
        .fi-tr-handshake:before {
    content: "\f718";
    }
        .fi-tr-hard-hat:before {
    content: "\f719";
    }
        .fi-tr-hashtag-lock:before {
    content: "\f71a";
    }
        .fi-tr-hashtag:before {
    content: "\f71b";
    }
        .fi-tr-hat-beach:before {
    content: "\f71c";
    }
        .fi-tr-hat-birthday:before {
    content: "\f71d";
    }
        .fi-tr-hat-chef:before {
    content: "\f71e";
    }
        .fi-tr-hat-cowboy-side:before {
    content: "\f71f";
    }
        .fi-tr-hat-cowboy:before {
    content: "\f720";
    }
        .fi-tr-hat-santa:before {
    content: "\f721";
    }
        .fi-tr-hat-winter:before {
    content: "\f722";
    }
        .fi-tr-hat-witch:before {
    content: "\f723";
    }
        .fi-tr-hat-wizard:before {
    content: "\f724";
    }
        .fi-tr-hdd:before {
    content: "\f725";
    }
        .fi-tr-head-side-brain:before {
    content: "\f726";
    }
        .fi-tr-head-side-cough-slash:before {
    content: "\f727";
    }
        .fi-tr-head-side-cough:before {
    content: "\f728";
    }
        .fi-tr-head-side-headphones:before {
    content: "\f729";
    }
        .fi-tr-head-side-heart:before {
    content: "\f72a";
    }
        .fi-tr-head-side-mask:before {
    content: "\f72b";
    }
        .fi-tr-head-side-medical:before {
    content: "\f72c";
    }
        .fi-tr-head-side-thinking:before {
    content: "\f72d";
    }
        .fi-tr-head-side-virus:before {
    content: "\f72e";
    }
        .fi-tr-head-side:before {
    content: "\f72f";
    }
        .fi-tr-head-vr:before {
    content: "\f730";
    }
        .fi-tr-heading:before {
    content: "\f731";
    }
        .fi-tr-heart-arrow:before {
    content: "\f732";
    }
        .fi-tr-heart-brain:before {
    content: "\f733";
    }
        .fi-tr-heart-crack:before {
    content: "\f734";
    }
        .fi-tr-heart-half-stroke:before {
    content: "\f735";
    }
        .fi-tr-heart-half:before {
    content: "\f736";
    }
        .fi-tr-heart-health-muscle:before {
    content: "\f737";
    }
        .fi-tr-heart-lock:before {
    content: "\f738";
    }
        .fi-tr-heart-partner-handshake:before {
    content: "\f739";
    }
        .fi-tr-heart-rate:before {
    content: "\f73a";
    }
        .fi-tr-heart-slash:before {
    content: "\f73b";
    }
        .fi-tr-heart-upside-down:before {
    content: "\f73c";
    }
        .fi-tr-heat:before {
    content: "\f73d";
    }
        .fi-tr-helicopter-side:before {
    content: "\f73e";
    }
        .fi-tr-helmet-battle:before {
    content: "\f73f";
    }
        .fi-tr-hexagon-check:before {
    content: "\f740";
    }
        .fi-tr-hexagon-divide:before {
    content: "\f741";
    }
        .fi-tr-hexagon-exclamation:before {
    content: "\f742";
    }
        .fi-tr-hexagon:before {
    content: "\f743";
    }
        .fi-tr-high-definition:before {
    content: "\f744";
    }
        .fi-tr-high-five-celebration-yes:before {
    content: "\f745";
    }
        .fi-tr-high-five:before {
    content: "\f746";
    }
        .fi-tr-highlighter-line:before {
    content: "\f747";
    }
        .fi-tr-highlighter:before {
    content: "\f748";
    }
        .fi-tr-hiking:before {
    content: "\f749";
    }
        .fi-tr-hippo:before {
    content: "\f74a";
    }
        .fi-tr-hockey-mask:before {
    content: "\f74b";
    }
        .fi-tr-hockey-puck:before {
    content: "\f74c";
    }
        .fi-tr-hockey-stick-puck:before {
    content: "\f74d";
    }
        .fi-tr-hockey-sticks:before {
    content: "\f74e";
    }
        .fi-tr-holding-hand-gear:before {
    content: "\f74f";
    }
        .fi-tr-holding-hand-revenue:before {
    content: "\f750";
    }
        .fi-tr-holly-berry:before {
    content: "\f751";
    }
        .fi-tr-home-heart:before {
    content: "\f752";
    }
        .fi-tr-honey-pot:before {
    content: "\f753";
    }
        .fi-tr-hood-cloak:before {
    content: "\f754";
    }
        .fi-tr-horizontal-rule:before {
    content: "\f755";
    }
        .fi-tr-horse-head:before {
    content: "\f756";
    }
        .fi-tr-horse-saddle:before {
    content: "\f757";
    }
        .fi-tr-horse:before {
    content: "\f758";
    }
        .fi-tr-horseshoe-broken:before {
    content: "\f759";
    }
        .fi-tr-horseshoe:before {
    content: "\f75a";
    }
        .fi-tr-hose-reel:before {
    content: "\f75b";
    }
        .fi-tr-hose:before {
    content: "\f75c";
    }
        .fi-tr-hospital-symbol:before {
    content: "\f75d";
    }
        .fi-tr-hospital-user:before {
    content: "\f75e";
    }
        .fi-tr-hospital:before {
    content: "\f75f";
    }
        .fi-tr-hospitals:before {
    content: "\f760";
    }
        .fi-tr-hot-tub:before {
    content: "\f761";
    }
        .fi-tr-hotdog:before {
    content: "\f762";
    }
        .fi-tr-hotel:before {
    content: "\f763";
    }
        .fi-tr-hourglass-end:before {
    content: "\f764";
    }
        .fi-tr-hourglass-start:before {
    content: "\f765";
    }
        .fi-tr-hourglass:before {
    content: "\f766";
    }
        .fi-tr-house-blank:before {
    content: "\f767";
    }
        .fi-tr-house-building:before {
    content: "\f768";
    }
        .fi-tr-house-chimney-blank:before {
    content: "\f769";
    }
        .fi-tr-house-chimney-crack:before {
    content: "\f76a";
    }
        .fi-tr-house-chimney-heart:before {
    content: "\f76b";
    }
        .fi-tr-house-chimney-medical:before {
    content: "\f76c";
    }
        .fi-tr-house-chimney-user:before {
    content: "\f76d";
    }
        .fi-tr-house-chimney-window:before {
    content: "\f76e";
    }
        .fi-tr-house-chimney:before {
    content: "\f76f";
    }
        .fi-tr-house-circle-check:before {
    content: "\f770";
    }
        .fi-tr-house-circle-exclamation:before {
    content: "\f771";
    }
        .fi-tr-house-circle-xmark:before {
    content: "\f772";
    }
        .fi-tr-house-crack-alt:before {
    content: "\f773";
    }
        .fi-tr-house-crack:before {
    content: "\f774";
    }
        .fi-tr-house-day:before {
    content: "\f775";
    }
        .fi-tr-house-fire:before {
    content: "\f776";
    }
        .fi-tr-house-flag:before {
    content: "\f777";
    }
        .fi-tr-house-flood:before {
    content: "\f778";
    }
        .fi-tr-house-laptop:before {
    content: "\f779";
    }
        .fi-tr-house-leave:before {
    content: "\f77a";
    }
        .fi-tr-house-lock:before {
    content: "\f77b";
    }
        .fi-tr-house-medical:before {
    content: "\f77c";
    }
        .fi-tr-house-night:before {
    content: "\f77d";
    }
        .fi-tr-house-return:before {
    content: "\f77e";
    }
        .fi-tr-house-signal:before {
    content: "\f77f";
    }
        .fi-tr-house-tree:before {
    content: "\f780";
    }
        .fi-tr-house-tsunami:before {
    content: "\f781";
    }
        .fi-tr-house-turret:before {
    content: "\f782";
    }
        .fi-tr-house-user:before {
    content: "\f783";
    }
        .fi-tr-house-window:before {
    content: "\f784";
    }
        .fi-tr-hr-group:before {
    content: "\f785";
    }
        .fi-tr-hr-person:before {
    content: "\f786";
    }
        .fi-tr-hr:before {
    content: "\f787";
    }
        .fi-tr-hryvnia:before {
    content: "\f788";
    }
        .fi-tr-humidity:before {
    content: "\f789";
    }
        .fi-tr-hundred-points:before {
    content: "\f78a";
    }
        .fi-tr-hurricane:before {
    content: "\f78b";
    }
        .fi-tr-i:before {
    content: "\f78c";
    }
        .fi-tr-ice-cream:before {
    content: "\f78d";
    }
        .fi-tr-icicles:before {
    content: "\f78e";
    }
        .fi-tr-icon-star:before {
    content: "\f78f";
    }
        .fi-tr-id-badge:before {
    content: "\f790";
    }
        .fi-tr-id-card-clip-alt:before {
    content: "\f791";
    }
        .fi-tr-igloo:before {
    content: "\f792";
    }
        .fi-tr-image-slash:before {
    content: "\f793";
    }
        .fi-tr-images-user:before {
    content: "\f794";
    }
        .fi-tr-images:before {
    content: "\f795";
    }
        .fi-tr-improve-user:before {
    content: "\f796";
    }
        .fi-tr-inbox-in:before {
    content: "\f797";
    }
        .fi-tr-inbox-out:before {
    content: "\f798";
    }
        .fi-tr-inboxes:before {
    content: "\f799";
    }
        .fi-tr-incense-sticks-yoga:before {
    content: "\f79a";
    }
        .fi-tr-incognito:before {
    content: "\f79b";
    }
        .fi-tr-indian-rupee-sign:before {
    content: "\f79c";
    }
        .fi-tr-industry-alt:before {
    content: "\f79d";
    }
        .fi-tr-industry-windows:before {
    content: "\f79e";
    }
        .fi-tr-infinity:before {
    content: "\f79f";
    }
        .fi-tr-info-guide:before {
    content: "\f7a0";
    }
        .fi-tr-information:before {
    content: "\f7a1";
    }
        .fi-tr-inhaler:before {
    content: "\f7a2";
    }
        .fi-tr-input-numeric:before {
    content: "\f7a3";
    }
        .fi-tr-input-pipe:before {
    content: "\f7a4";
    }
        .fi-tr-input-text:before {
    content: "\f7a5";
    }
        .fi-tr-insert-alt:before {
    content: "\f7a6";
    }
        .fi-tr-insert-arrows:before {
    content: "\f7a7";
    }
        .fi-tr-insert-button-circle:before {
    content: "\f7a8";
    }
        .fi-tr-insert-credit-card:before {
    content: "\f7a9";
    }
        .fi-tr-insert-square:before {
    content: "\f7aa";
    }
        .fi-tr-insert:before {
    content: "\f7ab";
    }
        .fi-tr-insight-alt:before {
    content: "\f7ac";
    }
        .fi-tr-insight-head:before {
    content: "\f7ad";
    }
        .fi-tr-insight:before {
    content: "\f7ae";
    }
        .fi-tr-integral:before {
    content: "\f7af";
    }
        .fi-tr-internet-speed-wifi:before {
    content: "\f7b0";
    }
        .fi-tr-intersection:before {
    content: "\f7b1";
    }
        .fi-tr-introduction-handshake:before {
    content: "\f7b2";
    }
        .fi-tr-introduction:before {
    content: "\f7b3";
    }
        .fi-tr-inventory-alt:before {
    content: "\f7b4";
    }
        .fi-tr-invest:before {
    content: "\f7b5";
    }
        .fi-tr-investment:before {
    content: "\f7b6";
    }
        .fi-tr-invite-alt:before {
    content: "\f7b7";
    }
        .fi-tr-invite:before {
    content: "\f7b8";
    }
        .fi-tr-iot-alt:before {
    content: "\f7b9";
    }
        .fi-tr-iot:before {
    content: "\f7ba";
    }
        .fi-tr-ip-address:before {
    content: "\f7bb";
    }
        .fi-tr-island-tropical:before {
    content: "\f7bc";
    }
        .fi-tr-issue-loupe:before {
    content: "\f7bd";
    }
        .fi-tr-it-alt:before {
    content: "\f7be";
    }
        .fi-tr-it-computer:before {
    content: "\f7bf";
    }
        .fi-tr-it:before {
    content: "\f7c0";
    }
        .fi-tr-italian-lira-sign:before {
    content: "\f7c1";
    }
        .fi-tr-j:before {
    content: "\f7c2";
    }
        .fi-tr-jam:before {
    content: "\f7c3";
    }
        .fi-tr-jar-alt:before {
    content: "\f7c4";
    }
        .fi-tr-jar-wheat:before {
    content: "\f7c5";
    }
        .fi-tr-javascript:before {
    content: "\f7c6";
    }
        .fi-tr-joint:before {
    content: "\f7c7";
    }
        .fi-tr-joker:before {
    content: "\f7c8";
    }
        .fi-tr-journal-alt:before {
    content: "\f7c9";
    }
        .fi-tr-journal:before {
    content: "\f7ca";
    }
        .fi-tr-journey:before {
    content: "\f7cb";
    }
        .fi-tr-joystick:before {
    content: "\f7cc";
    }
        .fi-tr-jpg:before {
    content: "\f7cd";
    }
        .fi-tr-jug-alt:before {
    content: "\f7ce";
    }
        .fi-tr-jug-bottle:before {
    content: "\f7cf";
    }
        .fi-tr-jug:before {
    content: "\f7d0";
    }
        .fi-tr-jumping-rope:before {
    content: "\f7d1";
    }
        .fi-tr-k:before {
    content: "\f7d2";
    }
        .fi-tr-kaaba:before {
    content: "\f7d3";
    }
        .fi-tr-kazoo:before {
    content: "\f7d4";
    }
        .fi-tr-kerning:before {
    content: "\f7d5";
    }
        .fi-tr-key-lock-crypto:before {
    content: "\f7d6";
    }
        .fi-tr-key-skeleton-left-right:before {
    content: "\f7d7";
    }
        .fi-tr-key:before {
    content: "\f7d8";
    }
        .fi-tr-keyboard-brightness-low:before {
    content: "\f7d9";
    }
        .fi-tr-keyboard-brightness:before {
    content: "\f7da";
    }
        .fi-tr-keyboard-down:before {
    content: "\f7db";
    }
        .fi-tr-keyboard-left:before {
    content: "\f7dc";
    }
        .fi-tr-keyboard:before {
    content: "\f7dd";
    }
        .fi-tr-keynote:before {
    content: "\f7de";
    }
        .fi-tr-kidneys:before {
    content: "\f7df";
    }
        .fi-tr-kip-sign:before {
    content: "\f7e0";
    }
        .fi-tr-kiss-beam:before {
    content: "\f7e1";
    }
        .fi-tr-kiss-wink-heart:before {
    content: "\f7e2";
    }
        .fi-tr-kiss:before {
    content: "\f7e3";
    }
        .fi-tr-kite:before {
    content: "\f7e4";
    }
        .fi-tr-kiwi-bird:before {
    content: "\f7e5";
    }
        .fi-tr-kiwi-fruit:before {
    content: "\f7e6";
    }
        .fi-tr-knife-kitchen:before {
    content: "\f7e7";
    }
        .fi-tr-knife:before {
    content: "\f7e8";
    }
        .fi-tr-knitting:before {
    content: "\f7e9";
    }
        .fi-tr-kpi-evaluation:before {
    content: "\f7ea";
    }
        .fi-tr-kpi:before {
    content: "\f7eb";
    }
        .fi-tr-l:before {
    content: "\f7ec";
    }
        .fi-tr-lacrosse-stick-ball:before {
    content: "\f7ed";
    }
        .fi-tr-lacrosse-stick:before {
    content: "\f7ee";
    }
        .fi-tr-lambda:before {
    content: "\f7ef";
    }
        .fi-tr-lamp-desk:before {
    content: "\f7f0";
    }
        .fi-tr-lamp-floor:before {
    content: "\f7f1";
    }
        .fi-tr-lamp-street:before {
    content: "\f7f2";
    }
        .fi-tr-lamp:before {
    content: "\f7f3";
    }
        .fi-tr-land-layer-location:before {
    content: "\f7f4";
    }
        .fi-tr-land-layers:before {
    content: "\f7f5";
    }
        .fi-tr-land-location:before {
    content: "\f7f6";
    }
        .fi-tr-landmark-alt:before {
    content: "\f7f7";
    }
        .fi-tr-language:before {
    content: "\f7f8";
    }
        .fi-tr-laptop-arrow-down:before {
    content: "\f7f9";
    }
        .fi-tr-laptop-binary:before {
    content: "\f7fa";
    }
        .fi-tr-laptop-code:before {
    content: "\f7fb";
    }
        .fi-tr-laptop-medical:before {
    content: "\f7fc";
    }
        .fi-tr-laptop-mobile:before {
    content: "\f7fd";
    }
        .fi-tr-laptop-slash:before {
    content: "\f7fe";
    }
        .fi-tr-laptop:before {
    content: "\f7ff";
    }
        .fi-tr-lari-sign:before {
    content: "\f800";
    }
        .fi-tr-lasso-sparkles:before {
    content: "\f801";
    }
        .fi-tr-last-square:before {
    content: "\f802";
    }
        .fi-tr-laugh-beam:before {
    content: "\f803";
    }
        .fi-tr-laugh-squint:before {
    content: "\f804";
    }
        .fi-tr-laugh-wink:before {
    content: "\f805";
    }
        .fi-tr-laugh:before {
    content: "\f806";
    }
        .fi-tr-lawyer-man:before {
    content: "\f807";
    }
        .fi-tr-lawyer-woman:before {
    content: "\f808";
    }
        .fi-tr-layer-minus:before {
    content: "\f809";
    }
        .fi-tr-layer-plus:before {
    content: "\f80a";
    }
        .fi-tr-lead-funnel:before {
    content: "\f80b";
    }
        .fi-tr-lead-management:before {
    content: "\f80c";
    }
        .fi-tr-lead:before {
    content: "\f80d";
    }
        .fi-tr-leader-alt:before {
    content: "\f80e";
    }
        .fi-tr-leader-speech:before {
    content: "\f80f";
    }
        .fi-tr-leader:before {
    content: "\f810";
    }
        .fi-tr-leaderboard-alt:before {
    content: "\f811";
    }
        .fi-tr-leaderboard-trophy:before {
    content: "\f812";
    }
        .fi-tr-leaderboard:before {
    content: "\f813";
    }
        .fi-tr-leadership-alt:before {
    content: "\f814";
    }
        .fi-tr-leadership:before {
    content: "\f815";
    }
        .fi-tr-leaf-heart:before {
    content: "\f816";
    }
        .fi-tr-leaf-maple:before {
    content: "\f817";
    }
        .fi-tr-leaf-oak:before {
    content: "\f818";
    }
        .fi-tr-leaf:before {
    content: "\f819";
    }
        .fi-tr-leafy-green:before {
    content: "\f81a";
    }
        .fi-tr-leave:before {
    content: "\f81b";
    }
        .fi-tr-left-from-bracket:before {
    content: "\f81c";
    }
        .fi-tr-left:before {
    content: "\f81d";
    }
        .fi-tr-legal:before {
    content: "\f81e";
    }
        .fi-tr-lemon:before {
    content: "\f81f";
    }
        .fi-tr-lesbian-couple:before {
    content: "\f820";
    }
        .fi-tr-less-than-equal:before {
    content: "\f821";
    }
        .fi-tr-less-than:before {
    content: "\f822";
    }
        .fi-tr-lesson-class:before {
    content: "\f823";
    }
        .fi-tr-lesson:before {
    content: "\f824";
    }
        .fi-tr-lettuce:before {
    content: "\f825";
    }
        .fi-tr-level-down-alt:before {
    content: "\f826";
    }
        .fi-tr-level-down:before {
    content: "\f827";
    }
        .fi-tr-level-up-alt:before {
    content: "\f828";
    }
        .fi-tr-level-up:before {
    content: "\f829";
    }
        .fi-tr-license:before {
    content: "\f82a";
    }
        .fi-tr-life:before {
    content: "\f82b";
    }
        .fi-tr-light-ceiling:before {
    content: "\f82c";
    }
        .fi-tr-light-emergency-on:before {
    content: "\f82d";
    }
        .fi-tr-light-emergency:before {
    content: "\f82e";
    }
        .fi-tr-light-switch-off:before {
    content: "\f82f";
    }
        .fi-tr-light-switch-on:before {
    content: "\f830";
    }
        .fi-tr-light-switch:before {
    content: "\f831";
    }
        .fi-tr-lightbulb-cfl-on:before {
    content: "\f832";
    }
        .fi-tr-lightbulb-cfl:before {
    content: "\f833";
    }
        .fi-tr-lightbulb-dollar:before {
    content: "\f834";
    }
        .fi-tr-lightbulb-exclamation:before {
    content: "\f835";
    }
        .fi-tr-lightbulb-head:before {
    content: "\f836";
    }
        .fi-tr-lightbulb-on:before {
    content: "\f837";
    }
        .fi-tr-lightbulb-question:before {
    content: "\f838";
    }
        .fi-tr-lightbulb-setting:before {
    content: "\f839";
    }
        .fi-tr-lightbulb-slash:before {
    content: "\f83a";
    }
        .fi-tr-lighthouse:before {
    content: "\f83b";
    }
        .fi-tr-lights-holiday:before {
    content: "\f83c";
    }
        .fi-tr-limit-hand:before {
    content: "\f83d";
    }
        .fi-tr-limit-speedometer:before {
    content: "\f83e";
    }
        .fi-tr-link-alt:before {
    content: "\f83f";
    }
        .fi-tr-link-horizontal-slash:before {
    content: "\f840";
    }
        .fi-tr-link-horizontal:before {
    content: "\f841";
    }
        .fi-tr-link-slash-alt:before {
    content: "\f842";
    }
        .fi-tr-link-slash:before {
    content: "\f843";
    }
        .fi-tr-lion-head:before {
    content: "\f844";
    }
        .fi-tr-lion:before {
    content: "\f845";
    }
        .fi-tr-lips:before {
    content: "\f846";
    }
        .fi-tr-lipstick:before {
    content: "\f847";
    }
        .fi-tr-lira-sign:before {
    content: "\f848";
    }
        .fi-tr-list-check:before {
    content: "\f849";
    }
        .fi-tr-list-dropdown:before {
    content: "\f84a";
    }
        .fi-tr-list-music:before {
    content: "\f84b";
    }
        .fi-tr-list-timeline:before {
    content: "\f84c";
    }
        .fi-tr-litecoin-sign:before {
    content: "\f84d";
    }
        .fi-tr-live-alt:before {
    content: "\f84e";
    }
        .fi-tr-live:before {
    content: "\f84f";
    }
        .fi-tr-loading:before {
    content: "\f850";
    }
        .fi-tr-loan:before {
    content: "\f851";
    }
        .fi-tr-lobster:before {
    content: "\f852";
    }
        .fi-tr-location-alt:before {
    content: "\f853";
    }
        .fi-tr-location-arrow:before {
    content: "\f854";
    }
        .fi-tr-location-crosshairs-slash:before {
    content: "\f855";
    }
        .fi-tr-location-crosshairs:before {
    content: "\f856";
    }
        .fi-tr-location-dot-slash:before {
    content: "\f857";
    }
        .fi-tr-location-exclamation:before {
    content: "\f858";
    }
        .fi-tr-lock-alt:before {
    content: "\f859";
    }
        .fi-tr-lock-hashtag:before {
    content: "\f85a";
    }
        .fi-tr-lock-open-alt:before {
    content: "\f85b";
    }
        .fi-tr-locust:before {
    content: "\f85c";
    }
        .fi-tr-loop-square:before {
    content: "\f85d";
    }
        .fi-tr-loveseat:before {
    content: "\f85e";
    }
        .fi-tr-low-vision:before {
    content: "\f85f";
    }
        .fi-tr-luchador:before {
    content: "\f860";
    }
        .fi-tr-luggage-cart:before {
    content: "\f861";
    }
        .fi-tr-lungs-virus:before {
    content: "\f862";
    }
        .fi-tr-lungs:before {
    content: "\f863";
    }
        .fi-tr-m:before {
    content: "\f864";
    }
        .fi-tr-mace:before {
    content: "\f865";
    }
        .fi-tr-magnet-user:before {
    content: "\f866";
    }
        .fi-tr-magnet:before {
    content: "\f867";
    }
        .fi-tr-mailbox:before {
    content: "\f868";
    }
        .fi-tr-makeup-brush:before {
    content: "\f869";
    }
        .fi-tr-man-head:before {
    content: "\f86a";
    }
        .fi-tr-man-scientist:before {
    content: "\f86b";
    }
        .fi-tr-manat-sign:before {
    content: "\f86c";
    }
        .fi-tr-mandolin:before {
    content: "\f86d";
    }
        .fi-tr-mango:before {
    content: "\f86e";
    }
        .fi-tr-manhole:before {
    content: "\f86f";
    }
        .fi-tr-map-location-track:before {
    content: "\f870";
    }
        .fi-tr-map-marker-check:before {
    content: "\f871";
    }
        .fi-tr-map-marker-edit:before {
    content: "\f872";
    }
        .fi-tr-map-marker-question:before {
    content: "\f873";
    }
        .fi-tr-map-marker-slash:before {
    content: "\f874";
    }
        .fi-tr-map-marker-smile:before {
    content: "\f875";
    }
        .fi-tr-map-pin:before {
    content: "\f876";
    }
        .fi-tr-map-point:before {
    content: "\f877";
    }
        .fi-tr-marker-time:before {
    content: "\f878";
    }
        .fi-tr-marketplace-alt:before {
    content: "\f879";
    }
        .fi-tr-marketplace-store:before {
    content: "\f87a";
    }
        .fi-tr-marketplace:before {
    content: "\f87b";
    }
        .fi-tr-mars-double:before {
    content: "\f87c";
    }
        .fi-tr-mars-stroke-right:before {
    content: "\f87d";
    }
        .fi-tr-mars-stroke-up:before {
    content: "\f87e";
    }
        .fi-tr-mars:before {
    content: "\f87f";
    }
        .fi-tr-martini-glass-citrus:before {
    content: "\f880";
    }
        .fi-tr-martini-glass-empty:before {
    content: "\f881";
    }
        .fi-tr-mask-carnival:before {
    content: "\f882";
    }
        .fi-tr-mask-face:before {
    content: "\f883";
    }
        .fi-tr-mask-snorkel:before {
    content: "\f884";
    }
        .fi-tr-massage:before {
    content: "\f885";
    }
        .fi-tr-match-fire:before {
    content: "\f886";
    }
        .fi-tr-mattress-pillow:before {
    content: "\f887";
    }
        .fi-tr-measuring-tape:before {
    content: "\f888";
    }
        .fi-tr-meat:before {
    content: "\f889";
    }
        .fi-tr-medal:before {
    content: "\f88a";
    }
        .fi-tr-medical-star:before {
    content: "\f88b";
    }
        .fi-tr-medicine:before {
    content: "\f88c";
    }
        .fi-tr-meditation:before {
    content: "\f88d";
    }
        .fi-tr-meeting-alt:before {
    content: "\f88e";
    }
        .fi-tr-meeting:before {
    content: "\f88f";
    }
        .fi-tr-megaphone-announcement-leader:before {
    content: "\f890";
    }
        .fi-tr-megaphone:before {
    content: "\f891";
    }
        .fi-tr-meh-blank:before {
    content: "\f892";
    }
        .fi-tr-meh-rolling-eyes:before {
    content: "\f893";
    }
        .fi-tr-meh:before {
    content: "\f894";
    }
        .fi-tr-melon-alt:before {
    content: "\f895";
    }
        .fi-tr-melon:before {
    content: "\f896";
    }
        .fi-tr-member-list:before {
    content: "\f897";
    }
        .fi-tr-member-search:before {
    content: "\f898";
    }
        .fi-tr-membership-vip:before {
    content: "\f899";
    }
        .fi-tr-membership:before {
    content: "\f89a";
    }
        .fi-tr-memo-circle-check:before {
    content: "\f89b";
    }
        .fi-tr-memo-pad:before {
    content: "\f89c";
    }
        .fi-tr-memo:before {
    content: "\f89d";
    }
        .fi-tr-memory:before {
    content: "\f89e";
    }
        .fi-tr-mercury:before {
    content: "\f89f";
    }
        .fi-tr-message-arrow-down:before {
    content: "\f8a0";
    }
        .fi-tr-message-arrow-up-right:before {
    content: "\f8a1";
    }
        .fi-tr-message-arrow-up:before {
    content: "\f8a2";
    }
        .fi-tr-message-bot:before {
    content: "\f8a3";
    }
        .fi-tr-message-code:before {
    content: "\f8a4";
    }
        .fi-tr-message-dollar:before {
    content: "\f8a5";
    }
        .fi-tr-message-heart:before {
    content: "\f8a6";
    }
        .fi-tr-message-image:before {
    content: "\f8a7";
    }
        .fi-tr-message-question:before {
    content: "\f8a8";
    }
        .fi-tr-message-quote:before {
    content: "\f8a9";
    }
        .fi-tr-message-slash:before {
    content: "\f8aa";
    }
        .fi-tr-message-sms:before {
    content: "\f8ab";
    }
        .fi-tr-message-text:before {
    content: "\f8ac";
    }
        .fi-tr-message-xmark:before {
    content: "\f8ad";
    }
        .fi-tr-messages-dollar:before {
    content: "\f8ae";
    }
        .fi-tr-messages-question:before {
    content: "\f8af";
    }
        .fi-tr-messages:before {
    content: "\f8b0";
    }
        .fi-tr-meteor:before {
    content: "\f8b1";
    }
        .fi-tr-meter-bolt:before {
    content: "\f8b2";
    }
        .fi-tr-meter-droplet:before {
    content: "\f8b3";
    }
        .fi-tr-meter-fire:before {
    content: "\f8b4";
    }
        .fi-tr-meter:before {
    content: "\f8b5";
    }
        .fi-tr-method:before {
    content: "\f8b6";
    }
        .fi-tr-microchip-ai:before {
    content: "\f8b7";
    }
        .fi-tr-microchip:before {
    content: "\f8b8";
    }
        .fi-tr-microphone-alt:before {
    content: "\f8b9";
    }
        .fi-tr-microphone-slash:before {
    content: "\f8ba";
    }
        .fi-tr-microscope:before {
    content: "\f8bb";
    }
        .fi-tr-microwave:before {
    content: "\f8bc";
    }
        .fi-tr-milk-alt:before {
    content: "\f8bd";
    }
        .fi-tr-milk:before {
    content: "\f8be";
    }
        .fi-tr-mill-sign:before {
    content: "\f8bf";
    }
        .fi-tr-mind-share:before {
    content: "\f8c0";
    }
        .fi-tr-minus-circle:before {
    content: "\f8c1";
    }
        .fi-tr-minus-hexagon:before {
    content: "\f8c2";
    }
        .fi-tr-mistletoe:before {
    content: "\f8c3";
    }
        .fi-tr-mix:before {
    content: "\f8c4";
    }
        .fi-tr-mobile-button:before {
    content: "\f8c5";
    }
        .fi-tr-mobile-notch:before {
    content: "\f8c6";
    }
        .fi-tr-mobile:before {
    content: "\f8c7";
    }
        .fi-tr-mockup:before {
    content: "\f8c8";
    }
        .fi-tr-mode-alt:before {
    content: "\f8c9";
    }
        .fi-tr-mode:before {
    content: "\f8ca";
    }
        .fi-tr-model-cube-arrows:before {
    content: "\f8cb";
    }
        .fi-tr-model-cube-space:before {
    content: "\f8cc";
    }
        .fi-tr-model-cube:before {
    content: "\f8cd";
    }
        .fi-tr-module:before {
    content: "\f8ce";
    }
        .fi-tr-monday:before {
    content: "\f8cf";
    }
        .fi-tr-money-bill-simple:before {
    content: "\f8d0";
    }
        .fi-tr-money-bill-transfer:before {
    content: "\f8d1";
    }
        .fi-tr-money-bill-wave-alt:before {
    content: "\f8d2";
    }
        .fi-tr-money-bill-wave:before {
    content: "\f8d3";
    }
        .fi-tr-money-bills-simple:before {
    content: "\f8d4";
    }
        .fi-tr-money-bills:before {
    content: "\f8d5";
    }
        .fi-tr-money-check-edit-alt:before {
    content: "\f8d6";
    }
        .fi-tr-money-check-edit:before {
    content: "\f8d7";
    }
        .fi-tr-money-check:before {
    content: "\f8d8";
    }
        .fi-tr-money-coin-transfer:before {
    content: "\f8d9";
    }
        .fi-tr-money-from-bracket:before {
    content: "\f8da";
    }
        .fi-tr-money-simple-from-bracket:before {
    content: "\f8db";
    }
        .fi-tr-money-transfer-alt:before {
    content: "\f8dc";
    }
        .fi-tr-money-transfer-coin-arrow:before {
    content: "\f8dd";
    }
        .fi-tr-money-transfer-smartphone:before {
    content: "\f8de";
    }
        .fi-tr-money-wings:before {
    content: "\f8df";
    }
        .fi-tr-money:before {
    content: "\f8e0";
    }
        .fi-tr-monkey:before {
    content: "\f8e1";
    }
        .fi-tr-monument:before {
    content: "\f8e2";
    }
        .fi-tr-moon-stars:before {
    content: "\f8e3";
    }
        .fi-tr-moon:before {
    content: "\f8e4";
    }
        .fi-tr-moped:before {
    content: "\f8e5";
    }
        .fi-tr-mortar-pestle:before {
    content: "\f8e6";
    }
        .fi-tr-mortgage:before {
    content: "\f8e7";
    }
        .fi-tr-mosque-alt:before {
    content: "\f8e8";
    }
        .fi-tr-mosque-moon:before {
    content: "\f8e9";
    }
        .fi-tr-mosque:before {
    content: "\f8ea";
    }
        .fi-tr-mosquito-net:before {
    content: "\f8eb";
    }
        .fi-tr-mosquito:before {
    content: "\f8ec";
    }
        .fi-tr-motorcycle:before {
    content: "\f8ed";
    }
        .fi-tr-mound:before {
    content: "\f8ee";
    }
        .fi-tr-mountain-city:before {
    content: "\f8ef";
    }
        .fi-tr-mountain:before {
    content: "\f8f0";
    }
        .fi-tr-mountains:before {
    content: "\f8f1";
    }
        .fi-tr-mouse-field:before {
    content: "\f8f2";
    }
        .fi-tr-mouse:before {
    content: "\f8f3";
    }
        .fi-tr-mov-file:before {
    content: "\f8f4";
    }
        .fi-tr-move-to-folder-2:before {
    content: "\f8f5";
    }
        .fi-tr-move-to-folder:before {
    content: "\f8f6";
    }
        .fi-tr-mp3-file:before {
    content: "\f8f7";
    }
        .fi-tr-mp3-player:before {
    content: "\f8f8";
    }
        .fi-tr-mp4-file:before {
    content: "\f8f9";
    }
        .fi-tr-mug-alt:before {
    content: "\f8fa";
    }
        .fi-tr-mug-hot-alt:before {
    content: "\f8fb";
    }
        .fi-tr-mug-hot:before {
    content: "\f8fc";
    }
        .fi-tr-mug-marshmallows:before {
    content: "\f8fd";
    }
        .fi-tr-mug-tea-saucer:before {
    content: "\f8fe";
    }
        .fi-tr-mug-tea:before {
    content: "\f8ff";
    }
        .fi-tr-mug:before {
    content: "\f900";
    }
        .fi-tr-multiple-alt:before {
    content: "\f901";
    }
        .fi-tr-multiple:before {
    content: "\f902";
    }
        .fi-tr-muscle:before {
    content: "\f903";
    }
        .fi-tr-mushroom-alt:before {
    content: "\f904";
    }
        .fi-tr-mushroom:before {
    content: "\f905";
    }
        .fi-tr-music-file:before {
    content: "\f906";
    }
        .fi-tr-music-note-slash:before {
    content: "\f907";
    }
        .fi-tr-music-note:before {
    content: "\f908";
    }
        .fi-tr-music-slash:before {
    content: "\f909";
    }
        .fi-tr-n:before {
    content: "\f90a";
    }
        .fi-tr-naira-sign:before {
    content: "\f90b";
    }
        .fi-tr-narwhal:before {
    content: "\f90c";
    }
        .fi-tr-nesting-dolls:before {
    content: "\f90d";
    }
        .fi-tr-network-analytic:before {
    content: "\f90e";
    }
        .fi-tr-network-cloud:before {
    content: "\f90f";
    }
        .fi-tr-network:before {
    content: "\f910";
    }
        .fi-tr-neuter:before {
    content: "\f911";
    }
        .fi-tr-newsletter-subscribe:before {
    content: "\f912";
    }
        .fi-tr-newspaper-open:before {
    content: "\f913";
    }
        .fi-tr-newspaper:before {
    content: "\f914";
    }
        .fi-tr-nfc-lock:before {
    content: "\f915";
    }
        .fi-tr-nfc-magnifying-glass:before {
    content: "\f916";
    }
        .fi-tr-nfc-pen:before {
    content: "\f917";
    }
        .fi-tr-nfc-slash:before {
    content: "\f918";
    }
        .fi-tr-nfc-trash:before {
    content: "\f919";
    }
        .fi-tr-nfc:before {
    content: "\f91a";
    }
        .fi-tr-night-day:before {
    content: "\f91b";
    }
        .fi-tr-no-fee:before {
    content: "\f91c";
    }
        .fi-tr-no-people:before {
    content: "\f91d";
    }
        .fi-tr-noodles:before {
    content: "\f91e";
    }
        .fi-tr-nose:before {
    content: "\f91f";
    }
        .fi-tr-not-equal:before {
    content: "\f920";
    }
        .fi-tr-not-found-alt:before {
    content: "\f921";
    }
        .fi-tr-not-found-magnifying-glass:before {
    content: "\f922";
    }
        .fi-tr-not-found:before {
    content: "\f923";
    }
        .fi-tr-notdef:before {
    content: "\f924";
    }
        .fi-tr-note-medical:before {
    content: "\f925";
    }
        .fi-tr-note-sticky:before {
    content: "\f926";
    }
        .fi-tr-note:before {
    content: "\f927";
    }
        .fi-tr-notebook-alt:before {
    content: "\f928";
    }
        .fi-tr-notebook:before {
    content: "\f929";
    }
        .fi-tr-notes-medical:before {
    content: "\f92a";
    }
        .fi-tr-notes:before {
    content: "\f92b";
    }
        .fi-tr-o:before {
    content: "\f92c";
    }
        .fi-tr-object-exclude:before {
    content: "\f92d";
    }
        .fi-tr-object-group:before {
    content: "\f92e";
    }
        .fi-tr-object-intersect:before {
    content: "\f92f";
    }
        .fi-tr-object-subtract:before {
    content: "\f930";
    }
        .fi-tr-object-ungroup:before {
    content: "\f931";
    }
        .fi-tr-object-union:before {
    content: "\f932";
    }
        .fi-tr-objects-column:before {
    content: "\f933";
    }
        .fi-tr-octagon-check:before {
    content: "\f934";
    }
        .fi-tr-octagon-divide:before {
    content: "\f935";
    }
        .fi-tr-octagon-exclamation:before {
    content: "\f936";
    }
        .fi-tr-octagon-minus:before {
    content: "\f937";
    }
        .fi-tr-octagon-plus:before {
    content: "\f938";
    }
        .fi-tr-octagon-xmark:before {
    content: "\f939";
    }
        .fi-tr-octagon:before {
    content: "\f93a";
    }
        .fi-tr-oil-can:before {
    content: "\f93b";
    }
        .fi-tr-oil-temp:before {
    content: "\f93c";
    }
        .fi-tr-olive-oil:before {
    content: "\f93d";
    }
        .fi-tr-olive:before {
    content: "\f93e";
    }
        .fi-tr-olives:before {
    content: "\f93f";
    }
        .fi-tr-om:before {
    content: "\f940";
    }
        .fi-tr-omega:before {
    content: "\f941";
    }
        .fi-tr-onboarding:before {
    content: "\f942";
    }
        .fi-tr-onion:before {
    content: "\f943";
    }
        .fi-tr-open-mail-clip:before {
    content: "\f944";
    }
        .fi-tr-operating-system-upgrade:before {
    content: "\f945";
    }
        .fi-tr-operation:before {
    content: "\f946";
    }
        .fi-tr-order-history:before {
    content: "\f947";
    }
        .fi-tr-organization-chart:before {
    content: "\f948";
    }
        .fi-tr-ornament:before {
    content: "\f949";
    }
        .fi-tr-otp:before {
    content: "\f94a";
    }
        .fi-tr-otter:before {
    content: "\f94b";
    }
        .fi-tr-outdent:before {
    content: "\f94c";
    }
        .fi-tr-oval-alt:before {
    content: "\f94d";
    }
        .fi-tr-oval:before {
    content: "\f94e";
    }
        .fi-tr-oven:before {
    content: "\f94f";
    }
        .fi-tr-overline:before {
    content: "\f950";
    }
        .fi-tr-overview:before {
    content: "\f951";
    }
        .fi-tr-p:before {
    content: "\f952";
    }
        .fi-tr-package:before {
    content: "\f953";
    }
        .fi-tr-padlock-check:before {
    content: "\f954";
    }
        .fi-tr-page-break:before {
    content: "\f955";
    }
        .fi-tr-pager:before {
    content: "\f956";
    }
        .fi-tr-paid:before {
    content: "\f957";
    }
        .fi-tr-paint-roller:before {
    content: "\f958";
    }
        .fi-tr-paintbrush-pencil:before {
    content: "\f959";
    }
        .fi-tr-pallet-alt:before {
    content: "\f95a";
    }
        .fi-tr-pallet:before {
    content: "\f95b";
    }
        .fi-tr-pan-food:before {
    content: "\f95c";
    }
        .fi-tr-pan-frying:before {
    content: "\f95d";
    }
        .fi-tr-pan:before {
    content: "\f95e";
    }
        .fi-tr-pancakes:before {
    content: "\f95f";
    }
        .fi-tr-panorama:before {
    content: "\f960";
    }
        .fi-tr-paper-plane-launch:before {
    content: "\f961";
    }
        .fi-tr-paper-plane-top:before {
    content: "\f962";
    }
        .fi-tr-paperclip-vertical:before {
    content: "\f963";
    }
        .fi-tr-parachute-box:before {
    content: "\f964";
    }
        .fi-tr-paragraph-left:before {
    content: "\f965";
    }
        .fi-tr-paragraph:before {
    content: "\f966";
    }
        .fi-tr-parking-circle-slash:before {
    content: "\f967";
    }
        .fi-tr-parking-circle:before {
    content: "\f968";
    }
        .fi-tr-parking-slash:before {
    content: "\f969";
    }
        .fi-tr-parking:before {
    content: "\f96a";
    }
        .fi-tr-party-bell:before {
    content: "\f96b";
    }
        .fi-tr-party-horn:before {
    content: "\f96c";
    }
        .fi-tr-passport:before {
    content: "\f96d";
    }
        .fi-tr-password-alt:before {
    content: "\f96e";
    }
        .fi-tr-password-computer:before {
    content: "\f96f";
    }
        .fi-tr-password-email:before {
    content: "\f970";
    }
        .fi-tr-password-smartphone:before {
    content: "\f971";
    }
        .fi-tr-password:before {
    content: "\f972";
    }
        .fi-tr-paste:before {
    content: "\f973";
    }
        .fi-tr-pattern:before {
    content: "\f974";
    }
        .fi-tr-pause-circle:before {
    content: "\f975";
    }
        .fi-tr-pause-square:before {
    content: "\f976";
    }
        .fi-tr-paw-claws:before {
    content: "\f977";
    }
        .fi-tr-paw-heart:before {
    content: "\f978";
    }
        .fi-tr-paw:before {
    content: "\f979";
    }
        .fi-tr-payment-pos:before {
    content: "\f97a";
    }
        .fi-tr-payroll-calendar:before {
    content: "\f97b";
    }
        .fi-tr-payroll-check:before {
    content: "\f97c";
    }
        .fi-tr-payroll:before {
    content: "\f97d";
    }
        .fi-tr-peace:before {
    content: "\f97e";
    }
        .fi-tr-peach:before {
    content: "\f97f";
    }
        .fi-tr-peanut:before {
    content: "\f980";
    }
        .fi-tr-peanuts:before {
    content: "\f981";
    }
        .fi-tr-peapod:before {
    content: "\f982";
    }
        .fi-tr-pear:before {
    content: "\f983";
    }
        .fi-tr-pedestal:before {
    content: "\f984";
    }
        .fi-tr-pen-circle:before {
    content: "\f985";
    }
        .fi-tr-pen-clip-slash:before {
    content: "\f986";
    }
        .fi-tr-pen-clip:before {
    content: "\f987";
    }
        .fi-tr-pen-fancy-slash:before {
    content: "\f988";
    }
        .fi-tr-pen-fancy:before {
    content: "\f989";
    }
        .fi-tr-pen-field:before {
    content: "\f98a";
    }
        .fi-tr-pen-nib-slash:before {
    content: "\f98b";
    }
        .fi-tr-pen-nib:before {
    content: "\f98c";
    }
        .fi-tr-pen-slash:before {
    content: "\f98d";
    }
        .fi-tr-pen-square:before {
    content: "\f98e";
    }
        .fi-tr-pen-swirl:before {
    content: "\f98f";
    }
        .fi-tr-pencil-paintbrush:before {
    content: "\f990";
    }
        .fi-tr-pencil-ruler:before {
    content: "\f991";
    }
        .fi-tr-pencil-slash:before {
    content: "\f992";
    }
        .fi-tr-pending:before {
    content: "\f993";
    }
        .fi-tr-pennant:before {
    content: "\f994";
    }
        .fi-tr-people-arrows-left-right:before {
    content: "\f995";
    }
        .fi-tr-people-carry-box:before {
    content: "\f996";
    }
        .fi-tr-people-dress:before {
    content: "\f997";
    }
        .fi-tr-people-network-partner:before {
    content: "\f998";
    }
        .fi-tr-people-pants:before {
    content: "\f999";
    }
        .fi-tr-people-poll:before {
    content: "\f99a";
    }
        .fi-tr-people-pulling:before {
    content: "\f99b";
    }
        .fi-tr-people-roof:before {
    content: "\f99c";
    }
        .fi-tr-people:before {
    content: "\f99d";
    }
        .fi-tr-pepper-alt:before {
    content: "\f99e";
    }
        .fi-tr-pepper-hot:before {
    content: "\f99f";
    }
        .fi-tr-pepper:before {
    content: "\f9a0";
    }
        .fi-tr-percent-10:before {
    content: "\f9a1";
    }
        .fi-tr-percent-100:before {
    content: "\f9a2";
    }
        .fi-tr-percent-20:before {
    content: "\f9a3";
    }
        .fi-tr-percent-25:before {
    content: "\f9a4";
    }
        .fi-tr-percent-30:before {
    content: "\f9a5";
    }
        .fi-tr-percent-40:before {
    content: "\f9a6";
    }
        .fi-tr-percent-50:before {
    content: "\f9a7";
    }
        .fi-tr-percent-60:before {
    content: "\f9a8";
    }
        .fi-tr-percent-70:before {
    content: "\f9a9";
    }
        .fi-tr-percent-75:before {
    content: "\f9aa";
    }
        .fi-tr-percent-80:before {
    content: "\f9ab";
    }
        .fi-tr-percent-90:before {
    content: "\f9ac";
    }
        .fi-tr-percentage:before {
    content: "\f9ad";
    }
        .fi-tr-person-burst:before {
    content: "\f9ae";
    }
        .fi-tr-person-carry-box:before {
    content: "\f9af";
    }
        .fi-tr-person-circle-check:before {
    content: "\f9b0";
    }
        .fi-tr-person-circle-exclamation:before {
    content: "\f9b1";
    }
        .fi-tr-person-circle-minus:before {
    content: "\f9b2";
    }
        .fi-tr-person-circle-plus:before {
    content: "\f9b3";
    }
        .fi-tr-person-circle-question:before {
    content: "\f9b4";
    }
        .fi-tr-person-circle-xmark:before {
    content: "\f9b5";
    }
        .fi-tr-person-cv:before {
    content: "\f9b6";
    }
        .fi-tr-person-dolly-empty:before {
    content: "\f9b7";
    }
        .fi-tr-person-dolly:before {
    content: "\f9b8";
    }
        .fi-tr-person-dress-simple:before {
    content: "\f9b9";
    }
        .fi-tr-person-dress:before {
    content: "\f9ba";
    }
        .fi-tr-person-luggage:before {
    content: "\f9bb";
    }
        .fi-tr-person-lunge:before {
    content: "\f9bc";
    }
        .fi-tr-person-pilates:before {
    content: "\f9bd";
    }
        .fi-tr-person-praying:before {
    content: "\f9be";
    }
        .fi-tr-person-pregnant:before {
    content: "\f9bf";
    }
        .fi-tr-person-seat-reclined:before {
    content: "\f9c0";
    }
        .fi-tr-person-seat:before {
    content: "\f9c1";
    }
        .fi-tr-person-shelter:before {
    content: "\f9c2";
    }
        .fi-tr-person-sign:before {
    content: "\f9c3";
    }
        .fi-tr-person-simple:before {
    content: "\f9c4";
    }
        .fi-tr-person-walking-with-cane:before {
    content: "\f9c5";
    }
        .fi-tr-peseta-sign:before {
    content: "\f9c6";
    }
        .fi-tr-peso-sign:before {
    content: "\f9c7";
    }
        .fi-tr-pets:before {
    content: "\f9c8";
    }
        .fi-tr-pharmacy:before {
    content: "\f9c9";
    }
        .fi-tr-phone-call:before {
    content: "\f9ca";
    }
        .fi-tr-phone-cross:before {
    content: "\f9cb";
    }
        .fi-tr-phone-flip:before {
    content: "\f9cc";
    }
        .fi-tr-phone-heart-message:before {
    content: "\f9cd";
    }
        .fi-tr-phone-office:before {
    content: "\f9ce";
    }
        .fi-tr-phone-pause:before {
    content: "\f9cf";
    }
        .fi-tr-phone-plus:before {
    content: "\f9d0";
    }
        .fi-tr-phone-rotary:before {
    content: "\f9d1";
    }
        .fi-tr-phone-slash:before {
    content: "\f9d2";
    }
        .fi-tr-photo-capture:before {
    content: "\f9d3";
    }
        .fi-tr-photo-film-music:before {
    content: "\f9d4";
    }
        .fi-tr-photo-video:before {
    content: "\f9d5";
    }
        .fi-tr-physics:before {
    content: "\f9d6";
    }
        .fi-tr-Pi:before {
    content: "\f9d7";
    }
        .fi-tr-piano-keyboard:before {
    content: "\f9d8";
    }
        .fi-tr-piano:before {
    content: "\f9d9";
    }
        .fi-tr-pickaxe:before {
    content: "\f9da";
    }
        .fi-tr-picking-box:before {
    content: "\f9db";
    }
        .fi-tr-picking:before {
    content: "\f9dc";
    }
        .fi-tr-picnic:before {
    content: "\f9dd";
    }
        .fi-tr-picpeople-filled:before {
    content: "\f9de";
    }
        .fi-tr-picpeople:before {
    content: "\f9df";
    }
        .fi-tr-pie:before {
    content: "\f9e0";
    }
        .fi-tr-pig-bank-bulb:before {
    content: "\f9e1";
    }
        .fi-tr-pig-face:before {
    content: "\f9e2";
    }
        .fi-tr-pig:before {
    content: "\f9e3";
    }
        .fi-tr-piggy-bank-budget:before {
    content: "\f9e4";
    }
        .fi-tr-piggy-bank:before {
    content: "\f9e5";
    }
        .fi-tr-pills:before {
    content: "\f9e6";
    }
        .fi-tr-pinata:before {
    content: "\f9e7";
    }
        .fi-tr-pineapple-alt:before {
    content: "\f9e8";
    }
        .fi-tr-pineapple:before {
    content: "\f9e9";
    }
        .fi-tr-pipe-smoking:before {
    content: "\f9ea";
    }
        .fi-tr-pizza-slice:before {
    content: "\f9eb";
    }
        .fi-tr-place-of-worship:before {
    content: "\f9ec";
    }
        .fi-tr-plan-strategy:before {
    content: "\f9ed";
    }
        .fi-tr-plan:before {
    content: "\f9ee";
    }
        .fi-tr-plane-alt:before {
    content: "\f9ef";
    }
        .fi-tr-plane-arrival:before {
    content: "\f9f0";
    }
        .fi-tr-plane-departure:before {
    content: "\f9f1";
    }
        .fi-tr-plane-prop:before {
    content: "\f9f2";
    }
        .fi-tr-plane-slash:before {
    content: "\f9f3";
    }
        .fi-tr-plane-tail:before {
    content: "\f9f4";
    }
        .fi-tr-planet-moon:before {
    content: "\f9f5";
    }
        .fi-tr-planet-ringed:before {
    content: "\f9f6";
    }
        .fi-tr-plant-care:before {
    content: "\f9f7";
    }
        .fi-tr-plant-growth:before {
    content: "\f9f8";
    }
        .fi-tr-plant-seed-invest:before {
    content: "\f9f9";
    }
        .fi-tr-plant-wilt:before {
    content: "\f9fa";
    }
        .fi-tr-plate-utensils:before {
    content: "\f9fb";
    }
        .fi-tr-plate-wheat:before {
    content: "\f9fc";
    }
        .fi-tr-plate:before {
    content: "\f9fd";
    }
        .fi-tr-play-circle:before {
    content: "\f9fe";
    }
        .fi-tr-play-microphone:before {
    content: "\f9ff";
    }
        .fi-tr-play-pause:before {
    content: "\fa00";
    }
        .fi-tr-plug-alt:before {
    content: "\fa01";
    }
        .fi-tr-plug-cable:before {
    content: "\fa02";
    }
        .fi-tr-plug-circle-bolt:before {
    content: "\fa03";
    }
        .fi-tr-plug-circle-check:before {
    content: "\fa04";
    }
        .fi-tr-plug-circle-exclamation:before {
    content: "\fa05";
    }
        .fi-tr-plug-circle-minus:before {
    content: "\fa06";
    }
        .fi-tr-plug-circle-plus:before {
    content: "\fa07";
    }
        .fi-tr-plug-connection:before {
    content: "\fa08";
    }
        .fi-tr-plug:before {
    content: "\fa09";
    }
        .fi-tr-plus-hexagon:before {
    content: "\fa0a";
    }
        .fi-tr-plus-minus:before {
    content: "\fa0b";
    }
        .fi-tr-png-file:before {
    content: "\fa0c";
    }
        .fi-tr-podcast:before {
    content: "\fa0d";
    }
        .fi-tr-podium-star:before {
    content: "\fa0e";
    }
        .fi-tr-podium-victory-leader:before {
    content: "\fa0f";
    }
        .fi-tr-podium:before {
    content: "\fa10";
    }
        .fi-tr-point-of-sale-bill:before {
    content: "\fa11";
    }
        .fi-tr-point-of-sale-signal:before {
    content: "\fa12";
    }
        .fi-tr-point-of-sale:before {
    content: "\fa13";
    }
        .fi-tr-pointer-loading:before {
    content: "\fa14";
    }
        .fi-tr-pointer-text:before {
    content: "\fa15";
    }
        .fi-tr-police-box:before {
    content: "\fa16";
    }
        .fi-tr-poll-h:before {
    content: "\fa17";
    }
        .fi-tr-pollution:before {
    content: "\fa18";
    }
        .fi-tr-pompebled:before {
    content: "\fa19";
    }
        .fi-tr-poo-bolt:before {
    content: "\fa1a";
    }
        .fi-tr-poo:before {
    content: "\fa1b";
    }
        .fi-tr-pool-8-ball:before {
    content: "\fa1c";
    }
        .fi-tr-poop:before {
    content: "\fa1d";
    }
        .fi-tr-popcorn:before {
    content: "\fa1e";
    }
        .fi-tr-popsicle:before {
    content: "\fa1f";
    }
        .fi-tr-portal-enter:before {
    content: "\fa20";
    }
        .fi-tr-portal-exit:before {
    content: "\fa21";
    }
        .fi-tr-postal-address:before {
    content: "\fa22";
    }
        .fi-tr-pot:before {
    content: "\fa23";
    }
        .fi-tr-potato:before {
    content: "\fa24";
    }
        .fi-tr-pound:before {
    content: "\fa25";
    }
        .fi-tr-ppt-file:before {
    content: "\fa26";
    }
        .fi-tr-practice:before {
    content: "\fa27";
    }
        .fi-tr-praying-hands:before {
    content: "\fa28";
    }
        .fi-tr-prescription-bottle-alt:before {
    content: "\fa29";
    }
        .fi-tr-prescription-bottle-pill:before {
    content: "\fa2a";
    }
        .fi-tr-prescription-bottle:before {
    content: "\fa2b";
    }
        .fi-tr-prescription:before {
    content: "\fa2c";
    }
        .fi-tr-presentation:before {
    content: "\fa2d";
    }
        .fi-tr-previous-square:before {
    content: "\fa2e";
    }
        .fi-tr-print-magnifying-glass:before {
    content: "\fa2f";
    }
        .fi-tr-print-slash:before {
    content: "\fa30";
    }
        .fi-tr-print:before {
    content: "\fa31";
    }
        .fi-tr-problem-solving:before {
    content: "\fa32";
    }
        .fi-tr-procedures:before {
    content: "\fa33";
    }
        .fi-tr-process:before {
    content: "\fa34";
    }
        .fi-tr-productivity:before {
    content: "\fa35";
    }
        .fi-tr-progress-complete:before {
    content: "\fa36";
    }
        .fi-tr-progress-download:before {
    content: "\fa37";
    }
        .fi-tr-progress-upload:before {
    content: "\fa38";
    }
        .fi-tr-projector:before {
    content: "\fa39";
    }
        .fi-tr-protractor:before {
    content: "\fa3a";
    }
        .fi-tr-pulse:before {
    content: "\fa3b";
    }
        .fi-tr-pump-medical:before {
    content: "\fa3c";
    }
        .fi-tr-pump:before {
    content: "\fa3d";
    }
        .fi-tr-pumpkin-alt-2:before {
    content: "\fa3e";
    }
        .fi-tr-pumpkin-alt:before {
    content: "\fa3f";
    }
        .fi-tr-pumpkin:before {
    content: "\fa40";
    }
        .fi-tr-puzzle-alt:before {
    content: "\fa41";
    }
        .fi-tr-puzzle-piece-integration:before {
    content: "\fa42";
    }
        .fi-tr-puzzle-piece:before {
    content: "\fa43";
    }
        .fi-tr-puzzle-pieces:before {
    content: "\fa44";
    }
        .fi-tr-pyramid:before {
    content: "\fa45";
    }
        .fi-tr-q:before {
    content: "\fa46";
    }
        .fi-tr-qr-scan:before {
    content: "\fa47";
    }
        .fi-tr-QR:before {
    content: "\fa48";
    }
        .fi-tr-qrcode:before {
    content: "\fa49";
    }
        .fi-tr-question-square:before {
    content: "\fa4a";
    }
        .fi-tr-question:before {
    content: "\fa4b";
    }
        .fi-tr-queue-alt:before {
    content: "\fa4c";
    }
        .fi-tr-queue-line:before {
    content: "\fa4d";
    }
        .fi-tr-queue-signal:before {
    content: "\fa4e";
    }
        .fi-tr-queue:before {
    content: "\fa4f";
    }
        .fi-tr-quill-pen-story:before {
    content: "\fa50";
    }
        .fi-tr-quiz-alt:before {
    content: "\fa51";
    }
        .fi-tr-quiz:before {
    content: "\fa52";
    }
        .fi-tr-r:before {
    content: "\fa53";
    }
        .fi-tr-rabbit-fast:before {
    content: "\fa54";
    }
        .fi-tr-rabbit:before {
    content: "\fa55";
    }
        .fi-tr-raccoon:before {
    content: "\fa56";
    }
        .fi-tr-racquet:before {
    content: "\fa57";
    }
        .fi-tr-radar-monitoring-track:before {
    content: "\fa58";
    }
        .fi-tr-radar:before {
    content: "\fa59";
    }
        .fi-tr-radiation-alt:before {
    content: "\fa5a";
    }
        .fi-tr-radiation:before {
    content: "\fa5b";
    }
        .fi-tr-radio-alt:before {
    content: "\fa5c";
    }
        .fi-tr-radio-button:before {
    content: "\fa5d";
    }
        .fi-tr-radio:before {
    content: "\fa5e";
    }
        .fi-tr-radish:before {
    content: "\fa5f";
    }
        .fi-tr-rainbow:before {
    content: "\fa60";
    }
        .fi-tr-raindrops:before {
    content: "\fa61";
    }
        .fi-tr-ram:before {
    content: "\fa62";
    }
        .fi-tr-ramp-loading:before {
    content: "\fa63";
    }
        .fi-tr-rank:before {
    content: "\fa64";
    }
        .fi-tr-ranking-podium-empty:before {
    content: "\fa65";
    }
        .fi-tr-ranking-podium:before {
    content: "\fa66";
    }
        .fi-tr-ranking-star:before {
    content: "\fa67";
    }
        .fi-tr-ranking-stars:before {
    content: "\fa68";
    }
        .fi-tr-raygun:before {
    content: "\fa69";
    }
        .fi-tr-razor-barber:before {
    content: "\fa6a";
    }
        .fi-tr-react:before {
    content: "\fa6b";
    }
        .fi-tr-recipe-book:before {
    content: "\fa6c";
    }
        .fi-tr-recipe:before {
    content: "\fa6d";
    }
        .fi-tr-record-vinyl:before {
    content: "\fa6e";
    }
        .fi-tr-rectangle-barcode:before {
    content: "\fa6f";
    }
        .fi-tr-rectangle-code:before {
    content: "\fa70";
    }
        .fi-tr-rectangle-history-circle-plus:before {
    content: "\fa71";
    }
        .fi-tr-rectangle-horizontal:before {
    content: "\fa72";
    }
        .fi-tr-rectangle-list:before {
    content: "\fa73";
    }
        .fi-tr-rectangle-panoramic:before {
    content: "\fa74";
    }
        .fi-tr-rectangle-pro:before {
    content: "\fa75";
    }
        .fi-tr-rectangle-vertical-history:before {
    content: "\fa76";
    }
        .fi-tr-rectangle-vertical:before {
    content: "\fa77";
    }
        .fi-tr-rectangle-xmark:before {
    content: "\fa78";
    }
        .fi-tr-rectangles-mixed:before {
    content: "\fa79";
    }
        .fi-tr-recycle-bin:before {
    content: "\fa7a";
    }
        .fi-tr-recycle:before {
    content: "\fa7b";
    }
        .fi-tr-redo-alt:before {
    content: "\fa7c";
    }
        .fi-tr-reel:before {
    content: "\fa7d";
    }
        .fi-tr-referral-alt:before {
    content: "\fa7e";
    }
        .fi-tr-referral-user:before {
    content: "\fa7f";
    }
        .fi-tr-referral:before {
    content: "\fa80";
    }
        .fi-tr-reflect-horizontal-alt:before {
    content: "\fa81";
    }
        .fi-tr-reflect-horizontal:before {
    content: "\fa82";
    }
        .fi-tr-reflect-vertical:before {
    content: "\fa83";
    }
        .fi-tr-refrigerator:before {
    content: "\fa84";
    }
        .fi-tr-refund-alt:before {
    content: "\fa85";
    }
        .fi-tr-refund:before {
    content: "\fa86";
    }
        .fi-tr-region-pin-alt:before {
    content: "\fa87";
    }
        .fi-tr-region-pin:before {
    content: "\fa88";
    }
        .fi-tr-registered:before {
    content: "\fa89";
    }
        .fi-tr-registration-paper:before {
    content: "\fa8a";
    }
        .fi-tr-remove-folder:before {
    content: "\fa8b";
    }
        .fi-tr-remove-user:before {
    content: "\fa8c";
    }
        .fi-tr-rent-signal:before {
    content: "\fa8d";
    }
        .fi-tr-rent:before {
    content: "\fa8e";
    }
        .fi-tr-replace:before {
    content: "\fa8f";
    }
        .fi-tr-replay-10:before {
    content: "\fa90";
    }
        .fi-tr-replay-30:before {
    content: "\fa91";
    }
        .fi-tr-replay-5:before {
    content: "\fa92";
    }
        .fi-tr-reply-all:before {
    content: "\fa93";
    }
        .fi-tr-republican:before {
    content: "\fa94";
    }
        .fi-tr-reservation-smartphone:before {
    content: "\fa95";
    }
        .fi-tr-reservation-table:before {
    content: "\fa96";
    }
        .fi-tr-resistance-band:before {
    content: "\fa97";
    }
        .fi-tr-resources:before {
    content: "\fa98";
    }
        .fi-tr-restaurant:before {
    content: "\fa99";
    }
        .fi-tr-restock:before {
    content: "\fa9a";
    }
        .fi-tr-restroom-simple:before {
    content: "\fa9b";
    }
        .fi-tr-resume:before {
    content: "\fa9c";
    }
        .fi-tr-revenue-alt:before {
    content: "\fa9d";
    }
        .fi-tr-revenue-euro:before {
    content: "\fa9e";
    }
        .fi-tr-Revenue:before {
    content: "\fa9f";
    }
        .fi-tr-review:before {
    content: "\faa0";
    }
        .fi-tr-rewind-button-circle:before {
    content: "\faa1";
    }
        .fi-tr-rhombus:before {
    content: "\faa2";
    }
        .fi-tr-ribbon:before {
    content: "\faa3";
    }
        .fi-tr-right-from-bracket:before {
    content: "\faa4";
    }
        .fi-tr-right:before {
    content: "\faa5";
    }
        .fi-tr-ring-diamond:before {
    content: "\faa6";
    }
        .fi-tr-ring:before {
    content: "\faa7";
    }
        .fi-tr-rings-wedding:before {
    content: "\faa8";
    }
        .fi-tr-risk-alt:before {
    content: "\faa9";
    }
        .fi-tr-risk:before {
    content: "\faaa";
    }
        .fi-tr-road-barrier:before {
    content: "\faab";
    }
        .fi-tr-road-sign-left:before {
    content: "\faac";
    }
        .fi-tr-robot:before {
    content: "\faad";
    }
        .fi-tr-robotic-arm:before {
    content: "\faae";
    }
        .fi-tr-rocket-lunch:before {
    content: "\faaf";
    }
        .fi-tr-rocket:before {
    content: "\fab0";
    }
        .fi-tr-roller-coaster:before {
    content: "\fab1";
    }
        .fi-tr-room-service:before {
    content: "\fab2";
    }
        .fi-tr-rotate-exclamation:before {
    content: "\fab3";
    }
        .fi-tr-rotate-reverse:before {
    content: "\fab4";
    }
        .fi-tr-rotate-square:before {
    content: "\fab5";
    }
        .fi-tr-roulette:before {
    content: "\fab6";
    }
        .fi-tr-route-highway:before {
    content: "\fab7";
    }
        .fi-tr-route-interstate:before {
    content: "\fab8";
    }
        .fi-tr-route:before {
    content: "\fab9";
    }
        .fi-tr-router:before {
    content: "\faba";
    }
        .fi-tr-rss-alt:before {
    content: "\fabb";
    }
        .fi-tr-rss:before {
    content: "\fabc";
    }
        .fi-tr-ruble-sign:before {
    content: "\fabd";
    }
        .fi-tr-rugby-helmet:before {
    content: "\fabe";
    }
        .fi-tr-ruler-combined:before {
    content: "\fabf";
    }
        .fi-tr-ruler-horizontal:before {
    content: "\fac0";
    }
        .fi-tr-ruler-triangle:before {
    content: "\fac1";
    }
        .fi-tr-ruler-vertical:before {
    content: "\fac2";
    }
        .fi-tr-rules-alt:before {
    content: "\fac3";
    }
        .fi-tr-rules:before {
    content: "\fac4";
    }
        .fi-tr-running-track:before {
    content: "\fac5";
    }
        .fi-tr-running:before {
    content: "\fac6";
    }
        .fi-tr-rupee-sign:before {
    content: "\fac7";
    }
        .fi-tr-rupiah-sign:before {
    content: "\fac8";
    }
        .fi-tr-rv:before {
    content: "\fac9";
    }
        .fi-tr-s:before {
    content: "\faca";
    }
        .fi-tr-sack-dollar:before {
    content: "\facb";
    }
        .fi-tr-sack:before {
    content: "\facc";
    }
        .fi-tr-sad-cry:before {
    content: "\facd";
    }
        .fi-tr-sad-tear:before {
    content: "\face";
    }
        .fi-tr-sad:before {
    content: "\facf";
    }
        .fi-tr-safe-box:before {
    content: "\fad0";
    }
        .fi-tr-sailboat:before {
    content: "\fad1";
    }
        .fi-tr-salad:before {
    content: "\fad2";
    }
        .fi-tr-salary-alt:before {
    content: "\fad3";
    }
        .fi-tr-salt-pepper:before {
    content: "\fad4";
    }
        .fi-tr-salt-shaker:before {
    content: "\fad5";
    }
        .fi-tr-sandwich-alt:before {
    content: "\fad6";
    }
        .fi-tr-sandwich:before {
    content: "\fad7";
    }
        .fi-tr-satellite-dish:before {
    content: "\fad8";
    }
        .fi-tr-satellite:before {
    content: "\fad9";
    }
        .fi-tr-saturday:before {
    content: "\fada";
    }
        .fi-tr-sauce:before {
    content: "\fadb";
    }
        .fi-tr-sausage:before {
    content: "\fadc";
    }
        .fi-tr-sax-hot:before {
    content: "\fadd";
    }
        .fi-tr-saxophone:before {
    content: "\fade";
    }
        .fi-tr-scale-comparison-alt:before {
    content: "\fadf";
    }
        .fi-tr-scale-comparison:before {
    content: "\fae0";
    }
        .fi-tr-scale:before {
    content: "\fae1";
    }
        .fi-tr-scalpel-path:before {
    content: "\fae2";
    }
        .fi-tr-scalpel:before {
    content: "\fae3";
    }
        .fi-tr-scanner-gun:before {
    content: "\fae4";
    }
        .fi-tr-scanner-image:before {
    content: "\fae5";
    }
        .fi-tr-scanner-keyboard:before {
    content: "\fae6";
    }
        .fi-tr-scanner-touchscreen:before {
    content: "\fae7";
    }
        .fi-tr-scarecrow:before {
    content: "\fae8";
    }
        .fi-tr-scarf:before {
    content: "\fae9";
    }
        .fi-tr-school-bus:before {
    content: "\faea";
    }
        .fi-tr-school:before {
    content: "\faeb";
    }
        .fi-tr-scooter:before {
    content: "\faec";
    }
        .fi-tr-screen:before {
    content: "\faed";
    }
        .fi-tr-screencast:before {
    content: "\faee";
    }
        .fi-tr-screw-alt:before {
    content: "\faef";
    }
        .fi-tr-screw:before {
    content: "\faf0";
    }
        .fi-tr-screwdriver:before {
    content: "\faf1";
    }
        .fi-tr-scribble:before {
    content: "\faf2";
    }
        .fi-tr-script:before {
    content: "\faf3";
    }
        .fi-tr-scroll-document-story:before {
    content: "\faf4";
    }
        .fi-tr-scroll-old:before {
    content: "\faf5";
    }
        .fi-tr-scroll-torah:before {
    content: "\faf6";
    }
        .fi-tr-scroll:before {
    content: "\faf7";
    }
        .fi-tr-scrubber:before {
    content: "\faf8";
    }
        .fi-tr-scythe:before {
    content: "\faf9";
    }
        .fi-tr-sd-card:before {
    content: "\fafa";
    }
        .fi-tr-sd-cards:before {
    content: "\fafb";
    }
        .fi-tr-seal-exclamation:before {
    content: "\fafc";
    }
        .fi-tr-seal-question:before {
    content: "\fafd";
    }
        .fi-tr-seal:before {
    content: "\fafe";
    }
        .fi-tr-search-alt:before {
    content: "\faff";
    }
        .fi-tr-search-dollar:before {
    content: "\fb00";
    }
        .fi-tr-search-heart:before {
    content: "\fb01";
    }
        .fi-tr-search-location:before {
    content: "\fb02";
    }
        .fi-tr-seat-airline:before {
    content: "\fb03";
    }
        .fi-tr-seatbelt-safety-driver:before {
    content: "\fb04";
    }
        .fi-tr-second-award:before {
    content: "\fb05";
    }
        .fi-tr-second-laurel:before {
    content: "\fb06";
    }
        .fi-tr-second-medal:before {
    content: "\fb07";
    }
        .fi-tr-second:before {
    content: "\fb08";
    }
        .fi-tr-seedling:before {
    content: "\fb09";
    }
        .fi-tr-selection:before {
    content: "\fb0a";
    }
        .fi-tr-sell:before {
    content: "\fb0b";
    }
        .fi-tr-seller-store:before {
    content: "\fb0c";
    }
        .fi-tr-seller:before {
    content: "\fb0d";
    }
        .fi-tr-selling:before {
    content: "\fb0e";
    }
        .fi-tr-send-back:before {
    content: "\fb0f";
    }
        .fi-tr-send-backward:before {
    content: "\fb10";
    }
        .fi-tr-send-money-smartphone:before {
    content: "\fb11";
    }
        .fi-tr-send-money:before {
    content: "\fb12";
    }
        .fi-tr-sensor-alert:before {
    content: "\fb13";
    }
        .fi-tr-sensor-fire:before {
    content: "\fb14";
    }
        .fi-tr-sensor-on:before {
    content: "\fb15";
    }
        .fi-tr-sensor-smoke:before {
    content: "\fb16";
    }
        .fi-tr-sensor:before {
    content: "\fb17";
    }
        .fi-tr-share-alt-square:before {
    content: "\fb18";
    }
        .fi-tr-share-square:before {
    content: "\fb19";
    }
        .fi-tr-sheep:before {
    content: "\fb1a";
    }
        .fi-tr-shekel-sign:before {
    content: "\fb1b";
    }
        .fi-tr-shelves:before {
    content: "\fb1c";
    }
        .fi-tr-shield-alt:before {
    content: "\fb1d";
    }
        .fi-tr-shield-cat:before {
    content: "\fb1e";
    }
        .fi-tr-shield-cross:before {
    content: "\fb1f";
    }
        .fi-tr-shield-dog:before {
    content: "\fb20";
    }
        .fi-tr-shield-exclamation:before {
    content: "\fb21";
    }
        .fi-tr-shield-interrogation:before {
    content: "\fb22";
    }
        .fi-tr-shield-keyhole:before {
    content: "\fb23";
    }
        .fi-tr-shield-minus:before {
    content: "\fb24";
    }
        .fi-tr-shield-security-risk:before {
    content: "\fb25";
    }
        .fi-tr-shield-slash:before {
    content: "\fb26";
    }
        .fi-tr-shield-trust:before {
    content: "\fb27";
    }
        .fi-tr-shield-virus:before {
    content: "\fb28";
    }
        .fi-tr-shield-xmark:before {
    content: "\fb29";
    }
        .fi-tr-shipping-fast:before {
    content: "\fb2a";
    }
        .fi-tr-shipping-timed:before {
    content: "\fb2b";
    }
        .fi-tr-shirt-long-sleeve:before {
    content: "\fb2c";
    }
        .fi-tr-shirt-running:before {
    content: "\fb2d";
    }
        .fi-tr-shirt-tank-top:before {
    content: "\fb2e";
    }
        .fi-tr-shirt:before {
    content: "\fb2f";
    }
        .fi-tr-shish-kebab:before {
    content: "\fb30";
    }
        .fi-tr-shoe-prints:before {
    content: "\fb31";
    }
        .fi-tr-shop-lock:before {
    content: "\fb32";
    }
        .fi-tr-shop-slash:before {
    content: "\fb33";
    }
        .fi-tr-shopping-basket:before {
    content: "\fb34";
    }
        .fi-tr-shopping-cart-buyer:before {
    content: "\fb35";
    }
        .fi-tr-shovel-snow:before {
    content: "\fb36";
    }
        .fi-tr-shovel:before {
    content: "\fb37";
    }
        .fi-tr-shower-down:before {
    content: "\fb38";
    }
        .fi-tr-shower:before {
    content: "\fb39";
    }
        .fi-tr-shredder:before {
    content: "\fb3a";
    }
        .fi-tr-shrimp:before {
    content: "\fb3b";
    }
        .fi-tr-shuttle-van:before {
    content: "\fb3c";
    }
        .fi-tr-shuttlecock:before {
    content: "\fb3d";
    }
        .fi-tr-Sickle:before {
    content: "\fb3e";
    }
        .fi-tr-sidebar-flip:before {
    content: "\fb3f";
    }
        .fi-tr-sidebar:before {
    content: "\fb40";
    }
        .fi-tr-sigma:before {
    content: "\fb41";
    }
        .fi-tr-sign-hanging:before {
    content: "\fb42";
    }
        .fi-tr-sign-in-alt:before {
    content: "\fb43";
    }
        .fi-tr-sign-out-alt:before {
    content: "\fb44";
    }
        .fi-tr-sign-posts-wrench:before {
    content: "\fb45";
    }
        .fi-tr-sign-posts:before {
    content: "\fb46";
    }
        .fi-tr-sign-up:before {
    content: "\fb47";
    }
        .fi-tr-signal-alt-1:before {
    content: "\fb48";
    }
        .fi-tr-signal-alt-2:before {
    content: "\fb49";
    }
        .fi-tr-signal-alt-slash:before {
    content: "\fb4a";
    }
        .fi-tr-signal-alt:before {
    content: "\fb4b";
    }
        .fi-tr-signal-bars-fair:before {
    content: "\fb4c";
    }
        .fi-tr-signal-bars-good:before {
    content: "\fb4d";
    }
        .fi-tr-signal-bars-weak:before {
    content: "\fb4e";
    }
        .fi-tr-signal-stream-slash:before {
    content: "\fb4f";
    }
        .fi-tr-signal-stream:before {
    content: "\fb50";
    }
        .fi-tr-signature-lock:before {
    content: "\fb51";
    }
        .fi-tr-signature-slash:before {
    content: "\fb52";
    }
        .fi-tr-signature:before {
    content: "\fb53";
    }
        .fi-tr-sim-card:before {
    content: "\fb54";
    }
        .fi-tr-sim-cards:before {
    content: "\fb55";
    }
        .fi-tr-sink:before {
    content: "\fb56";
    }
        .fi-tr-siren-on:before {
    content: "\fb57";
    }
        .fi-tr-Siren:before {
    content: "\fb58";
    }
        .fi-tr-site-alt:before {
    content: "\fb59";
    }
        .fi-tr-site-browser:before {
    content: "\fb5a";
    }
        .fi-tr-site:before {
    content: "\fb5b";
    }
        .fi-tr-sitemap:before {
    content: "\fb5c";
    }
        .fi-tr-skating:before {
    content: "\fb5d";
    }
        .fi-tr-skeleton-ribs:before {
    content: "\fb5e";
    }
        .fi-tr-skeleton:before {
    content: "\fb5f";
    }
        .fi-tr-skewer:before {
    content: "\fb60";
    }
        .fi-tr-ski-boot-ski:before {
    content: "\fb61";
    }
        .fi-tr-ski-jump:before {
    content: "\fb62";
    }
        .fi-tr-ski-lift:before {
    content: "\fb63";
    }
        .fi-tr-skiing-nordic:before {
    content: "\fb64";
    }
        .fi-tr-skiing:before {
    content: "\fb65";
    }
        .fi-tr-skill-alt:before {
    content: "\fb66";
    }
        .fi-tr-skill-user:before {
    content: "\fb67";
    }
        .fi-tr-skill:before {
    content: "\fb68";
    }
        .fi-tr-skin-arrow:before {
    content: "\fb69";
    }
        .fi-tr-skin-drop:before {
    content: "\fb6a";
    }
        .fi-tr-skin-hair:before {
    content: "\fb6b";
    }
        .fi-tr-skin:before {
    content: "\fb6c";
    }
        .fi-tr-skip-15-seconds:before {
    content: "\fb6d";
    }
        .fi-tr-skull-cow:before {
    content: "\fb6e";
    }
        .fi-tr-skull-crossbones:before {
    content: "\fb6f";
    }
        .fi-tr-skull:before {
    content: "\fb70";
    }
        .fi-tr-slash:before {
    content: "\fb71";
    }
        .fi-tr-sledding:before {
    content: "\fb72";
    }
        .fi-tr-sleeping-bag:before {
    content: "\fb73";
    }
        .fi-tr-sleigh:before {
    content: "\fb74";
    }
        .fi-tr-sliders-h-square:before {
    content: "\fb75";
    }
        .fi-tr-sliders-v-square:before {
    content: "\fb76";
    }
        .fi-tr-sliders-v:before {
    content: "\fb77";
    }
        .fi-tr-slot-machine:before {
    content: "\fb78";
    }
        .fi-tr-smart-home-alt:before {
    content: "\fb79";
    }
        .fi-tr-smart-home:before {
    content: "\fb7a";
    }
        .fi-tr-smartphone:before {
    content: "\fb7b";
    }
        .fi-tr-smile-beam:before {
    content: "\fb7c";
    }
        .fi-tr-smile-plus:before {
    content: "\fb7d";
    }
        .fi-tr-smile-wink:before {
    content: "\fb7e";
    }
        .fi-tr-smile:before {
    content: "\fb7f";
    }
        .fi-tr-smiley-comment-alt:before {
    content: "\fb80";
    }
        .fi-tr-smog:before {
    content: "\fb81";
    }
        .fi-tr-smoke:before {
    content: "\fb82";
    }
        .fi-tr-smoking-ban:before {
    content: "\fb83";
    }
        .fi-tr-smoking:before {
    content: "\fb84";
    }
        .fi-tr-snake:before {
    content: "\fb85";
    }
        .fi-tr-snap:before {
    content: "\fb86";
    }
        .fi-tr-snooze:before {
    content: "\fb87";
    }
        .fi-tr-snow-blowing:before {
    content: "\fb88";
    }
        .fi-tr-snowboarding:before {
    content: "\fb89";
    }
        .fi-tr-snowflake-droplets:before {
    content: "\fb8a";
    }
        .fi-tr-snowflake:before {
    content: "\fb8b";
    }
        .fi-tr-snowflakes:before {
    content: "\fb8c";
    }
        .fi-tr-snowman-alt:before {
    content: "\fb8d";
    }
        .fi-tr-snowman-head:before {
    content: "\fb8e";
    }
        .fi-tr-snowmobile:before {
    content: "\fb8f";
    }
        .fi-tr-snowplow:before {
    content: "\fb90";
    }
        .fi-tr-soap-alt:before {
    content: "\fb91";
    }
        .fi-tr-soap:before {
    content: "\fb92";
    }
        .fi-tr-socks:before {
    content: "\fb93";
    }
        .fi-tr-sofa-size:before {
    content: "\fb94";
    }
        .fi-tr-sofa:before {
    content: "\fb95";
    }
        .fi-tr-solar-panel-sun:before {
    content: "\fb96";
    }
        .fi-tr-solar-panel:before {
    content: "\fb97";
    }
        .fi-tr-solar-system:before {
    content: "\fb98";
    }
        .fi-tr-sold-house:before {
    content: "\fb99";
    }
        .fi-tr-sold-signal:before {
    content: "\fb9a";
    }
        .fi-tr-sort-alpha-down-alt:before {
    content: "\fb9b";
    }
        .fi-tr-sort-alpha-down:before {
    content: "\fb9c";
    }
        .fi-tr-sort-alpha-up-alt:before {
    content: "\fb9d";
    }
        .fi-tr-sort-alpha-up:before {
    content: "\fb9e";
    }
        .fi-tr-sort-alt:before {
    content: "\fb9f";
    }
        .fi-tr-sort-amount-down-alt:before {
    content: "\fba0";
    }
        .fi-tr-sort-amount-down:before {
    content: "\fba1";
    }
        .fi-tr-sort-amount-up-alt:before {
    content: "\fba2";
    }
        .fi-tr-sort-amount-up:before {
    content: "\fba3";
    }
        .fi-tr-sort-circle-down:before {
    content: "\fba4";
    }
        .fi-tr-sort-circle-up:before {
    content: "\fba5";
    }
        .fi-tr-sort-circle:before {
    content: "\fba6";
    }
        .fi-tr-sort-down:before {
    content: "\fba7";
    }
        .fi-tr-sort-numeric-down-alt:before {
    content: "\fba8";
    }
        .fi-tr-sort-numeric-down:before {
    content: "\fba9";
    }
        .fi-tr-sort-shapes-down:before {
    content: "\fbaa";
    }
        .fi-tr-sort-shapes-up:before {
    content: "\fbab";
    }
        .fi-tr-sort-size-down:before {
    content: "\fbac";
    }
        .fi-tr-sort-size-up:before {
    content: "\fbad";
    }
        .fi-tr-sort:before {
    content: "\fbae";
    }
        .fi-tr-soup:before {
    content: "\fbaf";
    }
        .fi-tr-source-data:before {
    content: "\fbb0";
    }
        .fi-tr-source-document-alt:before {
    content: "\fbb1";
    }
        .fi-tr-source-document:before {
    content: "\fbb2";
    }
        .fi-tr-spa:before {
    content: "\fbb3";
    }
        .fi-tr-space-shuttle:before {
    content: "\fbb4";
    }
        .fi-tr-space-station-moon-alt:before {
    content: "\fbb5";
    }
        .fi-tr-space-station-moon:before {
    content: "\fbb6";
    }
        .fi-tr-spade:before {
    content: "\fbb7";
    }
        .fi-tr-spaghetti-monster-flying:before {
    content: "\fbb8";
    }
        .fi-tr-spain-map:before {
    content: "\fbb9";
    }
        .fi-tr-sparkles:before {
    content: "\fbba";
    }
        .fi-tr-spartan-helmet:before {
    content: "\fbbb";
    }
        .fi-tr-speaker:before {
    content: "\fbbc";
    }
        .fi-tr-speakers:before {
    content: "\fbbd";
    }
        .fi-tr-speech-bubble-story:before {
    content: "\fbbe";
    }
        .fi-tr-speedometer-arrow:before {
    content: "\fbbf";
    }
        .fi-tr-speedometer-kpi:before {
    content: "\fbc0";
    }
        .fi-tr-sphere:before {
    content: "\fbc1";
    }
        .fi-tr-spider-black-widow:before {
    content: "\fbc2";
    }
        .fi-tr-spider-web:before {
    content: "\fbc3";
    }
        .fi-tr-spider:before {
    content: "\fbc4";
    }
        .fi-tr-split-up-relation:before {
    content: "\fbc5";
    }
        .fi-tr-split:before {
    content: "\fbc6";
    }
        .fi-tr-splotch:before {
    content: "\fbc7";
    }
        .fi-tr-spoon:before {
    content: "\fbc8";
    }
        .fi-tr-spray-can-sparkles:before {
    content: "\fbc9";
    }
        .fi-tr-spray-can:before {
    content: "\fbca";
    }
        .fi-tr-spring-calendar:before {
    content: "\fbcb";
    }
        .fi-tr-sprinkler:before {
    content: "\fbcc";
    }
        .fi-tr-spy:before {
    content: "\fbcd";
    }
        .fi-tr-square-0:before {
    content: "\fbce";
    }
        .fi-tr-square-1:before {
    content: "\fbcf";
    }
        .fi-tr-square-2:before {
    content: "\fbd0";
    }
        .fi-tr-square-3:before {
    content: "\fbd1";
    }
        .fi-tr-square-4:before {
    content: "\fbd2";
    }
        .fi-tr-square-5:before {
    content: "\fbd3";
    }
        .fi-tr-square-6:before {
    content: "\fbd4";
    }
        .fi-tr-square-7:before {
    content: "\fbd5";
    }
        .fi-tr-square-8:before {
    content: "\fbd6";
    }
        .fi-tr-square-9:before {
    content: "\fbd7";
    }
        .fi-tr-square-a:before {
    content: "\fbd8";
    }
        .fi-tr-square-b:before {
    content: "\fbd9";
    }
        .fi-tr-square-bolt:before {
    content: "\fbda";
    }
        .fi-tr-square-c:before {
    content: "\fbdb";
    }
        .fi-tr-square-code:before {
    content: "\fbdc";
    }
        .fi-tr-square-d:before {
    content: "\fbdd";
    }
        .fi-tr-square-dashed-circle-plus:before {
    content: "\fbde";
    }
        .fi-tr-square-dashed:before {
    content: "\fbdf";
    }
        .fi-tr-square-divide:before {
    content: "\fbe0";
    }
        .fi-tr-square-e:before {
    content: "\fbe1";
    }
        .fi-tr-square-ellipsis-vertical:before {
    content: "\fbe2";
    }
        .fi-tr-square-ellipsis:before {
    content: "\fbe3";
    }
        .fi-tr-square-exclamation:before {
    content: "\fbe4";
    }
        .fi-tr-square-f:before {
    content: "\fbe5";
    }
        .fi-tr-square-g:before {
    content: "\fbe6";
    }
        .fi-tr-square-h:before {
    content: "\fbe7";
    }
        .fi-tr-square-heart:before {
    content: "\fbe8";
    }
        .fi-tr-square-i:before {
    content: "\fbe9";
    }
        .fi-tr-square-info:before {
    content: "\fbea";
    }
        .fi-tr-square-j:before {
    content: "\fbeb";
    }
        .fi-tr-square-k:before {
    content: "\fbec";
    }
        .fi-tr-square-kanban:before {
    content: "\fbed";
    }
        .fi-tr-square-l:before {
    content: "\fbee";
    }
        .fi-tr-square-m:before {
    content: "\fbef";
    }
        .fi-tr-square-minus:before {
    content: "\fbf0";
    }
        .fi-tr-square-n:before {
    content: "\fbf1";
    }
        .fi-tr-square-o:before {
    content: "\fbf2";
    }
        .fi-tr-square-p:before {
    content: "\fbf3";
    }
        .fi-tr-square-phone-hangup:before {
    content: "\fbf4";
    }
        .fi-tr-square-plus:before {
    content: "\fbf5";
    }
        .fi-tr-square-poll-horizontal:before {
    content: "\fbf6";
    }
        .fi-tr-square-poll-vertical:before {
    content: "\fbf7";
    }
        .fi-tr-square-q:before {
    content: "\fbf8";
    }
        .fi-tr-square-quote:before {
    content: "\fbf9";
    }
        .fi-tr-square-r:before {
    content: "\fbfa";
    }
        .fi-tr-square-root:before {
    content: "\fbfb";
    }
        .fi-tr-square-s:before {
    content: "\fbfc";
    }
        .fi-tr-square-small:before {
    content: "\fbfd";
    }
        .fi-tr-square-star:before {
    content: "\fbfe";
    }
        .fi-tr-square-t:before {
    content: "\fbff";
    }
        .fi-tr-square-terminal:before {
    content: "\fc00";
    }
        .fi-tr-square-u:before {
    content: "\fc01";
    }
        .fi-tr-square-up-right:before {
    content: "\fc02";
    }
        .fi-tr-square-v:before {
    content: "\fc03";
    }
        .fi-tr-square-w:before {
    content: "\fc04";
    }
        .fi-tr-square-x:before {
    content: "\fc05";
    }
        .fi-tr-square-y:before {
    content: "\fc06";
    }
        .fi-tr-square-z:before {
    content: "\fc07";
    }
        .fi-tr-square:before {
    content: "\fc08";
    }
        .fi-tr-squid:before {
    content: "\fc09";
    }
        .fi-tr-squircle:before {
    content: "\fc0a";
    }
        .fi-tr-squirrel:before {
    content: "\fc0b";
    }
        .fi-tr-staff:before {
    content: "\fc0c";
    }
        .fi-tr-stage-concert:before {
    content: "\fc0d";
    }
        .fi-tr-stage-theatre:before {
    content: "\fc0e";
    }
        .fi-tr-stage:before {
    content: "\fc0f";
    }
        .fi-tr-stairs:before {
    content: "\fc10";
    }
        .fi-tr-stamp:before {
    content: "\fc11";
    }
        .fi-tr-standard-definition:before {
    content: "\fc12";
    }
        .fi-tr-star-and-crescent:before {
    content: "\fc13";
    }
        .fi-tr-star-christmas:before {
    content: "\fc14";
    }
        .fi-tr-star-comment-alt:before {
    content: "\fc15";
    }
        .fi-tr-star-exclamation:before {
    content: "\fc16";
    }
        .fi-tr-star-octogram:before {
    content: "\fc17";
    }
        .fi-tr-star-of-david:before {
    content: "\fc18";
    }
        .fi-tr-star-sharp-half-stroke:before {
    content: "\fc19";
    }
        .fi-tr-star-sharp-half:before {
    content: "\fc1a";
    }
        .fi-tr-star-shooting:before {
    content: "\fc1b";
    }
        .fi-tr-starfighter:before {
    content: "\fc1c";
    }
        .fi-tr-stars:before {
    content: "\fc1d";
    }
        .fi-tr-state-country:before {
    content: "\fc1e";
    }
        .fi-tr-stationary-bike:before {
    content: "\fc1f";
    }
        .fi-tr-stats:before {
    content: "\fc20";
    }
        .fi-tr-steak:before {
    content: "\fc21";
    }
        .fi-tr-steering-wheel:before {
    content: "\fc22";
    }
        .fi-tr-step-backward:before {
    content: "\fc23";
    }
        .fi-tr-step-forward:before {
    content: "\fc24";
    }
        .fi-tr-sterling-sign:before {
    content: "\fc25";
    }
        .fi-tr-stethoscope:before {
    content: "\fc26";
    }
        .fi-tr-sticker:before {
    content: "\fc27";
    }
        .fi-tr-stocking:before {
    content: "\fc28";
    }
        .fi-tr-stomach:before {
    content: "\fc29";
    }
        .fi-tr-stop-circle:before {
    content: "\fc2a";
    }
        .fi-tr-stop-square:before {
    content: "\fc2b";
    }
        .fi-tr-stopwatch:before {
    content: "\fc2c";
    }
        .fi-tr-store-alt:before {
    content: "\fc2d";
    }
        .fi-tr-store-buyer:before {
    content: "\fc2e";
    }
        .fi-tr-store-lock:before {
    content: "\fc2f";
    }
        .fi-tr-store-slash:before {
    content: "\fc30";
    }
        .fi-tr-story-book:before {
    content: "\fc31";
    }
        .fi-tr-story-fairy-tale:before {
    content: "\fc32";
    }
        .fi-tr-story-fantasy:before {
    content: "\fc33";
    }
        .fi-tr-strategy-chess-risk:before {
    content: "\fc34";
    }
        .fi-tr-strawberry:before {
    content: "\fc35";
    }
        .fi-tr-street-view:before {
    content: "\fc36";
    }
        .fi-tr-stretcher:before {
    content: "\fc37";
    }
        .fi-tr-strikethrough:before {
    content: "\fc38";
    }
        .fi-tr-stroopwafel:before {
    content: "\fc39";
    }
        .fi-tr-student-alt:before {
    content: "\fc3a";
    }
        .fi-tr-student:before {
    content: "\fc3b";
    }
        .fi-tr-subscript:before {
    content: "\fc3c";
    }
        .fi-tr-subscription-alt:before {
    content: "\fc3d";
    }
        .fi-tr-subscription:before {
    content: "\fc3e";
    }
        .fi-tr-subtitles-slash:before {
    content: "\fc3f";
    }
        .fi-tr-subway:before {
    content: "\fc40";
    }
        .fi-tr-suitcase-alt:before {
    content: "\fc41";
    }
        .fi-tr-summary-check:before {
    content: "\fc42";
    }
        .fi-tr-summer:before {
    content: "\fc43";
    }
        .fi-tr-sun-dust:before {
    content: "\fc44";
    }
        .fi-tr-sun-plant-wilt:before {
    content: "\fc45";
    }
        .fi-tr-sun-salutation-yoga:before {
    content: "\fc46";
    }
        .fi-tr-sun:before {
    content: "\fc47";
    }
        .fi-tr-sunday:before {
    content: "\fc48";
    }
        .fi-tr-sunglasses-alt:before {
    content: "\fc49";
    }
        .fi-tr-sunglasses:before {
    content: "\fc4a";
    }
        .fi-tr-sunrise-alt:before {
    content: "\fc4b";
    }
        .fi-tr-sunrise:before {
    content: "\fc4c";
    }
        .fi-tr-sunset:before {
    content: "\fc4d";
    }
        .fi-tr-superscript:before {
    content: "\fc4e";
    }
        .fi-tr-supplier-alt:before {
    content: "\fc4f";
    }
        .fi-tr-supplier:before {
    content: "\fc50";
    }
        .fi-tr-surprise:before {
    content: "\fc51";
    }
        .fi-tr-surveillance-camera:before {
    content: "\fc52";
    }
        .fi-tr-survey-xmark:before {
    content: "\fc53";
    }
        .fi-tr-sushi-alt:before {
    content: "\fc54";
    }
        .fi-tr-sushi-roll:before {
    content: "\fc55";
    }
        .fi-tr-sushi:before {
    content: "\fc56";
    }
        .fi-tr-svg:before {
    content: "\fc57";
    }
        .fi-tr-swap:before {
    content: "\fc58";
    }
        .fi-tr-swatchbook:before {
    content: "\fc59";
    }
        .fi-tr-swimmer:before {
    content: "\fc5a";
    }
        .fi-tr-swimming-pool:before {
    content: "\fc5b";
    }
        .fi-tr-swing:before {
    content: "\fc5c";
    }
        .fi-tr-swipe-down:before {
    content: "\fc5d";
    }
        .fi-tr-swipe-left:before {
    content: "\fc5e";
    }
        .fi-tr-swipe-right:before {
    content: "\fc5f";
    }
        .fi-tr-swipe-up:before {
    content: "\fc60";
    }
        .fi-tr-symbols:before {
    content: "\fc61";
    }
        .fi-tr-synagogue:before {
    content: "\fc62";
    }
        .fi-tr-syringe:before {
    content: "\fc63";
    }
        .fi-tr-system-cloud:before {
    content: "\fc64";
    }
        .fi-tr-t:before {
    content: "\fc65";
    }
        .fi-tr-tab-folder:before {
    content: "\fc66";
    }
        .fi-tr-table-columns:before {
    content: "\fc67";
    }
        .fi-tr-table-layout:before {
    content: "\fc68";
    }
        .fi-tr-table-list:before {
    content: "\fc69";
    }
        .fi-tr-table-picnic:before {
    content: "\fc6a";
    }
        .fi-tr-table-pivot:before {
    content: "\fc6b";
    }
        .fi-tr-table-rows:before {
    content: "\fc6c";
    }
        .fi-tr-table-tree:before {
    content: "\fc6d";
    }
        .fi-tr-table:before {
    content: "\fc6e";
    }
        .fi-tr-tablet-android-alt:before {
    content: "\fc6f";
    }
        .fi-tr-tablet-android:before {
    content: "\fc70";
    }
        .fi-tr-tablet-rugged:before {
    content: "\fc71";
    }
        .fi-tr-tablet:before {
    content: "\fc72";
    }
        .fi-tr-tachometer-alt-average:before {
    content: "\fc73";
    }
        .fi-tr-tachometer-alt-fastest:before {
    content: "\fc74";
    }
        .fi-tr-tachometer-alt-slow:before {
    content: "\fc75";
    }
        .fi-tr-tachometer-alt-slowest:before {
    content: "\fc76";
    }
        .fi-tr-tachometer-average:before {
    content: "\fc77";
    }
        .fi-tr-tachometer-fast:before {
    content: "\fc78";
    }
        .fi-tr-tachometer-fastest:before {
    content: "\fc79";
    }
        .fi-tr-tachometer-slow:before {
    content: "\fc7a";
    }
        .fi-tr-tachometer-slowest:before {
    content: "\fc7b";
    }
        .fi-tr-tachometer:before {
    content: "\fc7c";
    }
        .fi-tr-taco:before {
    content: "\fc7d";
    }
        .fi-tr-tags:before {
    content: "\fc7e";
    }
        .fi-tr-talent-alt:before {
    content: "\fc7f";
    }
        .fi-tr-talent-hand:before {
    content: "\fc80";
    }
        .fi-tr-talent:before {
    content: "\fc81";
    }
        .fi-tr-tally-1:before {
    content: "\fc82";
    }
        .fi-tr-tally-2:before {
    content: "\fc83";
    }
        .fi-tr-tally-3:before {
    content: "\fc84";
    }
        .fi-tr-tally-4:before {
    content: "\fc85";
    }
        .fi-tr-tally:before {
    content: "\fc86";
    }
        .fi-tr-tamale:before {
    content: "\fc87";
    }
        .fi-tr-tank-water:before {
    content: "\fc88";
    }
        .fi-tr-tap:before {
    content: "\fc89";
    }
        .fi-tr-tape:before {
    content: "\fc8a";
    }
        .fi-tr-target-audience:before {
    content: "\fc8b";
    }
        .fi-tr-target:before {
    content: "\fc8c";
    }
        .fi-tr-tax-alt:before {
    content: "\fc8d";
    }
        .fi-tr-tax:before {
    content: "\fc8e";
    }
        .fi-tr-taxi-bus:before {
    content: "\fc8f";
    }
        .fi-tr-team-check-alt:before {
    content: "\fc90";
    }
        .fi-tr-team-check:before {
    content: "\fc91";
    }
        .fi-tr-teddy-bear:before {
    content: "\fc92";
    }
        .fi-tr-teeth-open:before {
    content: "\fc93";
    }
        .fi-tr-telescope:before {
    content: "\fc94";
    }
        .fi-tr-temperature-down:before {
    content: "\fc95";
    }
        .fi-tr-temperature-frigid:before {
    content: "\fc96";
    }
        .fi-tr-temperature-high:before {
    content: "\fc97";
    }
        .fi-tr-temperature-list:before {
    content: "\fc98";
    }
        .fi-tr-temperature-low:before {
    content: "\fc99";
    }
        .fi-tr-temperature-up:before {
    content: "\fc9a";
    }
        .fi-tr-template-alt:before {
    content: "\fc9b";
    }
        .fi-tr-template:before {
    content: "\fc9c";
    }
        .fi-tr-tenge:before {
    content: "\fc9d";
    }
        .fi-tr-tent-arrow-down-to-line:before {
    content: "\fc9e";
    }
        .fi-tr-tent-arrow-left-right:before {
    content: "\fc9f";
    }
        .fi-tr-tent-arrow-turn-left:before {
    content: "\fca0";
    }
        .fi-tr-tent-arrows-down:before {
    content: "\fca1";
    }
        .fi-tr-tents:before {
    content: "\fca2";
    }
        .fi-tr-terminal:before {
    content: "\fca3";
    }
        .fi-tr-terrace:before {
    content: "\fca4";
    }
        .fi-tr-test-tube:before {
    content: "\fca5";
    }
        .fi-tr-test:before {
    content: "\fca6";
    }
        .fi-tr-text-box-dots:before {
    content: "\fca7";
    }
        .fi-tr-text-box-edit:before {
    content: "\fca8";
    }
        .fi-tr-text-box:before {
    content: "\fca9";
    }
        .fi-tr-text-height:before {
    content: "\fcaa";
    }
        .fi-tr-text-shadow:before {
    content: "\fcab";
    }
        .fi-tr-text-size:before {
    content: "\fcac";
    }
        .fi-tr-text-slash:before {
    content: "\fcad";
    }
        .fi-tr-text-width:before {
    content: "\fcae";
    }
        .fi-tr-theater-masks:before {
    content: "\fcaf";
    }
        .fi-tr-thermometer-alt:before {
    content: "\fcb0";
    }
        .fi-tr-thermometer-empty:before {
    content: "\fcb1";
    }
        .fi-tr-thermometer-full:before {
    content: "\fcb2";
    }
        .fi-tr-thermometer-half:before {
    content: "\fcb3";
    }
        .fi-tr-thermometer-quarter:before {
    content: "\fcb4";
    }
        .fi-tr-thermometer-three-quarters:before {
    content: "\fcb5";
    }
        .fi-tr-theta:before {
    content: "\fcb6";
    }
        .fi-tr-third-award:before {
    content: "\fcb7";
    }
        .fi-tr-third-laurel:before {
    content: "\fcb8";
    }
        .fi-tr-third-medal:before {
    content: "\fcb9";
    }
        .fi-tr-third:before {
    content: "\fcba";
    }
        .fi-tr-thought-bubble:before {
    content: "\fcbb";
    }
        .fi-tr-three-leaf-clover:before {
    content: "\fcbc";
    }
        .fi-tr-thumbs-up-trust:before {
    content: "\fcbd";
    }
        .fi-tr-thunderstorm-moon:before {
    content: "\fcbe";
    }
        .fi-tr-thunderstorm-risk:before {
    content: "\fcbf";
    }
        .fi-tr-thunderstorm-sun:before {
    content: "\fcc0";
    }
        .fi-tr-thunderstorm:before {
    content: "\fcc1";
    }
        .fi-tr-thursday:before {
    content: "\fcc2";
    }
        .fi-tr-ticket-airline:before {
    content: "\fcc3";
    }
        .fi-tr-ticket-alt:before {
    content: "\fcc4";
    }
        .fi-tr-tickets-airline:before {
    content: "\fcc5";
    }
        .fi-tr-tie:before {
    content: "\fcc6";
    }
        .fi-tr-tilde:before {
    content: "\fcc7";
    }
        .fi-tr-time-add:before {
    content: "\fcc8";
    }
        .fi-tr-time-check:before {
    content: "\fcc9";
    }
        .fi-tr-time-delete:before {
    content: "\fcca";
    }
        .fi-tr-time-fast:before {
    content: "\fccb";
    }
        .fi-tr-time-forward-sixty:before {
    content: "\fccc";
    }
        .fi-tr-time-forward-ten:before {
    content: "\fccd";
    }
        .fi-tr-time-forward:before {
    content: "\fcce";
    }
        .fi-tr-time-half-past:before {
    content: "\fccf";
    }
        .fi-tr-time-oclock:before {
    content: "\fcd0";
    }
        .fi-tr-time-quarter-past:before {
    content: "\fcd1";
    }
        .fi-tr-time-quarter-to:before {
    content: "\fcd2";
    }
        .fi-tr-time-twenty-four:before {
    content: "\fcd3";
    }
        .fi-tr-time-watch-calendar:before {
    content: "\fcd4";
    }
        .fi-tr-times-hexagon:before {
    content: "\fcd5";
    }
        .fi-tr-tint-slash:before {
    content: "\fcd6";
    }
        .fi-tr-tip-coin:before {
    content: "\fcd7";
    }
        .fi-tr-tire-flat:before {
    content: "\fcd8";
    }
        .fi-tr-tire-pressure-warning:before {
    content: "\fcd9";
    }
        .fi-tr-tire-rugged:before {
    content: "\fcda";
    }
        .fi-tr-tire:before {
    content: "\fcdb";
    }
        .fi-tr-tired:before {
    content: "\fcdc";
    }
        .fi-tr-to-do-alt:before {
    content: "\fcdd";
    }
        .fi-tr-to-do:before {
    content: "\fcde";
    }
        .fi-tr-together-people:before {
    content: "\fcdf";
    }
        .fi-tr-toggle-off:before {
    content: "\fce0";
    }
        .fi-tr-toggle-on:before {
    content: "\fce1";
    }
        .fi-tr-toilet-paper-blank-under:before {
    content: "\fce2";
    }
        .fi-tr-toilet-paper-blank:before {
    content: "\fce3";
    }
        .fi-tr-toilet-paper-slash:before {
    content: "\fce4";
    }
        .fi-tr-toilet-paper-under-slash:before {
    content: "\fce5";
    }
        .fi-tr-toilet-paper-under:before {
    content: "\fce6";
    }
        .fi-tr-toilet:before {
    content: "\fce7";
    }
        .fi-tr-toilets-portable:before {
    content: "\fce8";
    }
        .fi-tr-token:before {
    content: "\fce9";
    }
        .fi-tr-tomato:before {
    content: "\fcea";
    }
        .fi-tr-tombstone-alt:before {
    content: "\fceb";
    }
        .fi-tr-tombstone:before {
    content: "\fcec";
    }
        .fi-tr-tool-box:before {
    content: "\fced";
    }
        .fi-tr-tools:before {
    content: "\fcee";
    }
        .fi-tr-tooth:before {
    content: "\fcef";
    }
        .fi-tr-toothbrush:before {
    content: "\fcf0";
    }
        .fi-tr-torch-inspiration-leader:before {
    content: "\fcf1";
    }
        .fi-tr-torii-gate:before {
    content: "\fcf2";
    }
        .fi-tr-tornado:before {
    content: "\fcf3";
    }
        .fi-tr-total:before {
    content: "\fcf4";
    }
        .fi-tr-tour-guide-people:before {
    content: "\fcf5";
    }
        .fi-tr-tour-virtual:before {
    content: "\fcf6";
    }
        .fi-tr-tower-control:before {
    content: "\fcf7";
    }
        .fi-tr-track:before {
    content: "\fcf8";
    }
        .fi-tr-tractor:before {
    content: "\fcf9";
    }
        .fi-tr-trademark:before {
    content: "\fcfa";
    }
        .fi-tr-traffic-cone:before {
    content: "\fcfb";
    }
        .fi-tr-traffic-light-go:before {
    content: "\fcfc";
    }
        .fi-tr-traffic-light-slow:before {
    content: "\fcfd";
    }
        .fi-tr-traffic-light-stop:before {
    content: "\fcfe";
    }
        .fi-tr-traffic-light:before {
    content: "\fcff";
    }
        .fi-tr-trailer:before {
    content: "\fd00";
    }
        .fi-tr-train-journey:before {
    content: "\fd01";
    }
        .fi-tr-train-station-building:before {
    content: "\fd02";
    }
        .fi-tr-train-station:before {
    content: "\fd03";
    }
        .fi-tr-train-subway-tunnel:before {
    content: "\fd04";
    }
        .fi-tr-train-track:before {
    content: "\fd05";
    }
        .fi-tr-train-tram:before {
    content: "\fd06";
    }
        .fi-tr-tram:before {
    content: "\fd07";
    }
        .fi-tr-transformation-block:before {
    content: "\fd08";
    }
        .fi-tr-transformation-circle:before {
    content: "\fd09";
    }
        .fi-tr-transformation-design:before {
    content: "\fd0a";
    }
        .fi-tr-transformation-shapes:before {
    content: "\fd0b";
    }
        .fi-tr-transformer-bolt:before {
    content: "\fd0c";
    }
        .fi-tr-transgender:before {
    content: "\fd0d";
    }
        .fi-tr-transporter-1:before {
    content: "\fd0e";
    }
        .fi-tr-transporter-2:before {
    content: "\fd0f";
    }
        .fi-tr-transporter-3:before {
    content: "\fd10";
    }
        .fi-tr-transporter-4:before {
    content: "\fd11";
    }
        .fi-tr-transporter-5:before {
    content: "\fd12";
    }
        .fi-tr-transporter-6:before {
    content: "\fd13";
    }
        .fi-tr-transporter-7:before {
    content: "\fd14";
    }
        .fi-tr-transporter-empty:before {
    content: "\fd15";
    }
        .fi-tr-transporter:before {
    content: "\fd16";
    }
        .fi-tr-trash-can-check:before {
    content: "\fd17";
    }
        .fi-tr-trash-can-clock:before {
    content: "\fd18";
    }
        .fi-tr-trash-can-list:before {
    content: "\fd19";
    }
        .fi-tr-trash-can-plus:before {
    content: "\fd1a";
    }
        .fi-tr-trash-can-slash:before {
    content: "\fd1b";
    }
        .fi-tr-trash-check:before {
    content: "\fd1c";
    }
        .fi-tr-trash-clock:before {
    content: "\fd1d";
    }
        .fi-tr-trash-empty:before {
    content: "\fd1e";
    }
        .fi-tr-trash-list:before {
    content: "\fd1f";
    }
        .fi-tr-trash-plus:before {
    content: "\fd20";
    }
        .fi-tr-trash-restore-alt:before {
    content: "\fd21";
    }
        .fi-tr-trash-restore:before {
    content: "\fd22";
    }
        .fi-tr-trash-slash:before {
    content: "\fd23";
    }
        .fi-tr-trash-undo-alt:before {
    content: "\fd24";
    }
        .fi-tr-trash-undo:before {
    content: "\fd25";
    }
        .fi-tr-trash-xmark:before {
    content: "\fd26";
    }
        .fi-tr-treadmill:before {
    content: "\fd27";
    }
        .fi-tr-treasure-chest:before {
    content: "\fd28";
    }
        .fi-tr-treatment:before {
    content: "\fd29";
    }
        .fi-tr-tree-alt:before {
    content: "\fd2a";
    }
        .fi-tr-tree-christmas:before {
    content: "\fd2b";
    }
        .fi-tr-tree-deciduous:before {
    content: "\fd2c";
    }
        .fi-tr-tree:before {
    content: "\fd2d";
    }
        .fi-tr-trees-alt:before {
    content: "\fd2e";
    }
        .fi-tr-trees:before {
    content: "\fd2f";
    }
        .fi-tr-triangle-music:before {
    content: "\fd30";
    }
        .fi-tr-triangle-person-digging:before {
    content: "\fd31";
    }
        .fi-tr-triangle-warning:before {
    content: "\fd32";
    }
        .fi-tr-triangle:before {
    content: "\fd33";
    }
        .fi-tr-tricycle:before {
    content: "\fd34";
    }
        .fi-tr-trillium:before {
    content: "\fd35";
    }
        .fi-tr-trophy-achievement-skill:before {
    content: "\fd36";
    }
        .fi-tr-trophy-star:before {
    content: "\fd37";
    }
        .fi-tr-truck-arrow-left:before {
    content: "\fd38";
    }
        .fi-tr-truck-arrow-right:before {
    content: "\fd39";
    }
        .fi-tr-truck-bolt:before {
    content: "\fd3a";
    }
        .fi-tr-truck-box:before {
    content: "\fd3b";
    }
        .fi-tr-truck-check:before {
    content: "\fd3c";
    }
        .fi-tr-truck-container-empty:before {
    content: "\fd3d";
    }
        .fi-tr-truck-container:before {
    content: "\fd3e";
    }
        .fi-tr-truck-couch:before {
    content: "\fd3f";
    }
        .fi-tr-truck-fire:before {
    content: "\fd40";
    }
        .fi-tr-truck-flatbed:before {
    content: "\fd41";
    }
        .fi-tr-truck-front:before {
    content: "\fd42";
    }
        .fi-tr-truck-ladder:before {
    content: "\fd43";
    }
        .fi-tr-truck-loading:before {
    content: "\fd44";
    }
        .fi-tr-truck-medical:before {
    content: "\fd45";
    }
        .fi-tr-truck-monster:before {
    content: "\fd46";
    }
        .fi-tr-truck-moving:before {
    content: "\fd47";
    }
        .fi-tr-truck-pickup:before {
    content: "\fd48";
    }
        .fi-tr-truck-plow:before {
    content: "\fd49";
    }
        .fi-tr-truck-ramp:before {
    content: "\fd4a";
    }
        .fi-tr-truck-tow:before {
    content: "\fd4b";
    }
        .fi-tr-truck-utensils:before {
    content: "\fd4c";
    }
        .fi-tr-trumpet:before {
    content: "\fd4d";
    }
        .fi-tr-trust-alt:before {
    content: "\fd4e";
    }
        .fi-tr-trust:before {
    content: "\fd4f";
    }
        .fi-tr-tshirt:before {
    content: "\fd50";
    }
        .fi-tr-tty-answer:before {
    content: "\fd51";
    }
        .fi-tr-tty:before {
    content: "\fd52";
    }
        .fi-tr-tubes:before {
    content: "\fd53";
    }
        .fi-tr-tuesday:before {
    content: "\fd54";
    }
        .fi-tr-tugrik-sign:before {
    content: "\fd55";
    }
        .fi-tr-turkey:before {
    content: "\fd56";
    }
        .fi-tr-turn-left-down:before {
    content: "\fd57";
    }
        .fi-tr-turn-left:before {
    content: "\fd58";
    }
        .fi-tr-turn-right:before {
    content: "\fd59";
    }
        .fi-tr-turntable:before {
    content: "\fd5a";
    }
        .fi-tr-turtle:before {
    content: "\fd5b";
    }
        .fi-tr-tv-music:before {
    content: "\fd5c";
    }
        .fi-tr-tv-retro:before {
    content: "\fd5d";
    }
        .fi-tr-typewriter:before {
    content: "\fd5e";
    }
        .fi-tr-u:before {
    content: "\fd5f";
    }
        .fi-tr-ufo-beam:before {
    content: "\fd60";
    }
        .fi-tr-ufo:before {
    content: "\fd61";
    }
        .fi-tr-ui-ux:before {
    content: "\fd62";
    }
        .fi-tr-umbrella-beach:before {
    content: "\fd63";
    }
        .fi-tr-umbrella:before {
    content: "\fd64";
    }
        .fi-tr-under-construction:before {
    content: "\fd65";
    }
        .fi-tr-undo-alt:before {
    content: "\fd66";
    }
        .fi-tr-undo:before {
    content: "\fd67";
    }
        .fi-tr-unicorn:before {
    content: "\fd68";
    }
        .fi-tr-uniform-martial-arts:before {
    content: "\fd69";
    }
        .fi-tr-universal-access:before {
    content: "\fd6a";
    }
        .fi-tr-up-from-bracket:before {
    content: "\fd6b";
    }
        .fi-tr-up-left:before {
    content: "\fd6c";
    }
        .fi-tr-up-right-from-square:before {
    content: "\fd6d";
    }
        .fi-tr-up-right:before {
    content: "\fd6e";
    }
        .fi-tr-up:before {
    content: "\fd6f";
    }
        .fi-tr-url:before {
    content: "\fd70";
    }
        .fi-tr-usb-pendrive:before {
    content: "\fd71";
    }
        .fi-tr-usd-circle:before {
    content: "\fd72";
    }
        .fi-tr-usd-square:before {
    content: "\fd73";
    }
        .fi-tr-user-alien:before {
    content: "\fd74";
    }
        .fi-tr-user-astronaut:before {
    content: "\fd75";
    }
        .fi-tr-user-check:before {
    content: "\fd76";
    }
        .fi-tr-user-chef:before {
    content: "\fd77";
    }
        .fi-tr-user-coach:before {
    content: "\fd78";
    }
        .fi-tr-user-cowboy:before {
    content: "\fd79";
    }
        .fi-tr-user-crown:before {
    content: "\fd7a";
    }
        .fi-tr-user-gear:before {
    content: "\fd7b";
    }
        .fi-tr-user-graduate:before {
    content: "\fd7c";
    }
        .fi-tr-user-headset:before {
    content: "\fd7d";
    }
        .fi-tr-user-helmet-safety:before {
    content: "\fd7e";
    }
        .fi-tr-user-injured:before {
    content: "\fd7f";
    }
        .fi-tr-user-interface-ui:before {
    content: "\fd80";
    }
        .fi-tr-user-key:before {
    content: "\fd81";
    }
        .fi-tr-user-lock:before {
    content: "\fd82";
    }
        .fi-tr-user-md-chat:before {
    content: "\fd83";
    }
        .fi-tr-user-md:before {
    content: "\fd84";
    }
        .fi-tr-user-minus:before {
    content: "\fd85";
    }
        .fi-tr-user-music:before {
    content: "\fd86";
    }
        .fi-tr-user-ninja:before {
    content: "\fd87";
    }
        .fi-tr-user-nurse:before {
    content: "\fd88";
    }
        .fi-tr-user-pen:before {
    content: "\fd89";
    }
        .fi-tr-user-pilot-tie:before {
    content: "\fd8a";
    }
        .fi-tr-user-pilot:before {
    content: "\fd8b";
    }
        .fi-tr-user-police:before {
    content: "\fd8c";
    }
        .fi-tr-user-robot-xmarks:before {
    content: "\fd8d";
    }
        .fi-tr-user-robot:before {
    content: "\fd8e";
    }
        .fi-tr-user-salary:before {
    content: "\fd8f";
    }
        .fi-tr-user-shield:before {
    content: "\fd90";
    }
        .fi-tr-user-skill-gear:before {
    content: "\fd91";
    }
        .fi-tr-user-slash:before {
    content: "\fd92";
    }
        .fi-tr-user-tag:before {
    content: "\fd93";
    }
        .fi-tr-user-time:before {
    content: "\fd94";
    }
        .fi-tr-user-trust:before {
    content: "\fd95";
    }
        .fi-tr-user-unlock:before {
    content: "\fd96";
    }
        .fi-tr-user-visor:before {
    content: "\fd97";
    }
        .fi-tr-user-xmark:before {
    content: "\fd98";
    }
        .fi-tr-users-alt:before {
    content: "\fd99";
    }
        .fi-tr-users-class:before {
    content: "\fd9a";
    }
        .fi-tr-users-gear:before {
    content: "\fd9b";
    }
        .fi-tr-users-medical:before {
    content: "\fd9c";
    }
        .fi-tr-users-slash:before {
    content: "\fd9d";
    }
        .fi-tr-users:before {
    content: "\fd9e";
    }
        .fi-tr-utensils-slash:before {
    content: "\fd9f";
    }
        .fi-tr-utensils:before {
    content: "\fda0";
    }
        .fi-tr-utility-pole-double:before {
    content: "\fda1";
    }
        .fi-tr-utility-pole:before {
    content: "\fda2";
    }
        .fi-tr-ux-browser:before {
    content: "\fda3";
    }
        .fi-tr-ux:before {
    content: "\fda4";
    }
        .fi-tr-v:before {
    content: "\fda5";
    }
        .fi-tr-vacuum-robot:before {
    content: "\fda6";
    }
        .fi-tr-vacuum:before {
    content: "\fda7";
    }
        .fi-tr-value-absolute:before {
    content: "\fda8";
    }
        .fi-tr-vault:before {
    content: "\fda9";
    }
        .fi-tr-vector-circle:before {
    content: "\fdaa";
    }
        .fi-tr-vector-polygon:before {
    content: "\fdab";
    }
        .fi-tr-venus-double:before {
    content: "\fdac";
    }
        .fi-tr-venus-mars:before {
    content: "\fdad";
    }
        .fi-tr-venus:before {
    content: "\fdae";
    }
        .fi-tr-vest-patches:before {
    content: "\fdaf";
    }
        .fi-tr-vest:before {
    content: "\fdb0";
    }
        .fi-tr-video-arrow-down-left:before {
    content: "\fdb1";
    }
        .fi-tr-video-arrow-up-right:before {
    content: "\fdb2";
    }
        .fi-tr-video-camera-alt:before {
    content: "\fdb3";
    }
        .fi-tr-video-camera:before {
    content: "\fdb4";
    }
        .fi-tr-video-duration:before {
    content: "\fdb5";
    }
        .fi-tr-video-plus:before {
    content: "\fdb6";
    }
        .fi-tr-video-slash:before {
    content: "\fdb7";
    }
        .fi-tr-videoconference:before {
    content: "\fdb8";
    }
        .fi-tr-vihara:before {
    content: "\fdb9";
    }
        .fi-tr-violin:before {
    content: "\fdba";
    }
        .fi-tr-virus-slash:before {
    content: "\fdbb";
    }
        .fi-tr-virus:before {
    content: "\fdbc";
    }
        .fi-tr-viruses:before {
    content: "\fdbd";
    }
        .fi-tr-visit:before {
    content: "\fdbe";
    }
        .fi-tr-voicemail:before {
    content: "\fdbf";
    }
        .fi-tr-volcano:before {
    content: "\fdc0";
    }
        .fi-tr-volume-down:before {
    content: "\fdc1";
    }
        .fi-tr-volume-mute:before {
    content: "\fdc2";
    }
        .fi-tr-volume-off:before {
    content: "\fdc3";
    }
        .fi-tr-volume-slash:before {
    content: "\fdc4";
    }
        .fi-tr-vote-nay:before {
    content: "\fdc5";
    }
        .fi-tr-vote-yea:before {
    content: "\fdc6";
    }
        .fi-tr-vr-cardboard:before {
    content: "\fdc7";
    }
        .fi-tr-w:before {
    content: "\fdc8";
    }
        .fi-tr-waffle:before {
    content: "\fdc9";
    }
        .fi-tr-wagon-covered:before {
    content: "\fdca";
    }
        .fi-tr-walker:before {
    content: "\fdcb";
    }
        .fi-tr-walkie-talkie:before {
    content: "\fdcc";
    }
        .fi-tr-walking:before {
    content: "\fdcd";
    }
        .fi-tr-wallet-arrow:before {
    content: "\fdce";
    }
        .fi-tr-wallet-buyer:before {
    content: "\fdcf";
    }
        .fi-tr-wallet:before {
    content: "\fdd0";
    }
        .fi-tr-warehouse-alt:before {
    content: "\fdd1";
    }
        .fi-tr-warranty:before {
    content: "\fdd2";
    }
        .fi-tr-washer:before {
    content: "\fdd3";
    }
        .fi-tr-waste-pollution:before {
    content: "\fdd4";
    }
        .fi-tr-waste:before {
    content: "\fdd5";
    }
        .fi-tr-watch-calculator:before {
    content: "\fdd6";
    }
        .fi-tr-watch-fitness:before {
    content: "\fdd7";
    }
        .fi-tr-watch-smart:before {
    content: "\fdd8";
    }
        .fi-tr-watch:before {
    content: "\fdd9";
    }
        .fi-tr-water-bottle:before {
    content: "\fdda";
    }
        .fi-tr-water-ladder:before {
    content: "\fddb";
    }
        .fi-tr-water-lower:before {
    content: "\fddc";
    }
        .fi-tr-water-rise:before {
    content: "\fddd";
    }
        .fi-tr-water:before {
    content: "\fdde";
    }
        .fi-tr-watermelon:before {
    content: "\fddf";
    }
        .fi-tr-wave-sine:before {
    content: "\fde0";
    }
        .fi-tr-wave-square:before {
    content: "\fde1";
    }
        .fi-tr-wave-triangle:before {
    content: "\fde2";
    }
        .fi-tr-wave:before {
    content: "\fde3";
    }
        .fi-tr-waveform-path:before {
    content: "\fde4";
    }
        .fi-tr-waveform:before {
    content: "\fde5";
    }
        .fi-tr-web-design:before {
    content: "\fde6";
    }
        .fi-tr-web:before {
    content: "\fde7";
    }
        .fi-tr-webcam-slash:before {
    content: "\fde8";
    }
        .fi-tr-webcam:before {
    content: "\fde9";
    }
        .fi-tr-webhook:before {
    content: "\fdea";
    }
        .fi-tr-wednesday:before {
    content: "\fdeb";
    }
        .fi-tr-whale:before {
    content: "\fdec";
    }
        .fi-tr-wheat-awn-circle-exclamation:before {
    content: "\fded";
    }
        .fi-tr-wheat-awn-slash:before {
    content: "\fdee";
    }
        .fi-tr-wheat-awn:before {
    content: "\fdef";
    }
        .fi-tr-wheat-slash:before {
    content: "\fdf0";
    }
        .fi-tr-wheat:before {
    content: "\fdf1";
    }
        .fi-tr-wheelchair-move:before {
    content: "\fdf2";
    }
        .fi-tr-wheelchair-sleeping:before {
    content: "\fdf3";
    }
        .fi-tr-wheelchair:before {
    content: "\fdf4";
    }
        .fi-tr-whistle:before {
    content: "\fdf5";
    }
        .fi-tr-white-space:before {
    content: "\fdf6";
    }
        .fi-tr-wifi-1:before {
    content: "\fdf7";
    }
        .fi-tr-wifi-2:before {
    content: "\fdf8";
    }
        .fi-tr-wifi-alt:before {
    content: "\fdf9";
    }
        .fi-tr-wifi-exclamation:before {
    content: "\fdfa";
    }
        .fi-tr-wifi-slash:before {
    content: "\fdfb";
    }
        .fi-tr-wifi:before {
    content: "\fdfc";
    }
        .fi-tr-wind-turbine:before {
    content: "\fdfd";
    }
        .fi-tr-wind-warning:before {
    content: "\fdfe";
    }
        .fi-tr-wind:before {
    content: "\fdff";
    }
        .fi-tr-window-alt:before {
    content: "\fe10";
    }
        .fi-tr-window-frame-open:before {
    content: "\fe11";
    }
        .fi-tr-window-frame:before {
    content: "\fe12";
    }
        .fi-tr-window-maximize:before {
    content: "\fe13";
    }
        .fi-tr-window-minimize:before {
    content: "\fe14";
    }
        .fi-tr-window-restore:before {
    content: "\fe15";
    }
        .fi-tr-windsock:before {
    content: "\fe16";
    }
        .fi-tr-wine-bottle:before {
    content: "\fe17";
    }
        .fi-tr-wine-glass-crack:before {
    content: "\fe18";
    }
        .fi-tr-wine-glass-empty:before {
    content: "\fe19";
    }
        .fi-tr-wishlist-heart:before {
    content: "\fe1a";
    }
        .fi-tr-wishlist-star:before {
    content: "\fe1b";
    }
        .fi-tr-woman-head:before {
    content: "\fe1c";
    }
        .fi-tr-woman-scientist:before {
    content: "\fe1d";
    }
        .fi-tr-won-sign:before {
    content: "\fe1e";
    }
        .fi-tr-work-in-progress:before {
    content: "\fe1f";
    }
        .fi-tr-workshop:before {
    content: "\fe30";
    }
        .fi-tr-world:before {
    content: "\fe31";
    }
        .fi-tr-worm:before {
    content: "\fe32";
    }
        .fi-tr-wreath:before {
    content: "\fe33";
    }
        .fi-tr-wrench-alt:before {
    content: "\fe34";
    }
        .fi-tr-wrench-simple:before {
    content: "\fe35";
    }
        .fi-tr-x-ray:before {
    content: "\fe36";
    }
        .fi-tr-x:before {
    content: "\fe37";
    }
        .fi-tr-y:before {
    content: "\fe38";
    }
        .fi-tr-yen:before {
    content: "\fe39";
    }
        .fi-tr-yin-yang:before {
    content: "\fe3a";
    }
        .fi-tr-z:before {
    content: "\fe3b";
    }
        .fi-tr-zip-file:before {
    content: "\fe3c";
    }
